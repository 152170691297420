import styled from 'styled-components';
import { DefaultRowForm, ItemForm } from '../../../../../styles/FormGlobal';

export const IfoodConfigurationContainer = styled.div`
  max-width: 720px;
  margin: auto;
`;

export const Row = styled.div`
  ${DefaultRowForm}
`;

export const Item = styled.div`
  ${ItemForm}
`;

export const DivisionTitle = styled.div`
  font-size: 1.3rem;
  margin: 1rem;
`;

export const ButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
`;
