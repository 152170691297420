import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import { rolesReq } from '../../../../../../services/requests';

export const DeleteGroupModal = ({
  showModal,
  closeModal,
  groupSelected,
  reqGetAllGroups,
}: any) => {
  const { t } = useTranslation();

  const [watingReqDeleteGroup, setWatingReqDeleteGroup] = useState(false);

  const reqDeleteGroup = async () => {
    setWatingReqDeleteGroup(true);

    try {
      const { id } = groupSelected;

      const response = await rolesReq.delete(id);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('groupDeleted'),
          type: NotificationTypes.SUCCESS,
        });
      } else {
        throw response;
      }
      setWatingReqDeleteGroup(false);
      reqGetAllGroups();
      closeModal();
    } catch (err) {
      if (!rolesReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWatingReqDeleteGroup(false);
    }
  };

  return (
    <Modal
      title={t('registerUser')}
      showModal={showModal}
      cancelButton={closeModal}
      action={reqDeleteGroup}
      actionNameBtn={t('confirm')}
      closeModal={closeModal}
      isLoading={watingReqDeleteGroup}
    >
      <p>{t('deleteGroupSure')}</p>
      <b>{groupSelected.name.replace(/.+?(=?_)/, '')}</b>
    </Modal>
  );
};

export default DeleteGroupModal;
