import { AxiosError } from 'axios';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import TablePaginator from '../../../../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../../enums/httpStatus';
import i18n from '../../../../../../i18n';
import { DeleteIcon } from '../../../../../../icons';
import { advantageClubAPI, audienceAPI, productAPI } from '../../../../../../services/requests';
import { AdvantageClubRule } from '../../../../../../types';
import {
  SelectOption,
  getOptionsFromAudiences,
  getOptionsFromCalculationUnit,
  getOptionsFromProducts,
} from '../../../../../../utils/getSelectOptions';
import { currentDateMoreDays, getCurrentDateTime } from '../../../../../../utils/helpers';
import * as s from './style';

type Props = {
  closeModal: () => void;
  callback: () => void;
};

const ModalCreateJSX = ({ closeModal, callback }: Props) => {
  const { t } = useTranslation();

  const whoGetsThePointsSelectOptions: SelectOption[] = [
    { label: t('seller'), value: 'seller' },
    { label: t('customer'), value: 'customer' },
    { label: t('all'), value: 'all' },
  ];

  const [waitingReq, setWaitingReq] = useState(false);
  const [waitingGetParticipants] = useState(false);

  const [description, setDescription] = useState('');

  const [initialDate, setInitialDate] = useState(
    new Date(currentDateMoreDays(1)).toISOString().slice(0, 16),
  );
  const [finalDate, setFinalDate] = useState(
    new Date(currentDateMoreDays(31)).toISOString().slice(0, 16),
  );
  const [daysToExpiryPoints, setDaysToExpiryPoints] = useState(365);
  const [audience, setAudience] = useState<SelectOption>();
  const [potentiation, setPotentiation] = useState(1);
  const [whoGetsThePoints, setWhoGetsThePoints] = useState(whoGetsThePointsSelectOptions[0]);

  const [product, setProduct] = useState<SelectOption | null>(null);
  const [calculcationUnit, setCalculcationUnit] = useState(getOptionsFromCalculationUnit()[0]);
  const [value, setValue] = useState<number>(0);
  const [points, setPoints] = useState<number>(0);

  const [rules, setRules] = useState<AdvantageClubRule[]>([]);

  const reqCreateValidtion = (): boolean => {
    if (description.length <= 0) {
      frontendNotification({
        message: t('invalidDescription'),
        type: 'warning',
      });
      return false;
    }

    if (rules.length < 1) {
      frontendNotification({
        message: t('addAtLeastOneRule'),
        type: 'warning',
      });
      return false;
    }

    return true;
  };

  const reqCreate = async () => {
    if (!reqCreateValidtion()) {
      return () => null;
    }

    setWaitingReq(true);

    const source = advantageClubAPI.axios.CancelToken.source();

    try {
      advantageClubAPI.query = '';
      const res = await advantageClubAPI.store({
        active: false,
        description,
        initialDate,
        finalDate,
        daysToExpiryPoints,
        audienceId: audience?.value ?? null,
        potentiation,
        whoGetsThePoints: whoGetsThePoints.value,
        rules,
      });

      if (res.status === HttpStatus.OK) {
        setWaitingReq(false);
        frontendNotification({
          message: t('successfullyCreated'),
          type: 'success',
        });

        closeModal();
        callback();
      } else {
        throw res;
      }

      setWaitingReq(false);
    } catch (err) {
      if (!advantageClubAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingReq(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  const addRuleValidation = (): boolean => {
    // if (product === null) {
    //     frontendNotification({ message: t('selectLeastOneProduct'), type: 'warning' });
    //     return false;
    // }

    if (rules.some((rule) => rule.productCode === product?.value)) {
      frontendNotification({ message: t('thisRuleHasAlreadyBeenAdded'), type: 'warning' });
      return false;
    }

    if (value <= 0) {
      frontendNotification({ message: t('enterAValue'), type: 'warning' });
      return false;
    }

    if (points <= 0) {
      frontendNotification({ message: t('enterTheAmountOfPoints'), type: 'warning' });
      return false;
    }

    return true;
  };

  const handleAddBtn = () => {
    if (addRuleValidation()) {
      const copyRules: AdvantageClubRule[] = [...rules];
      copyRules.push({
        productCode: product ? (product.value as string) : null,
        description: product ? (product.label as string) : null,
        calculationUnit: calculcationUnit.value,
        value,
        points,
      });
      setRules(copyRules);
      setProduct(null);
    }
  };

  const removeParticipant = (roleSelected: AdvantageClubRule) => {
    const copyRules = rules.filter((rule) => rule.productCode !== roleSelected.productCode);
    setRules(copyRules);
  };

  const actionsButtons = (row: Row<AdvantageClubRule>) => (
    <ActionButtonsContainer type={1}>
      <DeleteIcon size="1.5rem" title={t('edit')} onClick={() => removeParticipant(row.original)} />
    </ActionButtonsContainer>
  );

  const handleProductColumn = (row: Row<AdvantageClubRule>) => {
    let text = t('generalRule');

    if (row.original.productCode) {
      text = row.original.productCode;
      if (row.original.description) {
        text = row.original.description;
      }
    }

    return <p>{text}</p>;
  };

  const handleCalculationUnitCokumn = (row: Row<AdvantageClubRule>) => (
    <p>{t(row.original.calculationUnit)}</p>
  );

  const columns: Column<AdvantageClubRule>[] = useMemo(
    () => [
      {
        Header: t('product') as string,
        Cell: ({ row }: any) => handleProductColumn(row),
      },
      {
        Header: t('calculationUnit') as string,
        Cell: ({ row }: any) => handleCalculationUnitCokumn(row),
      },
      {
        Header: t('value') as string,
        accessor: 'value',
      },
      {
        Header: t('points') as string,
        accessor: 'points',
      },
      {
        id: 'actions',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language, rules],
  );

  return (
    <Modal
      title={`${t('new')} ${t('advantageClub')}`}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => reqCreate()}
      isLoading={waitingReq || waitingGetParticipants}
      bodyStyle={{ overflowX: 'scroll', width: '100%' }}
      style={{ width: '100%' }}
    >
      <s.ContainerModalCreate>
        <s.Line className="first-line">
          <s.Item>
            <p>{t('description')}</p>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              disabled={waitingReq}
            />
          </s.Item>

          <s.Item style={{ maxWidth: '200px' }}>
            <p>{t('initialDate')}</p>
            <input
              className="input"
              type="datetime-local"
              value={initialDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setInitialDate(e.target.value)}
              min={getCurrentDateTime()}
              disabled={waitingReq}
            />
          </s.Item>

          <s.Item style={{ maxWidth: '200px' }}>
            <p>{t('finalDate')}</p>
            <input
              className="input"
              type="datetime-local"
              value={finalDate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFinalDate(e.target.value)}
              min={getCurrentDateTime()}
              disabled={waitingReq}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <p>{`${t('expirationPoints')} (${t('days')})`}</p>
            <input
              className="input"
              value={daysToExpiryPoints}
              onChange={(e: any) => setDaysToExpiryPoints(e.target.value)}
              type="number"
              min={0}
              disabled={waitingReq}
            />
          </s.Item>

          <s.Item>
            <p>{t('potentiation')}</p>
            <input
              className="input"
              value={potentiation}
              onChange={(e: any) => setPotentiation(e.target.value)}
              type="number"
              min={0}
              disabled={waitingReq}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <p>{t('audience')}</p>
            <SelectAsyncJSX
              value={audience}
              onChange={setAudience}
              request={audienceAPI}
              reqResponseToOption={getOptionsFromAudiences}
              disabled={waitingReq}
            />
          </s.Item>

          <s.Item>
            <p>{t('whoGetsThePoints')}</p>
            <SingleSelect
              placeholder={t('whoGetsThePoints') as string}
              options={whoGetsThePointsSelectOptions}
              value={whoGetsThePoints}
              onChange={(e) => setWhoGetsThePoints(e)}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <div className="division" />
        </s.Line>

        <s.Line>
          <s.Item>
            <p>{t('product')}</p>
            <SelectAsyncJSX
              value={product}
              onChange={setProduct}
              request={productAPI}
              reqResponseToOption={getOptionsFromProducts}
              disabled={waitingReq}
              placeholder={t('product')}
            />
          </s.Item>

          <s.Item style={{ maxWidth: '120px' }}>
            <p>{t('calculationUnit')}</p>
            <SingleSelect
              placeholder={t('calculationUnit') as string}
              options={getOptionsFromCalculationUnit()}
              value={calculcationUnit}
              onChange={(e) => setCalculcationUnit(e)}
            />
          </s.Item>

          <s.Item style={{ maxWidth: '70px' }}>
            <p>{t('value')}</p>
            <input
              className="input"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              type="number"
              min={1}
            />
          </s.Item>

          <s.Item style={{ maxWidth: '70px' }}>
            <p>{t('points')}</p>
            <input
              className="input"
              value={points}
              onChange={(e: any) => setPoints(e.target.value)}
              type="number"
              min={1}
            />
          </s.Item>

          <s.Item className="add-btn">
            <AddBtn onClick={() => handleAddBtn()} />
          </s.Item>
        </s.Line>

        <s.Line>
          <TablePaginator
            data={rules}
            columns={columns}
            globalFiltering
            style={{ width: '100%', marginTop: '2rem' }}
          />
        </s.Line>
      </s.ContainerModalCreate>
    </Modal>
  );
};

export default ModalCreateJSX;
