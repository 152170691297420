import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';
import { backendErrorNotification } from '../../../../../../components/Notification';
import TextareaJSX from '../../../../../../components/TextareaJSX';
import HttpStatus from '../../../../../../enums/httpStatus';
import { templateParametersReq } from '../../../../../../services/requests';
import { Template, TemplateParameter } from '../../../../../../types/apiResponse/template';

type Props = {
  template: Template;
  closeModal: () => void;
};

const SeeEmailTemplate = ({ template, closeModal }: Props) => {
  const [waitingGetParameters, setWaitingGetParameters] = useState(false);
  const [content, setContent] = useState('');

  const reqGetEmailTemplates = useCallback(async () => {
    setWaitingGetParameters(true);

    const source = templateParametersReq.axios.CancelToken.source();

    try {
      const res = await templateParametersReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        const { data }: { data: TemplateParameter[] | [] } = res.data;
        if (data.length >= 1) {
          let copyHtml = template.content;
          let copySubject = template.subject ?? '';

          data.forEach((parameter) => {
            const key = `\${${parameter.name}}`;
            copyHtml = copyHtml.replaceAll(key, parameter.example);
            copySubject = copySubject.replaceAll(key, parameter.example);
          });

          setContent(copyHtml);
        }
      } else {
        throw res;
      }

      setWaitingGetParameters(false);
    } catch (err) {
      if (!templateParametersReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingGetParameters(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  }, []);

  const handleContentPreview = () => {
    if (template.type === 'html') {
      return (
        <>
          <h1 style={{ marginBottom: '1rem' }}>{`${t('subject')}: ${template.subject}`}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </>
      );
    }

    return (
      <TextareaJSX
        value={content}
        language="json"
        placeholder={t('typeJsxHtmlCode')}
        onChange={() => null}
      />
    );
  };

  useEffect(() => {
    reqGetEmailTemplates();
    setContent(template.content);
  }, []);

  const handleCloseBtn = () => {
    setContent('');
    closeModal();
  };

  return (
    <Modal
      title={template.name}
      showModal
      closeModal={handleCloseBtn}
      onlyCloseButton
      bodyStyle={{ overflowX: 'scroll' }}
    >
      {waitingGetParameters ? <p>{`${t('loading')}...`}</p> : handleContentPreview()}
    </Modal>
  );
};

export default SeeEmailTemplate;
