import styled from 'styled-components';

interface SiderbarOpen {
  isSidebarOpen?: boolean;
}

export const MobileBarContainer = styled.div<SiderbarOpen>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  width: 40px;
  height: 40px;
  background: ${(p) => p.theme.colors.surface};

  display: flex;
  justify-content: center;
  align-items: center;

  /* padding: 0.7rem; */

  cursor: pointer;

  > * {
    margin: auto;
    width: 3rem;
    height: 3rem;

    color: ${(p) => p.theme.colors.onSurface};
  }
`;

export default MobileBarContainer;
