import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../../components/Notification';
import HttpStatus from '../../../../enums/httpStatus';
import { advantageClubHistoryGET } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { ApiResPaginated, AdvantageClubHistory as MainCompType } from '../../../../types';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import { fullDateToBr, numberFormat } from '../../../../utils/helpers';

const AdvantageClubHistoryJSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [advantageClubHistoric, setAdvantageClubHistoric] = useState<MainCompType[]>([]);

  const getHistoric: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = advantageClubHistoryGET.axios.CancelToken.source();

    try {
      advantageClubHistoryGET.query = `?search=${search}&page=${page}`;
      advantageClubHistoryGET.query += `&quantityPerPage=${quantityPerPage}`;
      advantageClubHistoryGET.query += '&withRules=true';
      const res = await advantageClubHistoryGET.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<MainCompType[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!advantageClubHistoryGET.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleRecipientTypeColumn = (row: MainCompType) => <p>{t(row.recipientType)}</p>;

  const handleTypeColumn = (row: MainCompType) => {
    let color = theme.colors.positive;

    if (row.type == 'debit') {
      color = theme.colors.negative;
    }

    return <p style={{ color }}>{t(row.type)}</p>;
  };

  const handleDueDateColumn = (row: MainCompType) =>
    row.pointsExpirationDate ? <p>{fullDateToBr(row.pointsExpirationDate, true)}</p> : <div />;

  const handleProcessDateColumn = (row: MainCompType) => (
    <p>{fullDateToBr(row.created_at, true)}</p>
  );

  const columns: Column<MainCompType>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('clubId') as string,
      accessor: 'advantageClubId',
    },
    {
      label: t('description') as string,
      accessor: 'description',
    },
    {
      label: t('orderNumber') as string,
      accessor: 'orderNumber',
    },
    {
      label: t('recipientType') as string,
      Cell: handleRecipientTypeColumn,
    },
    {
      label: t('recipient') as string,
      accessor: 'recipient',
    },
    {
      label: t('type') as string,
      accessor: 'type',
      Cell: handleTypeColumn,
    },
    {
      label: t('points') as string,
      accessor: 'points',
      Cell: (row) => numberFormat(row.points),
    },
    {
      label: t('dueDate') as string,
      Cell: handleDueDateColumn,
    },
    {
      label: t('processingDate') as string,
      Cell: handleProcessDateColumn,
    },
  ];

  return (
    <ContentContainer title={t('advantageClubHistory')}>
      <AsyncTable
        tableName={t('advantageClubHistory')}
        columns={columns}
        value={advantageClubHistoric}
        onChange={setAdvantageClubHistoric}
        requestPage={getHistoric}
        reqListener={[]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
        }}
      />
    </ContentContainer>
  );
};

export default AdvantageClubHistoryJSX;
