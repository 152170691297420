import styled from 'styled-components';

export const MainContainer = styled.div``;

export const MainViewContainer = styled.div`
  flex-grow: 1;
  max-width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 0 !important;

  overflow: auto;
  scrollbar-width: thin;

  @media (min-width: 1024px) {
    padding-top: 1rem;
  }
`;

export default MainViewContainer;
