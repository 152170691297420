import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { createUsersFromSellersAPI, tenantRolesGET } from '../../../../../services/requests';
import { Role } from '../../../../../types';
import { getOptionsFromRoles, SelectOption } from '../../../../../utils/getSelectOptions';
import * as tp from '../../types';

type Props = ModalProps & {
  tenantSelected: tp.Tenant;
  reqGetTenants: () => void;
  closeModal: () => void;
};

export const CreateUsersFromSellersJobJSX = ({
  tenantSelected,
  reqGetTenants,
  closeModal,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<SelectOption | null>(null);

  const getRoles = async () => {
    setIsLoading(true);
    try {
      const res = await tenantRolesGET.show(tenantSelected.id);

      if (res.status === HttpStatus.OK) {
        setRoles(res.data.data);
      } else {
        throw res;
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const createUsersAction = async () => {
    setIsLoading(true);
    const source = createUsersFromSellersAPI.axios.CancelToken.source();
    try {
      createUsersFromSellersAPI.query = `/${tenantSelected.id}`;
      const res = await createUsersFromSellersAPI.store({
        roleId: selectedRole?.value,
      });

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: res.data.message,
          type: 'success',
        });

        reqGetTenants();
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      if (!createUsersFromSellersAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
    return () => source.cancel('Component nmounted');
  };

  return (
    <Modal
      {...props}
      closeModal={closeModal}
      action={() => createUsersAction()}
      isLoading={isLoading}
    >
      <p>{t('selectPermissionGroup')}</p>
      <SingleSelect
        value={selectedRole}
        options={getOptionsFromRoles(roles)}
        onChange={(e) => setSelectedRole(e)}
        disabled={isLoading}
      />
    </Modal>
  );
};

export default CreateUsersFromSellersJobJSX;
