import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../../components/Notification';
import HttpStatus from '../../../../../../../enums/httpStatus';
import { motivesReq } from '../../../../../../../services/requests';
import * as TP from '../../../../../../../types/apiResponseTypes';

type Props = {
  closeModal: () => void;
  reqGetAllMotives: () => void;
  motiveSelected: TP.Motive;
};

const DeleteMotive = ({ closeModal, reqGetAllMotives, motiveSelected }: Props) => {
  const { t } = useTranslation();

  const [waitingCreateMotives, setWaitingCreateMotive] = useState(false);

  const reqDeleteMotive = async () => {
    setWaitingCreateMotive(true);

    const source = motivesReq.axios.CancelToken.source();

    try {
      const res = await motivesReq.delete(motiveSelected.id);

      if (res.status === HttpStatus.OK) {
        setWaitingCreateMotive(false);
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        closeModal();
        reqGetAllMotives();
      } else {
        throw res;
      }

      setWaitingCreateMotive(false);
    } catch (err) {
      if (!motivesReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingCreateMotive(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={`${t('delete')} ${t('motive')}`}
      showModal
      closeModal={closeModal}
      action={() => reqDeleteMotive()}
      actionNameBtn={t('delete')}
      isLoading={waitingCreateMotives}
    >
      <p>
        {`${t('deleteSure')}?`}
        <br />
        <br />
        <b>{t('description')}:</b> {motiveSelected.description}
      </p>
    </Modal>
  );
};

export default DeleteMotive;
