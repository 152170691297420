import React from 'react';
import { useTranslation } from 'react-i18next';
import * as s from './styles';

export type BtnType = 'text' | 'outlined' | 'contained' | 'link';

interface Button {
  onClick?: () => void;
  type?: BtnType;
  Icon?: any;
  text?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const Btn = ({
  text,
  Icon,
  onClick,
  type = 'contained',
  disabled = false,
  style,
  className,
}: Button) => {
  const { t } = useTranslation();

  const handleDisabled = () => {
    if (disabled) {
      return () => null;
    }
    return onClick;
  };

  return (
    <s.ButtonContainer onClick={handleDisabled()} style={style}>
      <s.Btn className={`${className} ${type}`} disabled={disabled}>
        {Icon && (
          <s.Icon>
            <Icon />
          </s.Icon>
        )}
        {text ? <p>{text}</p> : <p>{t('button')}</p>}
      </s.Btn>
    </s.ButtonContainer>
  );
};

Btn.defaultProps = {
  onClick: () => null,
  type: 'contained',
  Icon: null,
  text: 'button',
  disabled: false,
  style: {},
  className: '',
};

export default Btn;
