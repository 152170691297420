import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../store';
import { logoutRequest, refreshSuccess } from '../store/modules/auth/actions';
import { auth } from './endpoints';

export const getAccessToken = () => {
  const { token } = store.getState().auth;
  return token;
};

export function refreshToken() {
  const options: AxiosRequestConfig = {
    headers: {
      Authorization: getAccessToken() ?? 'token error',
    },
  };
  return axios.post(process.env.REACT_APP_API + auth['/refresh'], [], options);
}

export function setAccessToken(newToken: string) {
  store.dispatch(refreshSuccess({ newToken }));
}

export const logoutSystem = () => {
  store.dispatch(logoutRequest());
};
