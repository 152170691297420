import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import HttpStatus from '../../../../../enums/httpStatus';
import {
  customersApi,
  ediPaymentConditionsUpsertApi,
  paymentConditionApi,
  paymentMethodApi,
} from '../../../../../services/requests';
import { EdiPaymentCondition } from '../../../../../types/apiResponse/edi';
import {
  SelectOption,
  getOptionsFromCustomers,
  getOptionsFromPaymentCondition,
  getOptionsFromPaymentMethod,
} from '../../../../../utils/getSelectOptions';

type Props = {
  onClose: () => void;
  callback: () => void;
  initialData: EdiPaymentCondition[];
};

const UpdateOrInsertEdiPaymentConditionsTSX = ({ onClose, callback, initialData }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const [days, setDays] = useState('0');
  const [paymentConditionOption, setPaymentConditionOption] = useState<SelectOption | null>(null);
  const [paymentMethodOption, setPaymentMethodOption] = useState<SelectOption | null>(null);
  const [selectedCustomers, setSelectedCustomers] = useState<SelectOption[] | null>(null);

  useEffect(() => {
    if (initialData.length > 0) {
      setDays(`${initialData[0].number_of_days}`);

      setPaymentConditionOption(() => {
        let label = `${initialData[0].payment_condition_code}`;
        label += initialData[0].payment_condition
          ? ` - ${initialData[0].payment_condition.description}`
          : ` - ${t('notFound')}`;

        return {
          label: label,
          value: initialData[0].payment_condition_code,
        };
      });

      setPaymentMethodOption(() => {
        let label = `${initialData[0].payment_method_code}`;
        label += initialData[0].payment_method
          ? ` - ${initialData[0].payment_method.description}`
          : ` - ${t('notFound')}`;

        return {
          label: label,
          value: initialData[0].payment_method_code as string,
        };
      });

      setSelectedCustomers(
        initialData.map((item) => {
          const label = item.customer
            ? `${item.customer.code} - ${item.customer.name}`
            : `${item.customer_code} - ${t('notFound')}`;

          return {
            label: label,
            value: item.customer_code,
          };
        }),
      );
    }
  }, []);

  const onSaveBtn = useCallback(async () => {
    setIsLoading(true);

    const source = ediPaymentConditionsUpsertApi.axios.CancelToken.source();

    try {
      if (!selectedCustomers) {
        throw new Error(t('selectTheCustomers'));
      }
      if (!paymentConditionOption) {
        throw new Error(t('selectThePaymentCondition'));
      }

      ediPaymentConditionsUpsertApi.query = '';
      const res = await ediPaymentConditionsUpsertApi.store({
        numberOfDays: days,
        paymentConditionCode: paymentConditionOption.value,
        paymentMethodCode: paymentMethodOption ? paymentMethodOption.value : '0',
        customerCodes: selectedCustomers.map((items) => items.value),
      });

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: 'success',
        });

        callback();
        onClose();
      } else {
        throw res;
      }
    } catch (err) {
      if (!ediPaymentConditionsUpsertApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);

    return () => source.cancel('Component Roles got unmounted');
  }, [initialData, selectedCustomers, days, paymentConditionOption, paymentMethodOption]);

  return (
    <Modal
      title={t('toAddOrToEdit')}
      action={onSaveBtn}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={t('save')}
      isLoading={isLoading}
    >
      <div className="row">
        <div className="inputWithLabel">
          <div className="label">{t('numberOfDays') as string}</div>
          <input
            value={days}
            onChange={(e) => setDays(e.target.value)}
            type="number"
            placeholder={t('numberOfDays')}
            disabled={isLoading}
          />
        </div>
        <div className="inputWithLabel">
          <div className="label">{t('paymentCondition') as string}</div>
          <SelectAsyncJSX
            value={paymentConditionOption}
            onChange={setPaymentConditionOption}
            request={paymentConditionApi}
            reqResponseToOption={getOptionsFromPaymentCondition}
            placeholder={t('paymentCondition')}
            disabled={isLoading}
          />
        </div>
        <div className="inputWithLabel">
          <div className="label">{t('paymentMethod') as string}</div>
          <SelectAsyncJSX
            value={paymentMethodOption}
            onChange={setPaymentMethodOption}
            request={paymentMethodApi}
            reqResponseToOption={getOptionsFromPaymentMethod}
            placeholder={t('paymentMethod')}
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="row">
        <div className="inputWithLabel">
          <div className="label">{t('customer') as string}</div>
          <SelectAsyncJSX
            value={selectedCustomers}
            onChange={setSelectedCustomers}
            request={customersApi}
            reqResponseToOption={getOptionsFromCustomers}
            placeholder={t('customer')}
            disabled={isLoading}
            isMulti
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateOrInsertEdiPaymentConditionsTSX;
