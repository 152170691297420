import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../../components/NoData';
import { backendErrorNotification } from '../../../../components/Notification';
import TablePaginator from '../../../../components/TablePaginator';
import {
  ActionButtonsContainer,
  DeleteIconTable,
  EditIconTable,
  ViewIconTable,
} from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import i18n from '../../../../i18n';
import { templatesReq as reqTemplates } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import Loader from './Loader';
import * as s from './styles';

import { Template } from '../../../../types/apiResponse/template';
import CreateAndUpdateTemplate from './Components/ModalCreateAndUpdate';
import DeleteTemplate from './Components/ModalDelete';
import SeeTemplate from './Components/SeeTemplate';

const TemplateJSX = () => {
  const { t } = useTranslation();

  const [waitingGetTemplates, setWaitingGetTemplates] = useState(false);
  const [templates, setTemplates] = useState<Template[] | []>([]);
  const [templateSelected, setTemplateSelected] = useState<Template | null>(null);

  const [showSeeModal, setShowSeeModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const reqGetTemplates = useCallback(async () => {
    setWaitingGetTemplates(true);

    const source = reqTemplates.axios.CancelToken.source();
    reqTemplates.query = '';
    reqTemplates.query = '?quantityPerPage=1000000';

    try {
      const res = await reqTemplates.index(source.token);

      if (res.status === HttpStatus.OK) {
        const { data }: { data: Template[] | [] } = res.data;
        if (data.length >= 1) {
          setTemplates(data);
        }
      } else {
        setTemplates([]);
        throw res;
      }

      setWaitingGetTemplates(false);
    } catch (err) {
      if (!reqTemplates.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingGetTemplates(false);
    }

    return () => {
      source.cancel('Component Integrations Logs got unmounted');
    };
  }, []);

  useEffect(() => {
    reqGetTemplates();
  }, []);

  const handleViewTemplateBtn = (row: Row<Template>) => {
    setTemplateSelected(row.original);
    setShowSeeModal(true);
  };

  const handleTableEditBtn = (row: Row<Template>) => {
    setTemplateSelected(row.original);
    setShowCreateModal(true);
  };
  const handleTableDeleteBtn = (row: Row<Template>) => {
    setTemplateSelected(row.original);
    setShowDeleteModal(true);
  };

  const onCloseModals = () => {
    setTemplateSelected(null);
    setShowCreateModal(false);
    setShowSeeModal(false);
    setShowDeleteModal(false);
  };

  const actionsButtons = (row: Row<Template>) => (
    <ActionButtonsContainer>
      <ViewIconTable onClick={() => handleViewTemplateBtn(row)} />
      <EditIconTable onClick={() => handleTableEditBtn(row)} />
      <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<Template>[] = useMemo(
    () => [
      {
        Header: t('name') as string,
        accessor: 'name',
      },
      {
        Header: t('description') as string,
        accessor: 'description',
      },
      {
        Header: t('type') as string,
        accessor: 'type',
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('templates')}>
      <s.ContainerTemplate>
        <NewRegisterBtn
          onClick={() => setShowCreateModal(true)}
          disabled={waitingGetTemplates}
          style={{ marginLeft: '0', marginBottom: '1rem' }}
        />

        {showCreateModal && (
          <CreateAndUpdateTemplate
            closeModal={() => onCloseModals()}
            reqGetAllTemplates={() => reqGetTemplates()}
            template={templateSelected ?? undefined}
          />
        )}

        {showSeeModal && (
          <SeeTemplate template={templateSelected as Template} closeModal={() => onCloseModals()} />
        )}

        {showDeleteModal && (
          <DeleteTemplate
            template={templateSelected as Template}
            closeModal={() => onCloseModals()}
            reqGetAllTemplates={() => reqGetTemplates()}
          />
        )}

        <Loader isLoading={waitingGetTemplates}>
          {templates.length >= 1 ? (
            <TablePaginator data={templates} columns={columns} globalFiltering />
          ) : (
            <NoData />
          )}
        </Loader>
      </s.ContainerTemplate>
    </ContentContainer>
  );
};

export default TemplateJSX;
