import styled from 'styled-components';
import { defaultShadow } from '../../../../../../../../styles/global';

export interface CardContainerProps {
  background?: string;
}

export const CardContainer = styled.div<CardContainerProps>`
  color: ${(p) => p.theme.colors.onSurface};

  flex: 1;
  height: 6rem;
  min-width: 320px;
  display: flex;

  margin: 1rem;
  margin-top: 0;
  border: none;
  border-radius: 0.75rem;

  ${defaultShadow}
  font-weight: 600;

  &::before {
    content: '';
    width: 10px;
    background: ${(p) => p.background};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .card-icon-1354 {
    background-color: ${(p) => p.background}50;
    width: 4rem;
    height: 4rem;

    margin: auto;
    border-radius: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      color: ${(p) => p.background};
      size: 50px;
      font-size: 2rem;
    }
  }

  .card-info-1354 {
    width: 60%;
    height: 4rem;

    margin: auto;
    box-sizing: border-box;

    > p {
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
`;

export default CardContainer;
