// import React from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import HttpStatus from '../../enums/httpStatus';
import { NotificationTypes } from './notificationEnums';

export type Props = {
  message: string;
  type: 'success' | 'warning' | 'error' | 'info';
};

export const frontendNotification = ({ message, type }: Props) => {
  if (type === NotificationTypes.SUCCESS) {
    toast.success(message);
  } else if (type === NotificationTypes.WARNING) {
    toast.warning(message);
  } else if (type === NotificationTypes.ERROR) {
    toast.error(message);
  } else if (type === NotificationTypes.INFO) {
    toast.info(message);
  }
};

export const backendErrorNotification = (error: AxiosError) => {
  if (error.response) {
    const { response } = error;
    const { data } = response;

    if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      if (data.message) {
        toast.error(data.message);
      }

      if (data.errors) {
        const errosTypes = Object.values(data.errors) as Array<string[]>;

        errosTypes.forEach((v) => {
          let validationError = v[0];
          if (validationError === 'Disabled entity found with this email.') {
            validationError =
              // eslint-disable-next-line max-len
              'Um usuário deletado foi encontrado com esse e-mail, contacte o administrador para reativá-lo!';
          }
          if (validationError === 'The name has already been taken.') {
            validationError = 'O nome já existe!';
          }
          toast.warn(validationError);
        });
      }
    } else if (data && data.message !== null) {
      toast.error(data.message);
    }
  } else {
    toast.error(error.message);
  }
};
