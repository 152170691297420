import { t } from 'i18next';
import React from 'react';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import { DeleteIcon } from '../../../../../icons';
import { ExporterFixedKeysForApi } from '../../../../../types/apiResponse/exporter';
import { FixedKeysTable } from './styles';

interface TableProps {
  data: ExporterFixedKeysForApi[] | null;
  onDelete: (index: number) => void;
}

const ExporterFixedKeysTable: React.FC<TableProps> = ({ data, onDelete }) => {
  if (data == null) return <div />;

  return (
    <FixedKeysTable>
      <thead>
        <tr>
          <th>{t('keyName') as string}</th>
          <th>{t('value') as string}</th>
          <th style={{ minWidth: '60px' }}>{t('sentIn') as string}</th>
          <th>{t('actions') as string}</th>
        </tr>
      </thead>
      <tbody>
        {data ? (
          data.map((item, index) => (
            <tr key={item.key}>
              <td>{item.key}</td>
              <td>{item.value}</td>
              <td>{item.sendBy}</td>
              <td className="actionCell">
                <ActionButtonsContainer type={1}>
                  <DeleteIcon title={t('delete')} onClick={() => onDelete(index)} />
                </ActionButtonsContainer>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={4}>Tabela vazia</td>
          </tr>
        )}
      </tbody>
    </FixedKeysTable>
  );
};

export default ExporterFixedKeysTable;
