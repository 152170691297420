import { PropsWithChildren } from 'react';
import TableSkeleton from '../../../../../../../../components/TableSkeletonLoader';
import * as s from './style';

interface LoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
  !isLoading ? (
    <div>{children}</div>
  ) : (
    <s.Container>
      <s.GraphArea>
        <s.Graph id="ldg1" />
      </s.GraphArea>

      <s.CategoryTableArea>
        <TableSkeleton isSearch numberOfLines={3} />
      </s.CategoryTableArea>

      <s.TableArea>
        <TableSkeleton isSearch isBottons />
      </s.TableArea>
    </s.Container>
  );

export default Loader;
