import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import themes, { ThemesTypes } from '../styles/themes';

import 'react-toastify/dist/ReactToastify.css';
import Routes from '../routes';
import { StoreState } from '../store/createStore';

const App = () => {
  const { theme } = useSelector((state: StoreState) => state.theme);

  return (
    <ThemeProvider theme={themes[theme as keyof ThemesTypes]}>
      <div id="App">
        <BrowserRouter>
          <GlobalStyle />
          <Routes />
          <ToastContainer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
