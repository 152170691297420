import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

const style: CSSProperties = {
  width: '100%',
  textAlign: 'center',
  fontSize: '1.5rem',
  opacity: '0.5',
  margin: '1rem',
};

export const NoData = () => {
  const { t } = useTranslation();
  return <h3 style={style}>{t('noData')}</h3>;
};

export default NoData;
