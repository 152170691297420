import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Input from '../../../../../../components/Input';
import Modal, { ModalProps } from '../../../../../../components/Modal';
// import SelectDateTime from '../../../../../../components/SelectDateAndTime';
import SelectImageWithCropper from '../../../../../../components/SelectImgWithCropper';
import SingleSelect from '../../../../../../components/SingleSelect';
import { currentDateWithTimeString } from '../../../../../../utils/helpers';
import * as s from './styles';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { smartbannersReq } from '../../../../../../services/requests';

type Props = ModalProps & {
  reqGetAllBanners: () => void;
  closeModal: () => void;
};

export const AddBannerModal = ({ reqGetAllBanners, closeModal, ...props }: Props) => {
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState(currentDateWithTimeString());
  const [show, setShow] = useState({ value: 1, label: t('show') });
  const [sortNumber, setSortNumber] = useState({ value: 1, label: '1' });
  const [description, setDescription] = useState('');

  const [awaitReqSaveBanner, setAwaitReqSaveBanner] = useState(false);

  const reqSaveSmartbanner = async () => {
    setAwaitReqSaveBanner(true);
    try {
      const data = {
        image,
        link,
        expire_in: date.replace('T', ' '),
        show: show.value,
        sort_number: sortNumber.value,
        description,
      };

      const response = await smartbannersReq.store(data);

      if (response.status === HttpStatus.CREATED) {
        frontendNotification({
          message: t('successfullyCreated'),
          type: 'success',
        });

        reqGetAllBanners();

        setImage('');
        setLink('');
        setDate(currentDateWithTimeString());
        setShow({
          value: 1,
          label: t('show'),
        });
        setSortNumber({
          value: 1,
          label: '1',
        });
        setDescription('');

        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!smartbannersReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqSaveBanner(false);
  };

  return (
    <Modal
      {...props}
      action={() => reqSaveSmartbanner()}
      isLoading={awaitReqSaveBanner}
      closeModal={closeModal}
      bodyStyle={{ overflowX: 'scroll' }}
    >
      <s.AddBannerContainer>
        <s.Row>
          <s.Item>
            <b>{`${t('maxImaSizeAndResolution')}: 1mb / 1280 x 720`}</b>
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <SelectImageWithCropper getImage={(i) => setImage(i)} requiredCrop />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item className="img-area">
            <img alt={description} src={image} style={{ maxWidth: '100%', minWidth: '100%' }} />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('link') as string}</p>
            <Input
              value={link}
              onChange={(v) => setLink(v.currentTarget.value)}
              disabled={awaitReqSaveBanner}
            />
          </s.Item>
          <s.Item>
            <p>{t('expirationDate') as string}</p>
            {/* <SelectDateTime
                            // eslint-disable-next-line no-console
                            getDate={(e: any) => console.log(e)}
                            getValue={(e: any) => setDate(e)}
                            date={date}
                            disabled={awaitReqSaveBanner}
                        /> */}
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('show') as string}</p>
            <SingleSelect
              placeholder={t('show') as string}
              options={[
                { value: 1, label: t('show') },
                { value: 0, label: t('notShow') },
              ]}
              onChange={(v: any) => setShow(v)}
              value={show}
              isDisabled={awaitReqSaveBanner}
            />
          </s.Item>
          <s.Item>
            <p>{t('displayOrder') as string}</p>
            <SingleSelect
              placeholder={t('displayOrder') as string}
              options={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 0, label: t('notDisplay') },
              ]}
              onChange={(v: any) => setSortNumber(v)}
              value={sortNumber}
              isDisabled={awaitReqSaveBanner}
            />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('description') as string}</p>
            <Input
              value={description}
              onChange={(v) => setDescription(v.currentTarget.value)}
              disabled={awaitReqSaveBanner}
            />
          </s.Item>
        </s.Row>
      </s.AddBannerContainer>
    </Modal>
  );
};

export default AddBannerModal;
