import styled from 'styled-components';

export const FixedKeysTable = styled.table`
  width: 100%;

  border-collapse: collapse;
  width: 100%;

  color: ${(p) => p.theme.colors.onSurface};

  th,
  td {
    border: 1px solid ${(p) => p.theme.colors.text}50;
    padding: 0.5rem;
  }

  .actionCell {
    text-align: center;
  }
`;

export default FixedKeysTable;
