import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../components/Notification';
import HttpStatus from '../../../enums/httpStatus';
import { ediCustomersApi } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { ApiResPaginated } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import Btn from '../../../components/Buttons/Btn';
import { EdiCustomer } from '../../../types/apiResponse/edi';
import AddCustomersTSX from './Components/AddCustomers';
import RemoveCustomersTSX from './Components/RemoveCustomers';

const EdiCustomerTSX = () => {
  const { t } = useTranslation();

  const [isLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);
  const [jobHistories, setJobHistories] = useState<EdiCustomer[]>([]);
  const theme = useTheme();

  const [showAddCustomersModal, setShowAddCustomersModal] = useState(false);
  const [showRemoveCustomersModal, setShowRemoveCustomersModal] = useState(false);

  const [selectedCustomers, setSelectedCustomers] = useState<EdiCustomer[]>([]);

  const columns: Column<EdiCustomer>[] = [
    {
      label: t('customerCode') as string,
      accessor: 'customerCode',
    },
    {
      label: t('name') as string,
      Cell: (row) => (row.customer ? row.customer.name : `[${t('notFound')}]`),
    },
  ];

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = ediCustomersApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      ediCustomersApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await ediCustomersApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<EdiCustomer[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!ediCustomersApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleModalCallbacks = () => {
    setUpdateTable(updateTable + 1);
    setSelectedCustomers([]);
  };

  return (
    <ContentContainer title={t('customersEdi')}>
      <div style={{ display: 'flex' }}>
        <Btn
          onClick={() => setShowAddCustomersModal(true)}
          disabled={isLoading}
          style={{ marginLeft: '0', marginBottom: '1rem' }}
          text={t('addCustomers')}
        />

        {selectedCustomers && selectedCustomers.length > 0 && (
          <Btn
            onClick={() => setShowRemoveCustomersModal(true)}
            disabled={isLoading}
            style={{ marginLeft: '0', marginBottom: '1rem' }}
            text={t('removeCustomers')}
          />
        )}
      </div>

      {showAddCustomersModal && (
        <AddCustomersTSX
          onClose={() => setShowAddCustomersModal(false)}
          callback={handleModalCallbacks}
        />
      )}

      {showRemoveCustomersModal && (
        <RemoveCustomersTSX
          onClose={() => setShowRemoveCustomersModal(false)}
          callback={handleModalCallbacks}
          selectedCustomers={selectedCustomers}
        />
      )}

      <AsyncTable
        tableName={t('customersEdi')}
        columns={columns}
        value={jobHistories}
        onChange={setJobHistories}
        selectedItems={selectedCustomers}
        onChangeSelectedItems={setSelectedCustomers}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
          totalPageLabel: `${t('totalPages')}: `,
        }}
      />
    </ContentContainer>
  );
};

export default EdiCustomerTSX;
