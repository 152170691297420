import {
  customerBills,
  customerChannel,
  customerLimit,
  customers,
} from '../../../services/endpoints';

const modalItems = [
  {
    name: 'clientes',
    exportService: customers['/export'],
    importService: customers['/import'],
  },
  {
    name: 'titulos',
    exportService: customerBills['/export'],
    importService: customerBills['/import'],
  },
  {
    name: 'limitecliente',
    exportService: customerLimit['/export'],
    importService: customerLimit['/import'],
  },
  {
    name: 'canalcliente',
    exportService: customerChannel['/export'],
    importService: customerChannel['/import'],
  },
];

export default modalItems;
