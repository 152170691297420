import axios, { CancelToken } from 'axios';
import api from '../api';
import { handleError, handleResponse } from './response';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally

/** @param {string} resource */
const getAll = async (resource: string, cancelToken?: CancelToken) => {
  try {
    const response = await api.get(
      `${resource}`,
      cancelToken && {
        cancelToken,
      },
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

/**
 *
 * @param resource
 * @param id
 * @param cancelToken
 * @returns AxiosResponse<any> | any
 */
const getSingle = async (resource: string, id: number | string, cancelToken?: CancelToken) => {
  try {
    const response = await api.get(
      `${resource}/${id}`,
      cancelToken && {
        cancelToken,
      },
    );
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

/** @param {string} resource */
/** @param {object} model */
const get = (resource: string, params: any) =>
  api.get(`${resource}`, { params }).then(handleResponse).catch(handleError);

/** @param {string} resource */
/** @param {object} model */
const post = (resource: string, model: any) =>
  api.post(`${resource}`, model).then(handleResponse).catch(handleError);

/** @param {string} resource */
/** @param {object} model */
// const getWithBody = (resource: string, model: any) =>
// 	api.get(`${resource}`, model).then(handleResponse).catch(handleError);

/** @param {string} resource */
const getWithBody = async (resource: string, params?: any) => {
  try {
    const response = await api.get(`${resource}`, params);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

/** @param {string} resource */
const postWithFile = async (
  resource: string,
  file: any,
  fileInputName: string,
  data?: { [key: string | number]: any },
) => {
  try {
    const formData = new FormData();
    formData.append(fileInputName, file);

    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }

    const response = await api.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource: string, id: number | string, model: any) =>
  api.put(`${resource}/${id}`, model).then(handleResponse).catch(handleError);

/** @param {string} resource */
/** @param {string} id */
const remove = (resource: string, id: number | string) =>
  api.delete(`${resource}/${id}`).then(handleResponse).catch(handleError);
/** @param {string} resource */
/** @param {string} id */
const removeMany = (resource: string, id: Array<number> | Array<string>) =>
  api.delete(`${resource}/${id}`).then(handleResponse).catch(handleError);

const axiosApi = axios;

const apiProvider = {
  get,
  getAll,
  getSingle,
  post,
  put,
  remove,
  removeMany,
  axiosApi,
  getWithBody,
  postWithFile,
};

export default apiProvider;
