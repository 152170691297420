import { useTranslation } from 'react-i18next';
import { MultiSelect } from 'react-multi-select-component';

import * as s from './styles';
import * as tp from './types';

const MultiSelectComp = ({
  placeholder,
  options,
  value,
  onChange,
  className,
  disabled,
  style,
  label,
  id,
}: tp.MultiSelectProps) => {
  const { t } = useTranslation();

  return (
    <s.MultiselectContainer id={id} disabled={disabled} style={style}>
      {label && <s.Label>{label}</s.Label>}
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select"
        className={`${className} multi-select`}
        overrideStrings={{
          allItemsAreSelected: t('allItemsAreSelected'),
          clearSearch: t('clearSearch'),
          noOptions: t('noOptions'),
          search: t('search'),
          selectAll: t('selectAll'),
          selectSomeItems: placeholder || t('selectSomeItems'),
        }}
        disabled={disabled}
      />
    </s.MultiselectContainer>
  );
};

export default MultiSelectComp;
