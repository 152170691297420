import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { changeTheme } from '../../store/modules/theme/actions';

import * as s from './styles';

const ThemeSelect = () => {
  const dispatch = useDispatch();
  const { title } = useContext(ThemeContext);

  return (
    <s.ToggleThemeContainer>
      <select
        name="cars"
        id="cars"
        className="select"
        onChange={(e: any) => dispatch(changeTheme(e.target.value))}
        value={title}
      >
        <option value="LIGHT">☀️</option>
        <option value="DARK">🌙</option>
      </select>
    </s.ToggleThemeContainer>
  );
};

export default ThemeSelect;
