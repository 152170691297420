import { t } from 'i18next';
import Modal from '../../../../../components/Modal';

export const ParameterPreview = ({ parameter, showModal, closeModal }: any) => (
  <Modal
    title={t('parameterPreview')}
    showModal={showModal}
    closeModal={closeModal}
    onlyCloseButton
  >
    {Object.keys(parameter).map((key: any) => (
      <div key={key}>
        <b className="label-default" style={{ marginBottom: '10px' }}>
          {`${t(key)}: `}
        </b>
        <input
          value={parameter[key]}
          className="input"
          onChange={() => null}
          style={{ marginBottom: '10px', marginTop: '5px' }}
        />
      </div>
    ))}
  </Modal>
);

export default ParameterPreview;
