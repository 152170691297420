import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import { useTheme } from 'styled-components';

import AddBtn from '../../../../../components/Buttons/AddBtn';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import { frontendNotification } from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import { DragIcon } from '../../../../../icons';
import { FormQuestion } from '../../../../../types';
import { EventOnChange } from '../../../../../types/events';
import { answerTypes } from '../../../../../utils/fixedData';
import {
  getBooleanOptions,
  getOptionsFromAnswerTypes,
  getSelectOptionFromBoolean,
} from '../../../../../utils/getSelectOptions';
import * as s from './style';

type Props = {
  showModalEditQuestion: boolean;
  closeModal: () => void;
  saveActionBtn: (
    question: string,
    answerType: { label: string; value: string | number },
    erpReferenceKey: string | null,
    isRequired: boolean,
    minSize: number | null,
    maxSize: number | null,
    options: ItemInterface[],
    index: number,
  ) => boolean;
  selectedQuestion: FormQuestion;
  selectedIndex: number;
};

export const ModalCreateQuestionJSX = ({
  showModalEditQuestion,
  closeModal,
  saveActionBtn,
  selectedQuestion,
  selectedIndex,
}: Props) => {
  const handleType = () => ({
    value: selectedQuestion.type,
    label: answerTypes()[selectedQuestion.type],
  });
  const [question, setQuestion] = useState(selectedQuestion.question);
  const [erpReferenceKey, setErpReferenceKey] = useState(selectedQuestion.erpReferenceKey);
  const [answerType, setAnswerType] = useState(handleType());

  const [moreDetail, setMoreDetail] = useState(false);
  const [isRequired, setIsRequired] = useState(selectedQuestion.is_required);
  const [minSize, setMinSize] = useState(selectedQuestion.min_size);
  const [maxSize, setMaxSize] = useState(selectedQuestion.max_size);

  const [option, setOption] = useState('');
  const [options, setOptions] = useState<ItemInterface[] | []>(selectedQuestion.options);

  const theme = useTheme();

  useEffect(() => {
    if (isDetailedQuestion(selectedQuestion)) {
      setMoreDetail(true);
    }
  }, []);

  const isDetailedQuestion = (selectedQuestion: FormQuestion) => {
    if (
      selectedQuestion.is_required ||
      selectedQuestion.min_size != null ||
      selectedQuestion.max_size != null
    ) {
      return true;
    }

    return false;
  };

  const handleMoreDetailCheckbox = () => {
    if (moreDetail) {
      setMoreDetail(false);
      setIsRequired(false);
      setMinSize(null);
      setMaxSize(null);
    } else {
      setMoreDetail(true);
    }
  };

  const typeHasOptions = () => answerType.value === 'mi' || answerType.value === 'me';

  const addOptionBtn = () => {
    if (option.length <= 0) {
      return;
    }

    const optionsCopy = [...options];

    if (optionsCopy.find((o) => o.id === option) === undefined) {
      optionsCopy.push({ id: option });
      setOptions(optionsCopy);
    } else {
      frontendNotification({ message: t('optionAlreadyExists'), type: 'warning' });
    }
  };

  const handleActionBtn = () => {
    if (question.length <= 0) {
      frontendNotification({ message: t('questionIsRequired'), type: 'warning' });
    }

    if (answerType.value === 'mi' || answerType.value === 'me') {
      if (options.length <= 0) {
        frontendNotification({ message: t('optionIsRequired'), type: 'warning' });
      } else if (
        saveActionBtn(
          question,
          answerType,
          erpReferenceKey,
          isRequired,
          minSize,
          maxSize,
          options,
          selectedIndex,
        )
      ) {
        setQuestion('');
        setOption('');
        setOptions([]);
        closeModal();
      }
    } else if (
      saveActionBtn(
        question,
        answerType,
        erpReferenceKey,
        isRequired,
        minSize,
        maxSize,
        options,
        selectedIndex,
      )
    ) {
      setQuestion('');
      setOption('');
      setOptions([]);
      saveActionBtn(
        question,
        answerType,
        erpReferenceKey,
        isRequired,
        minSize,
        maxSize,
        options,
        selectedIndex,
      );
      closeModal();
    }
  };

  const handleShowMinSizeInput = () =>
    answerType.value === 'n' || answerType.value === 'currency_br';

  const handleShowMaxSizeInput = () =>
    answerType.value === 'n' || answerType.value === 'currency_br' || answerType.value === 't';

  useEffect(() => {
    if (answerType.value !== 'mi' && answerType.value !== 'me') {
      setOptions([]);
    }
  }, [answerType]);

  return (
    <Modal
      title={t('editQuestion')}
      showModal={showModalEditQuestion}
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => handleActionBtn()}
      bodyStyle={options.length >= 5 ? { overflowX: 'scroll' } : {}}
    >
      <s.Container>
        <s.Line>
          <s.Item>
            <div className="inputLabel">{t('question') as string}</div>
            <Input
              value={question}
              onChange={(e: EventOnChange) => setQuestion(e.target.value)}
              placeholder={t('question')}
            />
          </s.Item>

          <s.Item>
            <div className="inputLabel">{t('answerType') as string}</div>
            <SingleSelect
              value={answerType}
              options={getOptionsFromAnswerTypes()}
              onChange={(o) => setAnswerType(o)}
              placeholder={t('answerType')}
            />
          </s.Item>

          <s.Item>
            <div className="inputLabel">{t('erpReferenceKey') as string}</div>
            <Input
              value={erpReferenceKey as string}
              onChange={(e: EventOnChange) => setErpReferenceKey(e.target.value)}
              placeholder={t('erpReferenceKey')}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item style={{ display: 'flex' }}>
            <div
              className="inputLabel"
              style={{ display: 'flex', alignItems: 'center', margin: 0 }}
            >
              {t('moreDetail') as string}
            </div>
            <input
              type="checkbox"
              checked={moreDetail}
              onChange={() => handleMoreDetailCheckbox()}
            />
          </s.Item>
        </s.Line>

        {moreDetail && (
          <>
            <s.Line>
              <s.Item className="text-red">{t('formSecondVersionInfo') as string}</s.Item>
            </s.Line>
            <s.Line>
              <s.Item>
                <div className="inputLabel">{t('isRequired') as string}</div>
                <SingleSelect
                  value={getSelectOptionFromBoolean(isRequired)}
                  options={getBooleanOptions()}
                  onChange={(e) => setIsRequired(e.value)}
                />
              </s.Item>
              {handleShowMinSizeInput() && (
                <s.Item>
                  <div className="inputLabel">{t('minValue') as string}</div>
                  <input
                    className="input"
                    type="number"
                    value={minSize ?? 0}
                    onChange={(e: any) => setMinSize(e.target.value)}
                  />
                </s.Item>
              )}

              {handleShowMaxSizeInput() && (
                <s.Item>
                  <div className="inputLabel">
                    {answerType.value === 't'
                      ? (t('maxSize') as string)
                      : (t('maxValue') as string)}
                  </div>
                  <input
                    className="input"
                    type="number"
                    value={maxSize ?? 0}
                    onChange={(e: any) => setMaxSize(e.target.value)}
                  />
                </s.Item>
              )}
            </s.Line>
          </>
        )}

        {typeHasOptions() && (
          <s.OptionsArea>
            <s.Line>
              <s.Item>
                <Input
                  value={option}
                  onChange={(e: EventOnChange) => setOption(e.target.value)}
                  placeholder={t('option')}
                />
              </s.Item>
              <s.Item>
                <AddBtn onClick={addOptionBtn} />
              </s.Item>
            </s.Line>

            {options.length <= 0 && <s.NoOptions>{t('noOption') as string}</s.NoOptions>}

            <ReactSortable list={options} setList={setOptions}>
              {options.map((item) => (
                <s.Option key={item.id}>
                  <>
                    <s.DragArea>
                      <DragIcon title={t('drag')} color={theme.colors.onPrimary} disableHover />
                    </s.DragArea>
                    {item.id}
                  </>
                </s.Option>
              ))}
            </ReactSortable>
          </s.OptionsArea>
        )}
      </s.Container>
    </Modal>
  );
};

export default ModalCreateQuestionJSX;
