import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { reportsApi } from '../../../../../services/requests';
import { ReportGet } from '../../../../../types';

type Props = {
  selectedItem: ReportGet;
  closeModal: () => void;
  reqCallback: () => void;
};

const ModalDeleteJSX = ({ selectedItem, closeModal, reqCallback }: Props) => {
  const [waitingReq, setWaitingReq] = useState(false);

  const reqDeleteItem = useCallback(async () => {
    setWaitingReq(true);

    const source = reportsApi.axios.CancelToken.source();

    try {
      reportsApi.query = '';
      const res = await reportsApi.delete(selectedItem.id);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        setWaitingReq(false);
        reqCallback();
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      if (!reportsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitingReq(false);
    return () => {
      source.cancel('Component got unmounted');
    };
  }, []);

  return (
    <Modal
      title={`${t('delete')}`}
      action={() => reqDeleteItem()}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      actionNameBtn={t('delete')}
      isLoading={waitingReq}
    >
      <h3>{`${t('deleteSure') as string}?`}</h3>
      <p>
        <b>{t('id') as string}: </b>
        {selectedItem.id}
      </p>
      <p>
        <b>{t('description') as string}: </b>
        {selectedItem.description}
      </p>
    </Modal>
  );
};

export default ModalDeleteJSX;
