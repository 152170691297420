import styled from 'styled-components';

interface ContainerProps {
  direction: 'down' | 'right';
  dataIsVisible: boolean;
}

export const ExpandableDataContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(p) => (p.direction === 'right' ? 'row' : 'column')};

  margin-bottom: 1rem;

  .expandable-button {
    background-color: ${(p) => p.theme.colors.primary};
    width: ${(p) => (p.direction === 'right' ? '1rem' : '100%')};
    height: ${(p) => (p.direction === 'right' ? '100%' : '1rem')};

    border-radius: ${(p) => (p.direction === 'right' ? '0.5rem 0 0 0.5rem' : '0.5rem 0.5rem 0 0')};
    ${(p) => (p.dataIsVisible ? '' : 'border-radius: 0.5rem;')};

    padding: 0.3rem;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default ExpandableDataContainer;
