import styled from 'styled-components';
import { DefaultRowForm, ItemForm } from '../../../../../../styles/FormGlobal';

export const AddBannerContainer = styled.div`
  .img-area {
    margin-top: -10px;
  }
`;

export const Row = styled.div`
  ${DefaultRowForm}
`;

export const Item = styled.div`
  ${ItemForm}
`;
