import CodeEditor from '@uiw/react-textarea-code-editor';
import { CSSProperties } from 'react';
import { useTheme } from 'styled-components';
import * as s from './styles';

type Props = {
  value: string;
  language: string;
  onChange: (v: any) => void;
  placeholder?: string;
  style?: CSSProperties;
};

const TextareaJSX = ({ value, language, onChange, placeholder = '', style }: Props) => {
  const { title } = useTheme();

  return (
    <s.Container data-color-mode={title.toLowerCase()}>
      <CodeEditor
        value={value}
        language={language}
        placeholder={placeholder}
        onChange={onChange}
        padding={15}
        style={style}
        className="codeEditor"
      />
    </s.Container>
  );
};

TextareaJSX.defaultProps = {
  placeholder: '',
  style: {},
};

export default TextareaJSX;
