import { Route, Routes } from 'react-router-dom';

import NotAuthorized from '../pages/NotAuthorized';
import Notfound from '../pages/Notfound';

import * as Access from '../pages/TenantPages/AccessControl';
import Integration from '../pages/TenantPages/Integration';

import IntegrationsSuperadmin from '../pages/SuperadminPages/IntegrationModel';
import Jobs from '../pages/SuperadminPages/Jobs';
import AdminMain from '../pages/SuperadminPages/Main';
import AdminSignin from '../pages/SuperadminPages/Signin';
import SystemLogs from '../pages/SuperadminPages/SystemLogs';
import Tenant from '../pages/SuperadminPages/Tenant';
import Users from '../pages/SuperadminPages/Users';

import Forgotpass from '../pages/TenantPages/Auth/Forgotpass';
import Resetpass from '../pages/TenantPages/Auth/Resetpass';
import Signin from '../pages/TenantPages/Auth/Signin';

import AdvantageClub from '../pages/TenantPages/AdvantageClub/AdvantageClub';
import AdvClubBalance from '../pages/TenantPages/AdvantageClub/Balance';
import AdvClubHistory from '../pages/TenantPages/AdvantageClub/Historic';

import SurveysInsight from '../pages/TenantPages/Dashboard/MarketingResearch';
import DashboardSales from '../pages/TenantPages/Dashboard/Sales';
import DashboardLog from '../pages/TenantPages/Dashboard/Sales/Pages/Log';
import DashboardSettings from '../pages/TenantPages/Dashboard/Sales/Pages/Settings';
import DashboardScheduledVisits from '../pages/TenantPages/Dashboard/ScheduledVisits';

import Banners from '../pages/TenantPages/Registrations/Banners';
import CommunicationAutomation from '../pages/TenantPages/Registrations/CommunicationAutomation';
import CommunicationGroup from '../pages/TenantPages/Registrations/CommunicationGroup';
import Goal from '../pages/TenantPages/Registrations/Goal';
import Motives from '../pages/TenantPages/Registrations/Motives';
import Templates from '../pages/TenantPages/Registrations/Templates';
import Reports from '../pages/TenantPages/Reports';
import SmSellCustom from '../pages/TenantPages/SmartsellCustomization';
import Upload from '../pages/TenantPages/Upload';

import CommunicationLogs from '../pages/TenantPages/Logs/Communication';
import OrderChange from '../pages/TenantPages/Logs/OrderChange';

import Forms from '../pages/TenantPages/Forms';
import VisitSchedule from '../pages/TenantPages/Scripting/VisitSchedule';
import Taxes from '../pages/TenantPages/Taxes';

import ExternalAuthentication from './PrivateRoutes/ExternalAuthentication';
import Private from './PrivateRoutes/Private';
import SuperPrivate from './PrivateRoutes/SuperPrivate';

import Parameters from '../pages/TenantPages/Configurations/Parameters';
import SellersParameters from '../pages/TenantPages/Configurations/SellersParameters';
import EdiCustomers from '../pages/TenantPages/EdiCustomers';
import EdiLayout from '../pages/TenantPages/EdiLayout';
import EdiPaymentConditionsTSX from '../pages/TenantPages/EdiPaymentConditions';
import MixCustomers from '../pages/TenantPages/MixCustomers';
import Audience from '../pages/TenantPages/Registrations/Audience';
import MotivesScheduleJSX from '../pages/TenantPages/Scripting/MotivesSchedule';

import { UrlPaths, UrlPathsSuper } from '../enums/urlPaths.enum';
import SuperSmartsellAppVersionTSX from '../pages/SuperadminPages/SmartsellAppVersions';
import EmailTSX from '../pages/TenantPages/Email';
import ExporterJSX from '../pages/TenantPages/Exporter';
import ImporterJSX from '../pages/TenantPages/Importer';
import SmartsellAppVersionTSX from '../pages/TenantPages/SmartsellAppVersion';
import SmartsellSyncSellerLockTSX from '../pages/TenantPages/SmartsellSync';

import AccessControlTSX from '../pages/SuperadminPages/AccessControl';
import OrdersTSX from '../pages/TenantPages/Orders';

const RouteManage = () => (
  <Routes>
    <Route path={UrlPaths.ROOT} element={<Signin />} />
    <Route path={UrlPaths.LOGIN} element={<Signin />} />

    <Route path={UrlPaths.DASHBOARD_SALES} element={<Private component={DashboardSales} />} />
    <Route
      path={UrlPaths.DASHBOARD_MARKETING_RESEARCH_METRICS}
      element={<Private component={SurveysInsight} />}
    />
    <Route
      path={UrlPaths.DASHBOARD_SCHEDULED_VISITS}
      element={<Private component={DashboardScheduledVisits} />}
    />
    <Route path={UrlPaths.DASHBOARD_LOGS} element={<Private component={DashboardLog} />} />
    <Route path={UrlPaths.DASHBOARD_SETTINGS} element={<Private component={DashboardSettings} />} />

    <Route path={UrlPaths.ORDERS} element={<Private component={OrdersTSX} />} />

    <Route path={UrlPaths.REPORTS} element={<Private component={Reports} />} />

    <Route path={UrlPaths.ADVANTAGE_CLUB} element={<Private component={AdvantageClub} />} />
    <Route
      path={UrlPaths.ADVANTAGE_CLUB_HISTORY}
      element={<Private component={AdvClubHistory} />}
    />
    <Route
      path={UrlPaths.ADVANTAGE_CLUB_BALANCE}
      element={<Private component={AdvClubBalance} />}
    />

    <Route path={UrlPaths.SMARTSELL_CUSTOMIZATION} element={<Private component={SmSellCustom} />} />

    <Route path={UrlPaths.TEMPLATES} element={<Private component={Templates} />} />

    <Route path={UrlPaths.FORGOT_PASSWORD} element={<Forgotpass />} />

    <Route path={UrlPaths.RESET_PASSWORD} element={<Resetpass />} />

    <Route path={UrlPaths.USERS} element={<Private component={Access.Users} />} />

    <Route path={UrlPaths.ROLES} element={<Private component={Access.Roles} />} />

    <Route path={UrlPaths.UPLOAD} element={<Private component={Upload} />} />
    <Route path={UrlPaths.TAXES} element={<Private component={Taxes} />} />

    <Route path="/integration/:integration" element={<Private component={Integration} />} />

    <Route path={UrlPaths.GOALS} element={<Private component={Goal} />} />

    <Route path={UrlPaths.MOTIVES} element={<Private component={Motives} />} />

    <Route
      path={UrlPaths.COMMUNICATION_GROUPS}
      element={<Private component={CommunicationGroup} />}
    />

    <Route
      path={UrlPaths.COMMUNICATION_AUTOMATION}
      element={<Private component={CommunicationAutomation} />}
    />

    <Route path={UrlPaths.AUDIENCES} element={<Private component={Audience} />} />

    <Route path={UrlPaths.LOGS_COMMUNICATION} element={<Private component={CommunicationLogs} />} />

    <Route path={UrlPaths.LOGS_ORDER_CHANGE} element={<Private component={OrderChange} />} />

    <Route path={UrlPaths.BANNERS} element={<Private component={Banners} />} />

    <Route path={UrlPaths.CONFIG_PARAMETERS} element={<Private component={Parameters} />} />

    <Route
      path={UrlPaths.CONFIG_SELLERS_PARAMETERS}
      element={<Private component={SellersParameters} />}
    />

    <Route path={UrlPaths.VISIT_SCHEDULE} element={<Private component={VisitSchedule} />} />

    <Route path={UrlPaths.MOTIVE_VISIT} element={<Private component={MotivesScheduleJSX} />} />

    <Route path={UrlPaths.FORMS} element={<Private component={Forms} />} />

    <Route path={UrlPaths.ACCESS_PAGE_WITH_TOKEN} element={<ExternalAuthentication />} />

    <Route path={UrlPaths.EDI_LAYOUT} element={<Private component={EdiLayout} />} />
    <Route path={UrlPaths.EDI_CUSTOMERS} element={<Private component={EdiCustomers} />} />
    <Route
      path={UrlPaths.EDI_PAYMENT_CONDITION}
      element={<Private component={EdiPaymentConditionsTSX} />}
    />
    <Route path={UrlPaths.MIX_CUSTOMERS} element={<Private component={MixCustomers} />} />

    <Route path={UrlPaths.IMPORTER} element={<Private component={ImporterJSX} />} />

    <Route path={UrlPaths.EXPORTER} element={<Private component={ExporterJSX} />} />

    <Route path={UrlPaths.EMAIL} element={<Private component={EmailTSX} />} />

    <Route
      path={UrlPaths.SMARTSELL_SYNC_SELLER_LOCK}
      element={<Private component={SmartsellSyncSellerLockTSX} />}
    />

    <Route
      path={UrlPaths.SMARTSELL_APP_VERSION}
      element={<Private component={SmartsellAppVersionTSX} />}
    />

    {/* Start -- Superadmin */}
    <>
      <Route path={UrlPathsSuper.LOGIN} element={<AdminSignin />} />
      <Route path={UrlPathsSuper.ROOT} element={<AdminSignin />} />

      <Route path={UrlPathsSuper.HOME} element={<SuperPrivate component={AdminMain} />} />

      <Route path={UrlPathsSuper.TENANTS} element={<SuperPrivate component={Tenant} />} />
      <Route path={UrlPathsSuper.USERS} element={<SuperPrivate component={Users} />} />
      <Route path={UrlPathsSuper.JOBS} element={<SuperPrivate component={Jobs} />} />

      <Route
        path={UrlPathsSuper.INTEGRATION_MODELS}
        element={<SuperPrivate component={IntegrationsSuperadmin} />}
      />

      <Route path={UrlPathsSuper.SYSTEM_LOGS} element={<SuperPrivate component={SystemLogs} />} />

      <Route
        path={UrlPathsSuper.SMARTSELL_APP_VERSIONS}
        element={<SuperPrivate component={SuperSmartsellAppVersionTSX} />}
      />

      <Route
        path={UrlPathsSuper.ACCESS_CONTROL}
        element={<SuperPrivate component={AccessControlTSX} />}
      />
    </>
    {/* End -- Superadmin */}

    <Route path={UrlPaths.NOT_AUTHORIZED} element={<NotAuthorized />} />

    <Route path="*" element={<Notfound />} />
  </Routes>
);

export default RouteManage;
