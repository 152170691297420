import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Navigate, RouteProps, useLocation } from 'react-router-dom';

import { backendErrorNotification } from '../../components/Notification';
import HttpStatus from '../../enums/httpStatus';
import { UrlPaths } from '../../enums/urlPaths.enum';
import { signinByTokenReq } from '../../services/requests';
import { signInSuccess } from '../../store/modules/auth/actions';

type PrivateRouteProps = RouteProps;

const ExternalAuthentication: React.FC<PrivateRouteProps> = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [urlPath, setUrlPath] = useState<UrlPaths | string>(UrlPaths.NOT_FOUND);

  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const page = query.get('page');

  if (!token) {
    return <Navigate to={UrlPaths.NOT_AUTHORIZED} />;
  }

  if (!page) {
    return <Navigate to={UrlPaths.NOT_FOUND} />;
  }

  const loginByTokenRequest = async () => {
    setIsLoading(true);
    try {
      const response = await signinByTokenReq(token);

      if ('response' in response) {
        const { status } = response.response;

        if (status !== HttpStatus.OK) {
          throw status;
        }

        const { data: user } = response.response.data;

        let expirationDate = Date.now();
        expirationDate += 5.76e7; // 5.76e+7 = 960 minutos = 16h

        dispatch(
          signInSuccess({
            token,
            expirationDate,
            user,
          }),
        );

        setUrlPath(`/${page}`);
        setIsLoading(false);
      } else {
        setUrlPath(UrlPaths.NOT_AUTHORIZED);
        setIsLoading(false);
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);

      setUrlPath(UrlPaths.NOT_AUTHORIZED);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loginByTokenRequest();
  }, []);

  return isLoading ? <p>loading...</p> : <Navigate to={urlPath} />;
};

export default ExternalAuthentication;
