import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Input from '../../../../../../components/Input';
import Modal, { ModalProps } from '../../../../../../components/Modal';
// import SelectDateTime from '../../../../../../components/SelectDateAndTime';
import SelectImageWithCropper from '../../../../../../components/SelectImgWithCropper';
import SingleSelect from '../../../../../../components/SingleSelect';
import { currentDateWithTimeString } from '../../../../../../utils/helpers';
import * as s from './styles';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { smartbannersReq } from '../../../../../../services/requests';
import { Smartbanner } from '../../../../../../types/apiResponseTypes';

type Props = ModalProps & {
  reqGetAllBanners: () => void;
  closeModal: () => void;
  bannerSelected: Smartbanner;
};

export const EditBannerModal = ({
  reqGetAllBanners,
  closeModal,
  bannerSelected,
  ...props
}: Props) => {
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [date, setDate] = useState(bannerSelected.expire_in);
  const [description, setDescription] = useState('');
  const [show, setShow] = useState({ value: 1, label: t('show') });
  const [sortNumber, setSortNumber] = useState({ value: 1, label: '1' });

  const [awaitReqEditBanner, setAwaitReqEditBanner] = useState(false);

  const reqEditSmartbanner = async () => {
    setAwaitReqEditBanner(true);
    try {
      const data = {
        image,
        link,
        expire_in: date.replace('T', ' '),
        show: show.value,
        sort_number: sortNumber.value,
        description,
      };

      const response = await smartbannersReq.update(data, bannerSelected.id);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        reqGetAllBanners();

        setImage('');
        setLink('');
        setDate(currentDateWithTimeString());
        setShow({
          value: 1,
          label: t('show'),
        });
        setSortNumber({
          value: 1,
          label: '1',
        });
        setDescription('');

        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!smartbannersReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqEditBanner(false);
  };

  const setValues = () => {
    setImage(bannerSelected.image);
    setLink(bannerSelected.link ?? '');
    setDate(bannerSelected.expire_in);
    setDescription(bannerSelected.description);
    setShow(
      bannerSelected.show ? { value: 1, label: t('show') } : { value: 0, label: t('notShow') },
    );
    setSortNumber({
      value: bannerSelected.sort_number,
      label: bannerSelected.sort_number.toString(),
    });
  };

  useEffect(() => {
    setValues();
  }, []);

  return (
    <Modal
      {...props}
      action={() => reqEditSmartbanner()}
      isLoading={awaitReqEditBanner}
      closeModal={closeModal}
      bodyStyle={{ overflowX: 'scroll' }}
    >
      <s.AddBannerContainer>
        <s.Row>
          <s.Item>
            <SelectImageWithCropper getImage={(i) => setImage(i)} requiredCrop />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item className="img-area">
            <img alt={description} src={image} style={{ maxWidth: '100%', minWidth: '100%' }} />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('link') as string}</p>
            <Input
              value={link as string}
              onChange={(v) => setLink(v.currentTarget.value)}
              disabled={awaitReqEditBanner}
            />
          </s.Item>
          <s.Item>
            <p>{t('expirationDate') as string}</p>
            {/* <SelectDateTime
                            // eslint-disable-next-line no-console
                            getDate={(e: any) => console.log(e)}
                            getValue={(e: any) => setDate(e)}
                            date={date}
                            disabled={awaitReqEditBanner}
                        /> */}
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('show') as string}</p>
            <SingleSelect
              placeholder={t('show') as string}
              options={[
                { value: 1, label: t('show') },
                { value: 0, label: t('notShow') },
              ]}
              onChange={(v: any) => setShow(v)}
              value={show}
              isDisabled={awaitReqEditBanner}
            />
          </s.Item>
          <s.Item>
            <p>{t('displayOrder') as string}</p>
            <SingleSelect
              placeholder={t('displayOrder') as string}
              options={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 0, label: t('notDisplay') },
              ]}
              onChange={(v: any) => setSortNumber(v)}
              value={sortNumber}
              isDisabled={awaitReqEditBanner}
            />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <p>{t('description') as string}</p>
            <Input
              value={description}
              onChange={(v) => setDescription(v.currentTarget.value)}
              disabled={awaitReqEditBanner}
            />
          </s.Item>
        </s.Row>
      </s.AddBannerContainer>
    </Modal>
  );
};

export default EditBannerModal;
