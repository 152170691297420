import { AxiosResponse } from 'axios';

export function handleResponse(response: AxiosResponse<any>) {
  // if (response.data) {
  //     return response.data;
  // }

  return response;
}

export function handleError(error: any) {
  // if (error.data) {
  //     return error.data;
  // }
  return error;
}
