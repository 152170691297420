import { PropsWithChildren } from 'react';
import { MdDragIndicator } from 'react-icons/md';
import * as s from './styles';

export const ReactSortableItem = ({ children }: PropsWithChildren<any>) => (
  <s.Container>
    <s.DragArea>
      <MdDragIndicator />
    </s.DragArea>
    <div style={{ width: '100%' }}>{children}</div>
  </s.Container>
);

export default ReactSortableItem;
