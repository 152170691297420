import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import Modal, { ModalProps } from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../enums/httpStatus';
import { tenantResendingEmailApi } from '../../../../../services/requests';
import { EmailLog } from '../../../../../types/apiResponse/email';

type Props = ModalProps & {
  emailLog: EmailLog;
  callback: () => void;
};

const TenantResendingEmailModal = ({ emailLog, callback, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const requestEmailResendApi = async () => {
    const source = tenantResendingEmailApi.axios.CancelToken.source();
    try {
      setIsLoading(true);
      tenantResendingEmailApi.query = `/${emailLog.id}`;
      const res = await tenantResendingEmailApi.store(source.token);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: NotificationTypes.SUCCESS,
        });

        callback();
      } else {
        throw res;
      }
    } catch (err) {
      if (!tenantResendingEmailApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    } finally {
      setIsLoading(false);
    }

    return () => source.cancel('Component Roles got unmounted');
  };

  const renderEmailRecipients = () => {
    const recipientsList = emailLog.emailDestinatarios?.split(',');
    return recipientsList?.map((recipientEmail) => <li>{recipientEmail}</li>);
  };

  return (
    <Modal
      {...props}
      action={() => requestEmailResendApi()}
      actionNameBtn={t('resend')}
      bodyStyle={{ overflow: 'scroll', width: '100%' }}
      isLoading={isLoading}
    >
      <div>
        <p>
          <b>{t('subject') as string}:</b> {emailLog.subject}
        </p>
        <hr />
        <p>
          <b>{t('content') as string}:</b>
          <div
            style={{ border: '1px solid gray', padding: '1rem' }}
            dangerouslySetInnerHTML={{ __html: emailLog.body as string }}
          />
        </p>
        <hr />
        <p>
          <b>{t('recipients') as string}:</b> <ul>{renderEmailRecipients()}</ul>
        </p>
      </div>
    </Modal>
  );
};

export default TenantResendingEmailModal;
