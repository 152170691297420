import { t } from 'i18next';
import {
  AdvantageClub,
  AdvantageClubBalance,
  Audience,
  Form,
  FormQuestion,
  Product,
  Role,
} from '../types';
import { SellerParameter } from '../types/apiResponse/Parameters';
import { SmartsellAppVersion } from '../types/apiResponse/SmartsellAppVersions';
import { ExporterAvailableTable } from '../types/apiResponse/exporter';
import { MarketingResearch } from '../types/apiResponse/marketingResearch';
import { OrderType, PaymentCondition, PaymentMethod } from '../types/apiResponse/order';
import { Template, TemplateParameter } from '../types/apiResponse/template';
import * as TP from '../types/apiResponseTypes';
import { answerTypes, formOpeningLocations } from './fixedData';

export type SelectOption = { label: string; value: string | number };

export type SelectBooleanOption = { label: string; value: boolean };

export type SelectOptionSpecValue<T> = { label: string; value: T };

export const arrayToSelectOptions = <T>(
  data: T[],
  label: (item: T) => string,
): SelectOptionSpecValue<T>[] => {
  return data.map((item) => ({
    label: label(item),
    value: item,
  }));
};

type DataMapper<T> = {
  label: (item: T) => string;
  value: (item: T) => string | number;
};

export const getOptionsFromData = <T>(data: T[], mapper: DataMapper<T>): SelectOption[] => {
  return data.map((item) => ({
    label: mapper.label(item),
    value: mapper.value(item),
  }));
};

export const getOptionByValue = (
  value: unknown,
  options: SelectOption[] | SelectOptionSpecValue<any>[],
): SelectOption => {
  const result = options.find((option) => option.value === value);
  return result || options[0];
};

export const getOptionsFromSellers = (sellers: TP.Seller[]): SelectOption[] =>
  sellers.map((seller) => ({
    label: `${seller.CodigoVendedor} - ${seller.Nome}`,
    value: seller.CodigoVendedor,
  }));

export const getOptionsFromPaymentCondition = (options: PaymentCondition[]): SelectOption[] =>
  options.map((option) => ({
    label: `${option.Codigo} - ${option.Descricao}`,
    value: option.Codigo,
  }));

export const getOptionsFromPaymentMethod = (options: PaymentMethod[]): SelectOption[] =>
  options.map((option) => ({
    label: `${option.Codigo} - ${option.Descricao}`,
    value: option.Codigo,
  }));

export const getOptionsFromSellerParameter = (
  data: SellerParameter[],
): SelectOptionSpecValue<SellerParameter>[] =>
  data.map((item) => ({
    label: item.parametro,
    value: item,
  }));

export const getOptionsFromCustomers = (customers: TP.Customer[]): SelectOption[] =>
  customers.map((customer) => ({
    label: `${customer.CodigoCliente} - ${customer.RazaoSocial}`,
    value: customer.CodigoCliente,
  }));

export const getOptionsFromActions = (data: TP.Action[]): SelectOption[] =>
  data.map((item) => ({
    label: item.label,
    value: item.id,
  }));

export const getOptionsFromOrderOrigins = (data: TP.OrderOrigin[]): SelectOption[] =>
  data.map((item) => ({
    label: item.label,
    value: item.id,
  }));

export const getOptionsFromCommGroups = (data: TP.CommunicationGroup[]): SelectOption[] =>
  data.map((item) => ({
    label: item.description,
    value: item.id,
  }));

export const getOptionsFromTemplates = (data: Template[]): SelectOption[] =>
  data.map((item) => ({
    label: item.name,
    value: item.id as number,
  }));

export const getOptionsFromTemplatesJson = (data: Template[]): SelectOption[] =>
  data
    .filter((i) => i.type.includes('json'))
    .map((item) => ({
      label: item.name,
      value: item.id as number,
    }));

export const getOptionsFromAvailableTablesForExporter = (
  data: ExporterAvailableTable[],
): SelectOption[] =>
  data.map((item) => ({
    label: item.label,
    value: item.tableName,
  }));

export const getOptionsFromTemplatesJsonContent = (data: Template[]): SelectOption[] =>
  data
    .filter((i) => i.type.includes('json'))
    .map((item) => ({
      label: item.name,
      value: item.content,
    }));

export const getOptionsMotivesSchedule = () => [
  { value: 'svi', label: 'Sem visita' },
  { value: 'sve', label: 'Sem venda' },
  { value: 'nv', label: 'Nova visita' },
  { value: 'vfr', label: 'Fora do raio' },
  // { value: 'v', label: 'Venda' },
];

export const getSelectOptionsFromFormOpeningLocations = () => {
  const values = formOpeningLocations();
  const options: { value: number; label: string }[] = [];

  Object.keys(values).forEach((key: any) => {
    options.push({
      value: key,
      label: `${key} - ${values[key]}`,
    });
  });

  return options;
};

export const getOptionsFromAudiences = (audiences: Audience[]): SelectOption[] =>
  audiences.map((audience) => ({
    label: audience.description,
    value: audience.id as number,
  }));

export const getOptionsSmartsellAppVersion = (data: SmartsellAppVersion[]): SelectOption[] => {
  return data.map((item) => ({
    label: `v${item.version}.${item.file_type}`,
    value: item.version,
  }));
};

export const getOptionsFromForms = (data: Form[]): SelectOption[] =>
  data.map((item) => ({
    label: item.description,
    value: item.id as number,
  }));

export const getOptionsFromProducts = (products: Product[]): SelectOption[] =>
  products.map((product) => ({
    label: `${product.CodigoProduto} - ${product.Descricao}`,
    value: product.CodigoProduto,
  }));

export const getOptionsFromFormQuestion = (
  items: FormQuestion[],
): SelectOptionSpecValue<FormQuestion>[] =>
  items.map((item) => ({
    label: item.question,
    value: item,
  }));

export const getOptionsFromTemplateParameters = (
  parameters: TemplateParameter[],
): SelectOptionSpecValue<TemplateParameter>[] =>
  parameters.map((parameter) => ({
    label: `${parameter.name} - ${parameter.label}`,
    value: parameter,
  }));

export const getOptionsFromAnswerTypes = () => {
  const options: SelectOption[] = [];

  Object.keys(answerTypes()).forEach((key) => {
    options.push({
      value: key,
      label: answerTypes()[key],
    });
  });

  return options;
};

export const getOptionsFromRoles = (roles: Role[]): SelectOption[] =>
  roles.map((role) => {
    let label = role.name;
    if (role.name.includes('_')) {
      // eslint-disable-next-line prefer-destructuring
      label = role.name.split('_')[1];
    }
    return {
      label,
      value: role.id,
    };
  });

export const getBooleanOptions = () => [
  { value: false, label: t('not') },
  { value: true, label: t('yes') },
];

export const getSelectOptionFromBoolean = (value: boolean) =>
  value ? getBooleanOptions()[1] : getBooleanOptions()[0];

export const getOptionsFromBoolean = (value: boolean) =>
  value ? getBooleanOptions()[1] : getBooleanOptions()[0];

export const getExtensionForTheReports = () => [
  { value: 'pdf', label: t('PDF') },
  { value: 'xls', label: t('Excel') },
];

export const getOptionsFromCalculationUnit = (): {
  label: string;
  value: 'weight' | 'quantity';
}[] => [
  { label: `${t('weight')} (kg)`, value: 'weight' },
  { label: t('quantity'), value: 'quantity' },
];

export const getOptionFromCustomerWithBalance = (data: AdvantageClubBalance[]) =>
  data.map((item) => {
    let type = t('seller');
    let name = '';
    if (item.recipientType == 'customer') {
      type = t('customer');
      name = item.customer ? item.customer.RazaoSocial : '';
    } else {
      name = item.seller ? item.seller.Nome : '';
    }

    return {
      ...item,
      label: `${type} - ${item.recipient} - ${name}`,
    };
  });

export const getOptionFromOrderTypes = (data: OrderType[]) =>
  data.map((item) => ({
    label: item.DescricaoTipoPed,
    value: item.CodigoTipoPed,
  }));

export const getGoalTypeSelectOption = (): SelectOption[] => [
  { value: 'DEFAULT', label: t('default') },
  { value: 'SPECIFIC_MONTH', label: t('specificMonth') },
  { value: 'ADVANTAGE_CLUB', label: t('advantageClub') },
];

export const getOptionsFromAdvantageClub = (
  items: AdvantageClub[],
): SelectOptionSpecValue<AdvantageClub>[] =>
  items.map((item) => ({
    label: item.description,
    value: item,
  }));

export const getOptionsFromMarketingResearch = (
  items: MarketingResearch[],
): SelectOptionSpecValue<MarketingResearch>[] =>
  items.map((item) => ({
    label: item.description,
    value: item,
  }));

export const selectOptionMonths = (): SelectOption[] => [
  { value: 1, label: t('january') },
  { value: 2, label: t('february') },
  { value: 3, label: t('march') },
  { value: 4, label: t('april') },
  { value: 5, label: t('may') },
  { value: 6, label: t('june') },
  { value: 7, label: t('july') },
  { value: 8, label: t('august') },
  { value: 9, label: t('september') },
  { value: 10, label: t('october') },
  { value: 11, label: t('november') },
  { value: 12, label: t('december') },
];

export const filterTypeSelectOptions = (): SelectOption[] => [
  { value: 'simple', label: t('simple') },
  { value: 'detailed', label: t('detailed') },
];

export const orderStatusSelectOptions = (): SelectOption[] => [
  { value: 'all', label: t('all') },
  { value: 'approved', label: t('approved') },
  { value: 'notApproved', label: t('notApproved') },
  { value: 'cancelled', label: t('cancelled') },
];

export const orderFreightTypeSelectOptions = (): SelectOption[] => [
  { value: 'all', label: t('all') },
  { value: 'CIF', label: t('CIF') },
  { value: 'FOB', label: t('FOB') },
];

export const exportedErpSelectOptions = (): SelectOption[] => [
  { value: 'all', label: t('all') },
  { value: 's', label: t('yes') },
  { value: 'n', label: t('not') },
  { value: 'i', label: t('intermediary') },
  { value: 'e', label: t('error') },
];

export const orderOriginSelectOptions = (): SelectOption[] => [
  { value: 'all', label: t('all') },
  { value: '-1', label: t('portalFastmanager') },
  { value: '-2', label: t('external') },
  { value: '-3', label: t('edi') },
  { value: '-4', label: t('ifood') },
  { value: '-5', label: t('smartsellEcommerce') },
];
