import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer, Seller } from '../../../../../../types/apiResponseTypes';

import {
  getCurrentDateTime,
  getSpecificField,
  getYearMoreN,
} from '../../../../../../utils/helpers';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import { AxiosError } from 'axios';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
  advantageClubAPI,
  customersApi,
  goalsReq,
  sellersApi,
} from '../../../../../../services/requests';
import { AdvantageClub } from '../../../../../../types';
import { Goal } from '../../../../../../types/apiResponse/goal';
import {
  SelectOption,
  SelectOptionSpecValue,
  getGoalTypeSelectOption,
  getOptionsFromAdvantageClub,
  getOptionsFromCustomers,
  getOptionsFromSellers,
  selectOptionMonths,
} from '../../../../../../utils/getSelectOptions';

const yearsRangeWithLabel = () => {
  const yearsSelectOptions: any = [];

  getYearMoreN().forEach((year) => {
    yearsSelectOptions.push({
      value: year,
      label: year.toString(),
    });
  });

  return yearsSelectOptions;
};

type Props = ModalProps & {
  initialGoal: Goal;
  closeModal: () => void;
  callback: () => void;
};

const CreateOrEditGoalModal = ({ initialGoal, closeModal, callback, ...props }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [goal, setGoal] = useState<Goal>(initialGoal);

  const [year, setYear] = useState<SelectOption | null>(null);
  const [month, setMonth] = useState<SelectOption | null>(null);

  const [sellersOptions, setSellersOptions] = useState<Seller[]>([]);
  const [customersOptions, setCustomersOptions] = useState<Customer[]>([]);
  const [advantageClub, setAdvantageClub] = useState<SelectOptionSpecValue<AdvantageClub> | null>(
    null,
  );

  const formatedDate = () => {
    if (year && month) {
      return `${year.value}-${month.value}-01`;
    }

    return null;
  };

  /**
   * Create or edit data (request)
   */
  const handleSubmit = async () => {
    setLoading(true);
    try {
      validation();
      cleanAndOrganize();

      const data = {
        ...goal,
        date: formatedDate(),
        sellerCodes: getSpecificField('value', sellersOptions),
        customerCodes: getSpecificField('value', customersOptions),
        advantageClubId: advantageClub ? advantageClub.value.id : null,
      };

      const response = await goalsReq.store(data);
      if (response.status === HttpStatus.CREATED) {
        frontendNotification({
          message: t('successfullyCreated'),
          type: 'success',
        });

        callback();
      } else {
        throw response;
      }
    } catch (err) {
      if (!goalsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }
    setLoading(false);
  };

  const validation = () => {
    if (goal.description?.length == 0) throw new Error(t('enterADescription'));

    if (sellersOptions.length == 0 && customersOptions.length == 0)
      throw new Error(t('selectAtLeastOneSellerOrOneCustomer'));

    if (goal.goalType === 'DEFAULT' && !goal.start) throw new Error(t('setTheStartDate'));

    if (goal.goalType === 'SPECIFIC_MONTH' && (!month || !year))
      throw new Error(t('selectTheYearAndTheMonth'));

    if (goal.goalType === 'ADVANTAGE_CLUB' && !advantageClub)
      throw new Error(t('selectAAdvantageClub'));

    if (goal.goalType === 'ADVANTAGE_CLUB' && goal.weight <= 0 && goal.packages <= 0)
      throw new Error(t('enterAGoal'));

    if (
      goal.goalType != 'ADVANTAGE_CLUB' &&
      goal.weight <= 0 &&
      goal.packages <= 0 &&
      goal.coverage <= 0 &&
      goal.positivation <= 0 &&
      goal.amount <= 0
    )
      throw new Error(t('enterAGoal'));
  };

  const cleanAndOrganize = () => {
    if (goal.goalType == 'DEFAULT') {
      setYear(yearsRangeWithLabel()[0]);
      setMonth(null);
      setGoal({ ...goal, advantageClubId: null });
      setAdvantageClub(null);
    }

    if (goal.goalType == 'SPECIFIC_MONTH') {
      setGoal({ ...goal, advantageClubId: null });
      setGoal({ ...goal, start: null });
      setGoal({ ...goal, end: null });
      setAdvantageClub(null);
    }

    if (goal.goalType == 'ADVANTAGE_CLUB') {
      setYear(yearsRangeWithLabel()[0]);
      setMonth(null);
      setGoal({ ...goal, start: null });
      setGoal({ ...goal, end: null });
    }
  };

  return (
    <Modal
      {...props}
      action={() => handleSubmit()}
      isLoading={loading}
      closeModal={closeModal}
      cancelButton={closeModal}
      bodyStyle={{ overflowX: 'scroll' }}
      title={goal.id ? t('editGoal') : t('createGoal')}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('goalType')}</label>
            <SingleSelect
              placeholder={t('goalType')}
              options={getGoalTypeSelectOption()}
              onChange={(o: any) => setGoal({ ...goal, goalType: o.value })}
              value={
                getGoalTypeSelectOption().find((i) => i.value == goal.goalType) ??
                getGoalTypeSelectOption()[0]
              }
              isDisabled={loading}
            />
          </div>

          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('description')}</label>
            <input
              type="text"
              value={goal.description ?? ''}
              onChange={(e) => setGoal({ ...goal, description: e.target.value })}
              disabled={loading}
            />
          </div>
        </div>

        {goal.goalType == 'DEFAULT' && (
          <>
            <div className="row">
              <div className="itemFormWithLabel">
                <label className="inputLabel">{t('start')}</label>
                <input
                  className="input"
                  type="datetime-local"
                  value={goal.start ?? undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGoal({ ...goal, start: e.target.value })
                  }
                  min={getCurrentDateTime()}
                  disabled={loading}
                />
              </div>

              <div className="itemFormWithLabel">
                <label className="inputLabel">{t('end')}</label>
                <input
                  className="input"
                  type="datetime-local"
                  value={goal.end ?? undefined}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setGoal({ ...goal, end: e.target.value })
                  }
                  min={getCurrentDateTime()}
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )}

        {goal.goalType == 'SPECIFIC_MONTH' && (
          <>
            <div className="row">
              <div className="itemFormWithLabel">
                <label className="inputLabel">{t('selectYear')}</label>
                <SingleSelect
                  placeholder={t('filterYear')}
                  options={yearsRangeWithLabel()}
                  onChange={(v: any) => setYear(v)}
                  value={year}
                  isDisabled={loading}
                />
              </div>

              <div className="itemFormWithLabel">
                <label className="inputLabel">{t('selectMonths')}</label>

                <SingleSelect
                  options={selectOptionMonths()}
                  value={month}
                  onChange={(v: any) => setMonth(v)}
                  placeholder={t('filterTheMonth')}
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )}

        {goal.goalType == 'ADVANTAGE_CLUB' && (
          <div className="row">
            <div className="itemFormWithLabel">
              <label className="inputLabel">{t('advantageClub')}</label>
              <SelectAsyncJSX
                value={advantageClub}
                onChange={setAdvantageClub}
                request={advantageClubAPI}
                reqResponseToOption={getOptionsFromAdvantageClub}
                placeholder={t('selectTheAdvantageClub')}
                disabled={loading}
              />
            </div>
          </div>
        )}

        <hr className="division" style={{ margin: '1rem' }} />

        <div className="row">
          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('selectTheSellers')}</label>
            <SelectAsyncJSX
              value={sellersOptions}
              onChange={setSellersOptions}
              request={sellersApi}
              reqResponseToOption={getOptionsFromSellers}
              placeholder={t('filterSellers')}
              disabled={loading}
              isMulti
            />
          </div>

          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('selectTheCustomers')}</label>

            <SelectAsyncJSX
              value={customersOptions}
              onChange={setCustomersOptions}
              request={customersApi}
              reqResponseToOption={getOptionsFromCustomers}
              placeholder={t('filterTheCustomers')}
              disabled={loading}
              isMulti
            />
          </div>
        </div>

        <hr className="division" style={{ margin: '1rem' }} />

        <div className="row">
          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('enterWeight')} (kg)</label>
            <input
              type="number"
              min={0}
              className="input"
              value={goal.weight}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGoal({ ...goal, weight: Number(e.target.value) })
              }
              disabled={loading}
            />
          </div>

          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('enterNumberPackages')}</label>
            <input
              type="number"
              min={0}
              className="input"
              value={goal.packages}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGoal({
                  ...goal,
                  packages: Number(e.target.value),
                })
              }
              disabled={loading}
            />
          </div>
        </div>

        <div className="row" style={goal.goalType === 'ADVANTAGE_CLUB' ? { display: 'none' } : {}}>
          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('enterPositivation')} (%)</label>
            <input
              type="number"
              min={0}
              className="input"
              value={goal.positivation}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGoal({ ...goal, positivation: Number(e.target.value) })
              }
              disabled={loading}
            />
          </div>

          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('enterCoverage')} (%)</label>
            <input
              type="number"
              min={0}
              className="input"
              value={goal.coverage}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGoal({
                  ...goal,
                  coverage: Number(e.target.value),
                })
              }
              disabled={loading}
            />
          </div>
        </div>

        <div className="row" style={goal.goalType === 'ADVANTAGE_CLUB' ? { display: 'none' } : {}}>
          <div className="itemFormWithLabel">
            <label className="inputLabel">{t('enterAmount')}</label>
            <input
              type="number"
              className="input"
              value={goal.amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setGoal({ ...goal, amount: Number(e.target.value) })
              }
              disabled={loading}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateOrEditGoalModal;
