import { Container } from 'reactstrap';
import * as UploadComponent from '../../../components/Upload';
import * as s from './styles';

const Upload = () => (
  <Container>
    <s.Title>Gerenciamento de upload csv</s.Title>
    {/* <h1 className={styles.upload_title}>Gerenciamento de upload csv</h1> */}

    <UploadComponent.Csv />
  </Container>
);
export default Upload;
