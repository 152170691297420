import { Serie } from '@nivo/line';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartBar, FaStoreAltSlash } from 'react-icons/fa';
import { LuMapPin, LuMapPinOff } from 'react-icons/lu';
import { useTheme } from 'styled-components';

import { MdRemoveShoppingCart, MdShoppingCart } from 'react-icons/md';
import AsyncTable, { Column, RequestPage } from '../../../../../../components/AsyncTable';
import { backendErrorNotification } from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import useDashboardFilters from '../../../../../../hooks/useDashboardFilters';
import { visitHistoryApi, visitMetricsApi } from '../../../../../../services/requests';
import { VisitHistory } from '../../../../../../types/apiResponse/visitHistory';
import { ApiResPaginated } from '../../../../../../types/apiResponseTypes';
import { getPercent } from '../../../../../../utils/helpers';
import CardIcon from './Components/CardIcon';
import GraphLineSchedules from './Components/GraphLineSchedules';
import Loader from './Components/Loader';
import * as s from './styles';
import { VisitMetrics } from './types';

const Main = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const {
    filters: { filters },
  } = useDashboardFilters();

  const initialData: VisitMetrics = {
    graphData: [],
    metrics: {
      salesVisits: 0,
      noSalesVisits: 0,
      missedVisits: 0,
      totalVisits: 0,
      checkinsWithinRadius: 0,
      checkinsOutsideRadius: 0,
    },
  };

  const [metrics, setMetrics] = useState<VisitMetrics>(initialData);
  const [visitHistories, setVisitHistories] = useState<VisitHistory[]>([]);
  const [visitsFilterCategory, setVisitsFilterCategory] = useState<string[]>(['allVisits']);

  const [isLoading, setIsLoading] = useState(false);

  const columns: Column<VisitHistory>[] = [
    {
      label: t('id'),
      accessor: 'id',
    },
    {
      label: t('type') as string,
      accessor: 'FlagTipo',
    },
    {
      label: t('sellerCode') as string,
      accessor: 'CodigoVendedor',
    },
    {
      label: t('customerCode') as string,
      accessor: 'CodigoCliente',
    },
    {
      label: t('scheduledDate') as string,
      accessor: 'DataAgendada',
    },
    {
      label: t('checkin') as string,
      accessor: 'sistematica_checkin_realizado',
      Cell: (row) => (row.sistematica_checkin_realizado ? t('yes') : t('not')),
    },
    {
      label: t('checkout') as string,
      accessor: 'sistematica_checkout_realizado',
      Cell: (row) => (row.sistematica_checkout_realizado ? t('yes') : t('not')),
    },
    {
      label: t('note') as string,
      accessor: 'Observacao',
    },
  ];

  const requestDataForAsyncTable: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = visitHistoryApi.axios.CancelToken.source();

    try {
      let query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;

      if (visitsFilterCategory.length > 0 && !visitsFilterCategory.includes('allVisits')) {
        query += `&filter[types]=${visitsFilterCategory.join(',')}`;
      }

      const availableFilters = ['years', 'months', 'sellers', 'customers'];

      availableFilters.forEach((filterName) => {
        const f = filters as any;
        if (f[filterName] && f[filterName].length > 0) {
          query += `&filter[${filterName}]=${f[filterName].join(',')}`;
        }
      });

      visitHistoryApi.query = query;

      const res = await visitHistoryApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<VisitHistory[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!visitHistoryApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const getMetrics = async () => {
    setIsLoading(true);

    const source = visitMetricsApi.axios.CancelToken.source();

    let query = '?q=q'; //q=q apenas para iniciar a query, nao filtra nada

    const availableFilters = ['years', 'months', 'sellers', 'customers'];

    availableFilters.forEach((filterName) => {
      const f = filters as any;
      if (f[filterName] && f[filterName].length > 0) {
        query += `&filter[${filterName}]=${f[filterName].join(',')}`;
      }
    });

    visitMetricsApi.query = query;

    try {
      const res = await visitMetricsApi.index(source.token);

      if (res.status === HttpStatus.OK) {
        const { data }: { data: VisitMetrics } = res.data;
        setMetrics(data);
      } else {
        throw res;
      }
    } catch (err) {
      if (!visitMetricsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
    return () => {
      source.cancel('Component got unmounted');
    };
  };

  useEffect(() => {
    getMetrics();
  }, [filters]);

  /**
     * Essa funcao ficou complexa entao vale o comentario:

     --- Se o filtro selecionado for "allVisits" e já estiver presente, nada
     mais é feito. Pois nao pode ficar sem filtro.

     --- Se "allVisits" for selecionado e não estiver presente, ele é adicionado
     e todos os outros filtros são removidos. Pois nao faz sentido ter "allVisits"
     e outro filtro (all ja é tudo).

     --- Se outro filtro for selecionado e "allVisits" estiver presente,
     "allVisits" é removido e o novo filtro é adicionado. Pois nesse caso se
     nao remover allVisits, nunca vai filtrar so pelo novo filtro, sempre vai
     trazer tudo.

     --- Se um filtro for removido e isso deixar o array vazio, "allVisits"
     é adicionado automaticamente. Pois tem que ter pelo menos 1 filtro.

     --- Se um filtro que não é "allVisits" for selecionado e ele
     já estiver no array, ele é removido e verifica-se se o array ficou
     vazio para possivelmente adicionar "allVisits".
     */
  const handleFilterCategory = (categoryName: string) => {
    setVisitsFilterCategory((prevCategories) => {
      const allVisitsIndex = prevCategories.indexOf('allVisits');

      if (categoryName === 'allVisits') {
        // Caso 'allVisits' seja clicado
        if (allVisitsIndex !== -1) {
          // Se já contém 'allVisits', não faz nada
          return prevCategories;
        } else {
          // Adiciona 'allVisits' e remove todos os outros filtros
          return ['allVisits'];
        }
      } else {
        // Se outro filtro for selecionado
        if (allVisitsIndex !== -1) {
          // Se 'allVisits' estiver presente, remove 'allVisits' e adiciona o novo filtro
          return [categoryName];
        } else {
          // Se 'allVisits' não estiver, adiciona ou remove o filtro clicado
          const index = prevCategories.indexOf(categoryName);
          if (index !== -1) {
            // Se já contém o filtro, remove-o
            const newCategories = prevCategories.filter((category) => category !== categoryName);
            if (newCategories.length === 0) {
              // Se a lista ficar vazia, adiciona 'allVisits'
              return ['allVisits'];
            } else {
              return newCategories;
            }
          } else {
            // Se não contém, adiciona o filtro
            return [...prevCategories, categoryName];
          }
        }
      }
    });
  };

  const checkIfFilterIsSet = (categoryName: string): boolean => {
    const fixedCategory = 'allVisits';

    return (
      visitsFilterCategory.includes(categoryName) || visitsFilterCategory.includes(fixedCategory)
    );
  };

  const handlePercents = (type: string) => {
    switch (type) {
      case 'salesVisits':
        return getPercent(metrics.metrics.totalVisits, metrics.metrics.salesVisits);
      case 'noSalesVisits':
        return getPercent(metrics.metrics.totalVisits, metrics.metrics.noSalesVisits);
      case 'missedVisits':
        return getPercent(metrics.metrics.totalVisits, metrics.metrics.missedVisits);
      case 'checkinsWithinRadius':
        return getPercent(metrics.metrics.totalVisits, metrics.metrics.checkinsWithinRadius);
      case 'checkinsOutsideRadius':
        return getPercent(metrics.metrics.totalVisits, metrics.metrics.checkinsOutsideRadius);
      default:
        return 0;
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <s.Pag1Container>
        <s.GraphsContainer>
          <GraphLineSchedules data={metrics.graphData as Serie[]} />
        </s.GraphsContainer>

        <s.InfoCards>
          <CardIcon
            color="#6c63ff"
            icon={<FaChartBar />}
            title={t('totalVisits')}
            text={metrics.metrics.totalVisits as unknown as string}
            onClick={() => handleFilterCategory('allVisits')}
            disabled={!checkIfFilterIsSet('allVisits')}
          />

          <CardIcon
            color="#1D98DF"
            icon={<MdShoppingCart />}
            title={t('salesVisits')}
            text={`${metrics.metrics.salesVisits} | ${handlePercents('salesVisits')}%`}
            onClick={() => handleFilterCategory('ve')}
            disabled={!checkIfFilterIsSet('ve')}
          />

          <CardIcon
            color="#cfa705"
            icon={<MdRemoveShoppingCart />}
            title={t('noSalesVisits')}
            text={`
                            ${metrics.metrics.noSalesVisits} | ${handlePercents('noSalesVisits')}%
                        `}
            onClick={() => handleFilterCategory('sve')}
            disabled={!checkIfFilterIsSet('sve')}
          />

          <CardIcon
            color="#CD1719"
            icon={<FaStoreAltSlash />}
            title={t('missedVisits')}
            text={`
                            ${metrics.metrics.missedVisits} | ${handlePercents('missedVisits')}%
                        `}
            onClick={() => handleFilterCategory('svi')}
            disabled={!checkIfFilterIsSet('svi')}
          />

          <CardIcon
            color="#19870f"
            icon={<LuMapPin />}
            title={t('checkinsWithinRadius')}
            text={`
                            ${metrics.metrics.checkinsWithinRadius} |
                            ${handlePercents('checkinsWithinRadius')}
                        %`}
            onClick={() => handleFilterCategory('checkinsWithinRadius')}
            disabled={!checkIfFilterIsSet('checkinsWithinRadius')}
          />

          <CardIcon
            color="#DE7223"
            icon={<LuMapPinOff />}
            title={t('checkinsOutsideRadius')}
            text={`
                            ${metrics.metrics.checkinsOutsideRadius} |
                            ${handlePercents('checkinsOutsideRadius')}%
                        `}
            onClick={() => handleFilterCategory('checkinsOutsideRadius')}
            disabled={!checkIfFilterIsSet('checkinsOutsideRadius')}
          />
        </s.InfoCards>

        <s.TablesArea>
          <div className="top-visits-performers-table">
            <AsyncTable
              tableName={t('visitSchedule')}
              columns={columns}
              value={visitHistories}
              onChange={setVisitHistories}
              requestPage={requestDataForAsyncTable}
              reqListener={[filters, visitsFilterCategory]}
              options={{
                styles: {
                  primaryColor: `${theme.colors.surface}`,
                  secondaryColor: `${theme.colors.onSurface}`,
                  alternateRowColor: theme.colors.textLight,
                  textColor: theme.colors.text,
                },
                quantityPerPageLabel: t('quantityPerPage'),
                searchPlaceholder: t('search'),
              }}
            />
          </div>
        </s.TablesArea>
      </s.Pag1Container>
    </Loader>
  );
};

export default Main;
