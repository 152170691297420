/* eslint-disable default-param-last */
import { AuthAction, AuthState } from './types';

const initalState: AuthState = {
  loadingSigninRequest: false,
  isSignin: false,
  error: false,
  token: null,
  user: null,
  expirationDate: 0,
  integrationModels: [],
};

// ssignable to type 'Reducer<AuthState, any>'.
//       Types of parameters 'action' and 'state' are incompatible.
//         Type 'AuthState | undefined' is not assignable to type

export default function auth(state = initalState, action: AuthAction): AuthState {
  switch (action.type) {
    case '@auth/SIGN_IN_REQUEST':
      return {
        ...state,
        loadingSigninRequest: true,
      };
    case '@auth/SIGN_IN_BY_TOKEN_REQUEST':
      return {
        ...state,
        isSignin: true,
        loadingSigninRequest: true,
      };
    case '@auth/SUPERADMIN_SIGN_IN_REQUEST':
      return {
        ...state,
        loadingSigninRequest: true,
      };

    case '@auth/SIGN_IN_SUCCESS':
      return {
        ...state,
        loadingSigninRequest: false,
        error: false,
        isSignin: true,
        token: action.payload.token,
        user: action.payload.user,
        expirationDate: action.payload.expirationDate,
      };

    case '@auth/SET_INTEGRATION_MODELS':
      return {
        ...state,
        integrationModels: action.payload.IntegrationModels,
      };

    case '@auth/SIGN_IN_FAILURE':
      return {
        ...state,
        loadingSigninRequest: false,
        error: true,
      };

    case '@auth/LOGOUT_REQUEST':
      return {
        ...state,
        token: null,
        user: null,
        isSignin: false,
        loadingSigninRequest: true,
      };

    case '@auth/SUPERADMIN_LOGOUT_REQUEST':
      return {
        ...state,
        token: null,
        user: null,
        isSignin: false,
        loadingSigninRequest: true,
      };

    case '@auth/LOGOUT_SUCCESS':
      return {
        ...state,
        loadingSigninRequest: false,
        error: false,
      };
    case '@auth/REFRESH_REQUEST':
      return {
        ...state,
        loadingSigninRequest: true,
      };
    case '@auth/REFRESH_SUCCESS':
      return {
        ...state,
        error: false,
        loadingSigninRequest: false,
        token: action.payload.token,
      };
    case '@auth/REFRESH_FAILURE':
      return {
        ...state,
        loadingSigninRequest: false,
        error: true,
      };
    case '@auth/SET_STATUS_LOADING_LOGIN':
      return {
        ...state,
        loadingSigninRequest: action.payload.status,
      };

    default:
      return state;
  }
}
