import { t } from 'i18next';
import ExpandableData from '../../../../../components/ExpandableData';
import { Order } from '../../../../../types/apiResponse/order';

type Props = {
  order: Order;
};

const Commercial = ({ order }: Props) => {
  return (
    <div>
      <ExpandableData title={t('main')}>
        <ul>
          <li>
            <b>{t('orderNumber') as string}:</b>&nbsp;{order.NumeroPedido}
          </li>
          <li>
            <b>{t('datetime') as string}:</b>&nbsp;{order.DataPedido}&nbsp;{order.HoraInicial}
          </li>
          <li>
            <b>{t('shippingDatetime') as string}:</b>&nbsp;{order.DataHoraEnvio}
          </li>
          <li>
            <b>{t('paymentMethod') as string}:</b>&nbsp;{order.payment_method?.Descricao}
          </li>
          <li>
            <b>{t('financialDiscount') as string}:</b>&nbsp;{order.Desconto1}
          </li>
          <li>
            <b>{t('note') as string}:</b>&nbsp;{order.Obs1}
          </li>
          <li>
            <b>{t('onderNumberDevice') as string}:</b>&nbsp;{order.NumeroPedidoDisp}
          </li>
          <li>
            <b>{t('paymentCondition') as string}:</b>&nbsp;{order.paymentcondition?.Descricao}
          </li>
          <li>
            <b>{t('expectedDeliveryDate') as string}:</b>&nbsp;{order.DataEntrega}
          </li>
          <li>
            <b>{t('orderType') as string}:</b>&nbsp;{order.order_type?.DescricaoTipoPed}
          </li>
        </ul>
      </ExpandableData>
      <ExpandableData title={t('seller')}>
        <ul>
          <li>
            <b>{t('orderNumber') as string}:</b>&nbsp;{order.NumeroPedido}
          </li>
        </ul>
      </ExpandableData>
      <ExpandableData title={t('priceTable')}>
        <ul>
          <li>
            <b>{t('orderNumber') as string}:</b>&nbsp;{order.NumeroPedido}
          </li>
        </ul>
      </ExpandableData>
      <ExpandableData title={t('orderItems')}>
        <ul>
          <li>
            <b>{t('orderNumber') as string}:</b>&nbsp;{order.NumeroPedido}
          </li>
        </ul>
      </ExpandableData>
      <ExpandableData title={t('export')}>
        <ul>
          <li>
            <b>{t('orderNumber') as string}:</b>&nbsp;{order.NumeroPedido}
          </li>
        </ul>
      </ExpandableData>
    </div>
  );
};

export default Commercial;
