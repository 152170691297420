import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Column } from 'react-table';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import Btn from '../../../../../../components/Buttons/Btn';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import TablePaginator from '../../../../../../components/TablePaginator';
import HttpStatus from '../../../../../../enums/httpStatus';
import i18n from '../../../../../../i18n';
import { commGroupReq, customersApi, sellersApi } from '../../../../../../services/requests';
import * as TP from '../../../../../../types/apiResponseTypes';
import {
  getOptionsFromCustomers,
  getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';
import { getSpecificField } from '../../../../../../utils/helpers';
import { isValidEmail } from '../../../../../../utils/validations';
import * as s from './styles';

type Props = {
  closeModal: () => void;
  reqGetCommGroups: () => void;
  commGroupSelected: TP.CommunicationGroup;
};

type Option = {
  value: any;
  label: string;
};

type ColumnType = TP.CommGroupRecipient & {
  idTemp: string;
};

const ModalEditCommGroupJSX = ({ closeModal, reqGetCommGroups, commGroupSelected }: Props) => {
  const { t } = useTranslation();

  const [waitingResponse, setWaitingResponse] = useState(false);
  const [waitingGetSellerCustomer] = useState(false);

  const [description, setDescription] = useState('');
  const [senderEmail, setSenderEmail] = useState('');

  const [type, setType] = useState({ value: 'seller', label: t('seller') });
  const [receiver, setReceiver] = useState<Option | Record<string, unknown>>({});
  const [receiverEmail, setReceiverEmail] = useState('');

  const [recipients, setRecipients] = useState<ColumnType[] | []>([]);

  const [selectedRows, setSelectedRows] = useState<ColumnType[] | []>([]);

  const reqGetRecipients = async () => {
    setWaitingResponse(true);

    const source = commGroupReq.axios.CancelToken.source();

    try {
      const res = await commGroupReq.show(commGroupSelected.id, source.token);

      if (res.status === HttpStatus.OK) {
        const copyRecipients: ColumnType[] | [] = [];

        res.data.data.recipients.forEach((element: any) => {
          copyRecipients.push({
            idTemp: `${element.recipient_type}
                        -${element.recipient_id}-${element.email}`,
            recipient_type: element.recipient_type as never,
            recipient_id: element.recipient_id as never,
            email: element.email as never,
          } as never);
        });

        setRecipients(copyRecipients);
      } else {
        throw res;
      }

      setWaitingResponse(false);
    } catch (err) {
      if (!customersApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingResponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  useEffect(() => {
    setDescription(commGroupSelected.description);
    setSenderEmail(commGroupSelected.email_from);
    reqGetRecipients();
  }, []);

  const reqEditCommGroup = async () => {
    if (!isValidEmail(senderEmail)) {
      frontendNotification({
        message: t('invalidEmail'),
        type: 'warning',
      });
      return () => null;
    }

    setWaitingResponse(true);

    const source = commGroupReq.axios.CancelToken.source();

    const recipientsToReq = recipients.map((element) => ({
      type: element.recipient_type,
      id: element.recipient_id,
      email: element.email,
    }));

    try {
      const res = await commGroupReq.update(
        {
          description,
          email_from: senderEmail,
          recipients: recipientsToReq,
        },
        commGroupSelected.id,
      );

      if (res.status === HttpStatus.OK) {
        setWaitingResponse(false);
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        closeModal();
        reqGetCommGroups();
      } else {
        throw res;
      }

      setWaitingResponse(false);
    } catch (err) {
      if (!commGroupReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingResponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  const handleAddBtn = () => {
    if (!isValidEmail(receiverEmail)) {
      frontendNotification({
        message: t('invalidEmail'),
        type: 'warning',
      });
      return;
    }

    if (!receiver.value) {
      frontendNotification({
        message: t('receiverIsRequired'),
        type: 'warning',
      });
      return;
    }

    const copyRecipients: ColumnType[] | [] = [...recipients];
    const id = `${type.value}-${receiver.value}-${receiverEmail}`;

    if (copyRecipients.some((obj) => obj.idTemp === id)) {
      frontendNotification({
        message: t('alreadyExists'),
        type: 'warning',
      });
    } else {
      copyRecipients.push({
        idTemp: id,
        recipient_type: type.value as never,
        recipient_id: receiver.value as never,
        email: receiverEmail as never,
      } as never);

      setRecipients(copyRecipients);
      setReceiver({});
      setReceiverEmail('');
    }
  };

  useEffect(() => {
    setReceiver({});
    setReceiverEmail('');
  }, [type]);

  const columns: Column<ColumnType>[] = useMemo(
    () => [
      {
        Header: t('type') as string,
        accessor: 'recipient_type',
        Cell: ({ row }: any) => t(row.original.recipient_type),
      },
      {
        Header: t('receiver') as string,
        accessor: 'recipient_id',
      },
      {
        Header: t('email') as string,
        accessor: 'email',
        style: { overflow: 'hidden' },
      },
    ],
    [i18n.language],
  );

  const removeItemsAction = () => {
    let copyRecipients: ColumnType[] | [] = [...recipients];

    selectedRows.forEach((element) => {
      copyRecipients = copyRecipients.filter((obj) => obj.idTemp !== element.idTemp);
    });

    setRecipients(copyRecipients);
  };

  const loadOptions = async (search: any, _loadedOptions: any, { page }: any) => {
    let options: any = [];

    if (type.value === 'seller') {
      sellersApi.query = `?search=${search}&page=${page}`;
      const res = await sellersApi.index();
      options = getOptionsFromSellers(res.data.data);
    } else {
      customersApi.query = `?search=${search}&page=${page}`;
      const res = await customersApi.index();
      options = getOptionsFromCustomers(res.data.data);
    }

    return {
      options,
      hasMore: true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <Modal
      title={t('update')}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => reqEditCommGroup()}
      isLoading={waitingResponse || waitingGetSellerCustomer}
      bodyStyle={{ overflowX: 'scroll' }}
    >
      <s.ContainerModalEdit>
        <s.Line className="first-line">
          <s.Item>
            <p>{t('description')}</p>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              disabled={waitingResponse}
            />
          </s.Item>
          <s.Item>
            <p>{t('senderEmail')}</p>
            <Input
              value={senderEmail}
              onChange={(e: any) => setSenderEmail(e.target.value)}
              disabled={waitingResponse}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <p>{t('type')}</p>
            <SingleSelect
              value={type}
              options={[
                { value: 'seller', label: t('seller') },
                { value: 'customer', label: t('customer') },
              ]}
              onChange={setType as any}
              disabled={waitingResponse}
              style={{ minWidth: '150px' }}
            />
          </s.Item>

          <s.Item>
            <p>{t('receiver')}</p>
            <AsyncPaginate
              value={receiver}
              onChange={(e: any) => setReceiver(e)}
              className="select-async"
              loadOptions={loadOptions}
              additional={{
                page: 1,
              }}
              cacheUniqs={[type]}
            />
          </s.Item>

          <s.Item>
            <p>{t('email')}</p>
            <Input
              value={receiverEmail}
              onChange={(e: any) => setReceiverEmail(e.target.value)}
              style={{ minWidth: '150px' }}
              disabled={waitingResponse}
            />
          </s.Item>

          <s.Item className="add-btn">
            <AddBtn onClick={() => handleAddBtn()} />
          </s.Item>
        </s.Line>

        {selectedRows.length >= 1 && (
          <Btn text={t('removeItems')} onClick={() => removeItemsAction()} />
        )}

        <s.Line>
          <TablePaginator
            data={recipients}
            columns={columns}
            globalFiltering
            style={{ width: '100%', marginTop: '2rem' }}
            selecteds={(row: any) =>
              setSelectedRows(getSpecificField('original', row) as ColumnType[])
            }
          />
        </s.Line>
      </s.ContainerModalEdit>
    </Modal>
  );
};

export default ModalEditCommGroupJSX;
