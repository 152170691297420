import { action } from 'typesafe-actions';
import { IntegrationModel } from '../../../types/apiResponseTypes';

type SignInRequestProps = {
  login: string;
  password: string;
};

type SignInByTokenRequestProps = {
  token: string;
  urlPath: string;
};

export type UserProps = {
  id: number;
  name: string;
  username: string | null;
  email: string | null;
  email_verified_at: string | null;
  tenant_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  isSuperAdmin: boolean;
  isTenantAdmin?: boolean;
  roles: any[];
};

export type IntegrationModelsProp = IntegrationModel[] | [];

type SignInSuccessProps = {
  token: string;
  user: UserProps;
  expirationDate: number;
};

type RefreshSuccessProps = {
  newToken: string;
};

type RefreshRequestProps = {
  accessToken: string;
};

export function signInRequest({ login, password }: SignInRequestProps) {
  return action('@auth/SIGN_IN_REQUEST', {
    login,
    password,
  });
}

export function signInByTokenRequest({ token, urlPath }: SignInByTokenRequestProps) {
  return action('@auth/SIGN_IN_BY_TOKEN_REQUEST', {
    token,
    urlPath,
  });
}

export function superadminSignInRequest({ login, password }: SignInRequestProps) {
  return action('@auth/SUPERADMIN_SIGN_IN_REQUEST', {
    login,
    password,
  });
}

export function signInSuccess({ token, user, expirationDate }: SignInSuccessProps) {
  return action('@auth/SIGN_IN_SUCCESS', {
    token,
    user,
    expirationDate,
  });
}

export function setIntegrationModels(IntegrationModels: IntegrationModelsProp) {
  return action('@auth/SET_INTEGRATION_MODELS', {
    IntegrationModels,
  });
}

export function logoutRequest() {
  return action('@auth/LOGOUT_REQUEST');
}

export function superadminLogoutRequest() {
  return action('@auth/SUPERADMIN_LOGOUT_REQUEST');
}

export function logoutSuccess() {
  return action('@auth/LOGOUT_SUCCESS');
}

export function signInFailure() {
  return action('@auth/SIGN_IN_FAILURE');
}

export function refreshRequest({ accessToken }: RefreshRequestProps) {
  return action('@auth/REFRESH_REQUEST', {
    accessToken,
  });
}

export function refreshSuccess({ newToken }: RefreshSuccessProps) {
  return action('@auth/REFRESH_SUCCESS', {
    token: newToken,
  });
}

export function refreshFailure() {
  return action('@auth/REFRESH_FAILURE');
}

export function setStatusLoadingLogin(status: boolean) {
  return action('@auth/SET_STATUS_LOADING_LOGIN', {
    status,
  });
}
