import { useState } from 'react';
import { FaRegImage } from 'react-icons/fa';
import { MdClose, MdPhoneIphone } from 'react-icons/md';
import mobilePreviewImg from '../../../../../../assets/images/MobilePreviewFastmanager.svg';
import { Smartbanner } from '../../../../../../types/apiResponseTypes';
import * as s from './styles';

type Props = {
  bannerSelected: Smartbanner;
  closeImgPreview: () => void;
};

export const BannerPreviewComp = ({ bannerSelected, closeImgPreview }: Props) => {
  const [btnSelected, setBtnSelected] = useState('fullImage');

  const handleBtnBannerPreview = (previewName: string) => {
    setBtnSelected(previewName);
  };
  return (
    <s.BannerPreviewContainer>
      <s.ContentPreviewContainer>
        <s.Header>
          <s.CloseBtn onClick={() => closeImgPreview()}>
            <MdClose />
          </s.CloseBtn>
        </s.Header>
        {btnSelected === 'fullImage' ? (
          <s.FullImagePreview>
            <img src={bannerSelected.image} alt={bannerSelected.description} />
          </s.FullImagePreview>
        ) : (
          <s.MobilePreview img={bannerSelected.image}>
            <div>
              <img src={mobilePreviewImg} alt="mobile preview" />
              <div className="bannerAreaPreview" />
            </div>
          </s.MobilePreview>
        )}

        <s.Footer>
          <FaRegImage
            onClick={() => handleBtnBannerPreview('fullImage')}
            className={`btnBannerPreview ${btnSelected === 'fullImage' ? 'selected' : ''}`}
          />

          <MdPhoneIphone
            onClick={() => handleBtnBannerPreview('mobilePreview')}
            className={`btnBannerPreview ${btnSelected === 'mobilePreview' ? 'selected' : ''}`}
          />
        </s.Footer>
      </s.ContentPreviewContainer>
    </s.BannerPreviewContainer>
  );
};

export default BannerPreviewComp;
