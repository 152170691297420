import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.surface};
  color: ${(props) => props.theme.colors.onSurface};

  position: absolute;
  right: 25px;
  z-index: 5;

  border-radius: 8px;
  border: 1px solid #a0aec0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 8px;

  cursor: pointer;
  transition-duration: 0.3s;

  :hover {
    background-color: ${(props) => props.color};
  }

  :active {
    background-color: ${(props) => props.color};
    opacity: 0.5;
  }

  > p {
    margin-left: 5px;
  }
`;

export default Container;
