import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import { motiveVisitAPI } from '../../../../../../services/requests';
import { MotiveVisit } from '../../../../../../types';
import { getOptionsMotivesSchedule } from '../../../../../../utils/getSelectOptions';
import * as s from './styles';

type Props = {
  selectedItem: MotiveVisit;
  closeModal: () => void;
  reqCallback: () => void;
};

const ModalEdit = ({ selectedItem, closeModal, reqCallback }: Props) => {
  const { t } = useTranslation();

  const [waitingRequest, setWaitingRequest] = useState(false);

  const [description, setDescription] = useState(selectedItem.description);
  const [motiveType, setMotiveType] = useState(
    getOptionsMotivesSchedule().filter((obj) => obj.value === selectedItem.motiveFlag)[0],
  );

  const reqEdit = async () => {
    setWaitingRequest(true);
    const source = motiveVisitAPI.axios.CancelToken.source();
    try {
      const res = await motiveVisitAPI.update(
        {
          motiveFlag: motiveType.value,
          description,
        },
        selectedItem.id,
      );

      if (res.status === HttpStatus.OK) {
        setWaitingRequest(false);
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        closeModal();
        reqCallback();
      } else {
        throw res;
      }
    } catch (err) {
      if (!motiveVisitAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitingRequest(false);
    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={t('update')}
      showModal
      closeModal={closeModal}
      action={() => reqEdit()}
      isLoading={waitingRequest}
      bodyStyle={{ overflowX: 'scroll' }}
    >
      <s.ContainerModalEdit>
        <s.Line className="first-line">
          <s.Item>
            <p>{t('type')}</p>
            <SingleSelect
              value={motiveType}
              options={getOptionsMotivesSchedule()}
              onChange={setMotiveType as any}
              disabled={waitingRequest}
              style={{ minWidth: '150px' }}
            />
          </s.Item>
        </s.Line>
        <s.Line>
          <s.Item>
            <p>{t('description')}</p>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              disabled={waitingRequest}
            />
          </s.Item>
        </s.Line>
      </s.ContainerModalEdit>
    </Modal>
  );
};

export default ModalEdit;
