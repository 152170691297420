import { PropsWithChildren } from 'react';
import * as s from './styles';

interface LoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
  !isLoading ? (
    <div>{children}</div>
  ) : (
    <s.Pag1Container1>
      <s.GraphsContainer1 id="ldg1" />
      <s.ConversionRate1 id="ldg1" />
      <s.InfoCards1>
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
      </s.InfoCards1>
      <s.ExtraInfos1 id="ldg1" />
      <s.TopCustomers1 id="ldg1" />
    </s.Pag1Container1>
  );

export default Loader;
