import styled from 'styled-components';
import { defaultShadow } from '../../styles/global';

export const ChangeLanguageContainer = styled.div`
  width: 4rem;
  /* height: 2.4rem; */

  display: flex;

  border-radius: 0.4rem;
  margin: 0.5rem;

  transition: 0.5s;
  :hover {
    ${defaultShadow}
  }

  > select {
    padding: 0;
  }
`;

export default ChangeLanguageContainer;
