import { PropsWithChildren, useState } from 'react';
import { SlArrowDown, SlArrowLeft, SlArrowRight, SlArrowUp } from 'react-icons/sl';
import ExpandableDataContainer from './styles';

type Props = {
  direction?: 'down' | 'right';
  title?: string;
};

const ExpandableData = ({ children, direction = 'down', title = '' }: PropsWithChildren<Props>) => {
  const [dataIsVisible, setDataIsVisible] = useState(true);

  const handleClosedIcon = () => {
    return direction === 'down' ? <SlArrowDown /> : <SlArrowRight />;
  };

  const handleOpenedIcon = () => {
    return direction === 'down' ? <SlArrowUp /> : <SlArrowLeft />;
  };

  return (
    <ExpandableDataContainer direction={direction} dataIsVisible={dataIsVisible}>
      <div className="expandable-button" onClick={() => setDataIsVisible(!dataIsVisible)}>
        {direction === 'down' ? title : ''}&nbsp;
        {dataIsVisible ? handleOpenedIcon() : handleClosedIcon()}
        &nbsp;
      </div>

      {dataIsVisible && <div className="expandable-content">{children}</div>}
    </ExpandableDataContainer>
  );
};

export default ExpandableData;
