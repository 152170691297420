import { Order, OrdersFiltersInFrontend } from '../../../../../../types/apiResponse/order';

export type ColumnConfig = {
  key: keyof Order;
  label: string;
  fixed: boolean;
  visible: boolean;
  subItems: {
    key: string;
    label: string;
    visible: boolean;
  }[];
};

export const defaultOrderFilters: OrdersFiltersInFrontend = {
  filterType: 'detailed',
  orderNumber: '',
  orderStatus: 'all',
  isBudget: false,
  orderTypeCode: '',
  orderTypeDescription: '',
  billingUnitCode: '',
  tenantManagerCode: '',
  tenantManagerDescription: '',
  tenantSupervisorCode: '',
  tenantSupervisorDescription: '',
  freightType: 'all',
  orderOriginCode: 'all',
  sellerCode: '',
  customerCode: '',
  exportedErp: '',
  initialOrderCreationDate: '',
  finalOrderCreationDate: '',
  initialOrderSendDate: '',
  finalOrderSendDate: '',
  initialOrderDeliveryDate: '',
  finalOrderDeliveryDate: '',
  initialOrderBillingDate: '',
  finalOrderBillingDate: '',
};

export const defaultColumnsConfig: ColumnConfig[] = [
  {
    key: 'NumeroPedido',
    label: 'Número do pedido',
    fixed: true,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoEmpresa',
    label: 'Código da empresa',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataPedido',
    label: 'Data do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'HoraInicial',
    label: 'Hora inicial',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'HoraFinal',
    label: 'Hora final',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataEntrega',
    label: 'Data de entrega',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataFaturamento',
    label: 'Data de faturamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'customer',
    label: 'Cliente',
    fixed: true,
    visible: true,
    subItems: [
      {
        key: 'CodigoCliente',
        label: 'Codigo Cliente',
        visible: true,
      },
      {
        key: 'RazaoSocial',
        label: 'Razão social',
        visible: true,
      },
      {
        key: 'CodigoCanalCliente',
        label: 'Código canal',
        visible: true,
      },
      {
        key: 'channel_description',
        label: 'Descrição do canal',
        visible: true,
      },
    ],
  },
  {
    key: 'CodigoCliente',
    label: 'Código do cliente',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'CodigoTipoPedido',
    label: 'Código do tipo de pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoCondPagto',
    label: 'Código da condição de pagamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoFormaPagto',
    label: 'Código da forma de pagamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoEnderecoEntrega',
    label: 'Código do endereço de entrega',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoTransportador',
    label: 'Código do transportador',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoUnidFat',
    label: 'Código da unidade de faturamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'billing_unit',
    label: 'Unidade de faturamento',
    fixed: false,
    visible: true,
    subItems: [
      {
        key: 'CodigoUnidFat',
        label: 'Código',
        visible: true,
      },
      {
        key: 'DescricaoUnidFat',
        label: 'Descrição',
        visible: true,
      },
      {
        key: 'UF',
        label: 'UF',
        visible: true,
      },
    ],
  },
  {
    key: 'CodigoPromocao',
    label: 'Código da promoção',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'CodigoTabPreco',
    label: 'Código da tabela de preço',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'TipoFretePadrao',
    label: 'Tipo de frete padrão',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoNomeFrete',
    label: 'Código do nome do frete',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'OrdemCompra',
    label: 'Ordem de compra',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Obs1',
    label: 'Observação 1',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Obs2',
    label: 'Observação 2',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'FormaEntrega',
    label: 'Forma de entrega',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Complemento1',
    label: 'Complemento 1',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Complemento2',
    label: 'Complemento 2',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Desconto1',
    label: 'Desconto 1',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Desconto2',
    label: 'Desconto 2',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ValorLiquido',
    label: 'Valor líquido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ValorBruto',
    label: 'Valor bruto',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Bloqueado',
    label: 'Bloqueado',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'NumeroPedidoDisp',
    label: 'Número do pedido disponível',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHoraEnvio',
    label: 'Data e hora de envio',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHoraLiberacao',
    label: 'Data e hora de liberação',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoUsuarioLiberacao',
    label: 'Código do usuário de liberação',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHoraExclusao',
    label: 'Data e hora de exclusão',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'seller',
    label: 'Vendedor',
    fixed: false,
    visible: true,
    subItems: [
      {
        key: 'CodigoVendedor',
        label: 'Codigo vendedor',
        visible: true,
      },
      {
        key: 'Nome',
        label: 'Nome',
        visible: true,
      },
    ],
  },
  {
    key: 'CodigoVendedor',
    label: 'Código do vendedor',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'BonifNF',
    label: 'Bonificação na nota fiscal',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'CodigoUsuarioCancelamento',
    label: 'Código do usuário de cancelamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'MCPedido',
    label: 'MC Pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'MixPedido',
    label: 'Mix do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ObsCancelamento',
    label: 'Observação de cancelamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'UsuarioPedido',
    label: 'Usuário do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_tipo_pedido',
    label: 'Aprovação do tipo de pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_limite_credito',
    label: 'Aprovação do limite de crédito',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_limite_credito_obs',
    label: 'Observação da aprovação do limite de crédito',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_titulos',
    label: 'Aprovação dos títulos',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_titulos_obs',
    label: 'Observação da aprovação dos títulos',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_bloqueio_cliente',
    label: 'Aprovação do bloqueio do cliente',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_preco',
    label: 'Aprovação do preço',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_preco_auto',
    label: 'Aprovação automática do preço',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_bloqueio_cliente_obs',
    label: 'Observação do bloqueio do cliente',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHora_sincronizacao_status',
    label: 'Data e hora de sincronização de status',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'codigo_pedido_pai',
    label: 'Código do pedido pai',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_venclimitecred',
    label: 'Aprovação do vencimento do limite de crédito',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_venclimitecred_obs',
    label: 'Observação do vencimento do limite de crédito',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_venc_limitecred',
    label: 'Aprovação do vencimento do limite de crédito (alternativo)',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_venc_limitecred_obs',
    label: 'Observação do vencimento do limite de crédito (alternativo)',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'justificativa_pedido_nao_aprovado',
    label: 'Justificativa para pedido não aprovado',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aceita_pedido_parcial',
    label: 'Aceita pedido parcial',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'pedido_codigo_endereco',
    label: 'Código do endereço do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_przmedio',
    label: 'Aprovação de prazo médio',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_przmedio_obs',
    label: 'Observação do prazo médio',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_pedmin',
    label: 'Aprovação de pedido mínimo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_pedmin_vlr',
    label: 'Valor do pedido mínimo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_pedmin_obs',
    label: 'Observação do pedido mínimo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'qtd_itens',
    label: 'Quantidade de itens',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'pedido_ocorrencia',
    label: 'Ocorrência do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ExportadoErp',
    label: 'Exportado para ERP',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'ExportadoErpErro',
    label: 'Erro na exportação para ERP',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'ErpNumeroPedido',
    label: 'Número do pedido no ERP',
    fixed: false,
    visible: false,
    subItems: [],
  },
  {
    key: 'erp_info',
    label: 'Informações do ERP',
    fixed: false,
    visible: true,
    subItems: [
      {
        key: 'ErpNumeroPedido',
        label: 'Número do pedido no ERP',
        visible: true,
      },
      {
        key: 'ExportadoErp',
        label: 'Exportado',
        visible: true,
      },
      {
        key: 'ExportadoErpErro',
        label: 'Mensagem de exportação',
        visible: true,
      },
    ],
  },
  {
    key: 'aprov_ped_automatica',
    label: 'Aprovação automática do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ean_loc_comp',
    label: 'EAN de localização para compra',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ean_loc_cob_fat',
    label: 'EAN de localização para cobrança e faturamento',

    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ean_loc_entrega',
    label: 'EAN de localização para entrega',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ValorComissao',
    label: 'Valor da comissão',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_bloqueio_cliente_novo_sem_compra',
    label: 'Aprovação do bloqueio de cliente novo sem compra',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_bloqueio_cliente_novo_sem_compra_obs',
    label: 'Observação do bloqueio de cliente novo sem compra',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataEmissao',
    label: 'Data de emissão',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataCorte',
    label: 'Data de corte',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataCarga',
    label: 'Data de carga',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'RazaoSocial',
    label: 'Razão social',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'ean_loc_fornec',
    label: 'EAN de localização do fornecedor',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'IsClienteNovo',
    label: 'É cliente novo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'codigoClienteDestinatario',
    label: 'Código do cliente destinatário',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataSaida',
    label: 'Data de saída',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'valor_margem_comissao',
    label: 'Valor da margem de comissão',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'PesoPedido',
    label: 'Peso do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'EmailPedido',
    label: 'Email do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'Num_processo',
    label: 'Número do processo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'EOrcamento',
    label: 'É orçamento',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'PossuiSaldo',
    label: 'Possui saldo',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'SaldoPedido',
    label: 'Saldo do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'baixaEcommerce',
    label: 'Baixa em e-commerce',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'msgBaixaEcommerce',
    label: 'Mensagem de baixa em e-commerce',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHoraInicioTransferencia',
    label: 'Data e hora de início da transferência',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'DataHoraFimTransferencia',
    label: 'Data e hora de fim da transferência',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'RentabilidadePedido',
    label: 'Rentabilidade do pedido',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_rentabilidade',
    label: 'Aprovação da rentabilidade',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'aprov_rentabilidade_obs',
    label: 'Observação da rentabilidade',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'valor_do_frete',
    label: 'Valor do frete',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'valor_de_descarga',
    label: 'Valor de descarga',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'formHash',
    label: 'Hash do formulário',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'id_contrato',
    label: 'ID do contrato',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'data_vigencia_contrato',
    label: 'Data de vigência do contrato',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'deleted_at',
    label: 'Deletado em',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'created_at',
    label: 'Criado em',
    fixed: false,
    visible: true,
    subItems: [],
  },
  {
    key: 'updated_at',
    label: 'Atualizado em',
    fixed: false,
    visible: true,
    subItems: [],
  },
];
