import { MdFileCopy } from 'react-icons/md';
import { RiEyeLine, RiMailSendLine } from 'react-icons/ri';
import styled, { css } from 'styled-components';

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    transition: 0.3s;
    color: ${(p) => p.theme.colors.onSecondary}80;

    :hover {
      color: ${(p) => p.theme.colors.primary}80;
    }
  }
`;
const sharedBtnActionStyles = css`
  width: 15px;
  height: 15px;
  margin: 0 1rem 0 1rem;
  cursor: pointer;
  color: ${(p) => p.theme.colors.onSurface};
`;

export const RiEyeLineIcon = styled(RiEyeLine)`
  ${sharedBtnActionStyles}
  :hover {
    color: ${(p) => p.theme.colors.warning};
  }
`;

export const MdFileCopyIcon = styled(MdFileCopy)`
  ${sharedBtnActionStyles}
  :hover {
    color: ${(p) => p.theme.colors.positive};
  }
`;

export const RiMailSendLineIcon = styled(RiMailSendLine)`
  ${sharedBtnActionStyles}
  :hover {
    color: ${(p) => p.theme.colors.positive};
  }
`;
