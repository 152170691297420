import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { t } from 'i18next';

type Props = {
  value: string | null;
  onChange: (value: any) => void;
  disablePast?: boolean;
  disabled?: boolean;
};

export const BasicDatePicker = ({ value, onChange, disablePast, disabled }: Props) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      label={t('selectDate') as string}
      inputFormat="DD/MM/YYYY"
      views={['day']}
      disablePast={disablePast}
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => <TextField {...params} />}
      disabled={disabled}
    />
  </LocalizationProvider>
);

BasicDatePicker.defaultProps = {
  disablePast: true,
  disabled: false,
};

export default BasicDatePicker;
