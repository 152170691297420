import { FiltersContextProvider } from '../../context/filters';
import * as s from './styles';
import * as tp from './types';

import Sidebar from '../Sidebar';
import items from '../Sidebar/items';
import HeaderBar from './HeaderBar';

export const ContentManager = ({
  navbar = false,
  permissionProfile = 'tenant',
  Component,
}: tp.ContentManager) => {
  const SidebarItemsManager = () => {
    switch (permissionProfile) {
      case 'tenant':
        return items.Tenant();
      case 'seller':
        return items.Tenant();
      case 'customer':
        return items.Tenant();
      case 'superadmin':
        return items.Superadmin();
      default:
        return items.Tenant();
    }
  };

  const comp = (): JSX.Element => (
    <s.MainContainer>
      <Sidebar items={SidebarItemsManager()} />

      <s.MainViewContainer>
        <HeaderBar />
        <Component />
      </s.MainViewContainer>
    </s.MainContainer>
  );

  return navbar ? (
    <FiltersContextProvider>{comp()}</FiltersContextProvider>
  ) : (
    <s.MainViewContainer>
      <Component />
    </s.MainViewContainer>
  );
};

export default ContentManager;
