import { PropsWithChildren } from 'react';
import * as s from './styles';

interface LoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
  !isLoading ? (
    <div>{children}</div>
  ) : (
    <s.Pag1ContainerLoader>
      <s.GraphsContainerLoader id="ldg1" />
      <s.InfoCardsLoader>
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
      </s.InfoCardsLoader>
      <s.TablesAreaLoader id="ldg1" />
    </s.Pag1ContainerLoader>
  );

export default Loader;
