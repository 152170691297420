import axios from 'axios';
import { getAccessToken } from '../../authApi';

export const getSellers = async () => {
  const bearerToken = getAccessToken();
  const apiUrl = process.env.REACT_APP_API;

  const response = await axios.request({
    method: 'GET',
    url: `${apiUrl}/api/v1/sellers?quantityPerPage=100000`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
  });

  return response;
};

export default getSellers;
