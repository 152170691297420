import React from 'react';

import * as s from './styles';

type CardIconProps = {
  color: string;
  icon: JSX.Element;
  title: string;
  text: string;
  className?: string;
};

const CardIcon: React.FC<CardIconProps & React.HTMLAttributes<HTMLDivElement>> = ({
  color,
  icon,
  title,
  text,
  className,
}: CardIconProps) => (
  <s.CardContainer background={color} className={className}>
    <div className="card-icon-1354">{icon}</div>

    <div className="card-info-1354">
      <div className="card-info-title-1354">{title}</div>
      <div className="card-info-value-1354">{text}</div>
    </div>
  </s.CardContainer>
);

CardIcon.defaultProps = {
  className: '',
};

export default CardIcon;
