import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { ediLayoutApi } from '../../../../../services/requests';
import { EdiLayoutPersisted } from '../../../../../types/apiResponse/edi';

type Props = {
  selectedItem: EdiLayoutPersisted;
  onClose: () => void;
  callback: () => void;
};

const ConfirmDeleteModal = ({ selectedItem, onClose, callback }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);

    try {
      ediLayoutApi.query = '';
      const res = await ediLayoutApi.delete(selectedItem.id);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        callback();
        onClose();
      } else {
        throw res;
      }
    } catch (err) {
      if (!ediLayoutApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  }, [selectedItem, onClose, callback]);

  return (
    <Modal
      title={`${t('delete')}`}
      action={() => handleDelete()}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={t('delete')}
      isLoading={isLoading}
    >
      <h3>{`${t('deleteSure') as string}?`}</h3>
    </Modal>
  );
};

export default ConfirmDeleteModal;
