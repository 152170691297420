import { t } from 'i18next';
import { useState } from 'react';
import { CheckClipboardIcon, EmailIcon, SplitIcon, ViewDetailsIcon } from '../../../../../../icons';
import { Order } from '../../../../../../types/apiResponse/order';
import BudgeToOrderModal from '../../../Modals/BudgetToOrderModal';
import OrderDetailsModal from '../../../Modals/OrderDetailsModal';
import OrderEmailLogModal from '../../../Modals/OrderEmailLogModal';
import OrderFractionalItemsModal from '../../../Modals/OrderFractionalItemsModal';
import ColumnsVisibilityToggle from './ColumnsVisibilityToggle';
import TableRowEffect from './TableRowEffect';

type Props = {
  orders: Order[];
  className?: string;
  reloadOrders: () => void;
};

const OrderActions = ({ orders, className = '', reloadOrders }: Props) => {
  const [areColumnsVisible, setAreColumnsVisible] = useState(true);

  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [showOrderFractionalItemsModal, setShowOrderFractionalItemsModal] = useState(false);
  const [showBudgeToOrderModal, setShowBudgeToOrderModal] = useState(false);
  const [isVisibleEmailLogModal, setIsVisibleEmailLogModal] = useState(false);
  const [orderToAction, setOrderToAction] = useState<Order | undefined>();

  const handleColumnsAreaClass = () => {
    return areColumnsVisible ? '' : 'display-none';
  };

  const viewDetailsIconHandleClick = (order: Order) => {
    setShowOrderDetailsModal(true);
    setOrderToAction(order);
  };

  const fractionationIconHandleClick = (order: Order) => {
    setShowOrderFractionalItemsModal(true);
    setOrderToAction(order);
  };

  const handleClickBudgeToOrder = (order: Order) => {
    setShowBudgeToOrderModal(true);
    setOrderToAction(order);
  };

  const handleClickViewEmailLogIcon = (order: Order) => {
    setIsVisibleEmailLogModal(true);
    setOrderToAction(order);
  };

  const handleActionsCallback = () => {
    reloadOrders();
  };

  if (orders.length === 0) {
    return null;
  }

  return (
    <div className={`orders-table-container ${className}`}>
      <ColumnsVisibilityToggle visible={areColumnsVisible} setVisible={setAreColumnsVisible} />

      <TableRowEffect numberOfrows={orders.length} />

      <div className="order-table-header-division" />

      <div className={`orders-table-columns-area ${handleColumnsAreaClass()}`}>
        <div className="orders-table-column">
          <div className="orders-table-column-header visibility-hidden">
            {t('actions') as string}
          </div>
          <div className="orders-table-sub-columns-area">
            <div className="orders-table-sub-colmun">
              <div className="orders-table-sub-colmun-header">{t('actions') as string}</div>
              <div className="orders-table-data-list-area">
                {orders.map((order) => (
                  <div className="orders-table-row" key={order.NumeroPedido}>
                    <div className="orders-table-row-value">
                      <div className="orders-table-actions-row">
                        <div className="orders-table-action-item">
                          <ViewDetailsIcon
                            title={t('viewDetails')}
                            onClick={() => viewDetailsIconHandleClick(order)}
                          />
                        </div>

                        <div className="orders-table-action-item">
                          <SplitIcon
                            title={t('fractionation')}
                            onClick={() => fractionationIconHandleClick(order)}
                          />
                        </div>

                        {order.EOrcamento === 's' && (
                          <div className="orders-table-action-item">
                            <CheckClipboardIcon
                              title={t('convertBudgetToOrder')}
                              onClick={() => handleClickBudgeToOrder(order)}
                            />
                          </div>
                        )}

                        {order.EmailPedido && (
                          <div className="orders-table-action-item">
                            <EmailIcon
                              title={t('emailLog')}
                              onClick={() => handleClickViewEmailLogIcon(order)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {orderToAction && showOrderDetailsModal && (
        <OrderDetailsModal
          showModal={showOrderDetailsModal}
          closeModal={() => {
            setOrderToAction(undefined);
            setShowOrderDetailsModal(false);
          }}
          order={orderToAction}
        />
      )}

      {orderToAction && showOrderFractionalItemsModal && (
        <OrderFractionalItemsModal
          showModal={showOrderFractionalItemsModal}
          closeModal={() => {
            setOrderToAction(undefined);
            setShowOrderFractionalItemsModal(false);
          }}
          order={orderToAction}
        />
      )}

      {orderToAction && showBudgeToOrderModal && (
        <BudgeToOrderModal
          showModal={showBudgeToOrderModal}
          closeModal={() => {
            setOrderToAction(undefined);
            setShowBudgeToOrderModal(false);
          }}
          callback={() => handleActionsCallback()}
          order={orderToAction}
        />
      )}

      {orderToAction && isVisibleEmailLogModal && (
        <OrderEmailLogModal
          showModal={isVisibleEmailLogModal}
          closeModal={() => {
            setOrderToAction(undefined);
            setIsVisibleEmailLogModal(false);
          }}
          order={orderToAction}
        />
      )}
    </div>
  );
};

export default OrderActions;
