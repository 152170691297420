import { t } from 'i18next';
import { useState } from 'react';
import Modal from '../../../../../components/Modal';
import NavMenu from '../../../../../components/NavMenu';
import { NavMenyItem } from '../../../../../components/NavMenu/types';
import { Order } from '../../../../../types/apiResponse/order';
import ApprovalHistory from './ApprovalHistory';
import ClientCredit from './ClientCredit';
import ClientSecurities from './ClientSecurities';
import Commercial from './Commercial';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  order: Order;
};

const OrderDetailsModal = ({ showModal, closeModal, order }: Props) => {
  const [tabSelected, setTabSelected] = useState({
    value: 'commercial',
    name: 'commercial',
  });

  const navMenuItems: NavMenyItem[] = [
    {
      title: t('commercial'),
      action: () =>
        setTabSelected({
          value: 'commercial',
          name: 'commercial',
        }),
    },
    {
      title: t('clientCredit'),
      action: () =>
        setTabSelected({
          value: 'client_credit',
          name: 'client_credit',
        }),
    },
    {
      title: t('clientSecurities'),
      action: () =>
        setTabSelected({
          value: 'client_securities',
          name: 'client_securities',
        }),
    },
    {
      title: t('approvalHistory'),
      action: () =>
        setTabSelected({
          value: 'approval_history',
          name: 'approval_history',
        }),
    },
  ];

  const handleViewPag = (pagName: string) => {
    switch (pagName) {
      case 'commercial':
        return <Commercial order={order} />;
      case 'client_credit':
        return <ClientCredit />;
      case 'client_securities':
        return <ClientSecurities />;
      case 'approval_history':
        return <ApprovalHistory />;
      default:
        return <Commercial order={order} />;
    }
  };

  return (
    <Modal
      title={`${t('orderDetails')}: ${order.NumeroPedido}`}
      showModal={showModal}
      action={() => null}
      closeModal={closeModal}
      onlyCloseButton
      bodyStyle={{ width: '100%' }}
    >
      <NavMenu items={navMenuItems} />
      {handleViewPag(tabSelected.value)}
    </Modal>
  );
};

export default OrderDetailsModal;
