import { PropsWithChildren } from 'react';
import TableSkeleton from '../../../components/TableSkeletonLoader';

interface LoaderProps {
  isLoading: boolean;
}

const LoaderJSX = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
  !isLoading ? <div>{children}</div> : <TableSkeleton isSearch isBottons />;

export default LoaderJSX;
