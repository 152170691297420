import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';

type Props = {
  visible: boolean;
  setVisible: (v: boolean) => void;
};

const ColumnsVisibilityToggle = ({ visible, setVisible }: Props) => {
  return (
    <div className="orders-table-columns-visibility-toggle" onClick={() => setVisible(!visible)}>
      {visible ? <MdOutlineArrowBackIos /> : <MdOutlineArrowForwardIos />}
    </div>
  );
};

export default ColumnsVisibilityToggle;
