import { t } from 'i18next';
import { useState } from 'react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { BsPin, BsPinFill } from 'react-icons/bs';
import { MdVisibility } from 'react-icons/md';
import { ReactSortable } from 'react-sortablejs';

import Modal from '../../../../../../components/Modal';
import ReactSortableItem from '../../../../../../components/ReactSortableItem';
import { ColumnConfig } from '../constants/defaultColumnsConfig';

type Props = {
  visible?: boolean;
  closeModal: () => void;
  columnsConfig: ColumnConfig[];
  setColumnsConfig: (v: ColumnConfig[]) => void;
};

type ColumnConfigSortable = ColumnConfig & {
  id: string;
};

const OrdersColumnsConfig = ({
  closeModal,
  visible = false,
  columnsConfig,
  setColumnsConfig,
}: Props) => {
  const [columnsConfigSortabel, setColumnsConfigSortabel] = useState<ColumnConfigSortable[]>(() => {
    return columnsConfig.map((c) => ({ ...c, id: c.key }));
  });

  const handleVisibity = (itemArrayIndex: number) => {
    const columnsConfigSortableCopy = [...columnsConfigSortabel];
    columnsConfigSortableCopy[itemArrayIndex].visible =
      !columnsConfigSortableCopy[itemArrayIndex].visible;

    setColumnsConfigSortabel(columnsConfigSortableCopy);
  };

  const handleFixation = (itemArrayIndex: number) => {
    const columnsConfigSortableCopy = [...columnsConfigSortabel];
    columnsConfigSortableCopy[itemArrayIndex].fixed =
      !columnsConfigSortableCopy[itemArrayIndex].fixed;

    setColumnsConfigSortabel(columnsConfigSortableCopy);
  };

  const handleSave = () => {
    setColumnsConfig(columnsConfigSortabel);
    closeModal();
  };

  return (
    <Modal
      title={t('tableConfig')}
      showModal={visible}
      closeModal={closeModal}
      cancelButton={closeModal}
      actionNameBtn={t('save')}
      action={() => handleSave()}
      bodyStyle={{ overflow: 'scroll' }}
    >
      <ReactSortable list={columnsConfigSortabel} setList={setColumnsConfigSortabel}>
        {columnsConfigSortabel.map((column, index) => (
          <ReactSortableItem key={column.id}>
            <div key={column.key} className="order-config-sortable-item">
              <div>{column.key}</div>

              <div className="order-config-sortable-actions">
                <div className="order-config-sortable-actions-item">
                  {column.fixed ? (
                    <BsPinFill onClick={() => handleFixation(index)} />
                  ) : (
                    <BsPin onClick={() => handleFixation(index)} />
                  )}
                </div>
                <div className="order-config-sortable-actions-item">
                  {column.visible ? (
                    <MdVisibility onClick={() => handleVisibity(index)} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={() => handleVisibity(index)} />
                  )}
                </div>
              </div>
            </div>
          </ReactSortableItem>
        ))}
      </ReactSortable>
    </Modal>
  );
};

export default OrdersColumnsConfig;
