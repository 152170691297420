import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(p) => p.theme.colors.onBackground}10;

  flex: 1;
  position: relative;
  display: flex;

  border: 1px solid ${(p) => p.theme.colors.onBackground}30;
  padding: 1rem;
  padding-left: 2.5rem;
  margin: 0.5rem;
  border-radius: 8px;
  transition: box-shadow 0.25s cubic-bezier(0, 0, 0.2, 1);
`;

export const DragArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: move;

  height: calc(100% + 2px);
  background-color: ${(p) => p.theme.colors.primary};
  position: absolute;
  left: -1px;
  top: -1px;
  width: 2rem;
  border-radius: 8px 0 0 8px;

  > * {
    color: ${(p) => p.theme.colors.onPrimary};
    width: 100%;
    height: 2rem;
  }
`;

export const Item = styled.div`
  flex: 1;
  margin: 0.5rem;
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
`;
