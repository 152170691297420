import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { superSmartsellAppVersionsApi } from '../../../../../services/requests';

import InputFile from '../../../../../components/InputFile';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  callback: () => void;
};

const isValidVersion = (input: string): boolean => {
  const versionPattern = /^\d+(\.\d+){2,3}$/;
  return versionPattern.test(input);
};

export const AddAppVersionModal = ({ showModal, handleClose, callback }: Props) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [version, setVersion] = useState('');
  const [file, setFile] = useState<HTMLInputElement | null>(null);

  const handleSaveBtn = async () => {
    if (!isValidVersion(version)) {
      frontendNotification({
        message: t('invalidVersionFormat'),
        type: NotificationTypes.WARNING,
      });
      return;
    }

    setIsLoading(true);
    try {
      const appVersionData = {
        description,
        version,
        platform: 'android',
      };
      superSmartsellAppVersionsApi.query = '';
      const res = await superSmartsellAppVersionsApi.postWithFile(file, 'file', appVersionData);

      if (res.status === HttpStatus.CREATED || res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: NotificationTypes.SUCCESS,
        });

        callback();
        handleClose();
      } else {
        throw res;
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title={t('upload')}
      showModal={showModal}
      closeModal={handleClose}
      action={() => handleSaveBtn()}
      isLoading={isLoading}
      bodyStyle={{ overflow: 'scroll' }}
      cancelButton={handleClose}
    >
      <div className="row">
        <div className="inputWithLabel">
          <p className="label">{t('description') as string}</p>
          <input
            className="input"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </div>
        <div className="inputWithLabel">
          <p className="label">
            {t('version') as string} ({t('example') as string}: 1.15.3)
          </p>
          <input
            className="input"
            value={version}
            onChange={(e) => setVersion(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="inputWithLabel">
          <p className="label">{t('file') as string}</p>
          <InputFile value={file} setFile={setFile} disabled={isLoading} />
        </div>
      </div>
    </Modal>
  );
};

export default AddAppVersionModal;
