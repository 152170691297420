import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { smartbannersReq } from '../../../../../../services/requests';
import { Smartbanner } from '../../../../../../types/apiResponseTypes';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';

type Props = ModalProps & {
  reqGetAllBanners: () => void;
  closeModal: () => void;
  bannerSelected: Smartbanner;
};

const DeleteBannerModal = ({ reqGetAllBanners, closeModal, bannerSelected, ...props }: Props) => {
  const { t } = useTranslation();

  const [awaitReqDeleteBanner, setAwaitReqDeleteBanner] = useState(false);

  const reqDeleteBanner = async () => {
    setAwaitReqDeleteBanner(true);
    try {
      const { id } = bannerSelected;

      const response = await smartbannersReq.delete(id);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        reqGetAllBanners();
        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!smartbannersReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqDeleteBanner(false);
  };

  return (
    <Modal
      {...props}
      action={() => reqDeleteBanner()}
      isLoading={awaitReqDeleteBanner}
      closeModal={closeModal}
    >
      <p>
        {`${t('deleteSure')} (${t('banner')})?`}
        <br />
        <br />
        <b>ID:</b> {bannerSelected.id}
        <br />
        <b>{t('description')}: </b> {bannerSelected.description}
      </p>
    </Modal>
  );
};

export default DeleteBannerModal;
