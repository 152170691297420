import styled from 'styled-components';
import { defaultShadow } from '../../styles/global';

interface SidebarContainerProps {
  isSidebarOpen?: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  width: ${(p) => (p.isSidebarOpen ? '20%' : '5%')};
  max-width: 20rem;
  min-width: ${(p) => (p.isSidebarOpen ? '230px' : '5%')};
  height: 100%;
  overflow: hidden;

  background-color: ${(p) => p.theme.colors.surface};

  display: flex;

  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-shrink: 1s;

  margin-right: ${(p) => (p.isSidebarOpen ? '2rem' : '0')};

  transition-duration: 1s;
  transition: background-color 0s ease;

  @media (max-width: 1024px) {
    position: absolute;
    left: ${(p) => (p.isSidebarOpen ? '0' : '-33%')};
    z-index: 15;

    width: ${(p) => (p.isSidebarOpen ? '100%' : '5%')};
  }

  .sidebar-header {
    ${(p) => !p.isSidebarOpen && 'justify-content: center;'};
    > h1 {
      display: ${(p) => (p.isSidebarOpen ? 'inline' : 'none')};
      margin-right: ${(p) => (p.isSidebarOpen ? 'auto' : '0')};
    }
  }

  ${defaultShadow}
`;

export const HeaderSidebar = styled.div<SidebarContainerProps>`
  max-width: 100%;
  display: flex;
  align-items: center;

  padding: 1.5rem;
  margin-bottom: 1rem;
  transition-duration: 0.5s;
  transition: background-color 0s ease;

  background-color: ${(p) => p.theme.colors.primary};

  & > h1 {
    transition-duration: 0.5s;
    transition: color 0s ease;

    color: ${(p) => p.theme.colors.onPrimary};
    font-weight: 600;
    font-size: 1.6rem;
  }

  .expand-button {
    cursor: pointer;

    > * {
      color: ${(p) => p.theme.colors.onPrimary};
      width: 1.8rem;
      height: 100%;
    }
  }
`;

export const ItemsContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
`;

export const FooterSidebar = styled.div<SidebarContainerProps>`
  background-color: ${(p) => p.theme.colors.surface};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  border-top: 1px Solid #bbb;
  padding: ${(p) => (p.isSidebarOpen ? '1rem' : '0')};
  padding-bottom: ${(p) => (p.isSidebarOpen ? '' : '0.1rem')};

  ${(p) => !p.isSidebarOpen && 'flex-wrap: wrap;'};

  z-index: 20;

  > * {
    ${(p) => !p.isSidebarOpen && 'margin-bottom: 1rem;'};
  }
`;
