import styled from 'styled-components';

interface Props {
  disabled?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;

  cursor: ${(p) => (p.disabled ? 'no-drop' : 'pointer')};
  opacity: ${(p) => (p.disabled ? '0.5' : '1')};

  > input {
    display: none;
  }
`;

export const Label = styled.p`
  margin: 0;
  margin-left: 0.5rem;
  opacity: 0.5;
`;

export default Container;
