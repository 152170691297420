import { } from '../../../utils/helpers';
import { Props, frontendNotification } from '../../Notification';
import { NotificationTypes } from '../../Notification/notificationEnums';

const validadeCsvType = (file: File) => {
  const filesSplited = file.name.split('.');
  const ext = filesSplited[1];

  const extTypes = ['xlsx', 'csv', 'xls'];

  let notify: Props;

  if (!extTypes.includes(ext)) {
    notify = {
      message: 'extensao do arquivo invalido',
      type: NotificationTypes.WARNING,
    };

    frontendNotification(notify);
    return false;
  }

  return true;
};

export default validadeCsvType;
