import styled, { css } from 'styled-components';
import { defaultShadow } from '../../../styles/global';

interface SidebarProps {
  isSidebarOpen?: boolean;
  isPathSelected?: boolean;
}

const SidebarItemGeneric = css<SidebarProps>`
  min-height: 45px;

  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${defaultShadow}
    cursor: pointer;
    filter: brightness(110%);
  }

  #sidebar-item-icon {
    margin: ${(p) => (p.isSidebarOpen ? '0' : 'auto')};
  }

  #sidebar-item-title {
    margin-left: 1rem;
    > * {
      display: ${(p) => (p.isSidebarOpen ? '-webkit-box' : 'none')};
      margin: auto;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  #sidebar-item-arrow {
    margin-left: auto;
    margin-right: 1rem;

    position: ${(p) => (p.isSidebarOpen ? 'relative' : 'absolute')};
    left: ${(p) => (p.isSidebarOpen ? '0' : '70%')};

    > * {
      margin: ${(p) => (p.isSidebarOpen ? 'auto' : '0px')};
    }
  }
`;

export const SidebarItemContainer = styled.div<SidebarProps>`
  ${SidebarItemGeneric}

  padding-left: 15px;
  background: ${(p) => p.theme.colors.surface};
  color: ${(p) => p.theme.colors.onSurface};
  font-size: 1.4rem;

  ${(p) =>
    p.isPathSelected
      ? `
				border-left: 5px solid ${p.theme.colors.primary};
				padding-left: 10px;
			`
      : ''};

  &:hover {
    border-left: 5px solid ${(p) => p.theme.colors.primary};
    padding-left: 10px;
  }
`;

export const SidebarSubItemContainer = styled.div<SidebarProps>`
  ${SidebarItemGeneric}

  padding-left: 15px;
  background: ${(p) => p.theme.colors.onSurface}50;
  color: ${(p) => p.theme.colors.onSurface};
  font-size: 1.2rem;

  ${(p) =>
    p.isPathSelected
      ? `
				border-left: 5px solid ${p.theme.colors.primary};
				padding-left: 10px;
			`
      : ''};

  &:hover {
    border-left: 10px solid ${(p) => p.theme.colors.primary};
    padding-left: 5px;
  }

  ${(p) =>
    p.isSidebarOpen &&
    `
        #sidebar-item-icon {
            visibility: hidden;
        }
    `}
`;
