import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../enums/httpStatus';
import { integrationConfigReq, templatesReq } from '../../../../../services/requests';
import Loader from './Loader';
import * as s from './styles';

import Btn from '../../../../../components/Buttons/Btn';
import InfoButton from '../../../../../components/InfoButton';
import Modal from '../../../../../components/Modal';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import TextareaJSX from '../../../../../components/TextareaJSX';
import { IntegrationParameter } from '../../../../../types/apiResponseTypes';
import {
  getOptionsFromTemplatesJsonContent,
  SelectOption,
} from '../../../../../utils/getSelectOptions';

type Props = {
  integrationID: number;
};

interface modelInterface {
  [key: string]: IntegrationParameter;
}
interface configInterface {
  [key: string]: string | number | SelectOption;
}

const ConfigurationsJSX = ({ integrationID }: Props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsloading] = useState(true);

  const [model, setModel] = useState<modelInterface | null>(null);
  const [configData, setConfigData] = useState<configInterface>({});

  const [jsonToView, setJsonToView] = useState<SelectOption | null>(null);

  const reqGetIntegrationConfig = useCallback(async () => {
    setIsloading(true);
    const source = integrationConfigReq.axios.CancelToken.source();

    try {
      const res = await integrationConfigReq.show(integrationID, source.token);

      if (res.status === HttpStatus.OK) {
        const { data } = res.data;

        setModel(data.model);

        if (data.config !== null) {
          setConfigData(data.config.data);
        }
      } else {
        setModel(null);
        throw res;
      }
    } catch (err) {
      if (!integrationConfigReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsloading(false);
    return () => {
      source.cancel('Component Integrations Logs got unmounted');
    };
  }, [integrationID]);

  type inputType = 'text' | 'number' | 'boolean' | 'json' | 'templateJSON';
  const handleInputType = (type: string | null): inputType => {
    let inputTypeValue: inputType = 'text';

    if (type === null) return inputTypeValue;

    switch (type.toLowerCase()) {
      case 'integer':
        inputTypeValue = 'number';
        break;
      case 'int':
        inputTypeValue = 'number';
        break;
      case 'float':
        inputTypeValue = 'number';
        break;
      case 'double':
        inputTypeValue = 'number';
        break;
      case 'number':
        inputTypeValue = 'number';
        break;
      case 'boolean':
        inputTypeValue = 'boolean';
        break;
      case 'bool':
        inputTypeValue = 'boolean';
        break;
      case 'json':
        inputTypeValue = 'json';
        break;
      case 'templatejson':
        inputTypeValue = 'templateJSON';
        break;
      default:
        inputTypeValue = 'text';
        break;
    }

    return inputTypeValue;
  };

  const handleSaveBtn = async () => {
    setIsloading(true);
    try {
      const res = await integrationConfigReq.update(configData, integrationID);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('ConfigDoneSuccessfully'),
          type: NotificationTypes.SUCCESS,
        });
      } else {
        throw res;
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);
    }

    setIsloading(false);
  };

  const handleLanguageToDescription = (parameter: IntegrationParameter) => {
    const { language } = i18n;
    let description = '';

    switch (language) {
      case 'pt':
        description = parameter.description_pt as string;
        break;
      case 'es':
        description = parameter.description_es as string;
        break;
      default:
        description = parameter.description_en as string;
        break;
    }
    return description;
  };

  const setParameterValue = (value: any, index: string) => {
    const newConfigData = { ...configData };
    newConfigData[index] = value;
    setConfigData(newConfigData);
  };

  const handleOnChangeInputTextType = (event: any, index: string) => {
    setParameterValue(event.target.value, index);
  };

  const handleOnChangeInputNumberType = (event: any, index: string) => {
    setParameterValue(event.target.value, index);
  };

  const handleOnChangeInputTemplateJSON = (event: any, index: string) => {
    setParameterValue(event, index);
  };

  const handleOnChangeSelect = (event: any, index: string) => {
    setParameterValue(event.value, index);
  };

  const setValueSelectTypeBoolean = (value: string | number | null) => {
    if (value === null) {
      return null;
    }

    if (value) {
      return { label: t('yes'), value };
    }

    return { label: t('no'), value };
  };

  const handleBtnView = (configDataToView: SelectOption) => {
    if (configDataToView) {
      setJsonToView(configDataToView);
    }
  };

  useEffect(() => {
    reqGetIntegrationConfig();
    setModel(null);
  }, [integrationID]);

  return (
    <s.ConfigurationContainer>
      <Loader isLoading={isLoading}>
        {model != null ? (
          Object.keys(model).map((index: string) => (
            <s.IntegrationParameter key={index}>
              <div className="parameter-name">
                <p style={{ marginRight: '5px' }}>{index}</p>
                {handleLanguageToDescription(model[index]).length >= 1 && (
                  <InfoButton msg={handleLanguageToDescription(model[index])} />
                )}
              </div>

              {handleInputType(model[index].type) === 'text' && (
                <input
                  type={model[index].hidden ? 'password' : 'text'}
                  className="input"
                  value={configData[index] as string}
                  onChange={(e: any) => handleOnChangeInputTextType(e, index)}
                />
              )}

              {handleInputType(model[index].type) === 'number' && (
                <input
                  type="number"
                  className="input"
                  value={configData[index] as number}
                  onChange={(e: any) => handleOnChangeInputNumberType(e, index)}
                />
              )}

              {handleInputType(model[index].type) === 'boolean' && (
                <SingleSelect
                  value={setValueSelectTypeBoolean(configData[index] as string)}
                  options={[
                    { label: t('yes'), value: true },
                    { label: t('no'), value: false },
                  ]}
                  onChange={(e: any) => handleOnChangeSelect(e, index)}
                />
              )}

              {handleInputType(model[index].type) === 'json' && (
                <TextareaJSX
                  value={configData[index] as string}
                  language="json"
                  placeholder={t('typeJsxHtmlCode')}
                  onChange={(e: any) => handleOnChangeInputNumberType(e, index)}
                />
              )}

              {handleInputType(model[index].type) === 'templateJSON' && (
                <s.TemplateInputArea>
                  <SelectAsyncJSX
                    value={configData[index] as SelectOption}
                    onChange={(e: any) => handleOnChangeInputTemplateJSON(e, index)}
                    request={templatesReq}
                    reqResponseToOption={getOptionsFromTemplatesJsonContent}
                    disabled={isLoading}
                    placeholder={t('template')}
                    className="inputTemplateArea"
                  />
                  <button
                    type="button"
                    className="button"
                    id="viewTemplateArea"
                    onClick={() => handleBtnView(configData[index] as SelectOption)}
                  >
                    {t('view')}
                  </button>
                </s.TemplateInputArea>
              )}
            </s.IntegrationParameter>
          ))
        ) : (
          <s.NoContent>{t('integrationWithoutModel')}</s.NoContent>
        )}

        {model != null && (
          <s.ButtonsArea>
            <Btn text={t('save')} onClick={() => handleSaveBtn()} disabled={isLoading} />
          </s.ButtonsArea>
        )}
      </Loader>

      {jsonToView !== null && (
        <Modal
          title={jsonToView.label}
          showModal={jsonToView !== null}
          closeModal={() => setJsonToView(null)}
          onlyCloseButton
          bodyStyle={{ overflowX: 'scroll' }}
        >
          <TextareaJSX
            value={jsonToView.value as string}
            language="json"
            placeholder={t('typeJsxHtmlCode')}
            onChange={() => null}
          />
        </Modal>
      )}
    </s.ConfigurationContainer>
  );
};

export default ConfigurationsJSX;
