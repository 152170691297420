import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavMenu from '../../../components/NavMenu';
import { NavMenyItem } from '../../../components/NavMenu/types';
import OutContent from '../../../templates/OutContent';
import Page1 from './Pages/AccessControlHome';
import Page2 from './Pages/TenantsMetrics';

const AccessControlTSX = () => {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState({
    value: 'AccessControlHome',
    name: 'accessControl',
  });

  const navMenuItems: NavMenyItem[] = [
    {
      title: t('accessControl'),
      action: () =>
        setTabSelected({
          value: 'AccessControlHome',
          name: 'accessControl',
        }),
    },
    {
      title: t('tenantsMetrics'),
      action: () =>
        setTabSelected({
          value: 'accessControlTenantsMetrics',
          name: 'accessControlTenantsMetrics',
        }),
    },
  ];

  const handleViewPag = (pagName: string) => {
    let pageSelected = <Page1 />;

    switch (pagName) {
      case 'accessControlTenantsMetrics':
        pageSelected = <Page2 />;
        break;
      default:
        pageSelected = <Page1 />;
        break;
    }

    return pageSelected;
  };

  return (
    <>
      <OutContent>
        <NavMenu items={navMenuItems} />
      </OutContent>

      {handleViewPag(tabSelected.value)}
    </>
  );
};

export default AccessControlTSX;
