import ProgressBar from '@ramonak/react-progress-bar';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import { EditIcon } from '../../../../icons';
import { sellerVisitScheduleAPI } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { SellerVisitSchedule } from '../../../../types';
import { ApiResPaginated } from '../../../../types/apiResponseTypes';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import Btn from '../../../../components/Buttons/Btn';
import ModalManageSchedules from './Components/ModalManageSchedules';

const VisitScheduleJSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [sellerVisitSchedule, setSellerVisitSchedule] = useState<SellerVisitSchedule[]>([]);

  const [showModalManage, setModalManage] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SellerVisitSchedule | null>(null);
  const [updateTable, setUpdateTable] = useState(0);

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = sellerVisitScheduleAPI.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      sellerVisitScheduleAPI.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await sellerVisitScheduleAPI.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<SellerVisitSchedule[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!sellerVisitScheduleAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleSellerColumn = (row: SellerVisitSchedule) => {
    const { sellerCode } = row;
    const { name } = row;
    return <p>{`${sellerCode} - ${name}`}</p>;
  };

  const handlePercentColumn = (row: SellerVisitSchedule) => {
    const { schedulingQuantity } = row;
    const { customersQuantity } = row;
    let percent = (schedulingQuantity / customersQuantity) * 100;
    if (Number.isNaN(percent)) {
      percent = 0;
    }
    return (
      <ProgressBar
        completed={percent.toFixed(2)}
        bgColor={theme.colors.positive}
        baseBgColor={theme.colors.onBackground + 50}
        labelColor={theme.colors.onBackground}
      />
    );
  };

  const handleEditBtn = (row: SellerVisitSchedule) => {
    setSelectedItem(row);
    setModalManage(true);
  };

  const actionsButtons = (row: SellerVisitSchedule) => (
    <ActionButtonsContainer>
      <EditIcon size="1.5rem" title={t('edit')} onClick={() => handleEditBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<SellerVisitSchedule>[] = [
    {
      label: t('seller') as string,
      accessor: 'sellerCode',
      Cell: handleSellerColumn,
    },
    {
      label: t('totalScheduledCustomers') as string,
      accessor: 'schedulingQuantity',
    },
    {
      label: t('TotalCustomersInThePortfolio') as string,
      accessor: 'customersQuantity',
    },
    {
      label: `% ${t('scheduling')}`,
      Cell: handlePercentColumn,
    },
    {
      label: '',
      Cell: actionsButtons,
    },
  ];

  const callbackToManagerSchedules = () => {
    setSelectedItem(null);
    setUpdateTable(updateTable + 1);
  };

  return (
    <ContentContainer title={t('visitSchedule')}>
      <Btn
        onClick={() => setModalManage(true)}
        style={{ marginLeft: '0', marginBottom: '1rem' }}
        text={t('manageSchedules')}
      />

      {showModalManage && (
        <ModalManageSchedules
          closeModal={() => setModalManage(false)}
          selectedItem={selectedItem}
          callback={callbackToManagerSchedules}
        />
      )}

      <AsyncTable
        tableName={t('visitSchedule')}
        columns={columns}
        value={sellerVisitSchedule}
        onChange={setSellerVisitSchedule}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
            downloadBtns: { marginLeft: '0.5rem' },
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
        }}
      />
    </ContentContainer>
  );
};

export default VisitScheduleJSX;
