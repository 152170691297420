import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { goalsReq } from '../../../../../../services/requests';
import { Goal } from '../../../../../../types/apiResponse/goal';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { getSpecificField } from '../../../../../../utils/helpers';

type Props = ModalProps & {
  reqGetAllGoals: () => void;
  closeModal: () => void;
  goalsSelected: Goal[];
};

const DeleteManyGoalModal = ({ reqGetAllGoals, closeModal, goalsSelected, ...props }: Props) => {
  const { t } = useTranslation();

  const [awaitReqDeleteGoals, setAwaitReqDeleteGoals] = useState(false);

  const reqDeleteGoal = async () => {
    const ids = getSpecificField('id', goalsSelected) as Goal[];
    const idsString = ids.join(',');

    setAwaitReqDeleteGoals(true);
    try {
      goalsReq.query = '';
      const response = await goalsReq.delete(idsString);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        reqGetAllGoals();
        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!goalsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqDeleteGoals(false);
  };

  return (
    <Modal
      {...props}
      action={() => reqDeleteGoal()}
      isLoading={awaitReqDeleteGoals}
      closeModal={closeModal}
      cancelButton={closeModal}
    >
      <p>{`${t('deleteSure')}?`}</p>
      <b>{`${goalsSelected.length} ${t('goals')}`}</b>
    </Modal>
  );
};

export default DeleteManyGoalModal;
