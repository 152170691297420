import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';

import { useTranslation } from 'react-i18next';

import { IntegrationModel } from '../../../types/apiResponseTypes';
import Loader from './Loader';

import { backendErrorNotification } from '../../../components/Notification';
import { integrationModels } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';

import Btn from '../../../components/Buttons/Btn';

import AddIntegrationModelModal from './components/AddIntegrationModelModal';
import DeleteIntegrationModel from './components/DeleteIntegrationModelModal';
import EditIntegrationModelModal from './components/EditIntegrationModelModal';

import TablePaginator from '../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import i18n from '../../../i18n';
import { DeleteIcon, EditIcon } from '../../../icons';

const IntegrationModels = () => {
  const { t } = useTranslation();

  const [showAddInteModelModal, setShowAddInteModelModal] = useState(false);
  const [showEditInteModelModal, setShowEditInteModelModal] = useState(false);
  const [showDeleteInteModelModal, setShowDeleteInteModelModal] = useState(false);

  const [waitingReqGetIntegrationModels, setWaitinReqGetIntegrationModels] = useState(true);

  const [integrationModelsList, setIntegrationModelsList] = useState([]);
  const [inteModelSelected, setInteModelSelected] = useState<
    IntegrationModel | Record<string, never>
  >({});

  const reqGetIntegrationModels = async () => {
    setWaitinReqGetIntegrationModels(true);
    const source = integrationModels.axios.CancelToken.source();
    try {
      const res = await integrationModels.index(source.token);

      if (res.status === HttpStatus.OK) {
        setIntegrationModelsList(res.data.data.reverse());
      } else {
        throw res;
      }
    } catch (err) {
      if (!integrationModels.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitinReqGetIntegrationModels(false);
    return () => source.cancel('Component Roles got unmounted');
  };

  useEffect(() => {
    reqGetIntegrationModels();
  }, []);

  const handleTableEditBtn = (row: Row<IntegrationModel>) => {
    setInteModelSelected(row.original);
    setShowEditInteModelModal(true);
  };
  const handleTableDeleteBtn = (row: Row<IntegrationModel>) => {
    setInteModelSelected(row.original);
    setShowDeleteInteModelModal(true);
  };

  const actionsButtons = (row: Row<IntegrationModel>) => (
    <ActionButtonsContainer>
      <EditIcon title={t('edit')} onClick={() => handleTableEditBtn(row)} />
      <DeleteIcon title={t('delete')} onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const integrationModelsListMemo = useMemo(() => integrationModelsList, [integrationModelsList]);

  const columns: Column<IntegrationModel>[] = useMemo(
    () => [
      {
        Header: t('id') as string,
        accessor: 'id',
      },
      {
        Header: t('name') as string,
        accessor: 'name',
      },
      {
        id: 'actionsbtn',
        // accessor: 'actions',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('companies')}>
      <Btn
        text={t('registerIntegrationModel')}
        onClick={() => setShowAddInteModelModal(true)}
        style={{ marginBottom: '1rem' }}
      />
      <Loader isLoading={waitingReqGetIntegrationModels}>
        <TablePaginator
          data={integrationModelsListMemo}
          columns={columns}
          showMoreRows
          globalFiltering
        />

        {showAddInteModelModal && (
          <AddIntegrationModelModal
            showModal={showAddInteModelModal}
            closeModal={() => setShowAddInteModelModal(false)}
            reqGetIntegrationModels={reqGetIntegrationModels}
          />
        )}

        {showEditInteModelModal && (
          <EditIntegrationModelModal
            showModal={showEditInteModelModal}
            closeModal={() => setShowEditInteModelModal(false)}
            reqGetIntegrationModels={reqGetIntegrationModels}
            inteModelSelected={inteModelSelected}
          />
        )}

        {showDeleteInteModelModal && (
          <DeleteIntegrationModel
            showModal={showDeleteInteModelModal}
            closeModal={() => setShowDeleteInteModelModal(false)}
            inteModelSelected={inteModelSelected}
            reqGetIntegrationModels={reqGetIntegrationModels}
          />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default IntegrationModels;
