import { t } from 'i18next';
import { useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Btn, { BtnType } from '../Buttons/Btn';
import * as s from './styles';

export type ModalProps = {
  title: string;
  showModal: boolean;
  closeModal: () => void;
  children?: JSX.Element | JSX.Element[];
  action?: () => void;
  actionNameBtn?: string | undefined;
  cancelButton?: () => void;
  disableActionBtn?: boolean;
  isLoading?: boolean;
  onlyCloseButton?: boolean;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  additionalActions?: {
    name: string;
    onClick: () => void;
    type?: BtnType;
    style?: React.CSSProperties;
    disable?: boolean;
  }[];
};

const Modal = ({
  title,
  showModal,
  closeModal,
  children,
  action,
  cancelButton,
  actionNameBtn,
  disableActionBtn,
  isLoading,
  onlyCloseButton,
  style,
  bodyStyle,
  additionalActions,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // 	const interval = setInterval(() => {
  // 		if (modalRef.current) {
  // 			console.log(modalRef.current.clientHeight, 'd');
  // 		} else {
  // 			console.log('não tem div 2');
  // 		}
  // 	}, 1000);

  // 	return clearInterval(interval);
  // });

  const actionButtons = () => {
    return (
      <>
        {additionalActions?.map((action) => (
          <Btn
            text={action.name}
            onClick={action.onClick}
            type={action.type ?? 'contained'}
            style={action.style ?? { maxWidth: '150px' }}
            disabled={action.disable ?? (disableActionBtn || isLoading)}
          />
        ))}

        <Btn
          text={actionNameBtn ?? t('save')}
          type="contained"
          onClick={action}
          style={{ maxWidth: '100px' }}
          disabled={disableActionBtn || isLoading}
        />
      </>
    );
  };

  return showModal ? (
    <s.ModalContainer>
      <s.ModalContent
        id={isLoading ? 'ldg1' : ''}
        isLoading={isLoading}
        style={style}
        ref={modalRef}
      >
        <s.ModalHeader>
          <div id="modal-title">{title}</div>
          <div id="moda-close-icon">
            <AiOutlineClose onClick={isLoading ? () => null : closeModal} />
          </div>
        </s.ModalHeader>

        <s.ModalBody style={bodyStyle}>{children}</s.ModalBody>

        {onlyCloseButton ? (
          <s.ModalFooter>
            <div className="buttons-footer">
              <Btn text={t('close')} onClick={closeModal} disabled={isLoading} />
            </div>
          </s.ModalFooter>
        ) : (
          <s.ModalFooter>
            {cancelButton ? (
              <div className="buttons-footer">
                <Btn
                  text={t('cancel')}
                  type="text"
                  onClick={cancelButton}
                  style={{ maxWidth: '100px' }}
                />
                {actionButtons()}
              </div>
            ) : (
              <div className="buttons-footer">{actionButtons()}</div>
            )}
          </s.ModalFooter>
        )}
      </s.ModalContent>
    </s.ModalContainer>
  ) : null;
};

Modal.defaultProps = {
  children: {},
  action: () => null,
  actionNameBtn: undefined,
  cancelButton: undefined,
  disableActionBtn: false,
  isLoading: false,
  onlyCloseButton: false,
  style: {},
  bodyStyle: {},
};

export default Modal;
