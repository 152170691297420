/* eslint-disable no-shadow */

export enum DateFrequency {
  always = 0,
  daily = 1,
  weekly = 2,
  biweekly = 3,
  monthly = 4,
}

export enum WeekDays {
  sunday = 1,
  monday = 2,
  tuesday = 3,
  wednesday = 4,
  thursday = 5,
  friday = 6,
  saturday = 7,
}
