import { ResponsiveLine } from '@nivo/line';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { getFirst3Letters } from '../../../../../../../../utils/helpers';

// import fakeData from '../../fakesData/dataYearsExample';

const GraphLineSchedules = ({ data }: any) => {
  const theme = useTheme();

  const [margin, setMargin] = useState({
    top: 20,
    right: 0,
    bottom: 20,
    left: 30,
  });

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setMargin({
        top: 20,
        right: 0,
        bottom: 50,
        left: 95,
      });
    }
  }, []);

  return (
    <ResponsiveLine
      data={data}
      margin={margin}
      animate
      enableSlices="x" // disable to select only one point with the mouse
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      // yFormat={(v) => currencyBR(v as number)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickRotation: -25,
        format: getFirst3Letters,
      }}
      axisLeft={{
        // format: (v: any) => currencyBR(v as number),
        tickRotation: 0,
      }}
      pointSize={3}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={0}
      enableCrosshair
      layers={[
        'grid',
        'markers',
        'areas',
        'lines',
        'slices',
        'axes',
        'points',
        'legends',
        'mesh',
        'crosshair',
      ]}
      useMesh
      legends={[
        {
          anchor: 'top-left',
          direction: 'row',
          justify: false,
          translateX: -1,
          translateY: -20,
          itemWidth: 67,
          itemHeight: 10,
          itemsSpacing: 0,
          symbolSize: 11,
          symbolShape: 'circle',
          itemDirection: 'left-to-right',
          itemTextColor: theme.colors.onBackground,
          itemOpacity: 0.7,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      colors={[
        '#BF0103',
        '#aeaeae',
        '#F47560',
        '#ddb18d',
        '#8561cd',
        '#57e045',
        '#F1E15B',
        '#FF7096',
        '#e8a0a0',
        '#A0CED9',
        '#924b4b',
        '#457B9D',
        '#f44b76',
        '#449b4e',
        '#9b449a',
        '#00d6ba',
        '#1f609e',
        '#a87b2f',
        '#2ca9f7',
        '#d14db2',
        '#E8A838',
      ]}
      theme={{
        textColor: theme.colors.onBackground,
        grid: {
          line: {
            stroke: theme.colors.onBackground + 50,
            strokeWidth: 0.2,
          },
        },
        crosshair: {
          line: {
            stroke: theme.colors.onBackground,
            strokeWidth: 1,
            strokeOpacity: 0.75,
            strokeDasharray: '6 6',
          },
        },
        tooltip: {
          container: {
            background: theme.colors.surface,
            color: theme.colors.onSurface,
            borderRadius: '0.75rem',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px',
          },
        },
      }}
    />
  );
};

export default GraphLineSchedules;
