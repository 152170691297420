import axios from 'axios';
import i18n from '../../../i18n';
import { getAccessToken } from '../../authApi';

type Data = {
  description: string;
  active: any;
  jrxmlFile?: any;
};

export const update = async (id: number, data: Data) => {
  const bearerToken = getAccessToken();
  const apiUrl = process.env.REACT_APP_API;

  if (data.jrxmlFile) {
    const formData = new FormData();
    formData.append('jrxmlFile', data.jrxmlFile);
    formData.append('description', data.description);
    formData.append('active', data.active);

    const response = await axios.post(`${apiUrl}/api/v1/reports/jasper/bases/${id}`, formData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        ContentType: 'multipart/form-data',
        'Accept-Language': i18n.language,
      },
    });

    return response;
  }

  const response = await axios.request({
    method: 'POST',
    url: `${apiUrl}/api/v1/reports/jasper/bases/${id}`,
    params: { description: data.description, active: data.active },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Accept-Language': i18n.language,
    },
  });

  return response;
};

type Parameters = {
  label: string;
  value: any;
}[];

export const processReportReq = async (id: number, extension: string, parameters: Parameters) => {
  const bearerToken = getAccessToken();
  const apiUrl = process.env.REACT_APP_API;

  const data = JSON.stringify({
    extension,
    parameters,
  });

  const response = await axios.request({
    method: 'post',
    responseType: 'blob',
    url: `${apiUrl}/api/v1/reports/jasper/process/${id}`,
    headers: {
      Accept: 'application/json',
      'Accept-Language': i18n.language,
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    data,
  });

  return response;
};

export default update;
