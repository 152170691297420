import { useState } from 'react';
import { VisitBySeller } from '../../../../../../types';
import { frequencyIndex, weekDays } from '../../../../../../utils/helpers';

import ModalActionsJSX from '../ModalCardsActionsJSX';
import ModalDelete from '../ModalDelete';
import ModalEdit from '../ModalEditSpecificShedule';
import * as s from './styles';

type Props = {
  sellerVisits: VisitBySeller[] | [];
  weekDayIndex: number;
  color: string;
  reqGetAllSellerVisits: () => void;
};

export const WeekDayCard = ({
  sellerVisits,
  weekDayIndex,
  color,
  reqGetAllSellerVisits,
}: Props): JSX.Element => {
  const [, setShowModalActions] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const handleShowModalDelete = (sellerVisit: VisitBySeller) => {
    if (showModalDelete && selectedItem === sellerVisit.id) {
      return (
        <ModalDelete
          closeModal={() => setShowModalDelete(false)}
          selecteditem={sellerVisit}
          reqCallback={reqGetAllSellerVisits}
        />
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const handleShowModalEdit = (sellerVisit: VisitBySeller) => {
    if (showModalEdit && selectedItem === sellerVisit.id) {
      return (
        <ModalEdit
          closeModal={() => setShowModalEdit(false)}
          selecteditem={sellerVisit}
          reqCallback={reqGetAllSellerVisits}
        />
      );
    }
  };

  if (sellerVisits.find((e) => e.weekDay === weekDayIndex || e.frequency === 1)) {
    return (
      <s.Container color={color}>
        <s.Header color={color}>{weekDays()[weekDayIndex]}</s.Header>

        {sellerVisits.map((sellerVisit) => {
          if (sellerVisit.weekDay === weekDayIndex || sellerVisit.frequency === 1) {
            const frequencyInital = frequencyIndex()[sellerVisit.frequency];
            // eslint-disable-next-line max-len
            const lable = `(${frequencyInital}): ${sellerVisit.customerCode} - ${sellerVisit.customer?.RazaoSocial} - ${sellerVisit.hour}`;

            return (
              <s.Item key={sellerVisit.id} color={color}>
                <p
                  title={lable}
                  id="customerCode"
                >{`${sellerVisit.customerCode} - (${frequencyInital})`}</p>
                <p title={lable}>{sellerVisit.customer?.RazaoSocial}</p>

                {selectedItem == sellerVisit.id ? (
                  <s.MoreIconHorizontal onClick={() => setSelectedItem(null)} />
                ) : (
                  <s.MoreIconVertical onClick={() => setSelectedItem(sellerVisit.id)} />
                )}

                {selectedItem == sellerVisit.id && (
                  <ModalActionsJSX
                    closeModal={() => setShowModalActions(false)}
                    color={color}
                    reqDeleteVisit={() => setShowModalDelete(true)}
                    reqEditVisit={() => setShowModalEdit(true)}
                  />
                )}

                {handleShowModalDelete(sellerVisit)}
                {handleShowModalEdit(sellerVisit)}
              </s.Item>
            );
          }

          return <div key={sellerVisit.id} />;
        })}
      </s.Container>
    );
  }

  return <></>;
};

export default WeekDayCard;
