/* eslint-disable indent */
import i18n from '../i18n';
import { OrderOriginCode } from '../types/apiResponse/order';
import { DateFrequency, WeekDays } from './enums';

type ActionTypeMap<T extends string> = { [key in T]: string };

type formatNumber = (n: number) => string;

export const createActionTypesMap = <T extends string>(
  prefix: string,
  types: T[],
): ActionTypeMap<T> =>
  types.reduce(
    (obj, key) => ({
      ...obj,
      [key]: `${prefix}${key}`,
    }),
    {} as ActionTypeMap<T>,
  );

export const arrayValuesIncludesOnArray = (arr: Array<any>, target: Array<any>) =>
  target.every((v: any) => arr.includes(v));

export const arrayIsEqualsToArray = (arr: Array<any>, target: Array<any>) =>
  target.every((v1: any) => arr.find((v2) => v1 === v2));

export const isArrayInArray = (arr: Array<any>, item: Array<any>) => {
  const itemAsString = JSON.stringify(item);

  return arr.some((v: Array<any>) => JSON.stringify(v) === itemAsString);
};

export const getSpecificField = (field: string, data: Array<any>): unknown => {
  const newData = data.map((item) => item[field]);
  return newData;
};

export const fileReader = (blob: Blob) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Problem parsing file'));
    };

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsText(blob);
  });
};

export const getKeyValue =
  <T extends Record<string, unknown>, U extends keyof T>(obj: T) =>
  (key: U) =>
    obj[key];

export function currencyBR(value: number) {
  if (!Number(value)) return 'R$ 0,00';

  const amount = value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return `${amount}`;
}

export function weightBrFormatter(value: number) {
  if (!Number(value)) return '0';

  const amount = value.toLocaleString('pt-br', { minimumFractionDigits: 2 });

  return `${amount}`;
}

export function weightKgBrFormatter(value: number) {
  if (!Number(value)) return '0 kg';

  const amount = value.toLocaleString('pt-br', { minimumFractionDigits: 2 });

  return `${amount} kg`;
}

export const numberFormat = (n: number) => {
  const value =
    n % 1 > 0
      ? n.toLocaleString('pt-br', { maximumFractionDigits: 2 })
      : n.toLocaleString('pt-br', { maximumFractionDigits: 0 });
  return `${value}`;
};

export const percentFormat = (n: number) => {
  const value =
    n % 1 > 0
      ? n.toLocaleString('pt-br', { maximumFractionDigits: 2 })
      : n.toLocaleString('pt-br', { maximumFractionDigits: 0 });

  return `${value} %`;
};

export const findIndexNumber = (array: any[], element: string) =>
  array.findIndex((e) => {
    if (e.month === element) {
      return true;
    }
    return false;
  });

export const parseDate = (date: string) => {
  // const parts = input.match(/(\d+)/g)
  const dateSplited = date.split('-');
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  const year = parseFloat(dateSplited[0]);
  const month = parseFloat(dateSplited[1]) - 1;
  const day = parseFloat(dateSplited[2]);

  return new Date(year, month, day); // months are 0-based
};

export const fixRounding = (value: number, precision = 2) => {
  const power = 10 ** precision || 0;
  return Math.round(value * power) / power;
};

export const getYearsFrom2019toCurrent = (): Array<string> => {
  const yearOptions: Array<string> = [];
  const startYear = 2019;
  const currentYear = new Date().getFullYear();

  for (let i = startYear; i <= currentYear; i += 1) {
    yearOptions.push(i.toString());
  }

  return yearOptions;
};

export const getYearMoreN = (count = 1): Array<string> => {
  const yearOptions: Array<string> = [];

  let year = new Date().getFullYear();

  for (let i = 0; i <= count; i += 1) {
    yearOptions.push(year.toString());
    year += 1;
  }

  return yearOptions;
};

export const getFilterYearOptions = () => {
  const yearOptions: any = [];
  const years = getYearsFrom2019toCurrent();

  years.map((year) =>
    yearOptions.push({
      label: year.toString(),
      value: year.toString(),
    }),
  );

  return yearOptions;
};

export const getFilterMonthsOptions = () => {
  const months = [
    {
      label: i18n.t('january'),
      value: 'January',
    },
    {
      label: i18n.t('february'),
      value: 'February',
    },
    {
      label: i18n.t('march'),
      value: 'March',
    },
    {
      label: i18n.t('april'),
      value: 'April',
    },
    {
      label: i18n.t('may'),
      value: 'May',
    },
    {
      label: i18n.t('june'),
      value: 'June',
    },
    {
      label: i18n.t('july'),
      value: 'July',
    },
    {
      label: i18n.t('august'),
      value: 'August',
    },
    {
      label: i18n.t('september'),
      value: 'September',
    },
    {
      label: i18n.t('october'),
      value: 'October',
    },
    {
      label: i18n.t('november'),
      value: 'November',
    },
    {
      label: i18n.t('december'),
      value: 'December',
    },
  ];

  return months;
};

export const getWeekDayOptions = () => {
  const weekDays = [
    {
      label: i18n.t('sunday'),
      value: WeekDays.sunday,
    },
    {
      label: i18n.t('monday'),
      value: WeekDays.monday,
    },
    {
      label: i18n.t('tuesday'),
      value: WeekDays.tuesday,
    },
    {
      label: i18n.t('wednesday'),
      value: WeekDays.wednesday,
    },
    {
      label: i18n.t('thursday'),
      value: WeekDays.thursday,
    },
    {
      label: i18n.t('friday'),
      value: WeekDays.friday,
    },
    {
      label: i18n.t('saturday'),
      value: WeekDays.saturday,
    },
  ];

  return weekDays;
};

export const weekDays = (): { [key: number]: string } => ({
  [WeekDays.sunday]: i18n.t('sunday'),
  [WeekDays.monday]: i18n.t('monday'),
  [WeekDays.tuesday]: i18n.t('tuesday'),
  [WeekDays.wednesday]: i18n.t('wednesday'),
  [WeekDays.thursday]: i18n.t('thursday'),
  [WeekDays.friday]: i18n.t('friday'),
  [WeekDays.saturday]: i18n.t('saturday'),
});

export const frequencyIndex = (): { [key: number]: string } => ({
  [DateFrequency.daily]: i18n.t('daily'),
  [DateFrequency.weekly]: i18n.t('weekly'),
  [DateFrequency.biweekly]: i18n.t('biweekly'),
  [DateFrequency.monthly]: i18n.t('monthly'),
});

export const getFrequencyOptions = () => {
  const frequency = [
    {
      label: i18n.t('always'),
      value: DateFrequency.always,
    },
    {
      label: i18n.t('daily'),
      value: DateFrequency.daily,
    },
    {
      label: i18n.t('weekly'),
      value: DateFrequency.weekly,
    },
    {
      label: i18n.t('biweekly'),
      value: DateFrequency.biweekly,
    },
    {
      label: i18n.t('monthly'),
      value: DateFrequency.monthly,
    },
  ];

  return frequency;
};

export const getFirst3Letters = (value: any): string => {
  let newValue = value.toString();
  newValue = newValue.substring(0, 3);
  return newValue;
};

export type formatType =
  | 'coverage'
  | 'positivation'
  | 'percent'
  | 'weight'
  | 'financial'
  | 'currencyBR'
  | 'boxes'
  | '';

export const setValueByType = (value: formatType = ''): ((n: number) => string) => {
  if (value === 'coverage' || value === 'positivation' || value === 'percent') {
    return formatNumber.percent;
  }

  if (value === 'weight') {
    return formatNumber.weightBrKg;
  }

  if (value === 'financial' || value === 'currencyBR') {
    return formatNumber.currencyBr;
  }

  return formatNumber.number;
};

export const formatNumber = {
  percent: percentFormat,
  number: numberFormat,
  weightBr: weightBrFormatter,
  weightBrKg: weightKgBrFormatter,
  currencyBr: currencyBR,
  formatByType: setValueByType,
};

export const currentDateString = () => {
  const today = new Date();

  return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
};

/**
 *
 * @returns string "27-03-2022"
 */
export const currentDate = () => {
  const today = new Date();

  const month =
    today.getMonth() + 1 >= 10
      ? `${today.getMonth() + 1}`
      : `0${(today.getMonth() + 1).toString()}`;

  const day = today.getDate() > 10 ? `${today.getDate()}` : `0${today.getDate().toString()}`;

  return `${today.getFullYear()}-${month}-${day}`;
};

/**
 * pode receber um numero de dias e uma operacao se é pra adicionar dias ou subtrair
 * @returns string "2023-11-24T01:00"
 */
export const getCurrentDateTime = (days: number = 0, op: 'add' | 'sub' = 'add') => {
  const today = new Date();
  if (op == 'add') {
    today.setDate(today.getDate() + days);
  } else {
    today.setDate(today.getDate() - days);
  }
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const hours = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const currentDateMoreDays = (days: number) => {
  const today = new Date();

  const nextDate = new Date();
  nextDate.setDate(today.getDate() + days);

  const month =
    nextDate.getMonth() + 1 >= 10
      ? `${nextDate.getMonth() + 1}`
      : `0${(nextDate.getMonth() + 1).toString()}`;

  const day =
    nextDate.getDate() >= 10 ? `${nextDate.getDate()}` : `0${nextDate.getDate().toString()}`;

  return `${nextDate.getFullYear()}-${month}-${day}`;
};

export const isDateTodayOrPast = (dateToCheck: Date | string): boolean => {
  let date: Date;

  if (typeof dateToCheck === 'string') {
    date = new Date(dateToCheck);
  } else {
    date = dateToCheck;
  }

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format');
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date <= today;
};

export const isDateTimeBeforeNow = (dateToCheck: Date | string) => {
  let parsedDate: Date;

  if (typeof dateToCheck === 'string') {
    parsedDate = new Date(dateToCheck);
  } else {
    parsedDate = dateToCheck;
  }

  return Date.parse(parsedDate.toString()) < Date.now();
};

/**
 *
 * @returns string "2022-03-27"
 */
export const getDate = (value: string | number | Date) => {
  const date = new Date(value);

  let month: number | string = date.getMonth() + 1;
  month = (month as number) >= 10 ? month : `0${month}`;

  let day: number | string = date.getDate();
  day = day >= 10 ? day : `0${day}`;

  return `${date.getFullYear()}-${month}-${day}`;
};

/**
 * Return example: "2023-12-30T23:59:30"
 */
export const currentDateWithTimeString = () => {
  const today = new Date();

  let month: number | string = today.getMonth() + 1;
  month = (month as number) >= 10 ? month : `0${month}`;

  let day: number | string = today.getDate();
  day = day >= 10 ? day : `0${day}`;

  let hours: number | string = today.getHours();
  hours = hours >= 10 ? hours : `0${hours}`;

  let minutes: number | string = today.getMinutes();
  minutes = minutes >= 10 ? minutes : `0${minutes}`;

  let seconds: number | string = today.getSeconds();
  seconds = seconds >= 10 ? seconds : `0${seconds}`;

  return `${today.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const dateToString = (dateProp: string) => {
  const date = new Date(dateProp);

  let month: number | string = date.getMonth() + 1;
  month = (month as number) >= 10 ? month : `0${month}`;

  let day: number | string = date.getDate();
  day = day >= 10 ? day : `0${day}`;

  let hours: number | string = date.getHours();
  hours = hours >= 10 ? hours : `0${hours}`;

  let minutes: number | string = date.getMinutes();
  minutes = minutes >= 10 ? minutes : `0${minutes}`;

  let seconds: number | string = date.getSeconds();
  seconds = seconds >= 10 ? seconds : `0${seconds}`;

  return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const isJson = (str: any): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * Format input  = 2022-07-28T21:22:17.000000Z
 * Format output = 2022-07-28 21:22
 */
export const clearPHPdate = (date: string): string => {
  const step1 = date.split('.')[0]; // remove ".000000Z"
  const step2 = step1.replace('T', ' ');
  const step3 = step2.slice(0, -3); // remove seconds

  return step3;
};

/**
 * Format input  = 2022-07-28T21:22
 * Format output = 2022-07-28 21:22:00
 */
export const datetimeLocalToTimestamp = (date: string): string => {
  const step1 = date.replace('T', ' ');
  const step2 = step1 + ':00'; // add seconds

  return step2;
};

/**
 * input "2022/12/30" -> "output 30/12/2022"
 */
export const dateToBr = (date: string): string => {
  const newDate = date.slice(0, 10);
  const newDate2 = newDate.split('-');
  return `${newDate2[2]}/${newDate2[1]}/${newDate2[0]}`;
};

/**
 * input "2022-11-25 14:29:18" -> output "25/11/2022"
 */
export const fullDateToBr = (date: string, withTime = false): string => {
  const nDate = date.split(' ')[0];
  const newDate = nDate.slice(0, 10);
  const newDate2 = newDate.split('-');

  if (withTime) {
    const time = date.split(' ')[1] ?? '';
    return `${newDate2[2]}/${newDate2[1]}/${newDate2[0]} ${time}`;
  }

  return `${newDate2[2]}/${newDate2[1]}/${newDate2[0]}`;
};

export const delay = async (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, ms);
  });

export const getPathURL = () => {
  const pathArray = window.location.pathname.split('/');
  let pathName = '';
  for (let i = 0; i < pathArray.length; i += 1) {
    pathName += '/';
    pathName += pathArray[i];
  }

  return pathName.replace('//', '/');
};

export const getPercent = (total: number, part: number): number => {
  if (total === 0) {
    return 0;
  }

  return Math.round((part / total) * 10000) / 100;
};

export const getWeekDayName = (dayCode: number): string => {
  const dayCodeString = dayCode.toString();

  switch (dayCodeString) {
    case '1':
      return i18n.t('sunday');
    case '2':
      return i18n.t('monday');
    case '3':
      return i18n.t('tuesday');
    case '4':
      return i18n.t('wednesday');
    case '5':
      return i18n.t('thursday');
    case '6':
      return i18n.t('friday');
    case '7':
      return i18n.t('saturday');
    default:
      return i18n.t('unknownDay');
  }
};

export const getOrderOriginDescriptionByValue = (orderOriginCode: OrderOriginCode): string => {
  switch (orderOriginCode) {
    case '-1':
      return 'portalFastmanager';
    case '-2':
      return 'external';
    case '-3':
      return 'edi';
    case '-4':
      return 'ifood';
    case '-5':
      return 'smartsellEcommerce';

    default:
      return 'all';
  }
};
