import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import { useTheme } from 'styled-components';

import AddBtn from '../../../../../components/Buttons/AddBtn';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import { frontendNotification } from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import { DragIcon } from '../../../../../icons';
import { EventOnChange } from '../../../../../types/events';
import {
  getBooleanOptions,
  getOptionsFromAnswerTypes,
  getSelectOptionFromBoolean,
} from '../../../../../utils/getSelectOptions';
import * as s from './style';

type Props = {
  showModalCreateQuestion: boolean;
  closeModal: () => void;
  saveActionBtn: (
    question: string,
    answerType: { label: string; value: string | number },
    erpReferenceKey: string | null,
    isRequired: boolean,
    minSize: number | null,
    maxSize: number | null,
    options: ItemInterface[],
  ) => boolean;
};

interface ItemType {
  id: string;
}

export const ModalCreateQuestionJSX = ({
  showModalCreateQuestion,
  closeModal,
  saveActionBtn,
}: Props) => {
  const [question, setQuestion] = useState('');
  const [erpReferenceKey, setErpReferenceKey] = useState('');
  const [answerType, setAnswerType] = useState(getOptionsFromAnswerTypes()[0]);

  const [moreDetail, setMoreDetail] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [minSize, setMinSize] = useState(null);
  const [maxSize, setMaxSize] = useState(null);

  const [option, setOption] = useState('');
  const [options, setOptions] = useState<ItemType[] | []>([]);

  const theme = useTheme();

  const typeHasOptions = () => answerType.value === 'mi' || answerType.value === 'me';

  const addOptionBtn = () => {
    if (option.length <= 0) {
      return;
    }

    const optionsCopy: ItemType[] = [...options];

    if (optionsCopy.find((o) => o.id === option) === undefined) {
      optionsCopy.push({ id: option });
      setOptions(optionsCopy);
    } else {
      frontendNotification({ message: t('optionAlreadyExists'), type: 'warning' });
    }
  };

  const handleActionBtn = () => {
    if (question.length <= 0) {
      frontendNotification({ message: t('questionIsRequired'), type: 'warning' });
      return;
    }

    if (answerType.value === 'mi' || answerType.value === 'me') {
      if (options.length <= 0) {
        frontendNotification({ message: t('optionIsRequired'), type: 'warning' });
        return;
      }
    }

    saveActionBtn(
      question,
      answerType,
      erpReferenceKey,
      moreDetail ? isRequired : false,
      moreDetail ? minSize : null,
      moreDetail ? maxSize : null,
      options,
    );

    setQuestion('');
    setOption('');
    setOptions([]);
    closeModal();
  };

  const handleShowMinSizeInput = () =>
    answerType.value === 'n' || answerType.value === 'currency_br';

  const handleShowMaxSizeInput = () =>
    answerType.value === 'n' || answerType.value === 'currency_br' || answerType.value === 't';

  useEffect(() => {
    if (answerType.value !== 'mi' && answerType.value !== 'me') {
      setOptions([]);
    }
  }, [answerType]);

  return (
    <Modal
      title={t('addField')}
      showModal={showModalCreateQuestion}
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => handleActionBtn()}
      bodyStyle={options.length >= 5 ? { overflowX: 'scroll' } : {}}
    >
      <s.Container>
        <s.Line>
          <s.Item>
            <div className="inputLabel">{t('field') as string}</div>
            <Input
              value={question}
              onChange={(e: EventOnChange) => setQuestion(e.target.value)}
              placeholder={t('field')}
            />
          </s.Item>

          <s.Item>
            <div className="inputLabel">{t('answerType') as string}</div>
            <SingleSelect
              value={answerType}
              options={getOptionsFromAnswerTypes()}
              onChange={(o) => setAnswerType(o)}
              placeholder={t('answerType')}
            />
          </s.Item>

          <s.Item>
            <div className="inputLabel">{t('erpReferenceKey') as string}</div>
            <Input
              value={erpReferenceKey}
              onChange={(e: EventOnChange) => setErpReferenceKey(e.target.value)}
              placeholder={t('erpReferenceKey')}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item style={{ display: 'flex' }}>
            <div
              className="inputLabel"
              style={{ display: 'flex', alignItems: 'center', margin: 0 }}
            >
              {t('moreDetail') as string}
            </div>
            <input
              type="checkbox"
              checked={moreDetail}
              onChange={() => setMoreDetail(!moreDetail)}
            />
          </s.Item>
        </s.Line>

        {moreDetail && (
          <>
            <s.Line>
              <s.Item className="text-red">{t('formSecondVersionInfo') as string}</s.Item>
            </s.Line>
            <s.Line>
              <s.Item>
                <div className="inputLabel">{t('isRequired') as string}</div>
                <SingleSelect
                  value={getSelectOptionFromBoolean(isRequired)}
                  options={getBooleanOptions()}
                  onChange={(e) => setIsRequired(e.value)}
                />
              </s.Item>

              {handleShowMinSizeInput() && (
                <s.Item>
                  <div className="inputLabel">{t('minValue') as string}</div>
                  <input
                    className="input"
                    type="number"
                    value={minSize ?? 0}
                    onChange={(e: any) => setMinSize(e.target.value)}
                  />
                </s.Item>
              )}

              {handleShowMaxSizeInput() && (
                <s.Item>
                  <div className="inputLabel">
                    {answerType.value === 't'
                      ? (t('maxSize') as string)
                      : (t('maxValue') as string)}
                  </div>
                  <input
                    className="input"
                    type="number"
                    value={maxSize ?? 0}
                    onChange={(e: any) => setMaxSize(e.target.value)}
                  />
                </s.Item>
              )}
            </s.Line>
          </>
        )}

        {typeHasOptions() && (
          <s.OptionsArea>
            <s.Line>
              <s.Item>
                <Input
                  value={option}
                  onChange={(e: EventOnChange) => setOption(e.target.value)}
                  placeholder={t('option')}
                />
              </s.Item>
              <s.Item>
                <AddBtn onClick={addOptionBtn} />
              </s.Item>
            </s.Line>

            {options.length <= 0 && <s.NoOptions>{t('noOption') as string}</s.NoOptions>}

            <ReactSortable list={options} setList={setOptions}>
              {options.map((item) => (
                <s.Option key={item.id}>
                  <>
                    <s.DragArea>
                      <DragIcon title={t('drag')} color={theme.colors.onPrimary} disableHover />
                    </s.DragArea>
                    {item.id}
                  </>
                </s.Option>
              ))}
            </ReactSortable>
          </s.OptionsArea>
        )}
      </s.Container>
    </Modal>
  );
};

export default ModalCreateQuestionJSX;
