import { PropsWithChildren } from 'react';
import * as s from './styles';

interface LoaderProps {
  isLoading: boolean;
}

const LogsLoader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) => {
  const styleItems = {
    width: '100%',
    height: '30px',
    borderRadius: '5px',
  };

  return !isLoading ? (
    <div style={{ width: '100%' }}>{children}</div>
  ) : (
    <s.ConfigurationContainer>
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
      <s.IntegrationParameter id="ldg1" style={styleItems} />
    </s.ConfigurationContainer>
  );
};

export default LogsLoader;
