import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as s from './styles';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import { NotificationTypes } from '../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../enums/httpStatus';
import api from '../../../../services/api';
import { auth } from '../../../../services/endpoints';

import Btn from '../../../../components/Buttons/Btn';
import Input from '../../../../components/Input';
import LogoutContent from '../../../../templates/LogoutContent';

import ImgChecked from '../../../../assets/images/icons/checked.svg';
import { UrlPaths } from '../../../../enums/urlPaths.enum';

const Forgotpass = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleSendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(auth['/send-email-to-reset-password'], {
        email,
      });

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('linkSentToLogin'),
          type: NotificationTypes.SUCCESS,
        });

        setLinkSent(true);
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);
    }
    setIsLoading(false);
  };

  return (
    <LogoutContent
      title={t('forgotThePassword')}
      isLoading={isLoading}
      btnBack={() => navigate(UrlPaths.LOGIN)}
    >
      {linkSent ? (
        <s.SentSuccess>
          <img src={ImgChecked} alt="success icon" />
          <h3>{t('success')}</h3>
          <p>{t('weSentLink')}</p>
          <p>{t('checkYourInboxAndSpam')}</p>
          <Btn
            text={t('ok')}
            style={{ marginTop: '1.5rem', width: '100%' }}
            onClick={() => navigate(UrlPaths.LOGIN)}
          />
        </s.SentSuccess>
      ) : (
        <>
          <Input
            value={email}
            onChange={(v: any) => setEmail(v.target.value)}
            label={t('login')}
            style={{ marginTop: '50px' }}
            placeholder={t('enterYourEmailOrYourUsername')}
          />

          <Btn
            text={t('send')}
            style={{ margin: '1.5rem auto', width: '100%' }}
            onClick={handleSendEmail}
          />
        </>
      )}
    </LogoutContent>
  );
};

export default Forgotpass;
