import styled from 'styled-components';
import { defaultBorderRadius, defaultShadow } from '../../styles/global';

import backgroundLeft from '../../assets/images/signin/background/background_left.svg';
import backgroundRight from '../../assets/images/signin/background/background_right.svg';
import bgRightMobile from '../../assets/images/signin/background/background_right_mobile.svg';

export const LogoutContentContainer = styled.div`
  .theme-and-language {
    position: fixed;
    top: 10px;
    right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BackgroundLeft = styled.div`
  width: 50%;
  height: 100%;

  margin: 0 !important;
  padding: 0 !important;

  background: url(${backgroundLeft}) no-repeat left;
  background-color: ${(props) => props.theme.colors.primary};
  background-size: 60%;

  @media (max-width: 840px) {
    background-color: ${(props) => props.theme.colors.primary};
    background-size: 180%;
  }
`;

export const BackgroundRight = styled.div`
  width: 50%;
  height: 100%;

  margin: 0 !important;
  padding: 0 !important;

  background: url(${backgroundRight}) no-repeat right;
  background-size: 100%;
  background-color: ${(props) => props.theme.colors.background};

  @media (max-width: 840px) {
    background: url(${bgRightMobile}) no-repeat right;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 52px;
  padding: 2rem;

  transition: 1s;

  @media (max-width: 400px) {
    top: 0px;
    left: 30px;
    > * {
      width: 65px;
    }
  }

  /* @media (max-width: 640px) {
        display: none;
    } */
`;

export const MainArea = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${(p) => p.theme.colors.surface};
  width: 100%;
  height: 100%;
  max-width: 380px;
  max-height: 380px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 2rem;

  ${defaultShadow}
  ${defaultBorderRadius}
`;

export const Header = styled.div`
  text-align: center;
  font-size: 1.5rem;

  position: relative;

  .btn-back {
    position: absolute;
    top: 5px;
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary};
  }
`;
export const Content = styled.div`
  flex: 1;
`;
export const Footer = styled.div`
  align-self: center;
`;

export const Line = styled.hr`
  width: 100px;
  border: 2px solid ${(p) => p.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 1rem;
`;
