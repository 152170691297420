import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { commAutoReq } from '../../../../../../services/requests';
import * as TP from '../../../../../../types/apiResponseTypes';

type Props = {
  closeModal: () => void;
  commAutoSelected: TP.CommAutomation;
  reqGetCommAuto: () => void;
};

const ModalDeleteJSX = ({ closeModal, commAutoSelected, reqGetCommAuto }: Props) => {
  const { t } = useTranslation();

  const [waitingResponse, setWaitingResponse] = useState(false);

  const reqDelete = async () => {
    setWaitingResponse(true);

    const source = commAutoReq.axios.CancelToken.source();

    try {
      const res = await commAutoReq.delete(commAutoSelected.id);

      if (res.status === HttpStatus.OK) {
        setWaitingResponse(false);
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        closeModal();
        reqGetCommAuto();
      } else {
        throw res;
      }

      setWaitingResponse(false);
    } catch (err) {
      if (!commAutoReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingResponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={`${t('delete')} ${t('automation')}`}
      showModal
      closeModal={closeModal}
      action={() => reqDelete()}
      actionNameBtn={t('delete')}
      isLoading={waitingResponse}
    >
      <p>
        {`${t('deleteSure')}?`}
        <br />
        <br />
        <b>{t('description')}:</b> {commAutoSelected.description}
      </p>
    </Modal>
  );
};

export default ModalDeleteJSX;
