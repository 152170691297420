import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../../../components/AsyncTable';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import ViewLog from '../../../../../components/ViewLog';
import WarningCircle from '../../../../../components/WarningCircle';
import HttpStatus from '../../../../../enums/httpStatus';
import { CopyIcon, PreviewIcon, StepsIcon } from '../../../../../icons';
import { getImporterLogs } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { ImporterLog } from '../../../../../types/apiResponse/importer';

const ImporterLogs = () => {
  const theme = useTheme();

  const [logs, setLogs] = useState<ImporterLog[]>([]);

  const [viewLog, setViewLog] = useState(false);
  const [viewSteps, setViewSteps] = useState(false);
  const [logSelected, setLogSelected] = useState<ImporterLog | null>(null);
  const [updateTable] = useState(0);

  const handleTypeColumn = (row: ImporterLog) => {
    if (row.type === 'success') {
      return <WarningCircle type="success" />;
    }

    if (row.type === 'warning') {
      return <WarningCircle type="warning" />;
    }

    return <WarningCircle />;
  };

  const handleTableViewLogBtn = (row: ImporterLog) => {
    setLogSelected(row);
    setViewLog(true);
  };

  const handleTableViewStepBtn = (row: ImporterLog) => {
    setLogSelected(row);
    setViewSteps(true);
  };

  const handleTableCopyBtn = (row: ImporterLog) => {
    navigator.clipboard.writeText(JSON.stringify(row));
    frontendNotification({
      message: 'Copiado com sucesso',
      type: 'success',
    });
  };

  const actionsButtons = (row: ImporterLog) => (
    <ActionButtonsContainer type={4}>
      <PreviewIcon title={t('view')} onClick={() => handleTableViewLogBtn(row)} />
      <StepsIcon title={t('steps')} onClick={() => handleTableViewStepBtn(row)} />
      <CopyIcon title={t('copy')} onClick={() => handleTableCopyBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<ImporterLog>[] = [
    {
      label: t('type') as string,
      accessor: 'type',
      Cell: handleTypeColumn,
      cellStyle: {
        textAlign: 'center',
      },
      headerCellStyle: {
        textAlign: 'center',
      },
    },
    {
      label: t('orderNumber') as string,
      accessor: 'orderNumber',
    },
    {
      label: t('message') as string,
      accessor: 'message',
    },
    {
      label: t('duration') as string,
      accessor: 'duration',
    },
    {
      label: t('createdAt') as string,
      accessor: 'created_at',
      headerCellStyle: {
        width: '10rem',
      },
    },
    {
      label: t('actions') as string,
      headerCellStyle: {
        textAlign: 'center',
      },
      Cell: actionsButtons,
    },
  ];

  const getLogs: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = getImporterLogs.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      getImporterLogs.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await getImporterLogs.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data }: { data: ImporterLog[] } = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: 0,
      };
    } catch (err) {
      if (!getImporterLogs.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const rendeSteps = () => (
    <div>
      {logSelected?.steps.map((step: string, index: number) => (
        <p key={index} style={{ wordBreak: 'break-all' }}>
          {index + 1} - {step}
        </p>
      ))}
    </div>
  );

  return (
    <ContentContainter title={t('logs')}>
      <AsyncTable
        tableName={t('importerLogs')}
        columns={columns}
        value={logs}
        onChange={setLogs}
        requestPage={getLogs}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
        }}
      />

      {viewLog && (
        <ViewLog
          showModal={viewLog}
          closeModal={() => setViewLog(false)}
          log={JSON.stringify(logSelected, null, 2)}
        />
      )}

      {viewSteps && (
        <Modal
          showModal={viewSteps}
          closeModal={() => setViewSteps(false)}
          title={t('steps')}
          bodyStyle={{ overflow: 'scroll', width: '100%' }}
          onlyCloseButton
        >
          {rendeSteps()}
        </Modal>
      )}
    </ContentContainter>
  );
};

export default ImporterLogs;
