import { t } from 'i18next';
import { useState } from 'react';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../enums/httpStatus';
import { ordersApi } from '../../../../../services/requests';
import { Order } from '../../../../../types/apiResponse/order';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  callback: () => void;
  order: Order;
};

const BudgeToOrderModal = ({ showModal, closeModal, callback, order }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateBudgetOrder = async () => {
    try {
      setIsLoading(true);

      ordersApi.query = '';

      const response = await ordersApi.update({ EOrcamento: 'n' }, order.NumeroPedido);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: NotificationTypes.SUCCESS,
        });
        callback();
        closeModal();
      } else {
        throw response;
      }
    } catch (erro) {
      backendErrorNotification(erro as any);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={`${t('budget')}: ${order.NumeroPedido}`}
      showModal={showModal}
      action={() => updateBudgetOrder()}
      actionNameBtn={t('confirm')}
      cancelButton={closeModal}
      closeModal={closeModal}
      bodyStyle={{ overflow: 'scroll', width: '100%' }}
      isLoading={isLoading}
    >
      <p>{t('convertBudgetToOrder') as string}?</p>
    </Modal>
  );
};

export default BudgeToOrderModal;
