import { t } from 'i18next';
import Modal from '../../../../../../components/Modal';
import { CommunicationLogs } from '../../../../../../types/apiResponseTypes';
import { clearPHPdate } from '../../../../../../utils/helpers';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  log: CommunicationLogs;
};

const ViewLogJSX = ({ showModal, closeModal, log }: Props) => (
  <Modal
    title="Log"
    showModal={showModal}
    action={() => null}
    closeModal={closeModal}
    onlyCloseButton
    bodyStyle={{ minWidth: '60vw' }}
  >
    <p>{t('orderNumber') as string}</p>
    <input className="input" value={log.order_number} />

    <p>{t('dateTime') as string}</p>
    <input className="input" value={clearPHPdate(log.created_at as string)} />

    <p>{t('sender') as string}</p>
    <input className="input" value={log.email_from} />

    <p>{t('recipient') as string}</p>
    <input className="input" value={log.email_to} />

    <p>{t('message') as string}</p>
    <input className="input" value={log.message} />

    {(log.exception_info as any) && (
      <>
        <p>{t('detailedError') as string}</p>
        <textarea className="input" value={log.exception_info} />
      </>
    )}
  </Modal>
);

export default ViewLogJSX;
