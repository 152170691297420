import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import TablePaginator from '../../../../../../components/TablePaginator';
import { SalesCol } from '../../../../../../components/TablePaginator/types';
import HttpStatus from '../../../../../../enums/httpStatus';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlSellersRankingReq } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import Loader from './Loader';

// import dataTest from './dataFake';

const Page2 = () => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const [page2Data, setPage2Data] = useState<SalesCol[]>([]);

  const handleRankingSellersIndex = useCallback(() => {
    const source = dashboardEtlSellersRankingReq.axios.CancelToken.source();
    const query = new Query();

    setPage2Data([]);

    if (filters.filters.years && filters.filters.years.length >= 1) {
      query.whereIn('years', filters.filters.years);
    }

    if (filters.filters.months && filters.filters.months.length >= 1) {
      query.whereIn('months', filters.filters.months);
    }

    const urlQueryParams = query.get() ?? '';

    dashboardEtlSellersRankingReq.query = urlQueryParams;

    dashboardEtlSellersRankingReq
      .index(source.token)
      .then((res) => {
        if (res.status === HttpStatus.OK) {
          if (res.data.data) {
            const responseData = res.data.data;
            setPage2Data(responseData);
          } else {
            setPage2Data([
              {
                position: 0,
                seller: '',
                gross_amount: 0,
                net_amount: 0,
                total_weight: 0,
                average_ticket: 0,
                average_term: 0,
                average_mix: 0,
                conversion_rate: 0,
              },
            ]);
            frontendNotification({
              message: 'Sem dados de pedidos!',
              type: NotificationTypes.WARNING,
            });
          }
        } else {
          throw res;
        }
      })
      .catch((err) => {
        if (!dashboardEtlSellersRankingReq.axios.isCancel(err)) {
          backendErrorNotification(err);
        }
      });
    return () => {
      source.cancel('Component Page2 Dashboard got unmounted');
    };
  }, [filters]);

  useEffect(() => handleRankingSellersIndex(), [filters]);

  const columns: Column<SalesCol>[] = useMemo(
    () => [
      {
        Header: t('position') as string,
        accessor: 'position',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
      },
      {
        Header: t('seller') as string,
        accessor: 'seller',
        headerStyle: { textAlign: 'left' },
      },
      {
        Header: t('grossAmount') as string,
        accessor: 'gross_amount',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.gross_amount, type: 'currencyBR' }),
      },
      {
        Header: t('netAmount') as string,
        accessor: 'net_amount',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.net_amount, type: 'currencyBR' }),
      },
      {
        Header: `${t('totalWeight')} (kg)`,
        accessor: 'total_weight',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.total_weight }),
      },
      {
        Header: t('averageTicket') as string,
        accessor: 'average_ticket',
        Cell: (v) =>
          FormartNumberJSX({ number: v.row.original.average_ticket, type: 'currencyBR' }),
      },
      {
        Header: t('averageTerm') as string,
        accessor: 'average_term',
        // Cell: (v) => {
        // 	const valueFormated = `${formatNumber.number(v.row.original.average_term)} ${
        // 		v.row.original.average_term > 1 ? t('days') : t('day')
        // 	}`;
        // 	return v.row.original.average_term ? valueFormated : '(N/A)';
        // },
      },
      {
        Header: t('averageMix') as string,
        accessor: 'average_mix',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.average_mix }),
      },
      {
        Header: t('conversionRate') as string,
        accessor: 'conversion_rate',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.conversion_rate, type: 'percent' }),
      },
    ],
    [],
  );

  const data = useMemo(() => page2Data, [page2Data]);

  return (
    <Loader isLoading={page2Data.length <= 0}>
      <TablePaginator data={data} columns={columns} showMoreRows globalFiltering downloadXlsx />
    </Loader>
  );
};

export default Page2;
