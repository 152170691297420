import styled from 'styled-components';
import { defaultShadow } from '../../../../../../styles/global';

export const Pag1Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    margin: 1rem;
    border-radius: 0.75rem;
    padding: 1rem;
    box-sizing: border-box;
  }
`;

export const GraphsContainer = styled.div`
  flex: 1;

  width: 100%;
  max-width: 850px;
  min-width: 55%;
  height: 300px;

  margin: 0;

  @media (max-width: 900px) {
    min-width: 100%;
  }

  > div {
    /* max-height: 30px !important; */
  }
`;

export const ConversionRate = styled.div`
  flex: 1;

  @media (min-width: 800px) {
    max-width: 250px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .conversion-rate-title {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .circular-graph {
    width: 14rem;
    height: 14rem;
    > * {
      margin: auto;
    }
  }
`;

export const InfoCards = styled.div`
  flex: 1;

  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @media (min-width: 1000px) {
    min-width: 60%;
  }
`;

export const ExtraInfos = styled.div`
  flex: 1;
  max-width: 280px;
  min-width: 200px;

  ${defaultShadow}
  border-radius: 0.75rem;
  padding: 1rem;

  @media (max-width: 652px) {
    max-width: 100%;
  }

  .extrainfos-top,
  .extrainfos-bottom {
    display: flex;
    justify-content: space-between;
  }

  > hr {
    color: ${(p) => p.theme.colors.onBackground}50;
    margin-bottom: 1.5rem;
  }

  .extrainfo-item {
    text-align: center;
    > p {
      color: ${(p) => p.theme.colors.primary};
      font-weight: bold;
    }
  }
`;

export const TopCustomers = styled.div`
  flex: 1;
  color: ${(p) => p.theme.colors.onSurface};

  min-width: 250px;

  padding: 1rem;
  border-radius: 0.75rem;

  ${defaultShadow}

  @media (max-width: 552px) {
    width: 100%;
    margin: 1rem;
    min-width: auto;
  }

  .top-customers-title {
    text-align: center;
    font-size: 1.3rem;
  }

  > ul {
    list-style: none;
    padding: 0;
    max-height: 300px;
    overflow: auto;

    > li {
      height: 30px;
      margin-bottom: 1rem;
      display: flex;
      border-radius: 0.5rem;
      overflow: hidden;

      /* ${defaultShadow} */
      border: 1px solid ${(p) => p.theme.colors.onSurface}70;

      .customer-rank-number {
        background-color: ${(p) => p.theme.colors.primary};
        color: ${(p) => p.theme.colors.onPrimary};

        min-width: 25px;
        padding: 0.5rem;

        font-weight: bold;
        text-align: center;
      }

      .customer-rank-name {
        width: 100%;
        height: 10px;

        display: flex;
        justify-content: center;

        padding-left: 1rem;
        margin: auto;

        font-size: 0.9rem;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
`;
