import { PropsWithChildren } from 'react';
import ContentLoad from './ContentLoad';
import * as s from './styles';

interface ContentContainerProps {
  title?: string;
  isLoading?: boolean;
  actions?: JSX.Element[];
}

const ContentContainer: React.FC<PropsWithChildren<ContentContainerProps>> = ({
  title = '',
  isLoading = false,
  actions = [],
  children,
}) => (
  <s.Container className="content-shadow">
    <s.HeaderBar>
      <div className="content-container-title">{title}</div>
      <div className="content-container-actions-area">
        {actions.map((action) => {
          return <>{action}</>;
        })}
      </div>
    </s.HeaderBar>
    {isLoading ? <ContentLoad /> : <s.Content>{children}</s.Content>}
  </s.Container>
);

ContentContainer.defaultProps = {
  title: '',
};

export default ContentContainer;
