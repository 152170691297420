import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import { customersApi, visitScheduleAPI } from '../../../../../../services/requests';
import { VisitBySeller } from '../../../../../../types';
import { getOptionsFromCustomers } from '../../../../../../utils/getSelectOptions';
import {
  frequencyIndex,
  getDate,
  getFrequencyOptions,
  getWeekDayOptions,
  weekDays,
} from '../../../../../../utils/helpers';
import * as s from './styles';

import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import BasicDatePicker from '../../../../../../components/SelectDate';

type Props = {
  closeModal: () => void;
  selecteditem: VisitBySeller;
  reqCallback: () => void;
};

type Option = {
  value: any;
  label: string;
};

const ModalEditVisitScheduleJSX = ({ closeModal, selecteditem, reqCallback }: Props) => {
  const [waitingRequest, setWaitingRequest] = useState(false);

  const [weekDay, setWeekDay] = useState<Option | Record<string, unknown>>({
    label: weekDays()[selecteditem.weekDay],
    value: selecteditem.weekDay,
  });

  const [frequency, setFrequency] = useState<Option | Record<string, unknown>>({
    label: frequencyIndex()[selecteditem.frequency],
    value: selecteditem.frequency,
  });

  const [nextVisitDate, setNextVisitDate] = useState(selecteditem.nextVisitDate);

  const [hour, setHour] = useState(selecteditem.hour);
  const [salesTargetValue] = useState(selecteditem.salesTargetValue);
  const [note] = useState(selecteditem.note);

  const [customer, setCustomer] = useState<Option | Record<string, unknown>>({
    label: `${selecteditem.customer?.CodigoCliente} - ${selecteditem.customer?.RazaoSocial}`,
    value: selecteditem.customerCode,
  });

  const reqEdit = async () => {
    setWaitingRequest(true);
    try {
      const data = {
        sellerCode: selecteditem.sellerCode,
        customerCode: customer.value,
        frequency: frequency.value.toString(),
        weekDay: weekDay.value.toString(),
        nextVisitDate: getDate(nextVisitDate),
        hour,
        salesTargetValue,
        note,
      };

      const res = await visitScheduleAPI.update(data, selecteditem.id);

      if (res.status === HttpStatus.OK) {
        await reqCallback();
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      if (!visitScheduleAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }
    setWaitingRequest(false);
  };

  return (
    <Modal
      title={t('visitUpdate')}
      action={() => reqEdit()}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      actionNameBtn={t('save')}
      isLoading={waitingRequest}
      style={{ minWidth: 'max-content' }}
      bodyStyle={{ minWidth: '100%' }}
    >
      <s.Container>
        <s.Row>
          <s.Item>
            <s.Label>{t('seller') as string}</s.Label>
            {/* eslint-disable-next-line max-len */}
            <p>{`${selecteditem.seller?.CodigoVendedor} - ${selecteditem.seller?.Nome}`}</p>
          </s.Item>
          <s.Item id="weekDay">
            <s.Label>{t('weekDay') as string}</s.Label>
            <SingleSelect
              value={weekDay}
              onChange={(e: any) => setWeekDay(e)}
              options={getWeekDayOptions()}
            />
          </s.Item>

          <s.Item id="frequency">
            <s.Label>{t('frequency') as string}</s.Label>
            <SingleSelect
              value={frequency}
              onChange={(e: any) => setFrequency(e)}
              options={getFrequencyOptions()}
            />
          </s.Item>

          <s.Item id="nextDate">
            <s.Label>{t('nextVisitDate') as string}</s.Label>
            <BasicDatePicker value={nextVisitDate} onChange={setNextVisitDate} />
          </s.Item>

          <s.Item id="hour">
            <s.Label>{t('hour') as string}</s.Label>
            <Input
              value={hour}
              type="time"
              onChange={(e: any) => setHour(e.target.value)}
              disabled={waitingRequest}
            />
          </s.Item>

          <s.Item id="customer">
            <s.Label>{t('selectCustomer') as string}</s.Label>
            <SelectAsyncJSX
              value={customer}
              onChange={(e: any) => setCustomer(e)}
              request={customersApi}
              reqResponseToOption={getOptionsFromCustomers}
            />
          </s.Item>
        </s.Row>
      </s.Container>
    </Modal>
  );
};

export default ModalEditVisitScheduleJSX;
