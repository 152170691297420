import { t } from 'i18next';
import { useRef } from 'react';
import { DeleteIcon, FileUploadIcon } from '../../icons';
import * as s from './styles';

type Props = {
  value: HTMLInputElement | null;
  setFile: (e: any) => void;
  disabled?: boolean;
  placeholder?: string;
};

export const InputFileJSX = ({ value, setFile, disabled, placeholder }: Props) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const openDialog = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <s.Container className="input" disabled={disabled}>
      {value ? (
        <DeleteIcon title={t('removeFile')} onClick={removeFile} />
      ) : (
        <FileUploadIcon title={t('fileUpload')} onClick={openDialog} />
      )}
      {value ? (
        <s.Label onClick={openDialog}>{value.name}</s.Label>
      ) : (
        <s.Label onClick={openDialog}>{placeholder}</s.Label>
      )}
      <input
        type="file"
        onChange={(e: any) => setFile(e.target.files[0])}
        disabled={disabled}
        ref={fileInput}
      />
    </s.Container>
  );
};

InputFileJSX.defaultProps = {
  disabled: false,
  placeholder: 'Select a file',
};

export default InputFileJSX;
