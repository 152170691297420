type Props = {
  numberOfrows: number;
};

const TableRowEffect = ({ numberOfrows }: Props) => {
  const rows = [];

  for (let i = 0; i < numberOfrows; i++) {
    rows.push(<div className={`orders-table-row ${i % 2 !== 0 ? 'odd-row' : ''}`} key={i} />);
  }

  return (
    <div className="orders-table-rows-effect">
      {' '}
      <div className="orders-table-columns-area">
        <div className="orders-table-column tb-column-effect">
          <div className="orders-table-column-header visibility-hidden">.</div>
          <div className="orders-table-sub-columns-area">
            <div className="orders-table-sub-colmun tb-sub-column-effect">
              <div className="orders-table-sub-colmun-header visibility-hidden">.</div>
              <div className="orders-table-data-list-area">{rows}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableRowEffect;
