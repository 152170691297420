import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';

import AsyncTable, { Column, RequestPage } from '../../../../../../components/AsyncTable';
import { backendErrorNotification } from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import useDashboardFilters from '../../../../../../hooks/useDashboardFilters';
import { visitHistoryApi } from '../../../../../../services/requests';
import { VisitHistory } from '../../../../../../types/apiResponse/visitHistory';
import { ApiResPaginated } from '../../../../../../types/apiResponseTypes';

const VisitHistoryTsx = () => {
  const theme = useTheme();
  const {
    filters: { filters },
  } = useDashboardFilters();

  const [visitHistories, setVisitHistories] = useState<VisitHistory[]>([]);

  const columns: Column<VisitHistory>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('type') as string,
      accessor: 'FlagTipo',
    },
    {
      label: t('sellerCode') as string,
      accessor: 'CodigoVendedor',
    },
    {
      label: t('customerCode') as string,
      accessor: 'CodigoCliente',
    },
    {
      label: t('scheduledDate') as string,
      accessor: 'DataAgendada',
    },
    {
      label: t('checkin') as string,
      accessor: 'sistematica_checkin_realizado',
      Cell: (row) => (row.sistematica_checkin_realizado ? t('yes') : t('not')),
    },
    {
      label: t('checkout') as string,
      accessor: 'sistematica_checkout_realizado',
      Cell: (row) => (row.sistematica_checkout_realizado ? t('yes') : t('not')),
    },
    {
      label: t('note') as string,
      accessor: 'Observacao',
    },
  ];

  const requestGetItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = visitHistoryApi.axios.CancelToken.source();

    try {
      let query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;

      const availableFilters = ['years', 'months', 'sellers', 'customers'];

      availableFilters.forEach((filterName) => {
        const f = filters as any;
        if (f[filterName] && f[filterName].length > 0) {
          query += `&filter[${filterName}]=${f[filterName].join(',')}`;
        }
      });

      visitHistoryApi.query = query;

      const res = await visitHistoryApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<VisitHistory[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!visitHistoryApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  return (
    <AsyncTable
      tableName={t('visitHistory')}
      columns={columns}
      value={visitHistories}
      onChange={setVisitHistories}
      requestPage={requestGetItems}
      reqListener={filters}
      options={{
        styles: {
          primaryColor: `${theme.colors.surface}`,
          secondaryColor: `${theme.colors.onSurface}`,
          alternateRowColor: theme.colors.textLight,
          textColor: theme.colors.text,
        },
        quantityPerPageLabel: t('quantityPerPage'),
        searchPlaceholder: t('search'),
      }}
    />
  );
};

export default VisitHistoryTsx;
