import { Container as BaseContainer } from 'react-bootstrap';
import styled from 'styled-components';
import { defaultShadow } from '../../styles/global';

export const Container = styled(BaseContainer)`
  background: ${(p) => p.theme.colors.surface};

  width: 90%;

  padding: 0;
  border-radius: 10px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 50px;

  ${defaultShadow}

  @media(min-width: 1420px) {
    max-width: 1420px;
  }
`;

export const HeaderBar = styled.div`
  background: ${(p) => p.theme.colors.primary};
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;

  .content-container-title {
    font-size: 1.6rem;
    color: ${(p) => p.theme.colors.onPrimary};
    padding: 0.6rem 1.6rem;
  }

  .content-container-actions-area {
    padding: 0.6rem 1.6rem;
    display: flex;
    opacity: 0.8;
  }
`;

export const Content = styled.div`
  padding: 0.6rem 1.6rem;
`;
