import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';

export const HeaderBar = styled(Navbar)`
  width: 100%;
  min-height: 40px;
  margin-bottom: 1rem;

  justify-content: flex-end;
  background: ${(p) => p.theme.colors.surface};
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2);

  @media (min-width: 1024px) {
    display: none;
  }
`;

export default HeaderBar;
