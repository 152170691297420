import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { superSmartsellAppVersionsApi, tenantsReq } from '../../../../../services/requests';
import * as s from './styles';

import Input from '../../../../../components/Input';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import { TenantStore } from '../../../../../models';
import { SmartsellAppVersion } from '../../../../../types/apiResponse/SmartsellAppVersions';
import { SelectOption } from '../../../../../utils/getSelectOptions';

export const AddTenantModal = ({ showModal, closeModal, reqGetTenants }: any) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [trandingName, setTrandingName] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [communicationUrl, setcommunicationUrl] = useState('');
  const [smartsellAppVersionSelected, setSmartsellAppVersionSelected] = useState<SelectOption>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [isNewDB, setIsNewDB] = useState(false);
  const [database, setDatabase] = useState('');
  const [hostDB, setHostDB] = useState('173.212.235.161');
  const [userDB, setUserDB] = useState('');
  const [passwordDB, setPasswordDB] = useState('');
  const [portDB, setPortDB] = useState('3306');

  const handleCheckboxDB = () => {
    setIsNewDB(!isNewDB);
  };

  const handleCreateTenant = async () => {
    setIsLoading(true);
    try {
      const tenantStore: TenantStore = {
        name,
        trading_name: trandingName,
        database,
        communication_url: communicationUrl,
        smartsell_app_version_id: smartsellAppVersionSelected?.value as number,
        cnpj,
        email,
        password,
        password_confirmation: passwordConfirmation,
      };
      if (!isNewDB) {
        tenantStore.extra_data = {
          db: database.toString(),
          host: hostDB.toString(),
          user: userDB,
          pass: passwordDB,
          port: portDB,
        };
      }

      const res = await tenantsReq.store<TenantStore>(tenantStore);

      if (res.status === HttpStatus.CREATED || res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('registeredCompany'),
          type: NotificationTypes.SUCCESS,
        });

        setName('');
        setTrandingName('');
        setCnpj('');
        setcommunicationUrl('');
        setEmail('');
        setPassword('');
        setPasswordConfirmation('');
        setDatabase('');
        setHostDB('');
        setUserDB('');
        setPasswordDB('');
        setPortDB('');

        reqGetTenants();
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      backendErrorNotification(err as AxiosError<any, any>);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title={t('registerTenant')}
      showModal={showModal}
      closeModal={closeModal}
      action={() => handleCreateTenant()}
      isLoading={isLoading}
      bodyStyle={{ overflow: 'scroll' }}
    >
      <>
        <s.DivisionTitle>{t('tenantData')}</s.DivisionTitle>
        <s.Row>
          <s.Item style={{ maxWidth: '45%' }}>
            <div className="label">{t('smartsellAppVersion')}</div>
            <SelectAsyncJSX
              value={smartsellAppVersionSelected}
              onChange={setSmartsellAppVersionSelected}
              request={superSmartsellAppVersionsApi}
              reqResponseToOption={{
                mapper: {
                  label: (item: SmartsellAppVersion) => `v${item.version}.${item.file_type}`,
                  value: (item: SmartsellAppVersion) => item.id,
                },
              }}
              placeholder=""
              disabled
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              label={t('name')}
              required={t('required')}
              disabled={isLoading}
            />
          </s.Item>
          <s.Item>
            <Input
              value={trandingName}
              onChange={(e: any) => setTrandingName(e.target.value)}
              label={t('trandingName')}
              required={t('required')}
              disabled={isLoading}
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={cnpj}
              onChange={(e: any) => setCnpj(e.target.value)}
              label="CNPJ"
              required={t('isNotNumber')}
              type="number"
              disabled={isLoading}
            />
          </s.Item>
          <s.Item>
            <Input
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              label={t('email')}
              id={t('email')}
              required={t('required')}
              type="email"
              errorMsg={t('invalidEmail')}
              disabled={isLoading}
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={communicationUrl}
              onChange={(e: any) => setcommunicationUrl(e.target.value)}
              label={t('urlCommunication')}
              id={t('urlCommunication')}
              required={t('required')}
              disabled={isLoading}
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              label={t('password')}
              id={t('password')}
              required={t('required')}
              type="password"
              showPswBtn
              disabled={isLoading}
            />
          </s.Item>
          <s.Item>
            <Input
              value={passwordConfirmation}
              onChange={(e: any) => setPasswordConfirmation(e.target.value)}
              label={t('passwordConfirmation')}
              id={t('passwordConfirmation')}
              required={t('required')}
              type="password"
              isMatch={password}
              showPswBtn
              errorMsg={t('passwordsDoNotMatch')}
              disabled={isLoading}
            />
          </s.Item>
        </s.Row>
        <s.DivisionTitle>{t('configDatabase')}</s.DivisionTitle>

        <s.Row>
          <s.Item>
            <Input
              value={database}
              onChange={(e: any) => setDatabase(e.target.value)}
              label={t('databaseName')}
              required={t('required')}
              disabled={isLoading}
            />
          </s.Item>
          <s.Item>
            {t('createNewDatabase')}
            <input
              type="checkbox"
              checked={isNewDB}
              onChange={handleCheckboxDB}
              disabled={isLoading}
            />
          </s.Item>
        </s.Row>

        {!isNewDB && (
          <>
            <s.Row>
              <s.Item>
                <Input
                  value={hostDB}
                  onChange={(e: any) => setHostDB(e.target.value)}
                  label={t('host')}
                  required={t('required')}
                  disabled={isLoading}
                />
              </s.Item>
              <s.Item>
                <Input
                  value={portDB}
                  onChange={(e: any) => setPortDB(e.target.value)}
                  label={t('port')}
                  required={t('required')}
                  disabled={isLoading}
                />
              </s.Item>
            </s.Row>
            <s.Row>
              <s.Item>
                <Input
                  value={userDB}
                  onChange={(e: any) => setUserDB(e.target.value)}
                  label={t('user')}
                  required={t('required')}
                  disabled={isLoading}
                />
              </s.Item>
              <s.Item>
                <Input
                  value={passwordDB}
                  onChange={(e: any) => setPasswordDB(e.target.value)}
                  label={t('password')}
                  required={t('required')}
                  type="password"
                  showPswBtn
                  disabled={isLoading}
                />
              </s.Item>
            </s.Row>
          </>
        )}
      </>
    </Modal>
  );
};

export default AddTenantModal;
