import Theme from '../../enums/theme';

// all colors must be at the standard 6 characters, so they can use the CSS transparency feature.
//  #ffffff50 = white with 50% transparency.

export default {
  title: Theme.LIGHT,
  colors: {
    primary: '#CD1719',
    primaryLight: '#fa4c30',
    primaryMedium: '#cd1719',
    primaryDark: '#870000',

    secondary: '#c6184c',

    background: '#FFFFFF',
    surface: '#FFFFFF',

    text: '#555555',
    textLight: '#efefef',

    positive: '#007777',
    negative: '#b00020',
    warning: '#eace5d',

    onPrimary: '#ffffff',
    onSecondary: '#ffffff',
    onBackground: '#333333',
    onSurface: '#555555',
    onSurfaceLight: '#666666',
  },
};
