import { MdDragIndicator } from 'react-icons/md';

import { DeleteIcon, EditIcon } from '../../../../../icons';
import { FormQuestion } from '../../../../../types';
import * as s from './styles';

type Props = {
  question: FormQuestion;
  removeQuestion: () => void;
  editQuestion: () => void;
  formSubmissionStarted?: boolean;
};

export const QuestionCardJSX = ({
  question,
  removeQuestion,
  editQuestion,
  formSubmissionStarted,
}: Props) => (
  <s.Container>
    <s.DragArea>
      <MdDragIndicator />
    </s.DragArea>
    <s.Item>{question.question}</s.Item>
    <s.IconArea>
      {!formSubmissionStarted && (
        <>
          <DeleteIcon title="delete" onClick={removeQuestion} />
          <EditIcon title="edit" onClick={editQuestion} />
        </>
      )}
    </s.IconArea>
  </s.Container>
);

QuestionCardJSX.defaultProps = {
  formSubmissionStarted: false,
};

export default QuestionCardJSX;
