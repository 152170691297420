import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import { NotificationTypes } from '../../../../components/Notification/notificationEnums';
import history from '../../../../services/history';
import {
  superAccessControlApi,
  superAccessControlMetricsApi,
  tenantsReq,
} from '../../../../services/requests';
import { StoreState } from '../../../../store/createStore';
import ContentContainer from '../../../../templates/Content';

import { BsGraphUpArrow } from 'react-icons/bs';
import { FaHashtag, FaPercent } from 'react-icons/fa';
import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import Btn from '../../../../components/Buttons/Btn';
import CardIcon from '../../../../components/CardIcon';
import SelectAsyncJSX from '../../../../components/SelectAsync';
import { ActionButtonsContainer } from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import { UrlPaths } from '../../../../enums/urlPaths.enum';
import { ListIcon } from '../../../../icons';
import { AccessControl, AccessControlMetrics } from '../../../../types/apiResponse/accessControl';
import { ApiResPaginated } from '../../../../types/apiResponseTypes';
import { SelectOption } from '../../../../utils/getSelectOptions';
import { datetimeLocalToTimestamp, getCurrentDateTime } from '../../../../utils/helpers';
import { Tenant } from '../../Tenant/types';
import ViewAccessLogTSX from '../Components/ViewAccessLog';

const defaultMetrics: AccessControlMetrics = {
  total_access_count: 0,
  total_users_accessed: 0,
  average_access_per_user: 0,
  user_with_most_access: null,
  user_with_least_access: null,
};

const AccessControlTSX = () => {
  const { user } = useSelector((state: StoreState) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);

  const [metrics, setMetrics] = useState<AccessControlMetrics>(defaultMetrics);

  const [accessControl, setAccessControl] = useState<AccessControl[]>([]);
  const [isViewAllAccessModalVisible, setIsViewAllAccessModalVisible] = useState(false);
  const [accessControlSelected, setAccessControlSelected] = useState<AccessControl | null>(null);

  const [tenantSelected, setTenantSelected] = useState<SelectOption | null>(null);
  const [startDate, setStartDate] = useState<string>(getCurrentDateTime(30, 'sub'));
  const [endDate, setEndDate] = useState<string>(getCurrentDateTime());

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = superAccessControlApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      superAccessControlApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      if (tenantSelected) {
        superAccessControlApi.query += `&tenantId=${tenantSelected.value}`;
      }
      superAccessControlApi.query += `&startDate=${datetimeLocalToTimestamp(startDate)}`;
      superAccessControlApi.query += `&endDate=${datetimeLocalToTimestamp(endDate)}`;

      const res = await superAccessControlApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<AccessControl[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!superAccessControlApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const getMetrics = async () => {
    const source = superAccessControlMetricsApi.axios.CancelToken.source();
    setIsLoading(true);

    try {
      superAccessControlMetricsApi.query = '?q=q';

      if (tenantSelected) {
        superAccessControlMetricsApi.query += `&tenantId=${tenantSelected.value}`;
      }

      superAccessControlMetricsApi.query += `&startDate=${datetimeLocalToTimestamp(startDate)}`;
      superAccessControlMetricsApi.query += `&endDate=${datetimeLocalToTimestamp(endDate)}`;

      const response = await superAccessControlMetricsApi.index(source.token);

      if (response.status === HttpStatus.OK) {
        const data = response.data.data as AccessControlMetrics;
        setMetrics(data);
      } else {
        throw response;
      }
    } catch (err) {
      if (!superAccessControlMetricsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      frontendNotification({
        message: 'Área restrita!',
        type: NotificationTypes.WARNING,
      });

      history.push(UrlPaths.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    getMetrics();
  }, []);

  const handlePeriodColumn = (accessControl: AccessControl) => {
    const dateSplited = accessControl.created_at.split('-');

    return `${dateSplited[1]}/${dateSplited[0]}`;
  };

  const handleViewAllAccess = (accessControl: AccessControl) => {
    setAccessControlSelected(accessControl);
    setIsViewAllAccessModalVisible(true);
  };

  const actionsButtons = (accessControl: AccessControl) => (
    <ActionButtonsContainer type={4}>
      <ListIcon title={t('viewAllAccess')} onClick={() => handleViewAllAccess(accessControl)} />
    </ActionButtonsContainer>
  );

  const handleOnCloseModals = () => {
    setIsViewAllAccessModalVisible(false);
    setAccessControlSelected(null);
  };

  const columns: Column<AccessControl>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('tenantId') as string,
      Cell: (v) => v.user.tenant_id as unknown as string,
    },
    {
      label: t('userId') as string,
      accessor: 'user_id',
    },
    {
      label: t('username') as string,
      Cell: (v) => v.user.username,
    },
    {
      label: t('email') as string,
      Cell: (v) => v.user.email,
    },
    {
      label: t('location') as string,
      accessor: 'location',
    },
    {
      label: t('accesses') as string,
      accessor: 'monthly_access_count',
    },
    {
      label: t('period') as string,
      Cell: (v) => handlePeriodColumn(v),
    },
    {
      Cell: (v) => actionsButtons(v),
    },
  ];

  const handleFilterBtn = () => {
    getMetrics();
    setUpdateTable(updateTable + 1);
  };

  return (
    <ContentContainer title={t('accessControl')} isLoading={isLoading}>
      <div className="row">
        <div className="inputWithLabel">
          <div className="label">{t('tenant') as string}</div>
          <SelectAsyncJSX
            value={tenantSelected}
            onChange={setTenantSelected}
            request={tenantsReq}
            reqResponseToOption={{
              mapper: {
                label: (item: Tenant) => `${item.id} - ${item.name}`,
                value: (item: Tenant) => item.id,
              },
            }}
            placeholder={t('allTenants')}
          />
        </div>

        <div className="inputWithLabel">
          <div className="label">{t('startDate') as string}</div>
          <input
            className="input"
            type="datetime-local"
            value={startDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="inputWithLabel">
          <div className="label">{t('endDate') as string}</div>
          <input
            className="input"
            type="datetime-local"
            value={endDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <div className="inputWithLabel" style={{ maxWidth: 'max-content' }}>
          <div className="label">{''}</div>
          <Btn text={t('filter')} onClick={() => handleFilterBtn()} />
        </div>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CardIcon
          color="#6c63ff"
          icon={<FaHashtag />}
          title={t('totalNumberOfUsersWhoAccessed')}
          text={metrics.total_users_accessed as unknown as string}
        />

        <CardIcon
          color="#1D98DF"
          icon={<FaHashtag />}
          title={t('totalAccessCount')}
          text={metrics.total_access_count as unknown as string}
        />

        <CardIcon
          color="#DE7223"
          icon={<FaPercent />}
          title={t('averageAccessPerUser')}
          text={metrics.average_access_per_user as unknown as string}
        />

        {metrics.user_with_most_access && (
          <CardIcon
            color="#28731a"
            icon={<BsGraphUpArrow />}
            title={`
              ${metrics.user_with_most_access.id} - ${metrics.user_with_most_access.name}
            `}
            text={metrics.user_with_most_access.count as unknown as string}
          />
        )}

        {/* {metrics.user_with_least_access && (
          <CardIcon
            color="#CD1719"
            icon={<BsGraphDownArrow />}
            title={`
              ${metrics.user_with_least_access.id} - ${metrics.user_with_least_access.name}
            `}
            text={metrics.user_with_least_access.count as unknown as string}
          />
        )} */}
      </div>

      <AsyncTable
        tableName={t('accessControl')}
        columns={columns}
        value={accessControl}
        onChange={setAccessControl}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
          totalPageLabel: `${t('totalPages')}: `,
        }}
      />

      {isViewAllAccessModalVisible && accessControlSelected && (
        <ViewAccessLogTSX
          showModal={isViewAllAccessModalVisible}
          closeModal={handleOnCloseModals}
          accessControl={accessControlSelected}
          title={`${t('accessControl')} - ${accessControlSelected.user.username}`}
          onlyCloseButton
          bodyStyle={{ overflow: 'scroll', width: '100%' }}
        />
      )}
    </ContentContainer>
  );
};

export default AccessControlTSX;
