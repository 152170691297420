import styled from 'styled-components';
import { DefaultRowForm, ItemForm } from '../../../../../../styles/FormGlobal';

export const GoalModalContainer = styled.div``;

export const Row = styled.div`
  ${DefaultRowForm}
`;

export const Item = styled.div`
  ${ItemForm}
`;

export default GoalModalContainer;
