import { useState } from 'react';
import { frontendNotification } from '../Notification';
import * as s from './styles';
import * as tp from './types';

export const NavMenu = ({ items }: tp.NavMenuITF) => {
  const [itemSelected, setItemSelected] = useState(0);

  const handleClick = (action: any, itemKey: any, disabled: string | undefined) => {
    if (disabled) {
      frontendNotification({
        message: disabled,
        type: 'warning',
      });
    } else {
      setItemSelected(itemKey);
      action();
    }
  };

  return (
    <s.NavMenuContainer>
      <ul>
        {items.map((item, index) => (
          <li key={item.title}>
            <s.NavMenuItem
              onClick={() => handleClick(item.action, index, item.disabled)}
              className={itemSelected === index ? 'selected' : ''}
            >
              {item.title}
            </s.NavMenuItem>
          </li>
        ))}
      </ul>
    </s.NavMenuContainer>
  );
};

export default NavMenu;
