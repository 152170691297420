import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NavMenu from '../../../components/NavMenu';
import { NavMenyItem } from '../../../components/NavMenu/types';

import { store } from '../../../store';
import ContentContainter from '../../../templates/Content';
import OutContent from '../../../templates/OutContent';
import { IntegrationModel } from '../../../types/apiResponseTypes';
import Configurations from './Pages/Configuration';
import Logs from './Pages/Logs';

const Integration = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { integrationModels } = store.getState().auth;

  const integration = integrationModels.find((item: IntegrationModel) => {
    if (
      item.name === params.integration ||
      item.name.trim().replace(' ', '-').toLowerCase() === params.integration
    ) {
      return params.integration;
    }

    return '';
  });

  const [tabSelected, setTabSelected] = useState({
    value: 'logs',
    name: 'logs',
  });

  const navMenuItems: NavMenyItem[] = [
    {
      title: t('logs'),
      action: () =>
        setTabSelected({
          value: 'logs',
          name: 'logs',
        }),
    },
    {
      title: t('configuration'),
      action: () =>
        setTabSelected({
          value: 'configurations',
          name: 'configurations',
        }),
    },
  ];

  const handleViewPag = (pagName: string) => {
    let pageSelected = <Configurations integrationID={integration?.id as number} />;

    switch (pagName) {
      case 'logs':
        pageSelected = <Logs integrationID={integration?.id as number} />;
        break;
      default:
        pageSelected = <Configurations integrationID={integration?.id as number} />;
        break;
    }

    return pageSelected;
  };

  return (
    <>
      <OutContent>
        <NavMenu items={navMenuItems} />
      </OutContent>

      <ContentContainter title={t(integration?.name as string)}>
        {handleViewPag(tabSelected.value)}
      </ContentContainter>
    </>
  );
};

export default Integration;
