import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavMenu from '../../../components/NavMenu';
import { NavMenyItem } from '../../../components/NavMenu/types';
import OutContent from '../../../templates/OutContent';

import OrdersHomePage from './Pages/OrdersHomePage';

const OrdersTSX = () => {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState({
    value: 'main',
    name: 'main',
  });

  const navMenuItems: NavMenyItem[] = [
    {
      title: t('main'),
      action: () =>
        setTabSelected({
          value: 'main',
          name: 'main',
        }),
    },
  ];

  const handleViewPag = (pagName: string) => {
    let pageSelected = <OrdersHomePage />;

    switch (pagName) {
      case 'main':
        pageSelected = <OrdersHomePage />;
        break;
      default:
        pageSelected = <OrdersHomePage />;
        break;
    }

    return pageSelected;
  };

  return (
    <div>
      <OutContent>
        <NavMenu items={navMenuItems} />
      </OutContent>

      {handleViewPag(tabSelected.value)}
    </div>
  );
};

export default OrdersTSX;
