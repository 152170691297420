import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../components/Notification';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { formAPI } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { Form } from '../../../types';
import Loader from './Loader';

import AsyncTable, { Column } from '../../../components/AsyncTable';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../components/NoData';
import { DeleteIcon, EditIcon } from '../../../icons';
import getGenericRequestPageForAsyncTable from '../../../utils/TablesFunctions';
import { formOpeningLocations } from '../../../utils/fixedData';
import { isDateTodayOrPast } from '../../../utils/helpers';
import ModalCreate from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';

const FormsJSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [waitingReq, setWaitingReq] = useState(false);
  const [compData, setCompData] = useState<Form[]>([]);
  const [selectedItem, setSelectedItem] = useState<Form | null>(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [updateTable] = useState(0);

  const reqGetCompData = async () => {
    setWaitingReq(true);

    const source = formAPI.axios.CancelToken.source();

    try {
      const res = await formAPI.index(source.token);

      if (res.status === HttpStatus.OK) {
        const { data }: { data: Form[] } = res.data;
        if (data && data.length >= 1) {
          setCompData(data);
        } else {
          setCompData([]);
        }
      } else {
        setCompData([]);
        throw res;
      }
    } catch (err) {
      if (!formAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitingReq(false);
    return () => {
      source.cancel('Component Integrations Logs got unmounted');
    };
  };

  useEffect(() => {
    reqGetCompData();
  }, []);

  const handleTableEditBtn = (row: Form) => {
    setSelectedItem(row);
    setShowModalEdit(true);
  };

  const handleTableDeleteBtn = (row: Form) => {
    setSelectedItem(row);
    setShowModalDelete(true);
  };

  const actionsButtons = (row: Form) => (
    <ActionButtonsContainer type={4}>
      <EditIcon title={t('edit')} onClick={() => handleTableEditBtn(row)} />
      {!isDateTodayOrPast(row.initialDate) && (
        <DeleteIcon title={t('delete')} onClick={() => handleTableDeleteBtn(row)} />
      )}
    </ActionButtonsContainer>
  );

  const handleAudienceColumn = (row: Form) => <p>{row.audience?.description}</p>;

  const handleRoutineColumn = (row: Form) => <p>{formOpeningLocations()[row.openingLocal]}</p>;

  const columns: Column<Form>[] = [
    {
      label: t('description') as string,
      accessor: 'description',
    },
    {
      label: t('initialDate') as string,
      accessor: 'initialDate',
    },
    {
      label: t('finalDate') as string,
      accessor: 'finalDate',
    },
    {
      label: t('routine') as string,
      Cell: handleRoutineColumn,
    },
    {
      label: t('audience') as string,
      Cell: handleAudienceColumn,
    },
    {
      Cell: actionsButtons,
    },
  ];

  return (
    <ContentContainer title={t('forms')}>
      <NewRegisterBtn
        onClick={() => setShowModalCreate(true)}
        disabled={waitingReq}
        style={{ marginLeft: '0', marginBottom: '1rem' }}
      />

      {showModalCreate && (
        <ModalCreate
          closeModal={() => setShowModalCreate(false)}
          reqCallback={() => reqGetCompData()}
        />
      )}

      {showModalDelete && (
        <ModalDelete
          selectedItem={selectedItem as Form}
          closeModal={() => setShowModalDelete(false)}
          reqCallback={() => reqGetCompData()}
        />
      )}

      {showModalEdit && (
        <ModalEdit
          selectedItem={selectedItem as Form}
          closeModal={() => setShowModalEdit(false)}
          reqCallback={() => reqGetCompData()}
        />
      )}

      <Loader isLoading={waitingReq}>
        {compData.length >= 1 ? (
          <AsyncTable
            tableName={t('forms')}
            columns={columns}
            value={compData}
            onChange={setCompData}
            requestPage={getGenericRequestPageForAsyncTable(formAPI)}
            reqListener={[updateTable]}
            options={{
              styles: {
                primaryColor: `${theme.colors.surface}`,
                secondaryColor: `${theme.colors.onSurface}`,
                alternateRowColor: theme.colors.textLight,
                textColor: theme.colors.text,
              },
              quantityPerPageLabel: t('quantityPerPage'),
              searchPlaceholder: t('search'),
              totalPageLabel: `${t('totalPages')}: `,
            }}
          />
        ) : (
          <NoData />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default FormsJSX;
