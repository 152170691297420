import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { goalsReq } from '../../../../../../services/requests';

import Input from '../../../../../../components/Input';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { Goal } from '../../../../../../types/apiResponse/goal';
import * as s from './styles';

type Props = ModalProps & {
  reqGetAllGoals: () => void;
  closeModal: () => void;
  goalSelected: Goal;
};

const EditGoalModal = ({ reqGetAllGoals, closeModal, goalSelected, ...props }: Props) => {
  const { t } = useTranslation();

  const [amount, setAmount] = useState('');
  const [weight, setWeight] = useState('');
  const [packages, setPackages] = useState('');
  const [positivation, setPositivation] = useState('');
  const [coverage, setCoverage] = useState('');

  const [awaitReqEditGoals, setAwaitReqEditGoals] = useState(false);

  const reqEditGoal = async () => {
    setAwaitReqEditGoals(true);
    try {
      const data = {
        amount: parseFloat(amount),
        weight: parseFloat(weight),
        packages: parseFloat(packages),
        positivation: parseFloat(positivation),
        coverage: parseFloat(coverage),
      };

      goalsReq.query = '';
      const response = await goalsReq.update(data, goalSelected.id as number);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        reqGetAllGoals();

        setAmount('');
        setWeight('');
        setPackages('');
        setPositivation('');
        setCoverage('');

        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!goalsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqEditGoals(false);
  };

  useEffect(() => {
    setAmount(goalSelected.amount.toString() ?? '0');
    setWeight(goalSelected.weight.toString() ?? '0');
    setPackages(goalSelected.packages.toString() ?? '0');
    setPositivation(goalSelected.positivation.toString() ?? '0');
    setCoverage(goalSelected.coverage.toString() ?? '0');
  }, [goalSelected]);

  return (
    <Modal
      {...props}
      action={() => reqEditGoal()}
      isLoading={awaitReqEditGoals}
      closeModal={closeModal}
      bodyStyle={{ overflowX: 'scroll' }}
    >
      <s.GoalModalContainer>
        <s.Row>
          <s.Item>
            <p>
              {goalSelected.date?.substring(0, 7)}:{goalSelected.seller?.Nome}{' '}
              {goalSelected.customer?.RazaoSocial}
            </p>
          </s.Item>
          <s.Item>
            <p>{t('enterAmount')}</p>
            <Input
              value={amount}
              onChange={(v: any) => setAmount(v.currentTarget.value)}
              type="number"
              disabled={awaitReqEditGoals}
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={weight}
              onChange={(v: any) => setWeight(v.currentTarget.value)}
              type="number"
              label={t('enterWeight')}
              disabled={awaitReqEditGoals}
            />
          </s.Item>
          <s.Item>
            <Input
              value={packages}
              onChange={(v: any) => setPackages(v.currentTarget.value)}
              type="number"
              label={t('enterNumberOfPackages')}
              disabled={awaitReqEditGoals}
            />
          </s.Item>
        </s.Row>
        <s.Row>
          <s.Item>
            <Input
              value={positivation}
              onChange={(v: any) => setPositivation(v.currentTarget.value)}
              type="number"
              label={t('enterPositivation')}
              disabled={awaitReqEditGoals}
            />
          </s.Item>
          <s.Item>
            <Input
              value={coverage}
              onChange={(v: any) => setCoverage(v.currentTarget.value)}
              type="number"
              label={t('enterCoverage')}
              disabled={awaitReqEditGoals}
            />
          </s.Item>
        </s.Row>
      </s.GoalModalContainer>
    </Modal>
  );
};

export default EditGoalModal;
