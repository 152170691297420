import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../../../components/AsyncTable';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import ViewLog from '../../../../../components/ViewLog';
import HttpStatus from '../../../../../enums/httpStatus';
import { CopyIcon, PreviewIcon, ResendEmailIcon } from '../../../../../icons';
import { emailLogsApi } from '../../../../../services/requests';
import { EmailLog } from '../../../../../types/apiResponse/email';
import { Order } from '../../../../../types/apiResponse/order';
import TenantResendingEmailModal from '../../../Email/Components/TenantResendingEmailModal';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  order: Order;
};

const OrderEmailLogModal = ({ showModal, closeModal, order }: Props) => {
  const theme = useTheme();

  const [logs, setLogs] = useState<EmailLog[]>([]);

  const [viewLog, setViewLog] = useState(false);
  const [logSelected, setLogSelected] = useState<EmailLog | null>(null);
  const [isResendLogVisible, setIsResendLogVisible] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);

  const handleTableViewLogBtn = (row: EmailLog) => {
    setLogSelected(row);
    setViewLog(true);
  };

  const handleTableCopyBtn = (row: EmailLog) => {
    navigator.clipboard.writeText(JSON.stringify(row));
    frontendNotification({
      message: 'Copiado com sucesso',
      type: 'success',
    });
  };

  const handleResendEmailIconClick = (emailLog: EmailLog) => {
    setLogSelected(emailLog);
    setIsResendLogVisible(true);
  };

  const actionsButtons = (row: EmailLog) => (
    <ActionButtonsContainer type={4}>
      <PreviewIcon title={t('view')} onClick={() => handleTableViewLogBtn(row)} />
      <CopyIcon title={t('copy')} onClick={() => handleTableCopyBtn(row)} />
      <ResendEmailIcon title={t('resendEmail')} onClick={() => handleResendEmailIconClick(row)} />
    </ActionButtonsContainer>
  );

  const handleRecipientsColumn = (row: EmailLog) => {
    if (!row.emailDestinatarios) {
      return '';
    }

    const maxLenght = 50;

    const truncatedEmail =
      row.emailDestinatarios.length > maxLenght
        ? row.emailDestinatarios.substring(0, maxLenght) + '...'
        : row.emailDestinatarios;

    return <div>{truncatedEmail}</div>;
  };

  const columns: Column<EmailLog>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('subject') as string,
      accessor: 'subject',
    },
    {
      label: t('error') as string,
      accessor: 'motivoErro',
    },
    {
      label: t('recipients') as string,
      Cell: (row) => handleRecipientsColumn(row),
    },
    {
      label: t('createdAt') as string,
      accessor: 'created_at',
      headerCellStyle: {
        width: '10rem',
      },
    },
    {
      label: t('actions') as string,
      headerCellStyle: {
        textAlign: 'center',
      },
      Cell: actionsButtons,
    },
  ];

  const getLogs: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = emailLogsApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      emailLogsApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      emailLogsApi.query += `&orderNumber=${order.NumeroPedido}`;
      const res = await emailLogsApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data }: { data: EmailLog[] } = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: 0,
      };
    } catch (err) {
      if (!emailLogsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleCloseModals = () => {
    setViewLog(false);
    setIsResendLogVisible(false);
    setLogSelected(null);
  };

  const handleSuccessActionModal = () => {
    handleCloseModals();
    setUpdateTable(updateTable + 1);
  };

  return (
    <Modal
      title={`${t('emailLog')} - ${t('orderNumber')}: ${order.NumeroPedido}`}
      showModal={showModal}
      onlyCloseButton
      actionNameBtn={t('confirm')}
      cancelButton={closeModal}
      closeModal={closeModal}
      bodyStyle={{ overflow: 'scroll', width: '100%' }}
    >
      <>
        <AsyncTable
          tableName={t('emailLogs')}
          columns={columns}
          value={logs}
          onChange={setLogs}
          requestPage={getLogs}
          reqListener={[updateTable]}
          options={{
            styles: {
              primaryColor: `${theme.colors.surface}`,
              secondaryColor: `${theme.colors.onSurface}`,
              alternateRowColor: theme.colors.textLight,
              textColor: theme.colors.text,
            },
            quantityPerPageLabel: t('quantityPerPage'),
            searchPlaceholder: t('search'),
          }}
        />

        {viewLog && (
          <ViewLog
            showModal={viewLog}
            closeModal={() => setViewLog(false)}
            log={JSON.stringify(logSelected, null, 2)}
          />
        )}

        {isResendLogVisible && logSelected && (
          <TenantResendingEmailModal
            showModal={isResendLogVisible}
            closeModal={handleCloseModals}
            cancelButton={handleCloseModals}
            emailLog={logSelected}
            title={t('resendEmail')}
            callback={handleSuccessActionModal}
          />
        )}
      </>
    </Modal>
  );
};

export default OrderEmailLogModal;
