import Modal, { ModalProps } from '../../../../components/Modal';
import { AccessControl } from '../../../../types/apiResponse/accessControl';

type Props = ModalProps & {
  accessControl: AccessControl;
};

export const ViewAccessLogTSX = ({ accessControl, ...props }: Props) => {
  const itemElement = (item: string) => {
    return <div style={{ padding: '1rem' }}>{item}</div>;
  };

  return (
    <Modal {...props}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {accessControl.monthly_access_log.split(',').map((item) => itemElement(item))}
      </div>
    </Modal>
  );
};

export default ViewAccessLogTSX;
