import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { backendErrorNotification } from '../../../../../components/Notification';
import TablePaginator from '../../../../../components/TablePaginator';
import {
  ActionButtonsContainer,
  DeleteIconTable,
  EditIconTable,
} from '../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../enums/httpStatus';
import i18n from '../../../../../i18n';
import { motivesReq } from '../../../../../services/requests';
import ContentContainer from '../../../../../templates/Content';
import * as TP from '../../../../../types/apiResponseTypes';
import Loader from './Loader';

import NewRegisterBtn from '../../../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../../../components/NoData';
import CreateMotive from './Components/CreateMotive';
import DeleteMotive from './Components/DeleteMotive';
import EditeMotive from './Components/EditeMotive';

const Motives = () => {
  const { t } = useTranslation();

  const [waitingGetMotives, setWaitingGetMotive] = useState(false);
  const [motives, setMoties] = useState<TP.Motive[] | []>([]);
  const [motiveSelected, setMotiveSelected] = useState<TP.Motive | null>(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const reqGetMotives = useCallback(async () => {
    setWaitingGetMotive(true);

    const source = motivesReq.axios.CancelToken.source();

    try {
      const res = await motivesReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        const { data }: { data: TP.Motive[] | [] } = res.data;
        if (data.length >= 1) {
          setMoties(data);
        }
      } else {
        setMoties([]);
        throw res;
      }

      setWaitingGetMotive(false);
    } catch (err) {
      if (!motivesReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingGetMotive(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  }, []);

  useEffect(() => {
    reqGetMotives();
  }, []);

  const handleTableEditBtn = (row: Row<TP.Motive>) => {
    setMotiveSelected(row.original);
    setShowModalEdit(true);
  };
  const handleTableDeleteBtn = (row: Row<TP.Motive>) => {
    setMotiveSelected(row.original);
    setShowModalDelete(true);
  };

  const actionsButtons = (row: Row<TP.Motive>) => (
    <ActionButtonsContainer>
      <EditIconTable onClick={() => handleTableEditBtn(row)} />
      <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<TP.Motive>[] = useMemo(
    () => [
      {
        Header: t('description') as string,
        accessor: 'description',
      },
      {
        Header: t('actions') as string,
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('motives')}>
      <NewRegisterBtn
        onClick={() => setShowModalCreate(true)}
        disabled={waitingGetMotives}
        style={{ marginLeft: '0', marginBottom: '1rem' }}
      />

      {showModalCreate && (
        <CreateMotive
          closeModal={() => setShowModalCreate(false)}
          reqGetAllMotives={() => reqGetMotives()}
        />
      )}

      {showModalDelete && (
        <DeleteMotive
          closeModal={() => setShowModalDelete(false)}
          reqGetAllMotives={() => reqGetMotives()}
          motiveSelected={motiveSelected as TP.Motive}
        />
      )}

      {showModalEdit && (
        <EditeMotive
          closeModal={() => setShowModalEdit(false)}
          reqGetAllMotives={() => reqGetMotives()}
          motiveSelected={motiveSelected as TP.Motive}
        />
      )}

      <Loader isLoading={waitingGetMotives}>
        {motives.length >= 1 ? (
          <TablePaginator data={motives} columns={columns} globalFiltering />
        ) : (
          <NoData />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default Motives;
