import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { fixRounding } from '../../utils/helpers';
import CircularProgressBar from '../CircularProgressBar';

import InfoButton from '../InfoButton';
import * as s from './styles';

type ProgressColorCardProps = {
  type: string;
  goal: number;
  lack: number;
  result: number;
  title: string;
  infoMsg: string;
  valueFormat?: (v: any) => string | number | JSX.Element;
  onClick?: any;
  clickDisabled?: boolean;
};

const ProgressColorCard = ({
  type,
  goal,
  lack,
  result,
  title,
  infoMsg,
  onClick,
  clickDisabled,
  valueFormat = undefined,
}: ProgressColorCardProps) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const achivedGoal = result >= goal ? 1 : result / goal;

  const progress = fixRounding(achivedGoal * 100);

  const colorProgress = () => {
    let c = '#6C63FF';

    if (progress < 50) {
      c = colors.primaryMedium;
    }

    if (progress >= 50) {
      c = '#6C63FF';
    }

    if (progress === 100) {
      c = '#50A732';
    }

    return c;
  };

  const changeType = () => {
    if (!clickDisabled) {
      onClick(type);
    }
  };

  return (
    <s.CardContainer
      background={colorProgress()}
      onClick={changeType}
      clickDisabled={clickDisabled}
    >
      <s.CardHeader background={colorProgress()}>{title}</s.CardHeader>

      <s.CardBody>
        <div>
          <div className="values-container">
            <s.CardTitle>{t('goal')}: &nbsp;</s.CardTitle>
            {goal <= 0 ? (
              t('noGoal')
            ) : (
              <s.CardText>{valueFormat ? valueFormat(goal) : goal}</s.CardText>
            )}
          </div>

          <div className="values-container">
            <s.CardTitle>{t('result')}: &nbsp;</s.CardTitle>
            <s.CardText>{valueFormat ? valueFormat(result) : result}</s.CardText>
          </div>

          <div className="values-container">
            <s.CardTitle>{t('lack')}: &nbsp;</s.CardTitle>
            <s.CardText>{valueFormat ? valueFormat(lack) : lack}</s.CardText>
          </div>
        </div>
        <div className="progress-circle-area">
          <CircularProgressBar value={progress} color={colorProgress()} className="circle" />
        </div>
      </s.CardBody>

      <s.InfoButtonArea>
        <InfoButton msg={infoMsg} />
      </s.InfoButtonArea>
    </s.CardContainer>
  );
};

ProgressColorCard.defaultProps = {
  valueFormat: (v: any) => v,
  onClick: () => undefined,
  clickDisabled: false,
};

export default ProgressColorCard;
