import { useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { CSSProperties } from 'styled-components';
import * as s from './style';

type Props = {
  msg: string;
  style?: CSSProperties;
};

const InfoButton = ({ msg, style }: Props) => {
  const [showMsg, setShowMsg] = useState(false);
  return (
    <s.InfoModalContainer onClick={() => setShowMsg(!showMsg)} title={msg} style={style}>
      <AiFillInfoCircle />
      {showMsg && (
        <s.Message>
          <p>{msg}</p>
        </s.Message>
      )}
    </s.InfoModalContainer>
  );
};

InfoButton.defaultProps = {
  style: {},
};

export default InfoButton;
