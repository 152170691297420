import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Column, Row } from 'react-table';
import { useTheme } from 'styled-components';

import ModalViewJSON from '../../../../components/ModalViewJSON';
import NoData from '../../../../components/NoData';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import TablePaginator from '../../../../components/TablePaginator';
import HttpStatus from '../../../../enums/httpStatus';
import i18n from '../../../../i18n';
import { orderChangeLogs as logsReq } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { OrderChangeLogs as LogType } from '../../../../types/apiResponseTypes';
import Loader from './Loader';
import * as s from './styles';

const OrderChangeLogsJSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [waitingResponse, setWaitingResponse] = useState(false);

  const [logs, setLogs] = useState<LogType[] | []>([]);
  const [logSelected, setLogSelected] = useState<LogType | null>(null);
  const [viewLog, setViewLog] = useState(false);

  const reqGetLogs = useCallback(async () => {
    setWaitingResponse(true);

    const source = logsReq.axios.CancelToken.source();

    try {
      const res = await logsReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        setLogs(res.data.data);
      } else {
        throw res;
      }

      setWaitingResponse(false);
    } catch (err) {
      if (!logsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingResponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  }, []);

  useEffect(() => {
    reqGetLogs();
  }, []);

  const handleTableViewBtn = (row: Row<LogType>) => {
    setLogSelected(row.original);
    setViewLog(true);
  };
  const handleTableCopyBtn = (row: Row<LogType>) => {
    navigator.clipboard.writeText(JSON.stringify(row.original));
    frontendNotification({
      message: 'Copiado com sucesso',
      type: 'success',
    });
  };

  const typeJSX = (row: Row<LogType>) =>
    row.original.is_error ? (
      <RiErrorWarningFill style={{ color: theme.colors.negative, marginLeft: '-1.8rem' }} />
    ) : (
      <RiErrorWarningFill style={{ color: theme.colors.positive, marginLeft: '-1.8rem' }} />
    );

  const actionsButtons = (row: Row<LogType>) => (
    <s.ActionButtonsContainer>
      <s.RiEyeLineIcon onClick={() => handleTableViewBtn(row)} />
      <s.MdFileCopyIcon onClick={() => handleTableCopyBtn(row)} />
    </s.ActionButtonsContainer>
  );

  const columns: Column<LogType>[] = useMemo(
    () => [
      {
        Header: t('status') as string,
        accessor: 'is_error',
        Cell: ({ row }: any) => typeJSX(row),
        style: { textAlign: 'center' },
      },
      {
        Header: t('dateTime') as string,
        accessor: 'created_at',
        // Cell: ({ row }: any) => clearPHPdate(row.original.created_at),
      },
      {
        Header: t('change') as string,
        accessor: 'change',
      },
      {
        Header: t('order') as string,
        accessor: 'order_number',
      },
      {
        Header: t('action') as string,
        Cell: ({ row }: any) =>
          row.original.action ? row.original.action.label : row.original.action_id,
      },
      {
        Header: t('motive') as string,
        Cell: ({ row }: any) =>
          row.original.motive ? row.original.motive.description : row.original.motive_id,
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('automationLog')}>
      <Loader isLoading={waitingResponse}>
        {viewLog && (
          <ModalViewJSON
            showModal={viewLog}
            closeModal={() => setViewLog(false)}
            data={JSON.stringify(logSelected, null, 2)}
            title={t('log')}
          />
        )}

        {logs.length >= 1 ? (
          <TablePaginator data={logs} columns={columns} globalFiltering />
        ) : (
          <NoData />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default OrderChangeLogsJSX;
