import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import HttpStatus from '../../enums/httpStatus';
import { sandboxTestCodeApi } from '../../services/requests';
import { SelectOption } from '../../utils/getSelectOptions';
import Btn from '../Buttons/Btn';
import Modal from '../Modal';
import { backendErrorNotification, frontendNotification } from '../Notification';
import SingleSelect from '../SingleSelect';

type props = {
  initialCode: string;
  showModal: boolean;
  closeModal: () => void;
  onSave: (...args: any) => void;
  extraAttribute: any;
};

const SandboxTSX = ({ initialCode, showModal, closeModal, onSave, extraAttribute }: props) => {
  const entryTypesOptions = [
    {
      label: t('number'),
      value: 'number',
    },
    {
      label: 'JSON',
      value: 'json',
    },
    {
      label: t('text'),
      value: 'string',
    },
  ];
  // sandboxTestCodeApi
  const [typeOption, setTypeOption] = useState<SelectOption>(entryTypesOptions[0]);
  const [entryValue, setEntryValue] = useState('');
  const [code, setCode] = useState(initialCode);

  const [loading, setLoading] = useState(false);

  const testCodeRequest = async () => {
    setLoading(true);

    try {
      sandboxTestCodeApi.query = '';
      const response = await sandboxTestCodeApi.store({
        type: typeOption.value,
        entryValue,
        code,
      });

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: `${response.data.data.result}`,
          type: 'success',
        });
      } else {
        throw response;
      }
    } catch (err) {
      if (!sandboxTestCodeApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title={`${t('sandbox')} - ${t('testPhpExternalCode')}`}
      showModal={showModal}
      action={() => onSave(code, extraAttribute)}
      closeModal={closeModal}
      cancelButton={closeModal}
      style={{ width: '80%' }}
      bodyStyle={{ width: '80%', overflow: 'scroll' }}
    >
      <div className="itemFormWithLabel" style={{ width: '90%' }}>
        <label htmlFor="entryValue">{t('inputValueForTests') as string}:</label>
        <SingleSelect
          value={typeOption}
          options={entryTypesOptions}
          onChange={(option: SelectOption) => setTypeOption(option)}
          disabled={loading}
        />
        <textarea
          className="input"
          value={entryValue ?? ''}
          onChange={(e) => setEntryValue(e.target.value)}
          style={{ marginTop: '1rem' }}
          disabled={loading}
        />
      </div>

      <div className="itemFormWithLabel" style={{ width: '90%' }}>
        <label htmlFor="code">
          {`${t('code')} (${t('use')} $value, $item or $extraData)` as string}:
        </label>
        <textarea
          className="input"
          value={code ?? ''}
          onChange={(e) => setCode(e.target.value)}
          rows={10}
          disabled={loading}
        />
      </div>

      <div className="itemForm" style={{ width: '90%' }}>
        <Btn text={t('test')} disabled={loading} onClick={() => testCodeRequest()} />
      </div>
    </Modal>
  );
};

export default SandboxTSX;
