/* eslint-disable default-param-last */
import Theme from '../../../enums/theme';
import { ThemeAction, ThemeState } from './types';

const initalState: ThemeState = {
  theme: Theme.LIGHT,
};

export default function auth(state = initalState, action: ThemeAction): ThemeState {
  switch (action.type) {
    case '@theme/CHANGE_THEME':
      return {
        theme: action.payload.theme,
      };
    default:
      return state;
  }
}
