import { Serie } from '@nivo/line';
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FaDollarSign, FaPercentage } from 'react-icons/fa';
import GraphLineSalesConsolidated from './Components/GraphLineSalesConsolidated';

import CardIcon from '../../../../../../components/CardIcon';
import CircularProgressBar from '../../../../../../components/CircularProgressBar';
import InfoComponent from '../../../../../../components/InfoButton';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import useDashboardFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlsalesConsolidated } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import { currencyBR, formatNumber } from '../../../../../../utils/helpers';
import Loader from './Components/Loader';
import TopCustomerTable from './Components/TopCustomersTable';
import * as s from './styles';
import * as tp from './types';

const Page1 = () => {
  const { t } = useTranslation();

  const initialData = {
    graph_data: [],
    extra: {
      average_discount: null,
      average_mix: null,
      average_term: null,
      conversion_rate: null,
      gross_amount: null,
      average_ticket: null,
      net_amount: null,
      total_weight: null,
      new_customers: null,
      top_customers: null,
    },
  };

  const dataIfReqIsEmpty = {
    graph_data: [
      {
        id: '',
        data: [
          {
            x: '',
            y: 0,
          },
        ],
      },
    ],
    extra: {
      average_discount: null,
      average_mix: null,
      average_term: null,
      conversion_rate: null,
      gross_amount: null,
      average_ticket: null,
      net_amount: null,
      total_weight: null,
      new_customers: null,
      top_customers: null,
    },
  };

  const [page1Data, setPage1Data] = useState<tp.Page1Data>(initialData);

  const {
    filters: { filters },
  } = useDashboardFilters();

  const handlePag1DataRequest = useCallback(() => {
    const source = dashboardEtlsalesConsolidated.axios.CancelToken.source();
    const query = new Query();

    if (filters.years && filters.years.length >= 1) {
      query.whereIn('years', filters.years);
    }
    if (filters.months && filters.months.length >= 1) {
      query.whereIn('months', filters.months);
    }
    if (filters.sellers && filters.sellers.length >= 1) {
      query.whereIn('sellers', filters.sellers);
    }

    const urlQueryParams = query.get() ?? '';
    dashboardEtlsalesConsolidated.query = urlQueryParams;

    dashboardEtlsalesConsolidated
      .index(source.token)
      .then((res) => {
        if (res.status === HttpStatus.OK) {
          if (res.data.data) {
            const responseData = res.data.data;
            setPage1Data(responseData);
          } else {
            setPage1Data(dataIfReqIsEmpty);

            frontendNotification({
              message: t('noData'),
              type: NotificationTypes.WARNING,
            });
          }
        } else {
          throw res;
        }
      })
      .catch((err) => {
        if (!dashboardEtlsalesConsolidated.axios.isCancel(err)) {
          backendErrorNotification(err);
        }
      });

    return () => source.cancel('Component Page1 Dashboard got unmounted');
  }, [filters]);

  useEffect(() => {
    setPage1Data(initialData);
    return handlePag1DataRequest();
  }, [filters]);

  const conversionRate =
    page1Data.extra && page1Data.extra.conversion_rate ? page1Data.extra.conversion_rate : 0;

  const grossAmount =
    page1Data.extra && page1Data.extra.gross_amount
      ? currencyBR(page1Data.extra.gross_amount)
      : '(N/A)';

  const netAmount =
    page1Data.extra && page1Data.extra.net_amount
      ? currencyBR(page1Data.extra.net_amount)
      : '(N/A)';

  const averageDiscount =
    page1Data.extra && page1Data.extra.average_discount
      ? formatNumber.percent(page1Data.extra.average_discount)
      : '(N/A)';

  const averageTicket =
    page1Data.extra && page1Data.extra.average_ticket
      ? formatNumber.currencyBr(page1Data.extra.average_ticket)
      : '(N/A)';

  const averageMix =
    page1Data.extra && page1Data.extra.average_mix
      ? formatNumber.number(page1Data.extra.average_mix)
      : '(N/A)';

  const averageTerm =
    page1Data.extra && page1Data.extra.average_term
      ? `${formatNumber.number(page1Data.extra.average_mix as number)}
                ${(page1Data.extra.average_mix as number) > 1 ? t('days') : t('day')}`
      : '(N/A)';

  const newCustomers =
    page1Data.extra && page1Data.extra.new_customers ? page1Data.extra.new_customers.toString() : 0;

  const totalWeight =
    page1Data.extra && page1Data.extra.total_weight
      ? formatNumber.weightBrKg(page1Data.extra.total_weight)
      : '(N/A)';

  const graphDataExists =
    page1Data.graph_data.length >= 1 && page1Data.graph_data[0].data.length >= 1;

  return (
    <Loader isLoading={!graphDataExists}>
      <s.Pag1Container>
        <s.GraphsContainer>
          <GraphLineSalesConsolidated data={page1Data.graph_data as Serie[]} />
        </s.GraphsContainer>

        <s.ConversionRate>
          <div className="conversion-rate-title">{t('conversionRate')}</div>
          <div className="circular-graph">
            <CircularProgressBar value={conversionRate} />
          </div>
        </s.ConversionRate>

        <s.InfoCards>
          <CardIcon
            color="#6c63ff"
            icon={<FaDollarSign />}
            title={t('grossAmount')}
            text={grossAmount}
          />

          <CardIcon
            color="#1D98DF"
            icon={<FaDollarSign />}
            title={t('netAmount')}
            text={netAmount}
          />

          <CardIcon
            color="#DE7223"
            icon={<FaPercentage />}
            title={t('averageDiscount')}
            text={averageDiscount}
          />

          <CardIcon
            color="#CD1719"
            icon={<FaPercentage />}
            title={t('averageTicket')}
            text={averageTicket}
          />
        </s.InfoCards>

        <s.ExtraInfos>
          <div className="extrainfos-top">
            <div className="extrainfo-item">
              <h4 style={{ display: 'flex' }}>
                {t('averageMix')}&nbsp;
                <InfoComponent msg={t('info.averageMix')} />
              </h4>
              <p>{averageMix}</p>
            </div>
            <div className="extrainfo-item">
              <h4>{t('averageTerm')}</h4>
              <p>{averageTerm}</p>
            </div>
          </div>

          <hr />

          <div className="extrainfos-bottom">
            <div className="extrainfo-item">
              <h4>{t('newCustomers')}</h4>
              <p>{newCustomers}</p>
            </div>
            <div className="extrainfo-item">
              <h4>{t('totalWeight')}</h4>
              <p>{totalWeight}</p>
            </div>
          </div>
        </s.ExtraInfos>

        <s.TopCustomers>
          {page1Data.extra.top_customers && (
            <TopCustomerTable topCustomers={page1Data.extra.top_customers as tp.TopCustomer[]} />
          )}
        </s.TopCustomers>
      </s.Pag1Container>
    </Loader>
  );
};

export default Page1;
