import AnimatedLoadingIcon from './AnimatedLoadingIcon';
import * as s from './styles';

type Props = { loading?: boolean };

const LoadingOverlay = ({ loading = false }: Props) =>
  loading ? (
    <s.LoaderPageContainer>
      <div className="icon-area">
        <AnimatedLoadingIcon />
      </div>
    </s.LoaderPageContainer>
  ) : null;

LoadingOverlay.defaultProps = {
  loading: false,
};

export default LoadingOverlay;
