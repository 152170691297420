import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../components/Notification';
import HttpStatus from '../../../enums/httpStatus';
import { ediPaymentConditionsPaginatedApi } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { ApiResPaginated } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import { EdiPaymentCondition } from '../../../types/apiResponse/edi';

import Btn from '../../../components/Buttons/Btn';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import TableLoader from '../../../components/Loaders/TableLoader';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import { EditIcon } from '../../../icons';
import DeleteTablesItemsSelectedTSX from './Components/DeleteItems';
import EditEdiPaymentConditionsTSX from './Components/Edit';
import UpdateOrInsertEdiPaymentConditionsTSX from './Components/UpdateOrInsert';

const EdiPaymentConditionsTSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);
  const [tableData, setTableData] = useState<EdiPaymentCondition[]>([]);

  const [selectedTableItems, setSelectedTableItems] = useState<EdiPaymentCondition[]>([]);
  const [selectedTableItemToBeEdited, setSelectedTableItemToBeEdited] =
    useState<EdiPaymentCondition | null>(null);

  const [showUpdateOrInsertModal, setShowUpdateOrInsertModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteItemsModal, setShowDeleteItemsModal] = useState(false);

  const handleCustomerColumn = (row: EdiPaymentCondition) => {
    if (row.customer) {
      return `${row.customer.code} - ${row.customer.name}`;
    }
    return `${row.customer_code} - [${t('notFound')}]`;
  };

  const handlePaymentConditionColumn = (row: EdiPaymentCondition) => {
    if (row.payment_condition) {
      return `${row.payment_condition.code} - ${row.payment_condition.description}`;
    }
    return `${row.payment_condition_code} - [${t('notFound')}]`;
  };

  const handlePaymentMethodColumn = (row: EdiPaymentCondition) => {
    if (row.payment_method) {
      return `${row.payment_method.code} - ${row.payment_method.description}`;
    }
    return `${row.payment_method_code} - [${t('notFound')}]`;
  };

  const handleTableItemEditButton = (row: EdiPaymentCondition) => {
    setSelectedTableItemToBeEdited(row);
    setShowEditModal(true);
  };

  const tableItemActionButtons = (row: EdiPaymentCondition) => (
    <ActionButtonsContainer type={1}>
      <EditIcon title={t('edit')} onClick={() => handleTableItemEditButton(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<EdiPaymentCondition>[] = [
    {
      label: t('customer') as string,
      Cell: handleCustomerColumn,
    },
    {
      label: t('paymentCondition') as string,
      Cell: handlePaymentConditionColumn,
    },
    {
      label: t('paymentMethod') as string,
      Cell: handlePaymentMethodColumn,
    },
    {
      label: t('days') as string,
      accessor: 'number_of_days',
    },
    {
      label: t('createdAt') as string,
      accessor: 'created_at',
    },
    {
      Cell: tableItemActionButtons,
    },
  ];

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = ediPaymentConditionsPaginatedApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      ediPaymentConditionsPaginatedApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await ediPaymentConditionsPaginatedApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<EdiPaymentCondition[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!ediPaymentConditionsPaginatedApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const onCloseModals = () => {
    setShowDeleteItemsModal(false);
    setShowUpdateOrInsertModal(false);
    setSelectedTableItems([]);
    setSelectedTableItemToBeEdited(null);
    setShowEditModal(false);
  };

  const handleModalCallbacks = () => {
    setUpdateTable(updateTable + 1);
    onCloseModals();
  };

  return (
    <ContentContainer title={t('ediPaymentConditions')}>
      <TableLoader isLoading={isLoading}>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <NewRegisterBtn onClick={() => setShowUpdateOrInsertModal(true)} disabled={isLoading} />

          {selectedTableItems && selectedTableItems.length > 0 && (
            <Btn
              onClick={() => setShowDeleteItemsModal(true)}
              disabled={isLoading}
              text={t('deleteItems')}
            />
          )}
        </div>

        {showDeleteItemsModal && selectedTableItems && (
          <DeleteTablesItemsSelectedTSX
            onClose={onCloseModals}
            callback={handleModalCallbacks}
            selectedItems={selectedTableItems}
          />
        )}

        {showUpdateOrInsertModal && (
          <UpdateOrInsertEdiPaymentConditionsTSX
            onClose={onCloseModals}
            callback={handleModalCallbacks}
            initialData={selectedTableItems}
          />
        )}

        {showEditModal && selectedTableItemToBeEdited && (
          <EditEdiPaymentConditionsTSX
            onClose={onCloseModals}
            callback={handleModalCallbacks}
            initialData={selectedTableItemToBeEdited}
          />
        )}

        <AsyncTable
          tableName={t('ediPaymentConditions')}
          columns={columns}
          value={tableData}
          onChange={setTableData}
          selectedItems={selectedTableItems}
          onChangeSelectedItems={setSelectedTableItems}
          requestPage={getItems}
          reqListener={[updateTable]}
          options={{
            styles: {
              primaryColor: `${theme.colors.surface}`,
              secondaryColor: `${theme.colors.onSurface}`,
              alternateRowColor: theme.colors.textLight,
              textColor: theme.colors.text,
            },
            quantityPerPageLabel: t('quantityPerPage'),
            searchPlaceholder: t('search'),
            totalPageLabel: `${t('totalPages')}: `,
          }}
        />
      </TableLoader>
    </ContentContainer>
  );
};

export default EdiPaymentConditionsTSX;
