import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import history from '../../../services/history';
import { superSmartsellAppVersionsApi } from '../../../services/requests';
import { StoreState } from '../../../store/createStore';
import ContentContainer from '../../../templates/Content';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import TableLoader from '../../../components/Loaders/TableLoader';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths } from '../../../enums/urlPaths.enum';
import { DeleteIcon, DownloadIcon } from '../../../icons';
import { SmartsellAppVersion } from '../../../types/apiResponse/SmartsellAppVersions';
import AddAppVersionModal from './components/AddAppVersionModal';
import ModalDelete from './components/ModalDelete';

const SmartsellAppVersionTSX = () => {
  const { user } = useSelector((state: StoreState) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();
  const cancelTokenSource = superSmartsellAppVersionsApi.axios.CancelToken.source();

  const [updateTable, setUpdateTable] = useState(0);
  const [tableData, setTableData] = useState<SmartsellAppVersion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showAddAppVersionModal, setShowAddAppVersionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemSelected, setItemSelected] = useState<SmartsellAppVersion | undefined>(undefined);

  const getDataForAsyncTable: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = superSmartsellAppVersionsApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      superSmartsellAppVersionsApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await superSmartsellAppVersionsApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data }: { data: SmartsellAppVersion[] } = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: 0,
      };
    } catch (err) {
      if (!superSmartsellAppVersionsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const reqDownloadAppVersion: any = async (appVersion: SmartsellAppVersion) => {
    setIsLoading(true);

    try {
      superSmartsellAppVersionsApi.query = '';
      const response = await superSmartsellAppVersionsApi.show(
        appVersion.id,
        cancelTokenSource.token,
      );

      if (response.status === HttpStatus.OK) {
        // Cria um Blob com os dados do arquivo
        const blob = new Blob([response.data], { type: 'application/octet-stream' });

        // Cria uma URL para o Blob
        const downloadUrl = window.URL.createObjectURL(blob);

        // Cria um link temporário e faz o download do arquivo
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${appVersion.storage_file_name}.${appVersion.file_type}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Libera a URL do Blob após o download
        window.URL.revokeObjectURL(downloadUrl);

        frontendNotification({
          message: t('success'),
          type: 'success',
        });
      } else {
        throw response;
      }
    } catch (err) {
      if (!superSmartsellAppVersionsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      frontendNotification({
        message: 'Área restrita!',
        type: NotificationTypes.WARNING,
      });

      history.push(UrlPaths.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      cancelTokenSource.cancel('Requisição cancelada ao desmontar o componente');
    };
  }, []);

  const actionsButtons = (row: SmartsellAppVersion) => (
    <ActionButtonsContainer type={4}>
      <DownloadIcon title={t('download')} onClick={() => reqDownloadAppVersion(row)} />
      <DeleteIcon
        title={t('delete')}
        onClick={() => {
          setItemSelected(row);
          setShowDeleteModal(true);
        }}
      />
    </ActionButtonsContainer>
  );

  const columns: Column<SmartsellAppVersion>[] = [
    {
      label: t('version') as string,
      accessor: 'version',
    },
    {
      label: t('fileType') as string,
      accessor: 'file_type',
    },
    {
      label: t('platform') as string,
      accessor: 'platform',
    },
    {
      label: t('description') as string,
      accessor: 'description',
    },
    {
      Cell: actionsButtons,
    },
  ];

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setItemSelected(undefined);
  };

  return (
    <ContentContainer title={t('appVersions')}>
      <NewRegisterBtn
        onClick={() => setShowAddAppVersionModal(true)}
        disabled={isLoading}
        style={{ marginLeft: '0', marginBottom: '1rem' }}
      />

      {showAddAppVersionModal && (
        <AddAppVersionModal
          handleClose={() => setShowAddAppVersionModal(false)}
          showModal={showAddAppVersionModal}
          callback={() => setUpdateTable(updateTable + 1)}
        />
      )}

      {showDeleteModal && itemSelected && (
        <ModalDelete
          showModal={showDeleteModal}
          selectedItem={itemSelected}
          closeModal={() => handleCloseModal()}
          callback={() => setUpdateTable(updateTable + 1)}
        />
      )}

      <TableLoader isLoading={isLoading}>
        <AsyncTable
          tableName={t('appVersions')}
          columns={columns}
          value={tableData}
          onChange={setTableData}
          requestPage={getDataForAsyncTable}
          reqListener={[updateTable]}
          options={{
            styles: {
              primaryColor: `${theme.colors.surface}`,
              secondaryColor: `${theme.colors.onSurface}`,
              alternateRowColor: theme.colors.textLight,
              textColor: theme.colors.text,
            },
            quantityPerPageLabel: t('quantityPerPage'),
            searchPlaceholder: t('search'),
          }}
        />
      </TableLoader>
    </ContentContainer>
  );
};

export default SmartsellAppVersionTSX;
