import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditAction = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.colors.primaryLight};
`;

export const BtnArea = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
