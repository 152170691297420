import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../../../components/AsyncTable';
import Modal, { ModalProps } from '../../../../../components/Modal';
import { backendErrorNotification } from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { superTenantAccessLogApi } from '../../../../../services/requests';
import { AccessLog } from '../../../../../types/apiResponse/accessLog';

type Props = ModalProps & {
  tenantId: number;
};

export const ViewAccessLogModal = ({ tenantId, ...props }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [logs, setLogs] = useState<AccessLog[]>([]);
  const [updateTable] = useState(0);

  const columns: Column<AccessLog>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('type') as string,
      accessor: 'type',
    },
    {
      label: t('location') as string,
      accessor: 'location',
    },
    {
      label: t('userId') as string,
      accessor: 'user_id',
    },
    {
      label: t('createdAt') as string,
      accessor: 'created_at',
    },
  ];

  const getLogs: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = superTenantAccessLogApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      superTenantAccessLogApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await superTenantAccessLogApi.show(tenantId, source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data }: { data: AccessLog[] } = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: 0,
      };
    } catch (err) {
      if (!superTenantAccessLogApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  return (
    <Modal {...props}>
      <AsyncTable
        tableName={t('accessLog')}
        columns={columns}
        value={logs}
        onChange={setLogs}
        requestPage={getLogs}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
        }}
      />
    </Modal>
  );
};

export default ViewAccessLogModal;
