import { t } from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Navigate, RouteProps } from 'react-router-dom';
import ContentManager from '../../components/Content';
import { StoreState } from '../../store/createStore';

import { frontendNotification } from '../../components/Notification';
import { UrlPaths, UrlPathsSuper } from '../../enums/urlPaths.enum';
import { superadminLogoutRequest } from '../../store/modules/auth/actions';

interface Props {
  withNavBar?: boolean;
  component: React.ComponentType;
}

type PrivateRouteProps = Props & RouteProps;

const SuperPrivateRoute: React.FC<PrivateRouteProps> = ({
  withNavBar = true,
  component: Component,
}) => {
  const { isSignin, user, expirationDate } = useSelector((state: StoreState) => state.auth);

  const dispacth = useDispatch();

  if ((expirationDate as number) < Date.now()) {
    frontendNotification({
      message: t('sessionExpired'),
      type: 'warning',
    });
    dispacth(superadminLogoutRequest());
  }

  if (isSignin && !user?.isSuperAdmin) {
    return <Navigate to={UrlPaths.HOME} />;
  }

  if (isSignin && user?.isSuperAdmin) {
    return (
      <ContentManager permissionProfile="superadmin" Component={Component} navbar={withNavBar} />
    );
  }

  return <Navigate to={UrlPathsSuper.LOGIN} />;
};

SuperPrivateRoute.defaultProps = {
  withNavBar: true,
};

export default SuperPrivateRoute;
