import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import AnimatedLoadingIcon from '../../../../../components/LoadingOverlay/AnimatedLoadingIcon';
import Modal from '../../../../../components/Modal';
import { backendErrorNotification } from '../../../../../components/Notification';
import TablePaginator from '../../../../../components/TablePaginator';
import HttpStatus from '../../../../../enums/httpStatus';
import i18n from '../../../../../i18n';
import { orderFractionalItemsApi } from '../../../../../services/requests';
import { Order, OrderFractionalItem } from '../../../../../types/apiResponse/order';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  order: Order;
};

const OrderFractionalItemsModal = ({ showModal, closeModal, order }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fractionalItems, setFractionalItems] = useState<OrderFractionalItem[]>([]);

  const getOrderFractionalItems = async () => {
    try {
      setIsLoading(true);
      orderFractionalItemsApi.query = `?quantityPerPage=50&orderNumber=${order.NumeroPedido}`;

      const response = await orderFractionalItemsApi.index();

      if (response.status === HttpStatus.OK) {
        setFractionalItems(response.data.data);
      } else {
        throw response;
      }
    } catch (erro) {
      backendErrorNotification(erro as any);
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column<OrderFractionalItem>[] = useMemo(
    () => [
      {
        Header: t('deliveryDate') as string,
        accessor: 'itens_frac_dataEntrega',
      },
      {
        Header: t('quantity') as string,
        accessor: 'itens_frac_qtdItem',
      },
      {
        Header: t('productCode') as string,
        accessor: 'codigoProduto',
      },
      {
        Header: t('productDescription') as string,
        accessor: 'product',
        Cell: ({ cell: { row } }) => <div>{row.original.product.Descricao}</div>,
      },
    ],
    [i18n.language],
  );

  useEffect(() => {
    getOrderFractionalItems();
  }, []);

  return (
    <Modal
      title={`${t('orderFractionations')}: ${order.NumeroPedido}`}
      showModal={showModal}
      onlyCloseButton
      closeModal={closeModal}
      bodyStyle={{ overflow: 'scroll', width: '100%' }}
      isLoading={isLoading}
    >
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AnimatedLoadingIcon />
        </div>
      ) : (
        <></>
      )}

      {fractionalItems.length > 0 ? (
        <>
          <TablePaginator
            showLineNumbers
            data={fractionalItems}
            columns={columns}
            showMoreRows={false}
          />
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default OrderFractionalItemsModal;
