export default [
  '#BF0103', // Vermelho opaco
  '#aeaeae', // Cinza claro
  '#F47560', // Salmão
  '#ddb18d', // Bege
  '#8561cd', // Roxo claro
  '#57e045', // Verde claro
  '#F1E15B', // Amarelo claro
  '#FF7096', // Rosa claro
  '#e8a0a0', // Rosa antigo
  '#A0CED9', // Azul claro
  '#924b4b', // Marrom claro
  '#457B9D', // Azul acinzentado
  '#f44b76', // Rosa escuro
  '#449b4e', // Verde musgo
  '#9b449a', // Roxo escuro
  '#00d6ba', // Verde água
  '#1f609e', // Azul petróleo
  '#a87b2f', // Marrom claro
  '#2ca9f7', // Azul celeste
  '#d14db2', // Rosa choque
  '#E8A838', // Amarelo queimado
  '#B0A8B9', // Cinza lavanda
  '#C4A484', // Marrom areia
  '#8B8D7A', // Verde oliva
  '#A3A3A3', // Cinza médio
  '#D4A5A5', // Rosa pálido
  '#B5C7D3', // Azul gelo
  '#A9A9A9', // Cinza escuro
  '#C3B091', // Bege escuro
  '#8FBC8F', // Verde pálido
  '#B0C4DE', // Azul claro
  '#D2B48C', // Bege claro
  '#778899', // Cinza ardósia
  '#708090', // Cinza escuro
  '#4682B4', // Azul aço
  '#6B8E23', // Verde oliva escuro
  '#9ACD32', // Verde amarelado
  '#BC8F8F', // Rosa antigo
  '#D3D3D3', // Cinza claro
  '#F5DEB3', // Trigo
  '#DEB887', // Marrom claro
  '#D2B48C', // Bege claro
  '#BC8F8F', // Rosa antigo
  '#F4A460', // Marrom areia
  '#DAA520', // Dourado
  '#CD853F', // Marrom peru
  '#B8860B', // Marrom escuro
  '#D2691E', // Marrom chocolate
  '#8B4513', // Marrom sela
  '#A0522D', // Marrom terra
  '#A52A2A', // Marrom avermelhado
  '#800000', // Marrom vinho
  '#8B0000', // Vermelho escuro
  '#B22222', // Vermelho fogo
  '#FF6347', // Tomate
  '#FF4500', // Laranja avermelhado
  '#FF8C00', // Laranja escuro
  '#FFA500', // Laranja
  '#FFD700', // Ouro
  '#FFFF00', // Amarelo
  '#FFFFE0', // Amarelo claro
  '#FFFACD', // Amarelo limão
  '#FAFAD2', // Amarelo claro
  '#FFEFD5', // Papaya
  '#FFE4B5', // Marfim
  '#FFDAB9', // Pêssego
  '#EEE8AA', // Amarelo pálido
  '#F0E68C', // Caqui
  '#BDB76B', // Verde oliva
  '#ADFF2F', // Verde amarelado
  '#7FFF00', // Verde chartreuse
  '#7CFC00', // Verde grama
  '#00FF00', // Verde
  '#32CD32', // Verde lima
  '#98FB98', // Verde pálido
  '#00FA9A', // Verde médio
  '#00FF7F', // Verde primavera
  '#3CB371', // Verde mar
  '#2E8B57', // Verde mar escuro
  '#228B22', // Verde floresta
  '#008000', // Verde
  '#006400', // Verde escuro
  '#9ACD32', // Verde amarelado
  '#6B8E23', // Verde oliva escuro
  '#556B2F', // Verde oliva
  '#66CDAA', // Verde água
  '#8FBC8F', // Verde pálido
  '#20B2AA', // Verde claro
  '#008B8B', // Verde escuro
  '#008080', // Verde teal
  '#00FFFF', // Ciano
  '#00CED1', // Turquesa escuro
  '#40E0D0', // Turquesa
  '#48D1CC', // Turquesa médio
  '#AFEEEE', // Turquesa pálido
  '#7FFFD4', // Água-marinha
  '#B0E0E6', // Azul pó
  '#5F9EA0', // Azul cadete
  '#4682B4', // Azul aço
  '#B0C4DE', // Azul claro
  '#ADD8E6', // Azul claro
  '#87CEEB', // Azul céu
  '#87CEFA', // Azul céu claro
  '#00BFFF', // Azul profundo
  '#1E90FF', // Azul dodger
  '#6495ED', // Azul cornflower
  '#7B68EE', // Azul médio
  '#4169E1', // Azul royal
  '#0000FF', // Azul
  '#0000CD', // Azul médio
  '#00008B', // Azul escuro
  '#000080', // Azul marinho
  '#191970', // Azul meia-noite
];
