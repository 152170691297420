import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { commGroupReq } from '../../../../../../services/requests';
import * as TP from '../../../../../../types/apiResponseTypes';

type Props = {
  closeModal: () => void;
  reqGetCommGroups: () => void;
  commGroupSelected: TP.CommunicationGroup;
};

const DeleteCommGroupJSX = ({ closeModal, reqGetCommGroups, commGroupSelected }: Props) => {
  const { t } = useTranslation();

  const [waitingResponse, setWaitingResponse] = useState(false);

  const reqDelete = async () => {
    setWaitingResponse(true);

    const source = commGroupReq.axios.CancelToken.source();

    try {
      const res = await commGroupReq.delete(commGroupSelected.id);

      if (res.status === HttpStatus.OK) {
        setWaitingResponse(false);
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        closeModal();
        reqGetCommGroups();
      } else {
        throw res;
      }

      setWaitingResponse(false);
    } catch (err) {
      if (!commGroupReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingResponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={t('delete')}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => reqDelete()}
      actionNameBtn={t('delete')}
      isLoading={waitingResponse}
    >
      <p>
        <h3>{`${t('deleteSure')}?`}</h3>
        <br />
        <br />
        <b>{t('description')}:</b> {commGroupSelected.description}
        <br />
        <br />
        <b>{t('senderEmail')}:</b> {commGroupSelected.email_from}
      </p>
    </Modal>
  );
};

export default DeleteCommGroupJSX;
