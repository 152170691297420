import styled from 'styled-components';

export const TableItem = styled.div`
  margin-bottom: 10px;
`;

export const ComponentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.onPrimary};
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
`;

export const TableContent = styled.div`
  padding: 10px;
  border: 1px solid ${(p) => p.theme.colors.primary};
  border-radius: 0px 0px 5px 5px;
`;

export const TableForColumns = styled.table`
  width: 100%;

  > thead > tr > th,
  > tbody > tr > td {
    padding: 0.5rem;
  }

  > thead > tr > th {
    font-size: 1.5rem;
    text-align: left;
    padding-bottom: 1.5rem;
    margin-top: -1rem;
  }

  > tbody > tr:nth-child(odd) td {
    background-color: ${(p) => p.theme.colors.primary}20;
  }

  &.table-2 {
    > thead > tr > th {
      font-size: 1rem;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  span {
    margin-left: 10px;
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary};
  }
`;

export const ArrowIcon = styled.span`
  &.open {
    transform: rotate(90deg);
  }
`;

export const RequiredIndicator = styled.div`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
  background-color: yellow;
  display: inline-block;
`;
