import { FaAngleDoubleLeft } from 'react-icons/fa';
import { TiThMenu } from 'react-icons/ti';
import * as s from './styles';

const MobileBar = ({ isSidebarOpen, toggleIsSiderbarOpen }: any) => (
  <s.MobileBarContainer isSidebarOpen={isSidebarOpen}>
    {isSidebarOpen ? (
      <FaAngleDoubleLeft onClick={() => toggleIsSiderbarOpen()} />
    ) : (
      <TiThMenu onClick={() => toggleIsSiderbarOpen()} />
    )}
  </s.MobileBarContainer>
);

export default MobileBar;
