import { DefaultTheme } from 'styled-components';
import dark from './dark';
import light from './light';

export type ThemesTypes = {
  LIGHT: DefaultTheme;
  DARK: DefaultTheme;
};

export default {
  LIGHT: light,
  DARK: dark,
};
