import { ImArrowLeft2 } from 'react-icons/im';
import LoadingOverlay from '../../components/LoadingOverlay';
import Logo from '../../components/Logo';
import ChangeLanguage from '../../components/SelectLanguage';
import ThemeSelect from '../../components/SelectTheme';
import * as s from './style';

type Props = {
  title: string;
  children: JSX.Element | JSX.Element[];
  isLoading: boolean;
  btnBack?: () => void;
};

export const LogoutContent = ({ children, title, isLoading, btnBack }: Props) => (
  <s.LogoutContentContainer>
    <s.BackgroundLeft />
    <s.BackgroundRight />
    <div className="theme-and-language">
      <ThemeSelect />
      <ChangeLanguage />
    </div>
    <s.LogoContainer>
      <Logo fill="#fff" />
    </s.LogoContainer>
    <s.MainArea>
      <s.Header>
        {btnBack !== undefined && (
          <div className="btn-back">
            <ImArrowLeft2 onClick={btnBack} />
          </div>
        )}
        {title}
      </s.Header>
      <s.Line />
      <s.Content>{children}</s.Content>
      <s.Footer>{`v${process.env.REACT_APP_VERSION}`}</s.Footer>
    </s.MainArea>

    <LoadingOverlay loading={isLoading} />
  </s.LogoutContentContainer>
);

LogoutContent.defaultProps = {
  btnBack: undefined,
};

export default LogoutContent;
