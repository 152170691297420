import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { RiDeleteBin5Line, RiEyeLine } from 'react-icons/ri';

import Btn from '../../../../../components/Buttons/Btn';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { integrationModelsReq } from '../../../../../services/requests';
import { IntegrationModel } from '../../../../../types/apiResponseTypes';
import ParameterPreview from '../ParameterPreview';
import * as s from './styles';

const initialData: IntegrationModel = {
  id: 0,
  name: '',
  is_erp: false,
  react_icon: null,
  active: true,
  model: {
    '': {
      key: '',
      type: '',
      default: '',
      required: '',
      example: '',
      description_en: '',
      description_es: '',
      description_pt: '',
    },
  },
};

export const EditIntegrationModelModal = ({
  showModal,
  closeModal,
  reqGetIntegrationModels,
  inteModelSelected,
}: any) => {
  const [data, setData] = useState({ ...inteModelSelected });
  const [showParameterPreview, setShowParameterPreview] = useState(false);
  const [parameterToPreview, setParameterToPreview] = useState({});
  const [waitReqSave, setWaitReqSave] = useState(false);

  const btnParameterPreview = (parameter: any) => {
    setParameterToPreview(parameter);
    setShowParameterPreview(true);
  };

  const handleClose = () => {
    setData({ ...initialData });
    closeModal();
  };

  const handleInputName = (value: any) => {
    const dataCopy = { ...data };
    dataCopy.name = value;

    setData(dataCopy);
  };

  const btnNewParameter = () => {
    const dataCopy = { ...data };

    const tempName = Date.now().toString();

    dataCopy.model = {
      ...dataCopy.model,
      [tempName]: {
        key: '',
        type: '',
        default: '',
        required: '',
        example: '',
        description_en: '',
        description_es: '',
        description_pt: '',
      },
    };

    setData(dataCopy);
  };

  const btnDeleteParameter = (parameter: any) => {
    const dataCopy = { ...data };
    delete dataCopy.model[parameter];
    setData(dataCopy);
  };

  const handleParameterInput = (paremeter: any, parameterField: any, value: any) => {
    const dataCopy = { ...data };

    dataCopy.model[paremeter][parameterField] = value;

    setData(dataCopy);
  };

  const hasRepeatedName = () => {
    const keys = Object.keys(data.model);
    const keyValues = keys.map((key) => data.model[key].key);

    let countRepeated = 0;

    for (let i = 0; i < keys.length; i += 1) {
      for (let y = 0; y < keys.length; y += 1) {
        if (keyValues[i] && keyValues[i].toLowerCase() === keyValues[y].toLowerCase()) {
          countRepeated += 1;
        }
      }

      if (countRepeated <= 1) {
        countRepeated = 0;
      }
    }

    if (countRepeated > 0) {
      return true;
    }

    return false;
  };

  const checkForm = () => {
    if (data.name === '') {
      frontendNotification({
        message: t('nameIsRequired'),
        type: 'warning',
      });
      return false;
    }

    if (Object.keys(data.model).length <= 0) {
      frontendNotification({
        message: t('parametersNotFound'),
        type: 'warning',
      });
      return false;
    }

    let parameterWithoutName = false;
    Object.keys(data.model).forEach((element) => {
      if (data.model[element].key === '') {
        parameterWithoutName = true;
      }
    });

    if (parameterWithoutName) {
      frontendNotification({
        message: t('keylessParameter'),
        type: 'warning',
      });
      return false;
    }

    if (hasRepeatedName()) {
      frontendNotification({
        message: t('repeatedKey'),
        type: 'warning',
      });
      return false;
    }

    return true;
  };

  const handleActionSave = async () => {
    try {
      if (checkForm()) {
        setWaitReqSave(true);
        const newModel: any = {};

        Object.keys(data.model).forEach((index: string) => {
          const { key } = data.model[index];

          newModel[key] = data.model[index];
        });

        const response = await integrationModelsReq.update(
          {
            name: data.name,
            model: newModel,
          },
          inteModelSelected.id,
        );

        if (response.status === HttpStatus.OK) {
          frontendNotification({
            message: t('success'),
            type: 'success',
          });

          handleClose();
          reqGetIntegrationModels();
        } else {
          throw response;
        }
        setWaitReqSave(false);
      }
    } catch (err) {
      if (!integrationModelsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitReqSave(false);
    }
  };

  return (
    <Modal
      title={t('registerTenant')}
      showModal={showModal}
      closeModal={handleClose}
      action={() => handleActionSave()}
      isLoading={waitReqSave}
      bodyStyle={{ overflow: 'scroll' }}
    >
      <s.Row>
        <s.LabelSection>{t('integrationModelName') as string}</s.LabelSection>
      </s.Row>

      <s.Row>
        <Input
          placeholder={t('integrationModelName')}
          onChange={(v) => handleInputName(v.target.value)}
          value={data.name}
          style={{ marginTop: '5px', minWidth: '250px' }}
          required={t('nameIsRequired')}
        />
      </s.Row>

      <s.Row>
        <s.LabelSection>{t('parameters') as string}</s.LabelSection>
      </s.Row>

      <s.Row>
        {Object.keys(data.model).map((item: any) => (
          <s.Item key={item}>
            <s.ViewParameter onClick={() => btnParameterPreview(data.model[item])}>
              <RiEyeLine className="icon view" />
            </s.ViewParameter>
            <s.ViewParameter onClick={() => btnDeleteParameter(item)}>
              <RiDeleteBin5Line className="icon delete" />
            </s.ViewParameter>

            {Object.keys(data.model[item]).map((parameter) => (
              <Input
                onChange={(v) => handleParameterInput(item, parameter, v.target.value)}
                value={data.model[item][parameter]}
                label={t(parameter)}
                className="parameter-config"
                key={parameter}
              />
            ))}
          </s.Item>
        ))}
      </s.Row>

      <s.Row>
        <Btn text="+" onClick={() => btnNewParameter()} style={{ marginTop: '10px' }} />
      </s.Row>

      {showParameterPreview ? (
        <ParameterPreview
          showModal={showParameterPreview}
          closeModal={() => setShowParameterPreview(false)}
          parameter={parameterToPreview}
        />
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default EditIntegrationModelModal;
