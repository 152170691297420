import styled from 'styled-components';

export const ContainerModalCreate = styled.div``;

export const Line = styled.div`
  display: flex;
  flex-wrap: wrap;

  * > {
    flex: 1;
  }

  .add-btn {
    margin-top: auto !important;
    max-width: 30px !important;
  }
`;

export const Item = styled.div`
  margin: 0.5rem;
  flex: 1;
  min-width: 100px;
  justify-content: center;

  > p {
    margin-bottom: 0.5rem;
  }

  &#description {
    min-width: 200px;
  }

  &#isRequired {
    min-width: 80px;
    max-width: 80px;
  }

  &#frequency {
    min-width: 120px;
    max-width: 120px;
  }

  &#createNewAudience {
    margin-top: auto !important;
  }

  &#placeOfExecution,
  &#audience {
    min-width: 207px;
    max-width: 207px;
  }
`;

export const WithCheckbox = styled.p`
  position: relative;

  > input {
    position: absolute;
    top: -3px;
    right: auto;
  }
`;

export const NoQuestion = styled.h2`
  opacity: 0.7;
  text-align: center;
  margin: 2rem;
`;
