export enum UrlPaths {
  ROOT = '/',
  NOT_AUTHORIZED = '/not-authorized',
  NOT_FOUND = '/not-found',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  HOME = '/dashboard-sales',
  UPLOAD = '/upload',
  DASHBOARD_SALES = '/dashboard-sales',
  DASHBOARD_SCRIPTING = '/dashboard-scripting',
  DASHBOARD_MARKETING_RESEARCH_METRICS = '/dashboard-marketing-research-metrics',
  DASHBOARD_SCHEDULES = '/dashboard-schedules',
  DASHBOARD_SCHEDULED_VISITS = '/dashboard-scheduled-visits',
  DASHBOARD_SETTINGS = '/dashboard-settings',
  DASHBOARD_LOGS = '/dashboard-log',
  REPORTS = '/reports',
  SMARTSELL_CUSTOMIZATION = '/smartsell-customization',
  FORMS = '/forms',
  FORMS_VIEW = '/form-view',
  ACCESS_PAGE_WITH_TOKEN = '/accessPageWithToken',
  EDI_LAYOUT = '/layout-edi',
  EDI_CUSTOMERS = '/customers-edi',
  EDI_PAYMENT_CONDITION = '/payment-condition-edi',
  MIX_CUSTOMERS = '/mix-customers',
  ADVANTAGE_CLUB = '/advantage-club',
  ADVANTAGE_CLUB_HISTORY = '/advantage-club-history',
  ADVANTAGE_CLUB_BALANCE = '/advantage-club-balance',
  ORDERS = '/orders',
  GOALS = '/goals',
  BANNERS = '/banners',
  PRODUCTS = '/products',
  TEMPLATES = '/templates',
  MOTIVES = '/motives',
  AUDIENCES = '/audiences',
  COMMUNICATION_GROUPS = '/communication-groups',
  COMMUNICATION_AUTOMATION = '/communication-automation',
  USERS = '/users',
  ROLES = '/roles',
  VISIT_SCHEDULE = '/visit-schedule',
  MOTIVE_VISIT = '/motive-visit',
  TAXES = '/taxes',
  IMPORTER = '/importer',
  EXPORTER = '/exporter',
  EMAIL = '/email',
  LOGS_COMMUNICATION = '/logs/communication',
  LOGS_ORDER_CHANGE = '/logs/order-change',
  CONFIG_PARAMETERS = '/configuration/parameters',
  CONFIG_SELLERS_PARAMETERS = '/configuration/sellers-parameters',
  SMARTSELL_SYNC_SELLER_LOCK = '/smartsell/sync/sellers-lock',
  SMARTSELL_APP_VERSION = '/smartsell/app-version',
}

export enum UrlPathsSuper {
  ROOT = '/superadmin',
  LOGIN = '/superadmin/login',
  HOME = '/superadmin/dashboard',
  TENANTS = '/superadmin/tenants',
  USERS = '/superadmin/users',
  INTEGRATION_MODELS = '/superadmin/integration-models',
  JOBS = '/superadmin/jobs',
  SYSTEM_LOGS = '/superadmin/system/logs',
  SMARTSELL_APP_VERSIONS = '/superadmin/smartsell/app-versions',
  ACCESS_CONTROL = '/superadmin/access-control',
}
