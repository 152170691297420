import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import history from '../../../services/history';
import { jobHistoriesAPI } from '../../../services/requests';
import { StoreState } from '../../../store/createStore';
import ContentContainer from '../../../templates/Content';
import { JobHistory } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import ModalViewJSON from '../../../components/ModalViewJSON';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths } from '../../../enums/urlPaths.enum';
import { PreviewIcon } from '../../../icons';
import { ApiResPaginated } from '../../../types/apiResponseTypes';

const Jobs = () => {
  const { user } = useSelector((state: StoreState) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();

  const [updateTable] = useState(0);
  const [jobHistories, setJobHistories] = useState<JobHistory[]>([]);

  const [dataToViewInJson, setDataToViewInJson] = useState('');

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = jobHistoriesAPI.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      jobHistoriesAPI.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await jobHistoriesAPI.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<JobHistory[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!jobHistoriesAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      frontendNotification({
        message: 'Área restrita!',
        type: NotificationTypes.WARNING,
      });

      history.push(UrlPaths.LOGIN);
    }
  }, [user]);

  const handleStatusColumn = (row: JobHistory) => {
    switch (row.status) {
      case 'error':
        return <p style={{ color: theme.colors.negative }}>{t('error')}</p>;
      case 'success':
        return <p style={{ color: theme.colors.positive }}>{t('success')}</p>;
      case 'processing':
        return <p style={{ color: theme.colors.warning }}>{t('processing')}</p>;
      case 'inQueue':
        return <p>{t('inQueue')}</p>;
      default:
        return <p>{t('unknownStatus')}</p>;
    }
  };

  const handleDataColumn = (row: JobHistory) => (
    <PreviewIcon
      title={t('previewBtn')}
      style={{ width: '100%' }}
      onClick={() => setDataToViewInJson(row.data)}
    />
  );

  const handlePercentColumn = (row: JobHistory) => (
    <div style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: '20px', cursor: 'default' }} title={`${row.percent}%`}>
        <CircularProgressbar
          value={row.percent}
          text={`${row.percent}%`}
          styles={{
            text: {
              fill: theme.colors.positive,
              fontSize: '25px',
            },
            path: {
              stroke: theme.colors.positive,
            },
          }}
        />
      </div>
    </div>
  );

  const handleExceptionColumn = (row: JobHistory) => {
    if (row.exception) {
      return (
        <PreviewIcon
          title={t('previewBtn')}
          style={{ width: '100%' }}
          onClick={() => setDataToViewInJson(row.exception)}
        />
      );
    }

    return undefined;
  };

  const columns: Column<JobHistory>[] = [
    {
      label: t('job') as string,
      accessor: 'job',
    },
    {
      label: t('status') as string,
      Cell: handleStatusColumn,
    },
    {
      label: t('tenantId') as string,
      accessor: 'tenant_id',
      headerCellStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
    },
    {
      label: t('percent') as string,
      Cell: handlePercentColumn,
      cellStyle: { textAlign: 'center' },
      headerCellStyle: { textAlign: 'center' },
    },
    {
      label: t('data') as string,
      Cell: handleDataColumn,
      headerCellStyle: { textAlign: 'center' },
    },
    {
      label: t('exception') as string,
      Cell: handleExceptionColumn,
      headerCellStyle: { textAlign: 'center' },
    },
    {
      label: t('createdAt') as string,
      accessor: 'created_at',
    },
  ];

  return (
    <ContentContainer title={t('users')}>
      {dataToViewInJson.length >= 1 && (
        <ModalViewJSON
          showModal={dataToViewInJson.length >= 1}
          closeModal={() => setDataToViewInJson('')}
          data={dataToViewInJson}
          title={t('data')}
        />
      )}
      <AsyncTable
        tableName={t('jobs')}
        columns={columns}
        value={jobHistories}
        onChange={setJobHistories}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
          totalPageLabel: `${t('totalPages')}: `,
        }}
      />
    </ContentContainer>
  );
};

export default Jobs;
