import styled, { css } from 'styled-components';

const borderRadius = '3px';

interface ContainerProps {
  primaryColor: string;
  secondaryColor: string;
  alternateRowColor: string;
  textColor: string;
}

const commonFeature = css<ContainerProps>`
  background: ${(p) => p.primaryColor};
  color: ${(p) => p.textColor};
  border: 1px solid ${(p) => p.secondaryColor};
  border-radius: ${borderRadius};
`;

const interaction = css<ContainerProps>`
  cursor: pointer;
  transition: 0.3s;

  :hover {
    transform: scale(1.1);
  }

  :active {
    transform: scale(0.9);
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;

    :hover {
      transform: scale(1);
    }

    :active {
      transform: scale(1);
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: 100%;
  color: ${(p) => p.textColor};

  #searchAndDownloadArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    #searchArea {
      margin-bottom: 0.5rem;

      > input {
        border: 1px solid ${(p) => p.textColor};
        padding: 0.3rem;
        border-radius: ${borderRadius};

        &:focus {
          outline: none;
        }
      }
    }

    #downloadsArea {
      display: flex;
      justify-content: center;
      align-items: center;

      .asyncTableDhjDownloadBtns {
        background: transparent;
        border: none;

        .downloadBtnDefaultElement {
          ${commonFeature}
          padding: 0.2rem;

          ${interaction}
        }
      }
    }
  }

  #async-table-row-area {
    overflow-x: scroll;
  }

  .tableDhj {
    background-color: ${(p) => p.primaryColor};
    color: ${(p) => p.textColor};
    width: 100%;

    box-shadow: 0px 0px 4px 2px #00000020;
    border-spacing: 1;
    border-collapse: collapse;
    border-radius: ${borderRadius};
    overflow: hidden;

    text-align: left;

    > thead {
      background: ${(p) => p.primaryColor};
      border-bottom: 1px solid ${(p) => p.textColor};

      > tr {
        height: 3rem;

        > th {
          padding: 0.5rem;

          #titleColumn {
            display: flex;
          }

          &.sortable {
            cursor: pointer;
          }
        }
      }
    }

    > tbody {
      > tr {
        height: 3rem;

        &:last-child {
          border: 0;
        }

        &:nth-of-type(odd) {
          background-color: ${(p) => p.alternateRowColor};
        }
        &:nth-of-type(even) {
          background-color: ${(p) => p.primaryColor};
        }

        > td {
          padding: 0.5rem;
        }
      }
    }
  }

  .controllerArea {
    display: flex;
    justify-content: space-between;

    font-family: 'Lato', sans-serif;
    font-size: 1rem;

    margin-top: 0.5rem;

    .buttonsGroupOne {
      display: flex;

      > * {
        margin-right: 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: max-content;
        padding: 1px;
      }

      #pageNumberInput {
        ${commonFeature}

        width: 5rem;

        &:focus {
          outline: none;
        }
      }

      .buttonsGroupOneItem {
        ${commonFeature}
        ${interaction}

				width: 2rem;
      }

      .asyncTableDhjLabelTotalPage {
        height: 100%;
      }
    }

    .buttonsGroupTwo {
      display: flex;

      #quantityPerPage {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      #quantityPerPageInput {
        ${commonFeature}

        width: 5rem;
        margin-left: 0.5rem;
        text-align: center;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .tableSkeletonContainer {
    box-shadow: 0px 0px 4px 2px #00000020;
    border-radius: ${borderRadius};

    #ldg1 {
      box-shadow: none;
      position: relative;
      overflow: hidden;
      background: ${(p) => p.primaryColor} !important;

      ::before {
        content: '';
        display: block;
        position: absolute;
        left: -100%;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          to right,
          transparent 0%,
          ${(p) => p.alternateRowColor},
          transparent 100%
        );
        animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
      }

      @keyframes load {
        from {
          left: -100%;
        }
        to {
          left: 100%;
        }
      }
    }

    .tableSkeleton {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;

      .row {
        height: 50px;
        width: 100%;

        display: flex;
        justify-content: space-around;
        align-items: center;

        position: relative;

        &.header {
          height: 50px;
          background-color: ${(p) => p.primaryColor};
        }

        .headerValue {
          height: 15px;
          flex: 1;
          margin: 0 2rem 0 2rem;
          background-color: ${(p) => p.alternateRowColor};
          border-radius: 0.2rem;
        }

        &#light {
          background-color: ${(p) => p.primaryColor};
        }

        &:not(:last-child) {
          &::after {
            content: '';
            width: 100%;
            border: 1px solid ${(p) => p.alternateRowColor};
            position: absolute;
            bottom: 0;
          }
        }
      }

      .valueSkeleton {
        height: 1rem;
        flex: 1;
        margin: 0 1rem 0 1rem;
        background-color: ${(p) => p.theme.colors.onSurface}10;
        border-radius: 0.2rem;
      }
    }
  }
`;

export default Container;
