import styled from 'styled-components';
import { contentLoader } from '../../../../../../../../styles/ContentLoader';

import {
  ConversionRate,
  ExtraInfos,
  GraphsContainer,
  InfoCards,
  Pag1Container,
} from '../../styles';
// import { CardContainer, CardContainerProps } from '../CardIcon/styles';

export const Pag1Container1 = styled(Pag1Container)`
  ${contentLoader};
`;

export const GraphsContainer1 = styled(GraphsContainer)`
  width: 100%;
  max-width: 850px;
  min-width: 55%;
  height: 300px;
  margin: 1rem;
  box-sizing: border-box;

  @media (max-width: 900px) {
    min-width: 90%;
  }
`;
export const ConversionRate1 = styled(ConversionRate)`
  min-width: 200px;
  min-height: 200px;
`;
export const InfoCards1 = styled(InfoCards)``;
export const ExtraInfos1 = styled(ExtraInfos)`
  min-width: 200px;
`;
// export const TopCustomers1 = styled(TopCustomers)`
//     min-height: 300px;
//     min-width: 200px;
// `;

// export const Card = styled(CardContainer)<CardContainerProps>`
//     min-width: 200px;
// `;
