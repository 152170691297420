import i18n from '../i18n';

export const visitStates = (): { [key: string]: string } => ({
  svi: i18n.t('noVisit'),
  sve: i18n.t('noSale'),
  vrf: i18n.t('visitOutsideTheRadius'),
  v: i18n.t('sale'),
});

export const formOpeningLocations = (): { [key: number]: string } => ({
  1: i18n.t('visitScreenCard'),
  2: i18n.t('visitButton'),
  3: i18n.t('customerListWhenOrdering'),
  4: i18n.t('orderScreen'),
  5: i18n.t('saveOrderButton'),
  6: i18n.t('addOrderItemScreen'),
  7: i18n.t('saveOrderItemButton'),
  8: i18n.t('newCustomerButton'),
});

export const answerTypes = (): { [key: string]: string } => ({
  t: i18n.t('text'),
  n: i18n.t('numeric'),
  mi: i18n.t('multipleChoice'),
  me: i18n.t('singleChoice'),
  img: i18n.t('image'),
  cpf: i18n.t('cpf'),
  cnpj: i18n.t('cnpj'),
  phone_br: i18n.t('phone'),
  email: i18n.t('email'),
  mobile_phone_br: i18n.t('mobilePhone'),
  currency_br: i18n.t('currencyBr'),
  website: i18n.t('website'),
  date_br: i18n.t('dateBr'),
  datetime_br: i18n.t('datetimeBr'),
  hour_and_minute: i18n.t('hourAndMinute'),
  br_uf: i18n.t('br_uf'),
});
