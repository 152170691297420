import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Filters from '../../../../components/GlobalFilters';
import NavMenu from '../../../../components/NavMenu';
import { NavMenyItem } from '../../../../components/NavMenu/types';
import ContentContainter from '../../../../templates/Content';
import OutContent from '../../../../templates/OutContent';

import NoPermission from '../../../../components/NoPermission';
import checkPermission from '../../../../utils/checkPermission';

import Page1 from './Pages/ConsolidatedSales';
import Page4 from './Pages/Customers';
import Page5 from './Pages/Goals';
import Page3 from './Pages/Products';
import Page2 from './Pages/SellersRanking';

const Main = () => {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState({
    value: 'sales',
    name: 'consolidatedSales',
  });

  const navMenuItems: NavMenyItem[] = [
    {
      title: t('consolidatedSales'),
      action: () =>
        setTabSelected({
          value: 'sales',
          name: 'consolidatedSales',
        }),
    },
    {
      title: t('sellersRanking'),
      action: () =>
        setTabSelected({
          value: 'sellers-ranking',
          name: 'sellersRanking',
        }),
    },
    {
      title: t('products'),
      action: () =>
        setTabSelected({
          value: 'products-kpi',
          name: 'products',
        }),
    },
    {
      title: t('customers'),
      action: () =>
        setTabSelected({
          value: 'kpi',
          name: 'customers',
        }),
    },
    {
      title: t('goals'),
      action: () =>
        setTabSelected({
          value: 'goals',
          name: 'goals',
        }),
    },
  ];

  const handleViewPag = (pagName: string) => {
    let pageSelected = <Page1 />;

    switch (pagName) {
      case 'sales':
        pageSelected = <Page1 />;
        break;
      case 'sellers-ranking':
        pageSelected = <Page2 />;
        break;
      case 'products-kpi':
        pageSelected = <Page3 />;
        break;
      case 'kpi':
        pageSelected = <Page4 />;
        break;
      case 'goals':
        pageSelected = <Page5 />;
        break;
      default:
        pageSelected = <Page1 />;
        break;
    }

    return pageSelected;
  };

  if (!checkPermission('dashboard-view')) {
    return <NoPermission />;
  }

  return (
    <>
      <OutContent>
        <Filters
          yearsIsSingle={tabSelected.value === 'goals'}
          disableSellerFilter={tabSelected.value === 'sellers-ranking'}
          disableCustomerFilter
        />

        <NavMenu items={navMenuItems} />
      </OutContent>

      <ContentContainter title={t(tabSelected.name)}>
        {handleViewPag(tabSelected.value)}
      </ContentContainter>
    </>
  );
};

export default Main;
