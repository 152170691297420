import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { backendErrorNotification } from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { permissionsReq } from '../../../../../../services/requests';
import { Role } from '../../../../../../types';
import { Permission } from '../../../../../../types/apiResponseTypes';

import * as s from './styles';

export const RenderPermissions = ({ selectedPermissions, onChange }: any) => {
  const [permissionsGroup, setPermissionsGroup] = useState<any[]>([]);
  const [waitingGetPermissionsReq, setWaitingGetPermissionsReq] = useState(true);

  const createGroup = (permToGroup: any) => {
    const keys: any[] = [];
    const groups: any[] = [];

    permToGroup.forEach((p: any) => {
      const key = p.name.split('-')[0];

      if (!keys.includes(key)) {
        keys.push(key);
      }
    });

    keys.forEach((key: any) => {
      const permToAdd: any[] = [];

      permToGroup.forEach((p: any) => {
        if (p.name.split('-')[0] === key) {
          permToAdd.push(p);
        }
      });

      groups.push({
        name: key,
        permissions: permToAdd,
      });
    });

    return groups;
  };

  const getPermissionsReq = async () => {
    const source = permissionsReq.axios.CancelToken.source();
    setWaitingGetPermissionsReq(true);

    try {
      const response = await permissionsReq.index(source.token);

      if (response.status === HttpStatus.OK) {
        setPermissionsGroup(createGroup(response.data.data));
      } else {
        throw response;
      }

      setWaitingGetPermissionsReq(false);
    } catch (err) {
      if (!permissionsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingGetPermissionsReq(false);
    }
  };

  const handleCheckBox = (p: any) => {
    const selectedPermCopy = [...selectedPermissions];

    if (selectedPermCopy.some((e: any) => e.name === p.name)) {
      selectedPermCopy.forEach((item, index) => {
        if (item.name === p.name) {
          selectedPermCopy.splice(index, 1);
        }
      });
    } else {
      selectedPermCopy.push(p);
    }

    onChange(selectedPermCopy);
  };

  useEffect(() => {
    getPermissionsReq();
  }, []);

  return waitingGetPermissionsReq ? (
    <div> {t('loading') as string}...</div>
  ) : (
    <>
      {permissionsGroup.map((group: Role, index) => (
        <div key={index} style={{ marginTop: '1rem' }}>
          <s.TitleGroup key={group.id}>{t(group.name) as string}</s.TitleGroup>

          {group.permissions.map((p: Permission) => (
            <s.ItemGroup key={p.id}>
              <>
                <input
                  type="checkbox"
                  onChange={() => handleCheckBox(p)}
                  checked={selectedPermissions.some((e: any) => e.name === p.name)}
                />
                {t(`bk.permission.${p.name}`)}
              </>
            </s.ItemGroup>
          ))}
        </div>
      ))}
    </>
  );
};

export default RenderPermissions;
