import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import {
  formAPI,
  formQuestionApi,
  getEdiTemplatesParametersPaginated,
  getSpecificTemplatesParametersByName,
} from '../../../../../services/requests';
import { Form, FormQuestion } from '../../../../../types';
import { EdiLayout, EdiLayoutField } from '../../../../../types/apiResponse/edi';
import { TemplateParameter } from '../../../../../types/apiResponse/template';
import {
  SelectOptionSpecValue,
  getOptionsFromFormQuestion,
  getOptionsFromForms,
  getOptionsFromTemplateParameters,
} from '../../../../../utils/getSelectOptions';

type KeysToExclude = '';
type Field = Pick<EdiLayoutField, Exclude<keyof EdiLayoutField, KeysToExclude>>;

type Props = {
  onClose: () => void;
  ediLayout: EdiLayout;
  initialField: Field;
  fieldParamDefault: SelectOptionSpecValue<TemplateParameter> | null;
  addAction: (field: Field) => void;
};

const fieldTypes = [
  { label: t('decimal'), value: 'decimal' },
  { label: t('integer'), value: 'integer' },
  { label: t('text'), value: 'text' },
  { label: t('date'), value: 'date' },
];

const ModalCreateOrEditField = ({
  onClose,
  ediLayout,
  initialField,
  fieldParamDefault,
  addAction,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [field, setField] = useState<Field>(initialField);
  const [fieldParam, setFieldParam] = useState<SelectOptionSpecValue<TemplateParameter> | null>(
    fieldParamDefault,
  );
  const [formQuestionOption, setFormQuestionOption] =
    useState<SelectOptionSpecValue<FormQuestion> | null>();

  const [selectOptionForm, setSelectOptionForm] = useState<SelectOptionSpecValue<Form> | null>(
    null,
  );

  const getSpecificParameterByName = async () => {
    setIsLoading(true);

    try {
      const parameterNameToFind = initialField.parameter.split(' - ')[0];
      const res = await getSpecificTemplatesParametersByName.show(parameterNameToFind);

      if (res.status === HttpStatus.OK) {
        setFieldParam({
          label: `${res.data.data.name} - ${res.data.data.label}`,
          value: res.data.data,
        });
      } else {
        throw res;
      }
    } catch (err) {
      if (!getSpecificTemplatesParametersByName.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (initialField.parameter) {
      getSpecificParameterByName();
    }

    if (initialField.form_question) {
      setFormQuestionOption({
        label: initialField.form_question.question,
        value: initialField.form_question,
      });
    }
  }, []);

  const handleAddBtn = () => {
    try {
      if (!fieldParam) {
        frontendNotification({
          message: t('selectAParameter'),
          type: 'warning',
        });
        return;
      }

      const copyField = { ...field };
      copyField.dbTable = fieldParam.value.dbTable;
      copyField.dbColumn = fieldParam.value.dbColumn;
      copyField.parameter = fieldParam.value.name;
      copyField.formQuestionCode = formQuestionOption?.value.id as number;
      copyField.form_question = formQuestionOption?.value;

      if (copyField.formQuestionCode == undefined) {
        copyField.formQuestionCode = null;
      }

      addAction(copyField);
    } catch (err: unknown) {
      if (err instanceof Error) {
        frontendNotification({
          message: err.message,
          type: 'warning',
        });
      }
    }
  };

  const handleValueFieldType = (value: string) => {
    const fieldType = fieldTypes.find((fieldMatch) => fieldMatch.value === value);

    if (fieldType) {
      return fieldType;
    }
    return { label: t('text'), value: 'text' };
  };

  return (
    <Modal
      title={t('field')}
      action={handleAddBtn}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={t('save')}
      bodyStyle={{ width: '100%' }}
      style={{ minWidth: '80%' }}
      isLoading={isLoading}
    >
      <div>
        <div className="row">
          <div className="itemFormWithLabel" style={{ minWidth: '300px' }}>
            <label htmlFor="parameter">{t('orderParameter') as string}</label>
            <SelectAsyncJSX
              value={fieldParam}
              onChange={setFieldParam}
              request={getEdiTemplatesParametersPaginated}
              reqResponseToOption={getOptionsFromTemplateParameters}
              placeholder={t('parameters')}
              disabled={isLoading}
            />
          </div>

          {ediLayout.type === 'position' ? (
            <>
              <div
                className={`itemFormWithLabel ${ediLayout.type !== 'position' ? 'disabled' : ''}`}
                style={{ maxWidth: '60px' }}
              >
                <label htmlFor="startField">{t('fieldStart') as string}</label>
                <input
                  type="number"
                  id="startField"
                  name="startField"
                  value={field.start_field}
                  onChange={(e: any) =>
                    setField({
                      ...field,
                      start_field: e.target.value,
                    })
                  }
                  disabled={ediLayout.type !== 'position'}
                />
              </div>

              <div
                className={`itemFormWithLabel ${ediLayout.type !== 'position' ? 'disabled' : ''}`}
                style={{ maxWidth: '60px' }}
              >
                <label htmlFor="sizeField">{t('fieldSize') as string}</label>
                <input
                  type="number"
                  id="sizeField"
                  name="sizeField"
                  value={field.size_field}
                  onChange={(e: any) =>
                    setField({
                      ...field,
                      size_field: e.target.value,
                    })
                  }
                  disabled={ediLayout.type !== 'position'}
                />
              </div>
            </>
          ) : (
            <div
              className={`itemFormWithLabel ${ediLayout.type !== 'separator' ? 'disabled' : ''}`}
              style={{ maxWidth: '120px' }}
            >
              <label htmlFor="separatorPosition">{t('separatorPosition') as string}</label>
              <input
                type="number"
                id="separatorPosition"
                name="separatorPosition"
                value={field.separatorPosition}
                onChange={(e: any) =>
                  setField({
                    ...field,
                    separatorPosition: e.target.value,
                  })
                }
                disabled={ediLayout.type !== 'separator'}
              />
            </div>
          )}

          <div className="itemFormWithLabel">
            <label htmlFor="fieldType">{t('type') as string}</label>
            <SingleSelect
              id="fieldType"
              name="fieldType"
              value={handleValueFieldType(field.type)}
              options={fieldTypes}
              onChange={(e: any) => setField({ ...field, type: e.value })}
            />
          </div>

          {field.type === 'decimal' && (
            <div className="itemFormWithLabel" style={{ maxWidth: '100px' }}>
              <label htmlFor="qtd_decimal">{t('decimalPlacesCount') as string}</label>
              <input
                type="number"
                id="qtd_decimal"
                name="qtd_decimal"
                value={field.qtd_decimal}
                onChange={(e: any) =>
                  setField({
                    ...field,
                    qtd_decimal: e.target.value,
                  })
                }
              />
            </div>
          )}

          {field.type === 'date' && (
            <div className="itemFormWithLabel">
              <label htmlFor="formatType">{t('formatttingType') as string}</label>
              <input
                type="text"
                id="formatType"
                name="formatType"
                value={field.format_type}
                onChange={(e: any) =>
                  setField({
                    ...field,
                    format_type: e.target.value,
                  })
                }
              />
            </div>
          )}
        </div>

        <div className="row">
          <div className="itemFormWithLabel" style={{ maxWidth: '130px' }}>
            <label htmlFor="record">{t('record') as string}</label>
            <input
              type="text"
              id="record"
              name="record"
              value={field.record}
              onChange={(e: any) => setField({ ...field, record: e.target.value })}
            />
          </div>

          <div className="itemFormWithLabel">
            <label htmlFor="fixedValue">{t('fixedValue') as string}</label>
            <input
              type="text"
              id="fixedValue"
              name="fixedValue"
              value={field.fixed_value as string}
              onChange={(e: any) =>
                setField({
                  ...field,
                  fixed_value: e.target.value,
                })
              }
            />
          </div>

          <div className="itemForm" style={{ marginTop: 'auto', maxWidth: '100px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '30px',
              }}
            >
              <input
                type="checkbox"
                checked={field.isItem}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setField({
                    ...field,
                    isItem: e.target.checked,
                  })
                }
              />
              {t('isItem') as string}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="itemFormWithLabel" style={{ maxWidth: '33.5rem' }}>
            <label htmlFor="linkToAForm">{t('linkToAForm') as string}</label>
            <SelectAsyncJSX
              value={selectOptionForm}
              onChange={setSelectOptionForm}
              request={formAPI}
              reqResponseToOption={getOptionsFromForms}
              disabled={isLoading}
            />
          </div>

          <div className="itemFormWithLabel">
            <label htmlFor="linkToAForm">{t('linkToAFormQuestion') as string}</label>
            <SelectAsyncJSX
              value={formQuestionOption}
              onChange={setFormQuestionOption}
              request={formQuestionApi}
              reqResponseToOption={getOptionsFromFormQuestion}
              disabled={isLoading || !selectOptionForm}
              query={selectOptionForm ? `formId=${selectOptionForm.value}` : ''}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateOrEditField;
