import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import Btn from '../../../../../components/Buttons/Btn';
import InfoButton from '../../../../../components/InfoButton';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { importerAPI, templatesReq } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { Importer } from '../../../../../types/apiResponse/importer';
import {
  SelectOption,
  SelectOptionSpecValue,
  getOptionByValue,
  getOptionsFromTemplatesJson,
} from '../../../../../utils/getSelectOptions';
import importerValidations from '../../Validators';

const ImporterConfigJSX = () => {
  const importerTypes = [
    { label: t('apiRestJson'), value: 1 },
    { label: t('apiRestXml'), value: 2 },
    { label: t('directAccessDb'), value: 3 },
  ];

  const methodsHttp = [
    { label: t('POST'), value: 'POST' },
    { label: t('GET'), value: 'GET' },
    { label: t('PUT'), value: 'PUT' },
  ];

  const tokenSentAndReturnOptions = [
    { label: t('BODY'), value: 'BODY' },
    { label: t('HEADER'), value: 'HEADER' },
  ];

  const activeOptions: SelectOptionSpecValue<boolean>[] = [
    { label: t('not'), value: false },
    { label: t('yes'), value: true },
  ];

  const importerDefaultData: Importer = {
    active: activeOptions[0].value,
    processingIntervalInMinutes: 10,
    type: 1,
    urlToSendOrder: '',
    methodHttpToSendOrder: methodsHttp[0].value,
    templateIdToSendAsApiRequestBody: null,
    templateIdToSendAsApiRequestHeader: null,
    theApiHasAuthentication: true,
    urlForAuthentication: '',
    methodHttpForAuthentication: methodsHttp[0].value,
    templateIdToSendAsAuthenticationRequestBody: null,
    templateIdToSendAsAuthenticationRequestHeader: null,
    tokenReturnBy: tokenSentAndReturnOptions[0].value,
    tokenKeyName: '',
    statusIfRequestOk: 200,
    statusIfAuthRequestOk: 200,
    tokenIsSentBy: tokenSentAndReturnOptions[1].value,
    tokenSendKeyName: 'Authorization',
    importedOrderNumberField: '',
    messageKeyNameIfApiReturnsError: '',
    lastProcessed: '',
  };

  const [importer, setImporter] = useState<Importer>(importerDefaultData);
  const [isLoading, setIsLoading] = useState(false);

  const [templateBodyToRequest, setTemplateBodyToRequest] = useState<SelectOption>();
  const [templateHeaderToRequest, setTemplateHeaderToRequest] = useState<SelectOption>();
  const [templateBodyToAuth, setTemplateBodyToAuth] = useState<SelectOption>();
  const [templateHeaderToAuth, setTemplateHeaderToAuth] = useState<SelectOption>();

  const handleInput = (
    e: any,
    keyName: string,
    inputType: 'text' | 'int' | 'selectAsync' = 'text',
  ) => {
    const copyInporter = { ...importer };
    if (inputType === 'text') {
      setImporter({ ...copyInporter, [keyName]: e.target.value });
    }
    if (inputType === 'selectAsync') {
      setImporter({ ...copyInporter, [keyName]: e.value });
    }
    if (inputType === 'int') {
      setImporter({ ...copyInporter, [keyName]: parseInt(e.target.value, 10) });
    }
  };

  const handleTypeSelect = (option: SelectOption, keyName: string) => {
    setImporter({ ...importer, [keyName]: option.value as number });
  };

  const handleCheckboxInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copyImporter = { ...importer };
    copyImporter.theApiHasAuthentication = e.target.checked;
    setImporter({ ...copyImporter });
  };

  const handleSaveBtn = async () => {
    setIsLoading(true);

    const copyImporter = { ...importer };

    copyImporter.templateIdToSendAsApiRequestBody =
      (templateBodyToRequest?.value as number) ?? null;
    copyImporter.templateIdToSendAsApiRequestHeader =
      (templateHeaderToRequest?.value as number) ?? null;
    copyImporter.templateIdToSendAsAuthenticationRequestBody =
      (templateBodyToAuth?.value as number) ?? null;
    copyImporter.templateIdToSendAsAuthenticationRequestHeader =
      (templateHeaderToAuth?.value as number) ?? null;

    const validationHasError = importerValidations(copyImporter);
    if (validationHasError) {
      frontendNotification({ message: validationHasError, type: 'warning' });
      setIsLoading(false);
      return;
    }

    try {
      const response = await importerAPI.store(copyImporter);

      if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
        frontendNotification({
          message: t('success'),
          type: 'success',
        });
      } else {
        backendErrorNotification(response as AxiosError<any, any>);
      }
    } catch (err) {
      if (!importerAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  };

  const getImporterConfig = async () => {
    setIsLoading(true);
    const source = importerAPI.axios.CancelToken.source();
    try {
      const res = await importerAPI.index(source.token);

      if (res.status === HttpStatus.OK && res.data.data) {
        const config: Importer = res.data.data;
        templatesReq.query = '';
        if (config.templateIdToSendAsApiRequestBody) {
          const template = await templatesReq.show(config.templateIdToSendAsApiRequestBody);
          if (template.status === HttpStatus.OK) {
            setTemplateBodyToRequest({
              value: template.data.data.id,
              label: template.data.data.name,
            });
          } else {
            frontendNotification({
              message: t('templateForBodyRequestNotFound'),
              type: 'warning',
            });
          }
        }

        if (config.templateIdToSendAsApiRequestHeader) {
          const template = await templatesReq.show(config.templateIdToSendAsApiRequestHeader);
          if (template.status === HttpStatus.OK) {
            setTemplateHeaderToRequest({
              value: template.data.data.id,
              label: template.data.data.name,
            });
          } else {
            frontendNotification({
              message: t('templateForHeaderRequestNotFound'),
              type: 'warning',
            });
          }
        }

        if (config.templateIdToSendAsAuthenticationRequestBody) {
          const template = await templatesReq.show(
            config.templateIdToSendAsAuthenticationRequestBody,
          );
          if (template.status === HttpStatus.OK) {
            setTemplateBodyToAuth({
              value: template.data.data.id,
              label: template.data.data.name,
            });
          } else {
            frontendNotification({
              message: t('templateForBodyAuthRequestNotFound'),
              type: 'warning',
            });
          }
        }

        if (config.templateIdToSendAsAuthenticationRequestHeader) {
          const template = await templatesReq.show(
            config.templateIdToSendAsAuthenticationRequestHeader,
          );
          if (template.status === HttpStatus.OK) {
            setTemplateHeaderToAuth({
              value: template.data.data.id,
              label: template.data.data.name,
            });
          } else {
            frontendNotification({
              message: t('templateForHeaderAuthRequestNotFound'),
              type: 'warning',
            });
          }
        }

        setImporter(config);
      } else {
        throw res;
      }
    } catch (err) {
      if (!importerAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
    return () => source.cancel('Component Roles got unmounted');
  };

  useEffect(() => {
    getImporterConfig();
  }, []);

  return (
    <ContentContainter title={t('config')}>
      <div className="row">
        <div className="itemFormWithLabel">
          <label htmlFor="importerType">{t('importerType') as string}</label>
          <SingleSelect
            value={getOptionByValue(importer.type, importerTypes)}
            options={importerTypes}
            onChange={(option: SelectOption) => handleTypeSelect(option, 'type')}
            disabled={isLoading}
          />
        </div>

        <div className="itemFormWithLabel">
          <label htmlFor="processingIntervalInMinutes">
            {t('intervalInMinutesForProcessing') as string}
          </label>
          <input
            type="number"
            min={10}
            className={`input ${isLoading ? 'disabled' : ''}`}
            value={importer.processingIntervalInMinutes}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, 'processingIntervalInMinutes', 'int')
            }
            disabled={isLoading}
          />
        </div>

        <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
          <label htmlFor="processingIntervalInMinutes">{t('active') as string}</label>
          <SingleSelect
            value={getOptionByValue(!!importer.active, activeOptions)}
            options={activeOptions}
            onChange={(option: SelectOption) => handleTypeSelect(option, 'active')}
            disabled={isLoading}
          />
        </div>
      </div>

      {(importer.type === 2 || (importer.type === 3 && !isLoading)) && (
        <div style={{ opacity: 0.7, margin: '1rem', textAlign: 'center' }}>
          <h1>{t('typeCurrentlyUnavailable') as string}</h1>
        </div>
      )}

      {isLoading && (
        <div style={{ opacity: 0.7, margin: '1rem', textAlign: 'center' }}>
          <h1>{t('loading') as string}...</h1>
        </div>
      )}

      {importer.type === 1 && !isLoading && (
        <>
          <div className="row">
            <div className="itemFormWithLabel">
              <label htmlFor="urlToSendOrder">{t('urlToSendOrder') as string}</label>
              <input
                type="text"
                className={`input ${isLoading ? 'disabled' : ''}`}
                value={importer.urlToSendOrder as string}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, 'urlToSendOrder')}
                disabled={isLoading}
              />
            </div>

            <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
              <label htmlFor="methodHttpToSendOrder">{t('methodHttpToSendOrder') as string}</label>
              <SingleSelect
                value={getOptionByValue(importer.methodHttpToSendOrder, methodsHttp)}
                options={methodsHttp}
                onChange={(option: SelectOption) =>
                  handleTypeSelect(option, 'methodHttpToSendOrder')
                }
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="row">
            <div className="itemFormWithLabel">
              <label htmlFor="templateToSendAsApiRequestBody">
                {t('templateToSendAsApiRequestBody') as string}
              </label>
              <SelectAsyncJSX
                value={templateBodyToRequest}
                onChange={setTemplateBodyToRequest}
                request={templatesReq}
                reqResponseToOption={getOptionsFromTemplatesJson}
                disabled={isLoading}
                className="inputTemplateArea"
              />
            </div>

            <div className="itemFormWithLabel">
              <label htmlFor="templateToSendAsApiRequestHeader">
                {t('templateToSendAsApiRequestHeader') as string}
              </label>
              <SelectAsyncJSX
                value={templateHeaderToRequest}
                onChange={setTemplateHeaderToRequest}
                request={templatesReq}
                reqResponseToOption={getOptionsFromTemplatesJson}
                disabled={isLoading}
                className="inputTemplateArea"
              />
            </div>

            <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
              <label htmlFor="statusIfRequestOk">{t('statusIfRequestOk') as string}</label>
              <input
                type="number"
                min={100}
                max={999}
                className={`input ${isLoading ? 'disabled' : ''}`}
                value={importer.statusIfRequestOk as number}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInput(e, 'statusIfRequestOk', 'int')
                }
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="row">
            <div className="itemFormWithLabel" style={{ maxWidth: '415px' }}>
              <label htmlFor="messageKeyNameIfApiReturnsError" style={{ display: 'flex' }}>
                {t('messageKeyNameIfApiReturnsError') as string}
              </label>

              <input
                type="text"
                id="messageKeyNameIfApiReturnsError"
                className={`input ${isLoading ? 'disabled' : ''}`}
                value={importer.messageKeyNameIfApiReturnsError as string}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInput(e, 'messageKeyNameIfApiReturnsError')
                }
                disabled={isLoading}
              />
            </div>

            <div className="itemFormWithLabel" style={{ maxWidth: '415px' }}>
              <label htmlFor="importedOrderNumberField" style={{ display: 'flex' }}>
                {t('importedOrderNumberField') as string}
                <InfoButton
                  msg={t('info.importer.importedOrderNumberField')}
                  style={{ marginLeft: '0.5rem' }}
                />
              </label>

              <input
                type="text"
                id="importedOrderNumberField"
                className={`input ${isLoading ? 'disabled' : ''}`}
                value={importer.importedOrderNumberField as string}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInput(e, 'importedOrderNumberField')
                }
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="itemForm"
              style={{ display: 'flex', alignItems: 'center', margin: '1rem' }}
            >
              {t('theApiHasAuthentication') as string}

              <input
                type="checkbox"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckboxInput(e)}
                checked={importer.theApiHasAuthentication}
                disabled={isLoading}
              />
            </div>
          </div>

          {importer.theApiHasAuthentication && (
            <>
              <div className="row">
                <div className="itemFormWithLabel">
                  <label htmlFor="urlToSeurlForAuthenticationndOrder">
                    {t('urlForAuthentication') as string}
                  </label>
                  <input
                    type="text"
                    className={`input ${isLoading ? 'disabled' : ''}`}
                    value={importer.urlForAuthentication as string}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, 'urlForAuthentication')
                    }
                    disabled={isLoading}
                  />
                </div>

                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                  <label htmlFor="methodHttpForAuthentication">
                    {t('methodHttpForAuthentication') as string}
                  </label>
                  <SingleSelect
                    value={getOptionByValue(importer.methodHttpForAuthentication, methodsHttp)}
                    options={methodsHttp}
                    onChange={(option: SelectOption) =>
                      handleTypeSelect(option, 'methodHttpForAuthentication')
                    }
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="row">
                <div className="itemFormWithLabel">
                  <label htmlFor="templateToSendAsAuthApiRequestBody">
                    {t('templateToSendAsAuthApiRequestBody') as string}
                  </label>
                  <SelectAsyncJSX
                    value={templateBodyToAuth}
                    onChange={setTemplateBodyToAuth}
                    request={templatesReq}
                    reqResponseToOption={getOptionsFromTemplatesJson}
                    disabled={isLoading}
                    className="inputTemplateArea"
                  />
                </div>

                <div className="itemFormWithLabel">
                  <label htmlFor="templateToSendAsAuthApiRequestHeader">
                    {t('templateToSendAsAuthApiRequestHeader') as string}
                  </label>
                  <SelectAsyncJSX
                    value={templateHeaderToAuth}
                    onChange={setTemplateHeaderToAuth}
                    request={templatesReq}
                    reqResponseToOption={getOptionsFromTemplatesJson}
                    disabled={isLoading}
                    className="inputTemplateArea"
                  />
                </div>

                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                  <label htmlFor="statusIfAuthRequestOk">
                    {t('statusIfAuthRequestOk') as string}
                  </label>
                  <input
                    type="number"
                    min={100}
                    max={999}
                    className={`input ${isLoading ? 'disabled' : ''}`}
                    value={importer.statusIfAuthRequestOk as number}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, 'statusIfAuthRequestOk', 'int')
                    }
                    disabled={isLoading}
                  />
                </div>
              </div>

              <div className="row">
                <div className="itemFormWithLabel" style={{ maxWidth: '15rem' }}>
                  <label htmlFor="whereDoesTheTokenReturn">
                    {t('whereDoesTheTokenReturn') as string}
                  </label>
                  <SingleSelect
                    value={getOptionByValue(importer.tokenReturnBy, tokenSentAndReturnOptions)}
                    options={tokenSentAndReturnOptions}
                    onChange={(option: SelectOption) => handleTypeSelect(option, 'tokenReturnBy')}
                    disabled={isLoading}
                  />
                </div>

                <div className="itemFormWithLabel">
                  <label htmlFor="tokenKeyName">{t('tokenKeyName') as string}</label>
                  <input
                    type="text"
                    className={`input ${isLoading ? 'disabled' : ''}`}
                    value={importer.tokenKeyName as string}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, 'tokenKeyName')}
                    disabled={isLoading}
                  />
                </div>
              </div>

              <div className="row">
                <div className="itemFormWithLabel" style={{ maxWidth: '15rem' }}>
                  <label htmlFor="whereTheTokenIsSent">{t('whereTheTokenIsSent') as string}</label>
                  <SingleSelect
                    value={getOptionByValue(importer.tokenIsSentBy, tokenSentAndReturnOptions)}
                    options={tokenSentAndReturnOptions}
                    onChange={(option: SelectOption) => handleTypeSelect(option, 'tokenIsSentBy')}
                    disabled={isLoading}
                  />
                </div>

                <div className="itemFormWithLabel">
                  <label htmlFor="nameOfTheKeyWhereTheTokenIsSent">
                    {t('nameOfTheKeyWhereTheTokenIsSent') as string}
                  </label>
                  <input
                    type="text"
                    className={`input ${isLoading ? 'disabled' : ''}`}
                    value={importer.tokenSendKeyName as string}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, 'tokenSendKeyName')
                    }
                    disabled={isLoading}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}

      <div className="row">
        <div className="itemForm webkitRight" style={{ marginRight: '0.5rem' }}>
          <Btn text={t('save')} onClick={() => handleSaveBtn()} disabled={isLoading} />
        </div>
      </div>
    </ContentContainter>
  );
};

export default ImporterConfigJSX;
