import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { Column, Row } from 'react-table';

import AddBtn from '../../../../../components/Buttons/AddBtn';
import Btn from '../../../../../components/Buttons/Btn';
import Input from '../../../../../components/Input';
import InputFile from '../../../../../components/InputFile';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import TablePaginator from '../../../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../enums/httpStatus';
import { DeleteIcon } from '../../../../../icons';
import {
  audienceAPI,
  tenantCustomLibsVersionsApi as mainAPi,
} from '../../../../../services/requests';
import { Audience, GetTenantCustomLibVersion as LibVersionType } from '../../../../../types';
import { getOptionsFromAudiences } from '../../../../../utils/getSelectOptions';
import ModalCreateAudience from '../../../Registrations/Audience/Components/ModalCreate';
import * as s from './styles';

type Props = {
  closeModal: () => void;
  callback: () => void;
  selectedItem: LibVersionType;
};

type Option = {
  value: any;
  label: string;
};

const ModalEditJSX = ({ closeModal, callback, selectedItem }: Props) => {
  const [waitingRequest, setWaitingRequest] = useState(false);

  const [lib, setLib] = useState({ value: 'SmartsellMobile', label: 'SmartsellMobile' });
  const [description, setDescription] = useState(selectedItem.description);
  const [file, setFile] = useState<HTMLInputElement | null>(null);
  const [projectBaseDexFile, setProjectBaseDexFile] = useState<HTMLInputElement | null>(null);
  const [selectedAudience, setSelectedAudience] = useState<Option | Record<string, unknown>>({});

  const [showModalNewAudience, setShowModalNewAudience] = useState(false);

  const [addedAudiences, setAddedAudience] = useState<Audience[]>(selectedItem.audiences ?? []);
  const [selectAudienceCache, setSelectAudienceCache] = useState(0);

  const reqUpdate = async () => {
    if (addedAudiences.length <= 0) {
      frontendNotification({
        message: t('addAtLeastOneAudience'),
        type: 'warning',
      });
      return;
    }

    setWaitingRequest(true);

    try {
      mainAPi.query = '';
      const res = await mainAPi.update(
        {
          description,
          audiencesAsString: addedAudiences.map((item) => item.id).toString(),
        },
        selectedItem.id,
      );

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        callback();
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      if (!mainAPi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }
    setWaitingRequest(false);
  };

  const handleAddAudienceBtn = () => {
    if (!selectedAudience.value) {
      frontendNotification({
        message: t('selectAnAudience'),
        type: 'warning',
      });
      return;
    }

    const copyAddedAudiences = [...addedAudiences];

    if (copyAddedAudiences.some((addedAudience) => addedAudience.id === selectedAudience.value)) {
      frontendNotification({
        message: t('alreadyExists'),
        type: 'warning',
      });
    } else {
      copyAddedAudiences.push({
        id: selectedAudience.value,
        description: selectedAudience.label as string,
      });

      setAddedAudience(copyAddedAudiences);
      setSelectedAudience({});
    }
  };

  const removeAudi = (audienceToRemove: Audience) => {
    const filteredAudience = addedAudiences.filter(
      (addedAudience) => JSON.stringify(addedAudience) !== JSON.stringify(audienceToRemove),
    );

    setAddedAudience(filteredAudience);
  };

  const actionsButtons = (row: Row<Audience>) => (
    <ActionButtonsContainer type={1}>
      <DeleteIcon size="1.5rem" title={t('edit')} onClick={() => removeAudi(row.original)} />
    </ActionButtonsContainer>
  );

  const columns: Column<Audience>[] = useMemo(
    () => [
      {
        Header: t('id') as string,
        accessor: 'id',
      },
      {
        Header: t('description') as string,
        accessor: 'description',
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [addedAudiences],
  );

  return (
    <Modal
      title={t('updateLibraryVersion')}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      actionNameBtn={t('save')}
      action={reqUpdate}
      isLoading={waitingRequest}
    >
      <s.Container>
        <s.Row>
          <s.Item>
            <s.Label>{t('lib') as string}</s.Label>
            <SingleSelect
              value={lib}
              onChange={(e: any) => setLib(e.target.value)}
              options={[{ value: 'SmartsellMobile', label: 'SmartsellMobile' }]}
              disabled
            />
          </s.Item>
          <s.Item>
            <s.Label>{t('description') as string}</s.Label>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              placeholder={t('description')}
              disabled={waitingRequest}
            />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <s.Label>{`${t('file')} (.dex)`}</s.Label>
            <InputFile value={file} setFile={setFile} disabled />
          </s.Item>

          <s.Item>
            <s.Label>{`${t('projectFile')} (.dex)`}</s.Label>
            <InputFile value={projectBaseDexFile} setFile={setProjectBaseDexFile} disabled />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <s.Label>{t('audience') as string}</s.Label>
            <div id="audienceArea">
              <div id="selectAudienceArea">
                <SelectAsyncJSX
                  value={selectedAudience}
                  onChange={setSelectedAudience}
                  request={audienceAPI}
                  reqResponseToOption={getOptionsFromAudiences}
                  disabled={waitingRequest}
                  placeholder={t('audience')}
                  cacheUniqs={[selectAudienceCache]}
                />
              </div>

              <AddBtn onClick={() => handleAddAudienceBtn()} />
            </div>
          </s.Item>
          <s.Item id="createNewAudience">
            <Btn
              text={t('newAudience')}
              disabled={waitingRequest}
              onClick={() => setShowModalNewAudience(true)}
            />
          </s.Item>
        </s.Row>

        <s.Row>
          <s.Item>
            <TablePaginator
              data={addedAudiences}
              columns={columns}
              globalFiltering
              style={{ width: '100%', marginTop: '2rem' }}
            />
          </s.Item>
        </s.Row>

        {showModalNewAudience && (
          <ModalCreateAudience
            closeModal={() => setShowModalNewAudience(false)}
            callback={() => setSelectAudienceCache(selectAudienceCache + 1)}
            availableTypes={['seller']}
          />
        )}
      </s.Container>
    </Modal>
  );
};

export default ModalEditJSX;
