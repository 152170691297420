import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import ptBR from 'date-fns/locale/pt-BR';

import { AxiosError } from 'axios';
import { registerLocale } from 'react-datepicker';
import reqSellers from '../../../../services/requests/sellers/getSellers';
import ContentContainter from '../../../../templates/Content';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import { NotificationTypes } from '../../../../components/Notification/notificationEnums';
import {
  ActionButtonsContainer,
  DeleteIconTable,
  EditIconTable,
} from '../../../../components/TablePaginator/styles';
import { SellersCol } from '../../../../components/TablePaginator/types';
import HttpStatus from '../../../../enums/httpStatus';
import useFilters from '../../../../hooks/useFilters';
import { goalsReq } from '../../../../services/requests';
import Loader from './Loader';
import * as s from './styles';

import { Goal } from '../../../../types/apiResponse/goal';

import DeleteGoalModal from './Components/DeleteGoalModal';
import DeleteManyGoalModal from './Components/DeleteManyGoalModal';
import EditGoalModal from './Components/EditGoalModal';

import Button from '../../../../components/Buttons/Btn';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../../components/NoData';
import TablePaginator from '../../../../components/TablePaginator';
import { getSpecificField } from '../../../../utils/helpers';
import CreateOrEditGoalModal from './Components/CreateOrEditGoalModal';

registerLocale('pt-BR', ptBR);

type GoalFilterPros = {
  sellers: string[] | [];
  months: string[] | [];
  years: string[] | [];
};

type GoalCol = Goal & {
  actions?: string;
};

export type CreateBatchGoalProps = {
  sellers: [] | any;
  months: [] | any;
  years: [] | any;
  goal: null | number | undefined;
  weight: null | number | undefined;
  box: null | number | undefined;
  positivation: null | number | undefined;
  coverage: null | number | undefined;
};

export type CreateGoalProps = {
  seller: string | null;
  month: string | null;
  year: string | null;
  goal: null | number | undefined;
  weight: null | number | undefined;
  box: null | number | undefined;
  positivation: null | number | undefined;
  coverage: null | number | undefined;
};

const emptyGoal: Goal = {
  description: '',
  goalType: 'DEFAULT',
  weight: 0,
  packages: 0,
  positivation: 0,
  coverage: 0,
  amount: 0,
  seller_id: null,
  customerCode: null,
  date: null,
  start: null,
  end: null,
  advantageClubId: null,
  seller: null,
  customer: null,
  advantage_club: null,
};

const GoalComp: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { filters } = useFilters();

  const [isLoading, setIsLoading] = useState(false);

  const [showCreateGoalModal, setShowCreateGoalModal] = useState(false);
  const [showEditGoalModal, setShowEditGoalModal] = useState(false);
  const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
  const [showDelManyGoalModal, setShowDelManyGoalModal] = useState(false);

  const [goalSelected, setGoalSelected] = useState<Goal>(emptyGoal);
  const [goalsSelected, setGoalsSelected] = useState<Goal[] | []>([]);

  const [faker, setData] = useState<GoalCol[] | []>([]);
  const [sellersSelect] = useState([]);
  const [allSellers, setSellers] = useState<SellersCol[]>([]);
  const [, setGoalFilters] = useState<GoalFilterPros>({
    sellers: [],
    months: [],
    years: [],
  });

  const handleSetSellersSelected = () => {
    const allSellersSelected: string[] = [];
    if (sellersSelect.length >= 1) {
      sellersSelect.forEach((sel: any) => {
        allSellersSelected.push(sel.value as string);
      });
      setGoalFilters(({ ...rest }) => ({
        ...rest,
        sellers: allSellersSelected,
      }));
    } else {
      setGoalFilters(({ ...rest }) => ({
        ...rest,
        sellers: [],
      }));
    }
  };

  useEffect(() => {
    handleSetSellersSelected();
  }, [sellersSelect]);

  const getSellers = async () => {
    try {
      const response = await reqSellers();

      if (response.status === HttpStatus.OK) {
        setSellers(response.data.data);
      } else {
        throw response;
      }
    } catch (erro) {
      backendErrorNotification(erro as any);
    }
  };

  useEffect(() => {
    getSellers();
  }, []);

  const reqGetGoals = useCallback(async () => {
    setIsLoading(true);
    const source = goalsReq.axios.CancelToken.source();

    try {
      const res = await goalsReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        if (res.data.data) {
          const responseData = res.data.data;
          setData(responseData);
        } else {
          frontendNotification({
            message: 'Sem dados de metas!',
            type: NotificationTypes.WARNING,
          });
        }
      } else {
        throw res;
      }
    } catch (err) {
      if (!goalsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
    return () => {
      source.cancel('Component Goal got unmounted');
    };
  }, [filters]);

  useEffect(() => {
    reqGetGoals();
  }, [filters]);

  const handleTableEditBtn = (row: Row<GoalCol>) => {
    setGoalSelected(row.original);
    setShowEditGoalModal(true);
  };
  const handleTableDeleteBtn = (row: Row<GoalCol>) => {
    setGoalSelected(row.original);
    setShowDeleteGoalModal(true);
  };

  const actionsButtons = (row: Row<GoalCol>) => (
    <ActionButtonsContainer>
      <EditIconTable onClick={() => handleTableEditBtn(row)} />
      <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const handleSellerColumn = (row: Row<GoalCol>) => {
    if (row.original.seller) {
      return (
        <p>
          {row.original.seller.CodigoVendedor} - {row.original.seller.Nome}
        </p>
      );
    }

    return null;
  };

  const handleCustomerColumn = (row: Row<GoalCol>) => {
    const customer = row.original.customer;
    if (customer) {
      return (
        <p>
          {customer.CodigoCliente} - {customer.RazaoSocial}
        </p>
      );
    }

    return null;
  };

  const handlePeriodColumn = (row: Row<GoalCol>) => {
    if (row.original.goalType === 'DEFAULT' && row.original.start) {
      return (
        <div>
          <p>
            <b>{t('start')}:</b> {row.original.start}
          </p>
          <p>
            <b>{t('end')}:</b> {row.original.end}
          </p>
        </div>
      );
    }

    if (row.original.goalType === 'SPECIFIC_MONTH' && row.original.date) {
      return (
        <p>
          <b>{t('month')}:</b> {row.original.date.substring(0, 7)}
        </p>
      );
    }

    if (row.original.goalType === 'ADVANTAGE_CLUB' && row.original.advantage_club) {
      return (
        <p>
          <b>{t('advantageClub')}:</b> {row.original.advantage_club.description}
        </p>
      );
    }

    return null;
  };

  const columns: Column<GoalCol>[] = useMemo(
    () => [
      {
        Header: t('description') as string,
        accessor: 'description',
      },
      {
        Header: t('seller') as string,
        accessor: 'seller',
        Cell: ({ cell: { row } }) => handleSellerColumn(row),
      },
      {
        Header: t('customer') as string,
        accessor: 'customer',
        Cell: ({ cell: { row } }) => handleCustomerColumn(row),
      },
      {
        Header: t('period') as string,
        accessor: 'goalType',
        Cell: ({ cell: { row } }) => handlePeriodColumn(row),
      },
      {
        Header: t('financial') as string,
        accessor: 'amount',
        // Cell: ({ value }) =>
        // 	value > 0 ? formatNumber.currencyBr(value) : '--',
      },
      {
        Header: `${t('weight')} (kg)`,
        accessor: 'weight',
        // Cell: ({ value }) =>
        // 	value > 0 ? formatNumber.weightBrKg(value) : '--',
      },
      {
        Header: t('packages') as string,
        accessor: 'packages',
      },
      {
        Header: `${t('positivation')} (%)`,
        accessor: 'positivation',
        // Cell: ({ value }) =>
        // 	value > 0 ? formatNumber.percent(value) : '--',
      },
      {
        Header: `${t('coverage')} (%)`,
        accessor: 'coverage',
        // Cell: ({ value }) =>
        // 	value > 0 ? formatNumber.percent(value) : '--',
      },
      {
        id: 'actionsbtn',
        accessor: 'actions',
        Cell: ({ cell: { row } }) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  const data = useMemo(() => faker, [faker, allSellers]);

  return (
    <ContentContainter title={t('goals')}>
      {showCreateGoalModal && (
        <CreateOrEditGoalModal
          initialGoal={goalSelected}
          title="teste"
          showModal={showCreateGoalModal}
          closeModal={() => setShowCreateGoalModal(false)}
          callback={() => reqGetGoals()}
        />
      )}

      {showDeleteGoalModal && (
        <DeleteGoalModal
          title={t('delete')}
          closeModal={() => setShowDeleteGoalModal(false)}
          showModal={showDeleteGoalModal}
          actionNameBtn={t('delete')}
          reqGetAllGoals={() => reqGetGoals()}
          goalSelected={goalSelected as Goal}
        />
      )}

      {showDelManyGoalModal && (
        <DeleteManyGoalModal
          title={t('delete')}
          closeModal={() => setShowDelManyGoalModal(false)}
          showModal={showDelManyGoalModal}
          actionNameBtn={t('delete')}
          reqGetAllGoals={() => reqGetGoals()}
          goalsSelected={goalsSelected as Goal[]}
        />
      )}

      {showEditGoalModal && (
        <EditGoalModal
          title={t('edit')}
          closeModal={() => setShowEditGoalModal(false)}
          showModal={showEditGoalModal}
          actionNameBtn={t('edit')}
          reqGetAllGoals={() => reqGetGoals()}
          goalSelected={goalSelected as Goal}
        />
      )}

      <s.BtnArea>
        <NewRegisterBtn
          onClick={() => setShowCreateGoalModal(true)}
          disabled={isLoading}
          style={{ marginLeft: '0' }}
        />

        {goalsSelected.length >= 1 && (
          <Button
            text={t('batchDelete')}
            onClick={() => setShowDelManyGoalModal(true)}
            disabled={isLoading}
          />
        )}
      </s.BtnArea>

      <Loader isLoading={isLoading}>
        {data.length >= 1 ? (
          <TablePaginator
            data={data}
            columns={columns}
            globalFiltering
            selecteds={(r: any) => setGoalsSelected(getSpecificField('original', r) as Goal[])}
          />
        ) : (
          <NoData />
        )}
      </Loader>
    </ContentContainter>
  );
};

export default GoalComp;
