import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { motiveVisitAPI } from '../../../../../../services/requests';
import { MotiveVisit } from '../../../../../../types';

type Props = {
  closeModal: () => void;
  reqCallback: () => void;
  selectedItem: MotiveVisit;
};

const ModalDeleteJSX = ({ closeModal, reqCallback, selectedItem }: Props) => {
  const { t } = useTranslation();

  const [waitingResponse, setWaitingResponse] = useState(false);

  const reqDelete = async () => {
    setWaitingResponse(true);

    const source = motiveVisitAPI.axios.CancelToken.source();

    try {
      const res = await motiveVisitAPI.delete(selectedItem.id);

      if (res.status === HttpStatus.OK) {
        setWaitingResponse(false);
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        reqCallback();
        closeModal();
      } else {
        throw res;
      }
    } catch (err) {
      if (!motiveVisitAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitingResponse(false);
    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={`${t('delete')} ${t('motiveSchedule')}`}
      showModal
      closeModal={closeModal}
      action={() => reqDelete()}
      actionNameBtn={t('delete')}
      isLoading={waitingResponse}
    >
      <p>
        {`${t('deleteSure')}?`}
        <p>
          <b>{t('id')}:</b> {selectedItem.id}
        </p>
        <p>
          <b>{t('motiveFlag')}:</b> {selectedItem.motiveFlag}
        </p>
        <p>
          <b>{t('description')}:</b> {selectedItem.description}
        </p>
      </p>
    </Modal>
  );
};

export default ModalDeleteJSX;
