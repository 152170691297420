import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';

import { usersReq } from '../../../../services/requests';
import { User } from '../../../../types';

import { backendErrorNotification } from '../../../../components/Notification';
import * as s from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import ContentContainer from '../../../../templates/Content';
import { ApiResPaginated } from '../../../../types/apiResponseTypes';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import AddUserModal from './components/AddUserModal';
import DeleteUserModal from './components/DeleteUserModal';
import EditUserModal from './components/EditUserModal';

const Page1 = () => {
  const theme = useTheme();

  const [users, setUsers] = useState<User[]>([]);
  const [userSelected, setUserSelected] = useState<User | null>(null);

  const [showAddUserModal, setAddUserModal] = useState(false);
  const [showEditUserModal, setEditUserModal] = useState(false);
  const [showDeleteUserModal, setDeleteUserModal] = useState(false);

  const [updateTable, setUpdateTable] = useState(0);

  const handleTableEditBtn = (row: User) => {
    setUserSelected(row);
    setEditUserModal(true);
  };
  const handleTableDeleteBtn = (row: User) => {
    setUserSelected(row);
    setDeleteUserModal(true);
  };

  const actionsButtons = (row: User) => (
    <s.ActionButtonsContainer>
      <s.EditIconTable onClick={() => handleTableEditBtn(row)} />
      {!row.is_tenant_admin && <s.DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />}
    </s.ActionButtonsContainer>
  );

  const getUsers: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = usersReq.axios.CancelToken.source();

    try {
      usersReq.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await usersReq.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<User[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!usersReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleRolesColumn = (row: User) =>
    row.roles?.map((role) => {
      if (role.name.includes('_')) {
        return (
          <p key={role.name} className="textOnTheSameLine cardListTable">
            {role.name.split('_')[1]}
          </p>
        );
      }
      return (
        <p key={role.name} className="textOnTheSameLine cardListTable">
          {role.name}
        </p>
      );
    });

  const columns: Column<User>[] = [
    {
      label: t('name') as string,
      accessor: 'name',
    },
    {
      label: t('email') as string,
      accessor: 'email',
    },
    {
      label: t('group') as string,
      Cell: handleRolesColumn,
      cellStyle: {
        display: 'flex',
        minWidth: '100%',
        overflow: 'auto',
        maxWidth: '200px',
      },
      cellClassName: 'thinScrollbar',
    },
    {
      Cell: actionsButtons,
    },
  ];

  return (
    <ContentContainer title={t('users')}>
      <NewRegisterBtn
        onClick={() => setAddUserModal(true)}
        style={{ marginBottom: '1rem', marginLeft: '0' }}
      />

      <AsyncTable
        tableName={t('users')}
        columns={columns}
        value={users}
        onChange={setUsers}
        requestPage={getUsers}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
        }}
      />

      {showAddUserModal && (
        <AddUserModal
          showModal={showAddUserModal}
          closeModal={() => setAddUserModal(!showAddUserModal)}
          reqGetAllUsers={() => setUpdateTable(updateTable + 1)}
          callback={() => setUpdateTable(updateTable + 1)}
        />
      )}

      {showEditUserModal && userSelected != null && (
        <EditUserModal
          showModal={showEditUserModal}
          closeModal={() => setEditUserModal(!showEditUserModal)}
          userSelected={userSelected}
          callback={() => setUpdateTable(updateTable + 1)}
        />
      )}

      {showDeleteUserModal && (
        <DeleteUserModal
          showModal={showDeleteUserModal}
          closeModal={() => setDeleteUserModal(!showDeleteUserModal)}
          userSelected={userSelected}
          reqGetAllUsers={() => setUpdateTable(updateTable + 1)}
        />
      )}
    </ContentContainer>
  );
};

export default Page1;
