/* eslint-disable no-shadow */
enum HttpStatus {
  OK = 200,
  CREATED = 201,
  ACCEPTED_WITH_CHANGE = 203,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  PERMISSION_DENIED = 403,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500,
}

export default HttpStatus;
