import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
  newSellerParameterByReferenceReq,
  sellersApi,
  sellersParametersApi,
} from '../../../../../../services/requests';
import { SellerParameter } from '../../../../../../types/apiResponse/Parameters';
import { Seller } from '../../../../../../types/apiResponseTypes';
import {
  SelectOptionSpecValue,
  getOptionsFromSellerParameter,
  getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  callback: () => void;
};

export const NewSellerParameter = ({ showModal, closeModal, callback }: Props) => {
  const { t } = useTranslation();

  const booleanSelectOptions = [
    { value: true, label: t('active') },
    { value: false, label: t('inactive') },
  ];

  const [value, setValue] = useState<any>(undefined);
  const [seller, setSeller] = useState<SelectOptionSpecValue<Seller> | null>(null);
  const [selectedParameterOption, setSelectedParameterOption] =
    useState<SelectOptionSpecValue<SellerParameter> | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedParameterOption) {
      if (selectedParameterOption.value.valueType == 'boolean') {
        if (selectedParameterOption.value.valor === 's') {
          setValue(true);
        } else {
          setValue(false);
        }
      } else {
        setValue(selectedParameterOption.value.valor);
      }
    }
  }, [selectedParameterOption]);

  const saveBtnAction = async () => {
    setIsLoading(true);

    if (!selectedParameterOption) {
      return;
    }

    try {
      newSellerParameterByReferenceReq.query = '';
      const response = await newSellerParameterByReferenceReq.store({
        referenceParameterName: selectedParameterOption.value.parametro,
        sellerCode: seller ? seller.value : '-1',
        companyCode: 1,
        value,
      });

      if (response.status === HttpStatus.OK) {
        if (response.data.success) {
          frontendNotification({
            message: t('success'),
            type: 'success',
          });

          callback();
        }
      } else {
        throw response;
      }

      setIsLoading(false);
    } catch (err) {
      if (!sellersParametersApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setIsLoading(false);
    }
  };

  const allowedValuesToOptions = () => {
    if (selectedParameterOption && selectedParameterOption.value.allowedValues) {
      return selectedParameterOption.value.allowedValues.map((v) => ({
        label: v,
        value: v,
      }));
    }
    return [];
  };

  const handleInputType = () => {
    const numberTypes = ['numeric', 'number', 'double', 'float', 'int', 'integer'];

    if (selectedParameterOption && numberTypes.includes(selectedParameterOption.value.valueType)) {
      return 'number';
    }

    return 'text';
  };

  const handleDisableSaveBtn = () => {
    const firstCheck = !selectedParameterOption;
    const secondCheck = value === undefined;
    const thirdCheck = typeof value === 'string' && value.length == 0;

    return firstCheck || secondCheck || thirdCheck;
  };

  return (
    <Modal
      title={t('newSellerParameter')}
      showModal={showModal}
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => saveBtnAction()}
      isLoading={isLoading}
      style={{ minWidth: '350px' }}
      disableActionBtn={handleDisableSaveBtn()}
    >
      <div className="row">
        <div className="itemFormWithLabel">
          <div className="label">{t('sellerParameter') as string}</div>
          <SelectAsyncJSX
            value={selectedParameterOption}
            onChange={setSelectedParameterOption}
            request={sellersParametersApi}
            query="uniqueNamesOnly=true"
            reqResponseToOption={getOptionsFromSellerParameter}
            placeholder={t('sellerParameter')}
            disabled={isLoading}
          />
        </div>
        <div className="itemFormWithLabel">
          <div className="label">{t('seller') as string}</div>
          <SelectAsyncJSX
            value={seller}
            onChange={setSeller}
            request={sellersApi}
            reqResponseToOption={getOptionsFromSellers}
            placeholder={t('seller')}
            disabled={isLoading}
          />
        </div>
      </div>

      {selectedParameterOption ? (
        <div className="row">
          {selectedParameterOption.value.valueType === 'boolean' ? (
            <div className="row">
              <div className="itemForm">
                <SingleSelect
                  options={booleanSelectOptions}
                  style={{ minWidth: '100%' }}
                  value={value ? booleanSelectOptions[0] : booleanSelectOptions[1]}
                  onChange={(v: any) => setValue(v.value)}
                />
              </div>
            </div>
          ) : (
            <>
              {selectedParameterOption.value.allowedValues ? (
                <div className="row">
                  <div className="itemForm">
                    <SingleSelect
                      options={allowedValuesToOptions()}
                      style={{ minWidth: '100%' }}
                      value={{ value, label: value }}
                      onChange={(v: any) => setValue(v.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="inputWithLabel">
                    <div className="label">
                      {handleInputType() === 'number'
                        ? `${t('value')} (${t('number')})`
                        : t('value')}
                    </div>
                    <input
                      className="input"
                      value={value}
                      onChange={(e: any) => setValue(e.target.value)}
                      disabled={isLoading}
                      type={handleInputType()}
                      min={
                        handleInputType() == 'number'
                          ? selectedParameterOption.value.allowedValuesMinSize
                          : undefined
                      }
                      max={
                        handleInputType() == 'number'
                          ? selectedParameterOption.value.allowedValuesMaxSize
                          : undefined
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default NewSellerParameter;
