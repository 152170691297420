import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../components/Notification';
import HttpStatus from '../../../enums/httpStatus';
import { ediLayoutApi } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { ApiResPaginated } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import { DeleteIcon, EditIcon } from '../../../icons';
import { EdiLayoutPersisted } from '../../../types/apiResponse/edi';
import ModalCreateOrEdit from './Components/ModalCreateOrEdit';
import ConfirmDeleteModal from './Components/ModalDelete';

const LayoutEdiJSX = () => {
  const { t } = useTranslation();

  const [isLoading] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);
  const [jobHistories, setJobHistories] = useState<EdiLayoutPersisted[]>([]);
  const theme = useTheme();

  const [rowTableSelected, setRowTableSelected] = useState<EdiLayoutPersisted | null>(null);
  const [showModalCreateOrEdit, setShowModalCreateOrEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleTableEditBtn = (row: EdiLayoutPersisted) => {
    setRowTableSelected(row);
    setShowModalCreateOrEdit(true);
  };
  const handleTableDeleteBtn = (row: EdiLayoutPersisted) => {
    setRowTableSelected(row);
    setShowModalDelete(true);
  };

  const actionsButtons = (row: EdiLayoutPersisted) => (
    <ActionButtonsContainer type={1}>
      <EditIcon title={t('edit')} onClick={() => handleTableEditBtn(row)} />
      <DeleteIcon title={t('delete')} onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<EdiLayoutPersisted>[] = [
    {
      label: t('description') as string,
      accessor: 'description',
    },
    {
      label: t('type') as string,
      accessor: 'type',
    },
    {
      label: t('folderEdiName') as string,
      accessor: 'folder_edi_name',
    },
    {
      Cell: actionsButtons,
    },
  ];

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = ediLayoutApi.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      ediLayoutApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await ediLayoutApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<EdiLayoutPersisted[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!ediLayoutApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  const handleCallbacks = () => {
    setRowTableSelected(null);
    setUpdateTable(updateTable + 1);
  };

  const handleBtnNewRegister = () => {
    setRowTableSelected(null);
    setShowModalCreateOrEdit(true);
  };

  return (
    <ContentContainer title={t('registrationLayoutEdi')}>
      <NewRegisterBtn
        onClick={() => handleBtnNewRegister()}
        disabled={isLoading}
        style={{ marginLeft: '0', marginBottom: '1rem' }}
      />

      <AsyncTable
        tableName={t('layoutEdi')}
        columns={columns}
        value={jobHistories}
        onChange={setJobHistories}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
          totalPageLabel: `${t('totalPages')}: `,
        }}
      />

      {rowTableSelected && showModalDelete && (
        <ConfirmDeleteModal
          selectedItem={rowTableSelected}
          onClose={() => setShowModalDelete(false)}
          callback={handleCallbacks}
        />
      )}

      {showModalCreateOrEdit && (
        <ModalCreateOrEdit
          selectedItem={rowTableSelected}
          onClose={() => setShowModalCreateOrEdit(false)}
          callback={handleCallbacks}
        />
      )}
    </ContentContainer>
  );
};

export default LayoutEdiJSX;
