import { PropsWithChildren } from 'react';
import TableSkeletonLoader from '../../../../../../../../components/TableSkeletonLoader';
import * as s from './styles';

interface LoaderProps {
  isLoading: boolean;
}

const Loader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
  !isLoading ? (
    <div>{children}</div>
  ) : (
    <s.Pag5Container1>
      <s.TypeGoalTitle1 id="ldg1" />

      <s.GraphArea1 id="ldg1" />

      <s.CardsContainer1>
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
        <s.Card id="ldg1" />
      </s.CardsContainer1>

      <s.TableArea1>
        <TableSkeletonLoader />
      </s.TableArea1>
    </s.Pag5Container1>
  );

export default Loader;
