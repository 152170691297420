import styled from 'styled-components';
import { defaultBorderRadius } from '../../styles/global';

export const CropperArea = styled.div`
  background-color: #000;

  width: 100vw;
  height: 100vh;

  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CropperContent = styled.div`
  background-color: ${(p) => p.theme.colors.background};
  position: relative;
  width: 480px;
  height: 70vh;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media (max-height: 480px) {
    height: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;
  ${defaultBorderRadius}

  #cropper-area {
    position: relative;
    width: 100%;
    flex: 3;
  }

  #control-area {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    #buttons-area-cropper {
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }
`;
