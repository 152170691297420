import numbro from 'numbro';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import TablePaginator from '../../../../../../components/TablePaginator';
import { CustomersCol } from '../../../../../../components/TablePaginator/types';
import * as s from './styles';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlCustomersEtl } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import Loader from './components/Loader';

import { AxiosError } from 'axios';
import ChartDataTable from '../../../../../../components/ChartDataTable';
import { ChartDataTableItem } from '../../../../../../components/ChartDataTable/types';
import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import colorsOrder from '../../../../../../utils/colorsOrder';
import ChartResponsivePie from './components/ChartResponsivePie';

type CustomersReport = {
  customers: CustomersCol[];
  channel_total_gross_amount: number;
};

type ReportData = {
  channels_total_gross_amount: number;
  customers_report: { [x: string]: CustomersReport } | null;
};

const initialReportData: ReportData = {
  channels_total_gross_amount: 0,
  customers_report: null,
};

const Page4 = () => {
  const { t } = useTranslation();
  const { filters } = useFilters();

  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<ReportData>(initialReportData);
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [customersTableData, setCustomersTableData] = useState<CustomersCol[]>([]);
  const [dataForChart, setDataForChart] = useState<ChartDataTableItem[]>([]);

  const getDataFromAPI = async () => {
    const source = dashboardEtlCustomersEtl.axios.CancelToken.source();
    const query = new Query();

    if (filters.filters.years && filters.filters.years.length >= 1) {
      query.whereIn('years', filters.filters.years);
    }
    if (filters.filters.months && filters.filters.months.length >= 1) {
      query.whereIn('months', filters.filters.months);
    }
    if (filters.filters.sellers && filters.filters.sellers.length >= 1) {
      query.whereIn('sellers', filters.filters.sellers);
    }

    try {
      setIsLoading(true);

      const urlQueryParams = query.get() ?? '';
      dashboardEtlCustomersEtl.query = urlQueryParams;

      const apiResponse = await dashboardEtlCustomersEtl.index(source.token);

      setSelectedChannel(t('allChannels'));

      if (apiResponse.status === HttpStatus.OK) {
        if (apiResponse.data.data) {
          const responseData = apiResponse.data.data;

          // Traduzir "No channel"
          if (responseData.products_report['No channel']) {
            const noCategoryData = responseData.products_report['No channel'];
            responseData.products_report[t('noChannel')] = noCategoryData;
            delete responseData.products_report['No channel'];
          }

          setReportData(responseData);
        } else {
          setReportData(initialReportData);

          frontendNotification({
            message: t('noData'),
            type: NotificationTypes.WARNING,
          });
        }
      } else {
        throw apiResponse;
      }
    } catch (err) {
      if (!dashboardEtlCustomersEtl.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column<CustomersCol>[] = useMemo(
    () => [
      {
        Header: t('customer') as string,
        accessor: 'customer',
      },
      {
        Header: t('totalNetAmount') as string,
        accessor: 'total_net_amount',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_net_amount,
            type: 'currencyBR',
          }),
      },
      {
        Header: t('totalGrossAmount') as string,
        accessor: 'total_gross_amount',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_gross_amount,
            type: 'currencyBR',
          }),
      },
      {
        Header: `${t('totalWeight')} (kg)`,
        accessor: 'total_weight',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_weight,
          }),
      },
      {
        Header: t('averageTicket') as string,
        accessor: 'total_average_ticket',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_average_ticket,
            type: 'currencyBR',
          }),
      },
    ],
    [],
  );

  const handleDataForChart = () => {
    const itemsForChart: ChartDataTableItem[] = [];

    if (reportData.customers_report !== null) {
      const customersReport = reportData.customers_report;

      Object.keys(customersReport).forEach((channelName, index) => {
        const value = customersReport[channelName].channel_total_gross_amount;
        itemsForChart.push({
          id: channelName,
          label: channelName,
          value,
          color: colorsOrder[index],
          percent: parseFloat(((value / reportData.channels_total_gross_amount) * 100).toFixed(2)),
        });
      });
    }

    setDataForChart(itemsForChart);
  };

  const handleCustomerDataTable = () => {
    if (reportData.customers_report) {
      // procurar channel, ver se existe
      const hasTheChannelSelected = reportData.customers_report.hasOwnProperty(selectedChannel);

      // se existe channel ai busca so os clientes dela
      if (hasTheChannelSelected) {
        const channel = reportData.customers_report[selectedChannel];
        const customersInTheChannel = channel.customers;
        setCustomersTableData(customersInTheChannel);
      } else {
        // se nao tiver channel pega todos clientes
        const customersOfAllChannels = Object.values(reportData.customers_report).flatMap(
          (ch: any) => ch.customers,
        );
        setCustomersTableData(customersOfAllChannels);
      }
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, [filters]);

  useEffect(() => {
    handleDataForChart();
  }, [reportData]);

  useEffect(() => {
    handleCustomerDataTable();
  }, [selectedChannel, reportData]);

  return (
    <Loader isLoading={isLoading}>
      <s.Pag4Container>
        <s.GraphArea>
          <ChartResponsivePie
            data={dataForChart}
            onClick={(node: any) => setSelectedChannel(node.id)}
            valueFormat={(value: number) =>
              numbro(value).format({
                postfix: '%',
                thousandSeparated: true,
                mantissa: 2,
              })
            }
            clickedChannelName={selectedChannel}
          />
        </s.GraphArea>

        <s.ChannelTableArea>
          <ChartDataTable
            type={t('channels')}
            data={dataForChart}
            onClickRow={(channelName) => setSelectedChannel(channelName as string)}
          />
        </s.ChannelTableArea>

        <s.TableArea>
          <p style={{ fontSize: '1.5rem' }}>
            <b>{t('channels')}:</b> {selectedChannel}
          </p>

          <TablePaginator
            data={customersTableData}
            columns={columns}
            size={6}
            globalFiltering
            downloadXlsx
            showMoreRows
          />
        </s.TableArea>
      </s.Pag4Container>
    </Loader>
  );
};

export default Page4;
