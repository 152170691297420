// ref => https://www.codexworld.com/export-html-table-data-to-excel-using-javascript/

export const tableToXLS = (filename: string) => {
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const newFilename = `${filename.replace(/\s/g, '_')}_${year}-${month}-${day}`;

  let downloadLink = null;
  const dataType = 'application/vnd.ms-excel';
  const tableSelect = document.getElementById('tableDhj');

  const tableHTML = tableSelect?.outerHTML.replace(/ /g, '%20');

  // Create download link element
  downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);

  const navigator = window.navigator as any;

  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob(['\ufeff', tableHTML as BlobPart], {
      type: dataType,
    });
    navigator.msSaveOrOpenBlob(blob, newFilename);
  } else {
    // Create a link to the file
    downloadLink.href = `data:${dataType}, ${tableHTML}`;

    // Setting the file name
    downloadLink.download = newFilename;

    // triggering the function
    downloadLink.click();
  }
};

export default tableToXLS;
