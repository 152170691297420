import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Btn from '../../../components/Buttons/Btn';
import Input from '../../../components/Input';
import LogoutContent from '../../../templates/LogoutContent';

import { UrlPaths, UrlPathsSuper } from '../../../enums/urlPaths.enum';
import { StoreState } from '../../../store/createStore';
import {
  setStatusLoadingLogin,
  superadminSignInRequest,
} from '../../../store/modules/auth/actions';

const SuperSignin = () => {
  const { t } = useTranslation();
  const { loadingSigninRequest, isSignin } = useSelector((state: StoreState) => state.auth);

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const dispacth = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignin) {
      navigate(UrlPathsSuper.HOME);
    }
  }, [isSignin]);

  const enterBtnAction = () => {
    dispacth(superadminSignInRequest({ login, password }));
  };

  useEffect(() => {
    if (loadingSigninRequest) {
      dispacth(setStatusLoadingLogin(false));
    }
  }, []);

  return (
    <LogoutContent title={t('accessAccountAdm')} isLoading={loadingSigninRequest}>
      <div style={{ marginTop: '50px' }}>
        <Input
          value={login}
          onChange={(v: any) => setLogin(v.target.value)}
          label={t('login')}
          style={{ marginBottom: '30px' }}
          placeholder={t('enterYourEmailOrYourUsername')}
        />

        <Input
          value={password}
          onChange={(v: any) => setPassword(v.target.value)}
          label={t('password')}
          placeholder={t('enterYourPassword')}
          type="password"
          showPswBtn
        />

        <Btn
          text={t('enter')}
          style={{ marginTop: '1.5rem', width: '100%' }}
          onClick={enterBtnAction}
        />
        <Btn
          text={t('commonLogin')}
          style={{ marginTop: '1rem', width: '100%' }}
          onClick={() => navigate(UrlPaths.LOGIN)}
          type="link"
        />
      </div>
    </LogoutContent>
  );
};

export default SuperSignin;
