import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  advantageClubBalanceGET,
  clubPointsValueInBrCurrencyApi,
} from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { AdvantageClubBalance as MainCompType } from '../../../../types';

import AsyncTable, { Column } from '../../../../components/AsyncTable';
import Btn from '../../../../components/Buttons/Btn';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import HttpStatus from '../../../../enums/httpStatus';
import getGenericRequestPageForAsyncTable from '../../../../utils/TablesFunctions';
import { currencyBR, numberFormat } from '../../../../utils/helpers';
import WithdrawManualJSX from './components/WithdrawManual';

const AdvantageClubBalanceJSX = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const [balances, setBalances] = useState<MainCompType[]>([]);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [updateTable, setUpdateTable] = useState(0);
  const [clubPointValueInBrCurrency, setClubPointValueInBrCurrency] = useState(0);

  const getPointValueInBrCurrency = async () => {
    setIsLoading(true);

    try {
      const response = await clubPointsValueInBrCurrencyApi.index();

      if (response.status === HttpStatus.OK) {
        if (parseFloat(response.data.data) >= 0) {
          setClubPointValueInBrCurrency(parseFloat(response.data.data));
        } else {
          frontendNotification({
            message: t('errorWhenGettingPointValueInBrCurrency'),
            type: 'error',
          });
        }
      } else {
        frontendNotification({
          message: t('errorWhenGettingPointValueInBrCurrency'),
          type: 'error',
        });
      }
    } catch (err) {
      if (!clubPointsValueInBrCurrencyApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPointValueInBrCurrency();
  }, []);

  const handleRecipientTypeColumn = (row: MainCompType) => <p>{t(row.recipientType)}</p>;

  const handleRecipientNameColumn = (row: MainCompType) => {
    let name = '';

    if (row.recipientType == 'seller' && row.seller) {
      name = row.seller.Nome;
    }

    if (row.recipientType == 'customer' && row.customer) {
      name = row.customer.RazaoSocial;
    }

    return <p>{name}</p>;
  };

  const columns: Column<MainCompType>[] = [
    {
      label: t('recipientType') as string,
      Cell: handleRecipientTypeColumn,
    },
    {
      label: t('recipientCode') as string,
      accessor: 'recipient',
    },
    {
      label: t('name') as string,
      Cell: handleRecipientNameColumn,
    },
    {
      label: t('points') as string,
      accessor: 'pointsBalance',
      Cell: (row) => numberFormat(row.pointsBalance),
    },
    {
      label: t('balance') as string,
      accessor: 'valueBalance',
      Cell: (row) => currencyBR(row.pointsBalance * clubPointValueInBrCurrency),
    },
  ];

  return (
    <ContentContainer title={t('advantageClubCustomersBalance')}>
      {isLoading ? (
        <p>{t('loading')}...</p>
      ) : (
        <>
          <Btn
            text={t('withdrawManual')}
            onClick={() => setShowWithdrawModal(true)}
            style={{ margin: '0 0 1rem 0' }}
            disabled={isLoading}
          />

          <AsyncTable
            tableName={t('advantageClubCustomersBalance')}
            columns={columns}
            value={balances}
            onChange={setBalances}
            requestPage={getGenericRequestPageForAsyncTable(advantageClubBalanceGET)}
            reqListener={[updateTable]}
            options={{
              styles: {
                primaryColor: `${theme.colors.surface}`,
                secondaryColor: `${theme.colors.onSurface}`,
                alternateRowColor: theme.colors.textLight,
                textColor: theme.colors.text,
              },
              quantityPerPageLabel: t('quantityPerPage'),
              searchPlaceholder: t('search'),
            }}
          />

          {showWithdrawModal && (
            <WithdrawManualJSX
              closeModal={() => setShowWithdrawModal(false)}
              callback={() => setUpdateTable(updateTable + 1)}
              valuePointInBrCurrency={clubPointValueInBrCurrency}
            />
          )}
        </>
      )}
    </ContentContainer>
  );
};

export default AdvantageClubBalanceJSX;
