import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';
import { rolesReq } from '../../../../../../services/requests';
import * as s from './styles';

import RenderPermissions from '../RenderPermissions';

export const AddGroupModal = ({ showModal, closeModal, reqGetAllGroups }: any) => {
  const { t } = useTranslation();

  const [groupName, setGroupName] = useState('');
  const [permissionsSelected, setPermissionsSelected] = useState([]);

  const [waitingSaveRolesReq, setWaitingSaveRolesReq] = useState(false);

  const saveBtnAction = async () => {
    setWaitingSaveRolesReq(true);

    try {
      const response = await rolesReq.store({
        name: groupName,
        permissions: permissionsSelected,
      });

      if (
        response.data.status_code === HttpStatus.CREATED ||
        response.data.status_code === HttpStatus.OK
      ) {
        frontendNotification({
          message: t('registeredRole'),
          type: 'success',
        });

        reqGetAllGroups();

        setGroupName('');
        setPermissionsSelected([]);

        closeModal();
      } else {
        throw response.data;
      }

      setWaitingSaveRolesReq(false);
    } catch (err) {
      if (!rolesReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingSaveRolesReq(false);
    }
  };

  return (
    <Modal
      title={t('registerRole')}
      showModal={showModal}
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => saveBtnAction()}
      isLoading={waitingSaveRolesReq}
      style={{ width: '35rem' }}
      bodyStyle={{ overflowX: 'scroll', width: '-webkit-fill-available' }}
    >
      <s.Row style={{ marginBottom: '1rem' }}>
        <s.Item>
          <Input
            value={groupName}
            onChange={(e: any) => setGroupName(e.target.value)}
            label={t('groupName')}
            errorMsg={t('invalidName')}
            disabled={waitingSaveRolesReq}
          />
        </s.Item>
      </s.Row>

      <s.Row style={{ marginBottom: '1rem' }}>
        <s.Item>
          <RenderPermissions
            selectedPermissions={permissionsSelected}
            onChange={(v: any) => setPermissionsSelected(v)}
          />
        </s.Item>
      </s.Row>
    </Modal>
  );
};

export default AddGroupModal;
