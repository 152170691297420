/* eslint-disable max-len */
import React from 'react';

// import { Container } from './stysles';

const Logo = ({ styles, fill }: { styles?: React.CSSProperties; fill?: string }) => {
  const color = fill ?? '#CD1719';
  // return <img src={logo} className={className} alt="logo" />;
  const svgstyles = {
    transform: 'scale(2)',
    ...styles,
  };

  return (
    <svg
      width="84"
      height="38"
      viewBox="0 0 84 38"
      style={svgstyles}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2709 15.117L28.4315 12.0124C27.7247 11.559 27.8578 10.4888 28.6542 10.2224L41.7637 5.83687C42.6166 5.55156 43.3832 6.44929 42.9679 7.247L35.7024 21.2024C35.2236 22.1222 33.8339 21.795 33.8156 20.7583L33.7308 15.9411C33.7249 15.6068 33.5523 15.2976 33.2709 15.117Z"
        fill={color}
      />
      <path
        d="M7.04883 16.5371C7.17773 15.875 6.82031 15.3623 5.97656 14.999L4.53516 14.4014C2.51367 13.4932 1.55273 12.2568 1.65234 10.6924C1.69336 9.97754 1.93945 9.3418 2.39062 8.78516C2.8418 8.22266 3.46582 7.78613 4.2627 7.47559C5.05957 7.16504 5.92969 7.01563 6.87305 7.02734C8.13867 7.05078 9.15527 7.41992 9.92285 8.13477C10.6963 8.84961 11.0771 9.80762 11.0654 11.0088H8.07715C8.09473 10.5166 7.98926 10.124 7.76074 9.83105C7.53809 9.53223 7.16895 9.37695 6.65332 9.36523C6.14941 9.35352 5.7041 9.4707 5.31738 9.7168C4.93066 9.96289 4.70215 10.2939 4.63184 10.71C4.52051 11.2959 4.9541 11.7764 5.93262 12.1514C6.91699 12.5264 7.63184 12.8486 8.07715 13.1182C9.47754 13.9561 10.1338 15.0869 10.0459 16.5107C9.99902 17.249 9.76172 17.8965 9.33398 18.4531C8.90625 19.0098 8.31152 19.4404 7.5498 19.7451C6.79395 20.0498 5.94434 20.1963 5.00098 20.1846C4.30957 20.1729 3.66797 20.0645 3.07617 19.8594C2.48438 19.6543 1.97754 19.3555 1.55566 18.9629C0.700195 18.1719 0.290039 17.1318 0.325195 15.8428L3.33984 15.8516C3.30469 16.5078 3.43066 17.0059 3.71777 17.3457C4.00488 17.6797 4.48828 17.8467 5.16797 17.8467C5.67188 17.8467 6.09082 17.7324 6.4248 17.5039C6.76465 17.2695 6.97266 16.9473 7.04883 16.5371ZM17.1562 7.20312L18.3779 16.0801L22.667 7.20312H26.6748L24.46 20H21.4365L21.9814 16.8623L23.2822 11.0791L18.7998 20H16.8047L15.3545 10.7451L14.5459 17.1611L14.0449 20H11.0391L13.2539 7.20312H17.1562Z"
        fill={color}
      />
      <path
        d="M47.4844 15.4824H45.8145L45.0234 20H42.0176L44.2324 7.20312L48.75 7.21191C50.1973 7.21191 51.3105 7.55176 52.0898 8.23145C52.875 8.90527 53.2266 9.82812 53.1445 11C53.0801 11.9258 52.8135 12.6934 52.3447 13.3027C51.876 13.9062 51.2197 14.3838 50.376 14.7354L52.0986 19.8506V20H48.8906L47.4844 15.4824ZM46.2188 13.1006L47.9766 13.1182C48.457 13.1182 48.873 13.001 49.2246 12.7666C49.582 12.5322 49.8369 12.2129 49.9893 11.8086C50.1416 11.3984 50.1855 11.0029 50.1211 10.6221C50.0039 9.97168 49.5879 9.62891 48.873 9.59375L46.834 9.58496L46.2188 13.1006ZM64.623 9.58496H60.8965L59.0947 20H56.0801L57.8906 9.58496H54.2256L54.6475 7.20312H65.0361L64.623 9.58496Z"
        fill={color}
      />
      <path
        d="M49.0488 31.5371C49.1777 30.875 48.8203 30.3623 47.9766 29.999L46.5352 29.4014C44.5137 28.4932 43.5527 27.2568 43.6523 25.6924C43.6934 24.9775 43.9395 24.3418 44.3906 23.7852C44.8418 23.2227 45.4658 22.7861 46.2627 22.4756C47.0596 22.165 47.9297 22.0156 48.873 22.0273C50.1387 22.0508 51.1553 22.4199 51.9229 23.1348C52.6963 23.8496 53.0771 24.8076 53.0654 26.0088H50.0771C50.0947 25.5166 49.9893 25.124 49.7607 24.8311C49.5381 24.5322 49.1689 24.377 48.6533 24.3652C48.1494 24.3535 47.7041 24.4707 47.3174 24.7168C46.9307 24.9629 46.7021 25.2939 46.6318 25.71C46.5205 26.2959 46.9541 26.7764 47.9326 27.1514C48.917 27.5264 49.6318 27.8486 50.0771 28.1182C51.4775 28.9561 52.1338 30.0869 52.0459 31.5107C51.999 32.249 51.7617 32.8965 51.334 33.4531C50.9062 34.0098 50.3115 34.4404 49.5498 34.7451C48.7939 35.0498 47.9443 35.1963 47.001 35.1846C46.3096 35.1729 45.668 35.0645 45.0762 34.8594C44.4844 34.6543 43.9775 34.3555 43.5557 33.9629C42.7002 33.1719 42.29 32.1318 42.3252 30.8428L45.3398 30.8516C45.3047 31.5078 45.4307 32.0059 45.7178 32.3457C46.0049 32.6797 46.4883 32.8467 47.168 32.8467C47.6719 32.8467 48.0908 32.7324 48.4248 32.5039C48.7646 32.2695 48.9727 31.9473 49.0488 31.5371ZM61.6963 29.5771H56.9941L56.458 32.627H62.0215L61.6084 35H53.0391L55.2539 22.2031H63.8408L63.4277 24.585H57.8555L57.3809 27.2832H62.0918L61.6963 29.5771ZM66.3193 32.627H71.5312L71.1182 35H62.9004L65.1152 22.2031H68.1299L66.3193 32.627ZM75.8818 32.627H81.0938L80.6807 35H72.4629L74.6777 22.2031H77.6924L75.8818 32.627Z"
        fill={color}
      />
    </svg>
  );
};

Logo.defaultProps = {
  styles: {},
  fill: '#CD1719',
};

export default Logo;
