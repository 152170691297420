import { ResponsivePie } from '@nivo/pie';
import { useTheme } from 'styled-components';
import colorsOrder from '../../../../../../../../utils/colorsOrder';

const GraphResponsivePie = ({ data, onClick, valueFormat }: any) => {
  const theme = useTheme();

  return (
    <ResponsivePie
      layers={['arcs', 'arcLabels']}
      data={data}
      onClick={onClick}
      valueFormat={valueFormat}
      margin={{
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
      }}
      innerRadius={0.5}
      padAngle={0}
      cornerRadius={0}
      borderWidth={0}
      animate
      colors={colorsOrder}
      arcLabelsTextColor="#fff"
      theme={{
        tooltip: {
          container: {
            fontSize: '13px',
            background: theme.colors.surface,
            color: theme.colors.onSurface,
          },
        },
      }}
    />
  );
};

export default GraphResponsivePie;
