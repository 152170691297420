import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Filters from '../../../../components/GlobalFilters';
import ContentContainter from '../../../../templates/Content';
import OutContent from '../../../../templates/OutContent';

import NoPermission from '../../../../components/NoPermission';
import checkPermission from '../../../../utils/checkPermission';

import VisitHistory from './Pages/Historic';
import Page1 from './Pages/Main';

const Main = () => {
  const { t } = useTranslation();

  const [tabSelected] = useState({
    value: 'scheduledVisits',
    name: 'scheduledVisits',
  });

  const handleViewPag = (pagName: string) => {
    let pageSelected = <Page1 />;

    switch (pagName) {
      case 'scheduledVisits':
        pageSelected = <Page1 />;
        break;
      default:
        pageSelected = <VisitHistory />;
        break;
    }

    return pageSelected;
  };

  if (!checkPermission('dashboard-view')) {
    return <NoPermission />;
  }

  return (
    <>
      <OutContent>
        <Filters />

        {/* <NavMenu items={navMenuItems} /> */}
      </OutContent>

      <ContentContainter title={t(tabSelected.name)}>
        {handleViewPag(tabSelected.value)}
      </ContentContainter>
    </>
  );
};

export default Main;
