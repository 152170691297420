import { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { StoreState } from '../../store/createStore';
import LogoutBtn from '../Buttons/LogoutBtn';
import ChangeLanguage from '../SelectLanguage';
import ToggleTheme from '../SelectTheme';
import MobileBar from './MobileBar';
import RenderItem from './RenderItem';
import * as s from './styles';
import * as tp from './types';

const SideBar = ({ items }: tp.SidebarITF) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { user } = useSelector((state: StoreState) => state.auth);

  const titleHoverText = user ? `${user.name} - ${user.email}` : process.env.REACT_APP_NAME;

  useEffect(() => {
    if (window.screen.width < 900) {
      setIsSidebarOpen(false);
    }
  }, [window.screen]);

  const toggleIsSiderbarOpen = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <>
      <MobileBar isSidebarOpen={isSidebarOpen} toggleIsSiderbarOpen={toggleIsSiderbarOpen} />

      <s.SidebarContainer isSidebarOpen={isSidebarOpen}>
        <s.HeaderSidebar className="sidebar-header">
          <h1 id="sidebar-title" title={titleHoverText}>
            {process.env.REACT_APP_NAME}
          </h1>
          <div className="expand-button">
            {isSidebarOpen ? (
              <FaAngleDoubleLeft onClick={toggleIsSiderbarOpen} />
            ) : (
              <FaAngleDoubleRight onClick={toggleIsSiderbarOpen} />
            )}
          </div>
        </s.HeaderSidebar>

        <s.ItemsContainer>
          {items.map((item) => {
            // Verifica se o item deve ser mostrado:
            // Para isso verifica a chave "show" do proprio item
            // e tambem se pelo menos 1 subitem tem show=true
            // pois se nenhum submenu é mostrado, o menu principal tbm nao sera mostrado
            const shouldShowItem =
              item.show &&
              (!item.sidebarSubItems || item.sidebarSubItems.some((subItem) => subItem.show));

            if (shouldShowItem) {
              return (
                <RenderItem
                  item={item}
                  key={item.title}
                  isSidebarOpen={isSidebarOpen}
                  closeSidebar={() => setIsSidebarOpen(false)}
                />
              );
            }
          })}
        </s.ItemsContainer>

        <s.FooterSidebar isSidebarOpen={isSidebarOpen}>
          <ToggleTheme />
          <ChangeLanguage />
          <LogoutBtn />
        </s.FooterSidebar>
      </s.SidebarContainer>
    </>
  );
};

export default SideBar;
