import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { ediRemoveCustomersApi } from '../../../../../services/requests';
import { EdiCustomer } from '../../../../../types/apiResponse/edi';

type Props = {
  onClose: () => void;
  callback: () => void;
  selectedCustomers: EdiCustomer[] | null;
};

const RemoveCustomersTSX = ({ onClose, callback, selectedCustomers }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveBtn = useCallback(async () => {
    setIsLoading(true);

    const data: any = {};
    if (selectedCustomers) {
      data.customerCodes = selectedCustomers.map((option) => option.customerCode as string);
    }

    const source = ediRemoveCustomersApi.axios.CancelToken.source();

    try {
      ediRemoveCustomersApi.query = '';
      const res = await ediRemoveCustomersApi.store(data);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: 'success',
        });

        callback();
        onClose();
      } else {
        throw res;
      }
    } catch (err) {
      if (!ediRemoveCustomersApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);

    return () => source.cancel('Component Roles got unmounted');
  }, [selectedCustomers]);

  return (
    <Modal
      title={t('removeCustomers')}
      action={handleSaveBtn}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={t('toRemove')}
      isLoading={isLoading}
      bodyStyle={{
        overflowX: 'scroll',
        width: '100%',
      }}
      // style={{ minWidth: '50%', minHeight: '30vh' }}
    >
      <h3>{`${t('deleteSure') as string}?`}</h3>
      <>
        {selectedCustomers && selectedCustomers.length > 0 && (
          <p>
            {selectedCustomers.length} {` ${t('customer')}(s)`}
          </p>
        )}
      </>
    </Modal>
  );
};

export default RemoveCustomersTSX;
