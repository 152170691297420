/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../components/Notification';
import { NotificationTypes } from '../../../../components/Notification/notificationEnums';
import history from '../../../../services/history';
import { superAccessControlTenantMetricsApi } from '../../../../services/requests';
import { StoreState } from '../../../../store/createStore';
import ContentContainer from '../../../../templates/Content';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import Btn from '../../../../components/Buttons/Btn';
import HttpStatus from '../../../../enums/httpStatus';
import { UrlPaths } from '../../../../enums/urlPaths.enum';
import {
  AccessControl,
  AccessControlTenantMetrics,
} from '../../../../types/apiResponse/accessControl';
import { ApiResPaginated } from '../../../../types/apiResponseTypes';
import { datetimeLocalToTimestamp, getCurrentDateTime } from '../../../../utils/helpers';

const AccessControlTenantsMetricsTSX = () => {
  const { user } = useSelector((state: StoreState) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();

  const [updateTable, setUpdateTable] = useState(0);

  const [accessControl, setAccessControl] = useState<AccessControl[]>([]);

  const [startDate, setStartDate] = useState<string>(getCurrentDateTime(30, 'sub'));
  const [endDate, setEndDate] = useState<string>(getCurrentDateTime());

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = superAccessControlTenantMetricsApi.axios.CancelToken.source();

    try {
      superAccessControlTenantMetricsApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      superAccessControlTenantMetricsApi.query += `&startDate=${datetimeLocalToTimestamp(startDate)}`;
      superAccessControlTenantMetricsApi.query += `&endDate=${datetimeLocalToTimestamp(endDate)}`;

      const res = await superAccessControlTenantMetricsApi.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<AccessControl[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }
      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!superAccessControlTenantMetricsApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      frontendNotification({
        message: 'Área restrita!',
        type: NotificationTypes.WARNING,
      });

      history.push(UrlPaths.LOGIN);
    }
  }, [user]);

  const handleTotalColumn = (v: AccessControlTenantMetrics) => {
    const smartsellWebTotal = v.smartsell_web_active_users;
    const smartsellMobileTotal = v.smartsell_mobile_active_users;
    return (smartsellWebTotal + smartsellMobileTotal) as unknown as string;
  };

  const columns: Column<AccessControlTenantMetrics>[] = [
    {
      label: t('tenantId') as string,
      accessor: 'tenant_id',
    },
    {
      label: t('tenantName') as string,
      accessor: 'tenant_name',
    },
    {
      label: t('smartsellMobile') as string,
      accessor: 'smartsell_mobile_active_users',
    },
    {
      label: t('smartsellWeb') as string,
      accessor: 'smartsell_web_active_users',
    },
    {
      label: t('total') as string,
      Cell: (v) => handleTotalColumn(v),
    },
  ];

  const handleFilterBtn = () => {
    setUpdateTable(updateTable + 1);
  };

  return (
    <ContentContainer title={`${t('accessControl')}: ${t('tenantsMetrics')}`}>
      <div className="row" style={{ justifyContent: 'end' }}>
        <div className="inputWithLabel" style={{ maxWidth: '200px' }}>
          <div className="label">{t('startDate') as string}</div>
          <input
            className="input"
            type="datetime-local"
            value={startDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
          />
        </div>

        <div className="inputWithLabel" style={{ maxWidth: '200px' }}>
          <div className="label">{t('endDate') as string}</div>
          <input
            className="input"
            type="datetime-local"
            value={endDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
          />
        </div>

        <div className="inputWithLabel" style={{ maxWidth: 'max-content', marginRight: 0 }}>
          <div className="label">{''}</div>
          <Btn text={t('filter')} onClick={() => handleFilterBtn()} style={{ marginRight: 0 }} />
        </div>
      </div>

      <AsyncTable
        tableName={`${t('accessControl')}: ${t('tenantsMetrics')}`}
        columns={columns}
        value={accessControl}
        onChange={setAccessControl}
        requestPage={getItems}
        reqListener={[updateTable]}
        options={{
          styles: {
            primaryColor: `${theme.colors.surface}`,
            secondaryColor: `${theme.colors.onSurface}`,
            alternateRowColor: theme.colors.textLight,
            textColor: theme.colors.text,
          },
          quantityPerPageLabel: t('quantityPerPage'),
          searchPlaceholder: t('search'),
          totalPageLabel: `${t('totalPages')}: `,
        }}
      />
    </ContentContainer>
  );
};

export default AccessControlTenantsMetricsTSX;
