import { createContext, useMemo, useState } from 'react';

type FiltersProps = {
  filters: {
    years?: string[];
    months?: string[];
    goalType?: string;
    sellers?: string[];
    customers?: string[];
    isXformatByDays?: boolean;
    startPeriod?: string | null;
    endPeriod?: string | null;
  };
};

type FilterHandlerProps = {
  [x: string]: string | string[] | null;
};

export interface FiltersContextData {
  filters: FiltersProps;
  handleSetFilterState: (f: FilterHandlerProps) => void;
}

const FiltersContext = createContext<FiltersContextData>({} as FiltersContextData);

type Props = {
  children: JSX.Element;
};

const FiltersContextProvider = ({ children }: Props) => {
  const [filters, setFilters] = useState<FiltersProps>({
    filters: {
      years: [],
      months: [],
      sellers: [],
      customers: [],
      goalType: '',
      isXformatByDays: false,
    },
  });

  const handleSetFilterState = (f: FilterHandlerProps) => {
    const { month } = f;
    const isXformatByDays = !!month;

    setFilters((prev) => ({
      filters: {
        ...prev.filters,
        isXformatByDays,
        ...f,
      },
    }));
  };

  const menoProps = useMemo(() => ({ filters, handleSetFilterState }), [filters]);

  return <FiltersContext.Provider value={menoProps}>{children as never}</FiltersContext.Provider>;
};

export { FiltersContext, FiltersContextProvider };
