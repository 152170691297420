import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '../../store/createStore';
import * as s from './style';

// import Vetor from './Vetor';
import logoImg from '../../assets/images/main/logo.svg';
import Btn from '../../components/Buttons/Btn';
import { UrlPaths, UrlPathsSuper } from '../../enums/urlPaths.enum';

const Notfound = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: StoreState) => state.auth);
  const home = user?.isSuperAdmin ? UrlPathsSuper.HOME : UrlPaths.HOME;

  return (
    <s.ContainerNotFound>
      <s.ContentNotFound>
        <img src={logoImg} alt="Smartsell BI logo" width={280} />
        <p style={{ fontSize: '2rem' }}>{t('pageNotFound')}</p>
        <Link to={home}>
          <Btn text={t('backToHome')} style={{ marginTop: '1rem' }} />
        </Link>
      </s.ContentNotFound>
    </s.ContainerNotFound>
  );
};

export default Notfound;
