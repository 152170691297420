import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
  actionsReq,
  commAutoReq,
  commGroupReq,
  orderOriginReq,
  templatesReq,
} from '../../../../../../services/requests';
import { Template } from '../../../../../../types/apiResponse/template';
import * as TP from '../../../../../../types/apiResponseTypes';
import {
  getOptionsFromActions,
  getOptionsFromCommGroups,
  getOptionsFromOrderOrigins,
  getOptionsFromTemplates,
} from '../../../../../../utils/getSelectOptions';
import * as s from './styles';

type Props = {
  closeModal: () => void;
  reqGetCommAuto: () => void;
  commAutoSelected: TP.CommAutomation;
};

type Option = {
  value: any;
  label: string | number;
};

const CreateMotive = ({ closeModal, reqGetCommAuto, commAutoSelected }: Props) => {
  const { t } = useTranslation();

  const [waitingResponse, setWaitingReponse] = useState(false);
  const [waitingGetNecessaryData, setWaitingGetNecessaryData] = useState(false);

  const [description, setDescription] = useState('');
  const [actions, setActions] = useState<TP.Action[] | []>([]);
  const [orderOrigins, setOrderOrigins] = useState<TP.OrderOrigin[] | []>([]);
  const [commGroups, setCommGroups] = useState<TP.CommunicationGroup[] | []>([]);
  const [templates, setTemplates] = useState<Template[] | []>([]);

  const [actionsSelected, setActionsSelected] = useState<Option | null>(null);
  const [orderOriginsSelected, setOrderOriginsSelected] = useState<Option | null>(null);
  const [commGroupsSelected, setCommGroupsSelected] = useState<Option | null>(null);
  const [templatesSelected, setTemplatesSelected] = useState<Option | null>(null);

  const reqNecessaryData = async () => {
    setWaitingGetNecessaryData(true);
    const source = actionsReq.axios.CancelToken.source();

    try {
      const res1 = await actionsReq.index();
      const res2 = await orderOriginReq.index();
      const res3 = await commGroupReq.index();
      const res4 = await templatesReq.index();

      if (res1.status === HttpStatus.OK) {
        setActions(res1.data.data);
      } else {
        throw res1;
      }

      if (res2.status === HttpStatus.OK) {
        setOrderOrigins(res2.data.data);
      } else {
        throw res2;
      }

      if (res3.status === HttpStatus.OK) {
        setCommGroups(res3.data.data);
      } else {
        throw res3;
      }

      if (res4.status === HttpStatus.OK) {
        setTemplates(res4.data.data);
      } else {
        throw res4;
      }

      setWaitingGetNecessaryData(false);
    } catch (err) {
      if (!actionsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingGetNecessaryData(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  useEffect(() => {
    setDescription(commAutoSelected.description);
    setActionsSelected({
      value: commAutoSelected.action_id,
      label: commAutoSelected.action ? commAutoSelected.action.label : commAutoSelected.action_id,
    });

    setOrderOriginsSelected({
      value: commAutoSelected.order_origin_id,
      label: commAutoSelected.order_origin
        ? commAutoSelected.order_origin.label
        : commAutoSelected.order_origin_id,
    });

    setCommGroupsSelected({
      value: commAutoSelected.communication_group_id,
      label: commAutoSelected.communication_group
        ? commAutoSelected.communication_group.description
        : commAutoSelected.communication_group_id,
    });

    setTemplatesSelected({
      value: commAutoSelected.template_id,
      label: commAutoSelected.template
        ? commAutoSelected.template.name
        : commAutoSelected.template_id,
    });

    reqNecessaryData();
  }, []);

  const reqCreate = async () => {
    setWaitingReponse(true);

    const source = commAutoReq.axios.CancelToken.source();

    try {
      const res = await commAutoReq.update(
        {
          description,
          action_id: actionsSelected?.value,
          order_origin_id: orderOriginsSelected?.value,
          communication_group_id: commGroupsSelected?.value,
          template_id: templatesSelected?.value,
        },
        commAutoSelected.id,
      );

      if (res.status === HttpStatus.OK) {
        setWaitingReponse(false);
        frontendNotification({
          message: t('successfullyUpdated'),
          type: 'success',
        });

        closeModal();
        reqGetCommAuto();
      } else {
        throw res;
      }

      setWaitingReponse(false);
    } catch (err) {
      if (!commAutoReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingReponse(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={t('register')}
      showModal
      closeModal={closeModal}
      action={() => reqCreate()}
      isLoading={waitingResponse || waitingGetNecessaryData}
    >
      <s.ContainerModalEdit>
        <s.Line>
          <s.Item>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              disabled={waitingResponse}
              placeholder={t('description')}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <SingleSelect
              value={actionsSelected}
              onChange={(op) => setActionsSelected(op as Option)}
              disabled={waitingResponse}
              placeholder={t('action')}
              options={getOptionsFromActions(actions)}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <SingleSelect
              value={orderOriginsSelected}
              onChange={(op) => setOrderOriginsSelected(op as Option)}
              disabled={waitingResponse}
              placeholder={t('orderOrigin')}
              options={getOptionsFromOrderOrigins(orderOrigins)}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <SingleSelect
              value={commGroupsSelected}
              onChange={(op) => setCommGroupsSelected(op as Option)}
              disabled={waitingResponse}
              placeholder={t('audience')}
              options={getOptionsFromCommGroups(commGroups)}
            />
          </s.Item>
        </s.Line>

        <s.Line>
          <s.Item>
            <SingleSelect
              value={templatesSelected}
              onChange={(op) => setTemplatesSelected(op as Option)}
              disabled={waitingResponse}
              placeholder={t('template')}
              options={getOptionsFromTemplates(templates)}
            />
          </s.Item>
        </s.Line>
      </s.ContainerModalEdit>
    </Modal>
  );
};

export default CreateMotive;
