import { css } from 'styled-components';
// import { defaultShadow } from './global';

export const contentLoader = css`
  .ldg1,
  #ldg1 {
    /* box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15); */
    /* border-radius: 4px; */
    /* height: 200px; */
    box-shadow: none;
    position: relative;
    overflow: hidden;
    background: ${(p) => (p.theme.title === 'DARK' ? '#424242' : '#eaeced')};

    ::before {
      content: '';
      display: block;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        to right,
        transparent 0%,
        ${(p) => (p.theme.title === 'DARK' ? '#727272' : '#ffffff')} 50%,
        transparent 100%
      );
      animation: load 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }

    @keyframes load {
      from {
        left: -100%;
      }
      to {
        left: 100%;
      }
    }
  }
`;

export default contentLoader;
