import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import history from '../../../services/history';
import { runMigrationsAPI, runSeedsAPI, tenantsReq } from '../../../services/requests';
import { StoreState } from '../../../store/createStore';
import ContentContainer from '../../../templates/Content';
import Loader from './Loader';
import * as s from './styles';
import * as tp from './types';

import Btn from '../../../components/Buttons/Btn';

import AddTenantModal from './components/AddTenantModal';
import DeleteTenantModal from './components/DeleteTenantModal';
import EditTenantModal from './components/EditTenantModal';
import CreateUsersFromSellersJob from './components/RunCreateUsersJob';
import ViewDBMOdal from './components/ViewDBModal';

import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths } from '../../../enums/urlPaths.enum';
import {
  CreateUserIcon,
  DbIcon,
  DeleteIcon,
  EditIcon,
  ListIcon,
  RunAgainIcon,
  RunIcon,
} from '../../../icons';
import { ApiResPaginated } from '../../../types/apiResponseTypes';
import ViewAccessLogModal from './components/ViewAccessLogModal';

const Tenant = () => {
  const { user } = useSelector((state: StoreState) => state.auth);
  const { t } = useTranslation();
  const theme = useTheme();

  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [showEditTenantModal, setShowEditTenantModal] = useState(false);
  const [showDeleteTenantModal, setShowDeleteTenantModal] = useState(false);
  const [showViewDBModal, setViewDBModal] = useState(false);
  const [showCreateUsersModal, setShowCreateUsersModal] = useState(false);
  const [isViewAccessLogModalVisible, setIsViewAccessLogModalVisible] = useState(false);

  const [waitingReqGetTenants, setWaitinReqGetTenants] = useState(true);

  const [tenantsList, setTenantsList] = useState<tp.Tenant[]>([]);
  const [tenantSelected, setTenantSelected] = useState<tp.Tenant | Record<string, never>>({});

  const [updateTable] = useState(0);

  const reqGetTenants = async () => {
    setWaitinReqGetTenants(true);
    const source = tenantsReq.axios.CancelToken.source();
    try {
      const res = await tenantsReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        setTenantsList(res.data.data.reverse());
      } else {
        throw res;
      }
    } catch (err) {
      if (!tenantsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitinReqGetTenants(false);
    return () => source.cancel('Component Roles got unmounted');
  };

  useEffect(() => {
    if (!user?.isSuperAdmin) {
      frontendNotification({
        message: 'Área restrita!',
        type: NotificationTypes.WARNING,
      });

      history.push(UrlPaths.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    reqGetTenants();
  }, []);

  const handleTableDBBtn = (row: tp.Tenant) => {
    if (row.extra_data === null) {
      frontendNotification({
        message: t('databaseUndefined'),
        type: 'warning',
      });
      return;
    }
    setTenantSelected(row);
    setViewDBModal(true);
  };
  const handleTableEditBtn = (row: tp.Tenant) => {
    setTenantSelected(row);
    setShowEditTenantModal(true);
  };
  const handleTableDeleteBtn = (row: tp.Tenant) => {
    setTenantSelected(row);
    setShowDeleteTenantModal(true);
  };
  const handleTableCreateSellersBtn = (row: tp.Tenant) => {
    setTenantSelected(row);
    setShowCreateUsersModal(true);
  };
  const handleViewAccessLogBtn = (row: tp.Tenant) => {
    setTenantSelected(row);
    setIsViewAccessLogModalVisible(true);
  };

  const handleOnCloseModals = () => {
    setShowAddTenantModal(false);
    setShowEditTenantModal(false);
    setViewDBModal(false);
    setShowEditTenantModal(false);
    setShowDeleteTenantModal(false);
    setShowCreateUsersModal(false);
    setIsViewAccessLogModalVisible(false);

    setTenantSelected({});
  };

  const runMigrations = async (row?: tp.Tenant) => {
    setWaitinReqGetTenants(true);
    const source = runMigrationsAPI.axios.CancelToken.source();
    try {
      runMigrationsAPI.query = '';
      const res = await runMigrationsAPI.store({
        ...(row && { tenantIds: [row.id] }),
      });

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: res.data.message,
          type: 'success',
        });
      } else {
        throw res;
      }
    } catch (err) {
      if (!runMigrationsAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitinReqGetTenants(false);
    return () => source.cancel('Component nmounted');
  };

  const runSeeds = async (row?: tp.Tenant) => {
    setWaitinReqGetTenants(true);
    const source = runSeedsAPI.axios.CancelToken.source();
    try {
      runSeedsAPI.query = '';
      const res = await runSeedsAPI.store({
        ...(row && { tenantIds: [row.id] }),
      });

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: res.data.message,
          type: 'success',
        });
      } else {
        throw res;
      }
    } catch (err) {
      if (!runSeedsAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitinReqGetTenants(false);
    return () => source.cancel('Component nmounted');
  };

  const actionsButtons = (row: tp.Tenant) => (
    <ActionButtonsContainer type={4}>
      <DbIcon title={t('database')} onClick={() => handleTableDBBtn(row)} />
      <EditIcon title={t('edit')} onClick={() => handleTableEditBtn(row)} />
      <RunIcon title={t('runMigrations')} onClick={() => runMigrations(row)} />
      <RunAgainIcon title={t('runSeed')} onClick={() => runSeeds(row)} />
      <CreateUserIcon title={t('createUsers')} onClick={() => handleTableCreateSellersBtn(row)} />
      <ListIcon title={t('accessLog')} onClick={() => handleViewAccessLogBtn(row)} />
      <DeleteIcon title={t('delete')} onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<tp.Tenant>[] = [
    {
      label: t('id') as string,
      accessor: 'id',
    },
    {
      label: t('name') as string,
      accessor: 'name',
    },
    {
      label: t('email') as string,
      accessor: 'email',
    },
    {
      label: t('database') as string,
      accessor: 'database',
    },
    {
      label: 'actionsbtn',
      Cell: (row) => actionsButtons(row),
    },
  ];

  const getItems: RequestPage = async ({ search, page, quantityPerPage }) => {
    let hasMore = true;
    const source = tenantsReq.axios.CancelToken.source();

    try {
      // eslint-disable-next-line max-len
      tenantsReq.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
      const res = await tenantsReq.index(source.token);

      if (res.status !== HttpStatus.OK) {
        throw res;
      }

      const { data, paginated }: ApiResPaginated<tp.Tenant[]> = res.data;

      if (data.length === 0) {
        hasMore = false;
      }

      return {
        data,
        hasMore,
        totalPage: paginated.totalPage,
      };
    } catch (err) {
      if (!tenantsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }

      return {
        data: [],
        hasMore: false,
        totalPage: 0,
      };
    }
  };

  return (
    <ContentContainer title={t('companies')}>
      <s.ButtonArea>
        <Btn text={t('registerTenant')} onClick={() => setShowAddTenantModal(true)} />
        <Btn text={t('runMigrations')} onClick={() => runMigrations()} />
        <Btn text={t('runSeed')} onClick={() => runSeeds()} />
      </s.ButtonArea>

      <Loader isLoading={waitingReqGetTenants}>
        <AsyncTable
          tableName={t('accessControl')}
          columns={columns}
          value={tenantsList}
          onChange={setTenantsList}
          requestPage={getItems}
          reqListener={[updateTable]}
          options={{
            styles: {
              primaryColor: `${theme.colors.surface}`,
              secondaryColor: `${theme.colors.onSurface}`,
              alternateRowColor: theme.colors.textLight,
              textColor: theme.colors.text,
            },
            quantityPerPageLabel: t('quantityPerPage'),
            searchPlaceholder: t('search'),
            totalPageLabel: `${t('totalPages')}: `,
          }}
        />

        {showAddTenantModal && (
          <AddTenantModal
            showModal={showAddTenantModal}
            closeModal={handleOnCloseModals}
            reqGetTenants={reqGetTenants}
          />
        )}

        {showEditTenantModal && (
          <EditTenantModal
            showModal={showEditTenantModal}
            closeModal={handleOnCloseModals}
            reqGetTenants={reqGetTenants}
            tenantSelected={tenantSelected as tp.Tenant}
            title={t('registerTenant')}
          />
        )}

        {showDeleteTenantModal && (
          <DeleteTenantModal
            showModal={showDeleteTenantModal}
            closeModal={handleOnCloseModals}
            tenantSelected={tenantSelected as tp.Tenant}
            title={tenantSelected.name}
            actionNameBtn={t('confirm')}
            reqGetTenants={reqGetTenants}
          />
        )}

        {showCreateUsersModal && (
          <CreateUsersFromSellersJob
            showModal={showCreateUsersModal}
            closeModal={handleOnCloseModals}
            tenantSelected={tenantSelected as tp.Tenant}
            title={tenantSelected.name}
            actionNameBtn={t('confirm')}
            reqGetTenants={reqGetTenants}
          />
        )}

        {showViewDBModal && (
          <ViewDBMOdal
            showModal={showViewDBModal}
            closeModal={handleOnCloseModals}
            dbInfos={tenantSelected.extra_data as any}
            title={tenantSelected.name}
            onlyCloseButton
          />
        )}

        {isViewAccessLogModalVisible && tenantSelected && (
          <ViewAccessLogModal
            showModal={isViewAccessLogModalVisible}
            closeModal={handleOnCloseModals}
            tenantId={tenantSelected.id as any}
            title={`${t('accessLog')} - ${tenantSelected.name}`}
            onlyCloseButton
          />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default Tenant;
