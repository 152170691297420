import styled from 'styled-components';

export const Container = styled.div`
  width: 100% !important;
  max-width: 100% !important;
  min-height: 30px !important;

  .codeEditor {
    box-sizing: border-box;
    border-radius: 5px !important;
    border: 1px solid ${(p) => p.theme.colors.onBackground}50 !important;
    resize: both;
    max-width: 100% !important;
  }
`;

export default Container;
