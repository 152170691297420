import styled from 'styled-components';
import { defaultShadow, defaultShadowHover } from '../../styles/global';

export interface CardContainerProps {
  background?: string;
  clickDisabled?: boolean | undefined;
}

export const CardContainer = styled.div<CardContainerProps>`
  flex: 1;
  min-width: 120px;
  max-width: 175px;
  max-height: 230px;

  border: none;
  border-radius: 8px !important;
  ${defaultShadow}
  margin: 0.5rem;

  &::before {
    content: '';
    width: 10px;

    background: ${(p) => p.background};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & svg {
    color: ${(p) => p.background};
  }

  ${(p) =>
    !p.clickDisabled &&
    `
        cursor: pointer;
        :hover {
            ${defaultShadowHover}
        }
    `};

  ${(p) =>
    p.clickDisabled &&
    `
        opacity: 0.7;
    `};

  .values-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  .progress-circle-area {
    display: flex;
    justify-content: center;
    margin-top: 0.2rem;

    .circle {
      max-width: 50%;
    }
  }
`;

export const CardHeader = styled.div<CardContainerProps>`
  background: ${(p) => p.background ?? p.theme.colors.primary};
  color: ${(p) => p.theme.colors.onPrimary};

  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;

  padding: 0.1rem 0 0.1rem 0;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
`;

export const CardBody = styled.div`
  padding: 1rem;
`;

export const CardTitle = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.1rem;
`;

export const CardText = styled.span`
  font-size: 0.8rem;
  padding-top: 3px;
`;

export const InfoButtonArea = styled.div`
  width: max-content;
  margin-left: auto;
  margin-right: 5px;
`;
