import { applyMiddleware, compose, createStore, Middleware, Reducer } from 'redux';
import { AuthAction, AuthState } from './modules/auth/types';
import { ThemeAction, ThemeState } from './modules/theme/types';

export interface StoreState {
  theme: ThemeState;
  auth: AuthState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type StoreAction = AuthAction | ThemeAction;

export default (reducers: Reducer<StoreState, StoreAction>, middlewares: Middleware[]) => {
  const enhacer = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(reducers, enhacer);
};
