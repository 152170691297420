/* eslint-disable camelcase */
import numbro from 'numbro';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import TablePaginator from '../../../../../../components/TablePaginator';
import { ProductsCol } from '../../../../../../components/TablePaginator/types';
import * as s from './styles';
// import { currencyBR, formatNumber } from '../../../../../utils/helpers';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlProductsEtl } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import Loader from './components/Loader';

import { AxiosError } from 'axios';
import ChartDataTable from '../../../../../../components/ChartDataTable';
import { ChartDataTableItem } from '../../../../../../components/ChartDataTable/types';
import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import colorsOrder from '../../../../../../utils/colorsOrder';
import GraphResponsivePie from './components/ChartResponsivePie';

type ProductsReport = {
  products: ProductsCol[];
  category_total_gross_amount: number;
};

type ReportData = {
  categories_total_gross_amount: number;
  products_report: { [x: string]: ProductsReport } | null;
};

const initalPage3State: ReportData = {
  categories_total_gross_amount: 0,
  products_report: null,
};

const Page3 = () => {
  const { t } = useTranslation();
  const { filters } = useFilters();

  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<ReportData>(initalPage3State);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [productTableData, setProductTableData] = useState<ProductsCol[]>([]);
  const [dataForChart, setDataForChart] = useState<ChartDataTableItem[]>([]);

  const getDataFromAPI = async () => {
    const source = dashboardEtlProductsEtl.axios.CancelToken.source();
    const query = new Query();

    if (filters.filters.years && filters.filters.years.length >= 1) {
      query.whereIn('years', filters.filters.years);
    }
    if (filters.filters.months && filters.filters.months.length >= 1) {
      query.whereIn('months', filters.filters.months);
    }
    if (filters.filters.sellers && filters.filters.sellers.length >= 1) {
      query.whereIn('sellers', filters.filters.sellers);
    }

    try {
      setIsLoading(true);

      const urlQueryParams = query.get() ?? '';
      dashboardEtlProductsEtl.query = urlQueryParams;

      const apiResponse = await dashboardEtlProductsEtl.index(source.token);

      setSelectedCategory(t('allCategories'));

      if (apiResponse.status === HttpStatus.OK) {
        if (apiResponse.data.data) {
          const responseData = apiResponse.data.data;

          // Traduzir "No category"
          if (responseData.products_report['No category']) {
            const noCategoryData = responseData.products_report['No category'];
            responseData.products_report[t('noCategory')] = noCategoryData;
            delete responseData.products_report['No category'];
          }

          setReportData(responseData);
        } else {
          setReportData(initalPage3State);

          frontendNotification({
            message: t('noData'),
            type: NotificationTypes.WARNING,
          });
        }
      } else {
        throw apiResponse;
      }
    } catch (err) {
      if (!dashboardEtlProductsEtl.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column<ProductsCol>[] = useMemo(
    () => [
      {
        Header: t('name') as string,
        accessor: 'name',
      },
      {
        Header: t('code') as string,
        accessor: 'code',
      },
      {
        Header: t('quantity') as string,
        accessor: 'total_qty',
      },
      {
        Header: t('totalNetAmount') as string,
        accessor: 'total_net_amount',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_net_amount,
            type: 'currencyBR',
          }),
      },
      {
        Header: t('totalGrossAmount') as string,
        accessor: 'total_gross_amount',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_gross_amount,
            type: 'currencyBR',
          }),
      },
      {
        Header: `${t('totalWeight')} (kg)`,
        accessor: 'total_weight',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_weight,
          }),
      },
      {
        Header: t('averageTicket') as string,
        accessor: 'total_average_ticket',
        Cell: (v) =>
          FormartNumberJSX({
            number: v.row.original.total_average_ticket,
            type: 'currencyBR',
          }),
      },
    ],
    [],
  );

  const handleDataForChart = () => {
    const itemsForChart: ChartDataTableItem[] = [];

    if (reportData.products_report !== null) {
      const productsReport = reportData.products_report;

      Object.keys(productsReport).forEach((categoryName, index) => {
        const value = productsReport[categoryName].category_total_gross_amount;
        itemsForChart.push({
          id: categoryName,
          label: categoryName,
          value,
          color: colorsOrder[index],
          percent: parseFloat(
            ((value / reportData.categories_total_gross_amount) * 100).toFixed(2),
          ),
        });
      });
    }

    setDataForChart(itemsForChart);
  };

  const handleProductDataTable = () => {
    if (reportData.products_report) {
      // procurar categoria, ver se existe
      const hasTheCategorySelected = reportData.products_report.hasOwnProperty(selectedCategory);

      // se existe categoria ai busca so os produtos dela
      if (hasTheCategorySelected) {
        const category = reportData.products_report[selectedCategory];
        const productsInTheCategory = category.products;
        setProductTableData(productsInTheCategory);
      } else {
        // se nao tiver categoria pega todos produtos
        const productsOfAllCategories = Object.values(reportData.products_report).flatMap(
          (cat: any) => cat.products,
        );
        setProductTableData(productsOfAllCategories);
      }
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, [filters]);

  useEffect(() => {
    handleDataForChart();
  }, [reportData]);

  useEffect(() => {
    handleProductDataTable();
  }, [selectedCategory, reportData]);

  return (
    <Loader isLoading={isLoading}>
      <s.Pag3Container>
        <s.GraphArea>
          <GraphResponsivePie
            data={dataForChart}
            onClick={(node: any) => setSelectedCategory(node.id)}
            valueFormat={(value: number) =>
              numbro(value).format({
                postfix: '%',
                thousandSeparated: true,
                mantissa: 2,
              })
            }
            clickedCategoryName={selectedCategory}
          />
        </s.GraphArea>

        <s.CategoryTableArea>
          <ChartDataTable
            type={t('category')}
            data={dataForChart}
            onClickRow={(categoryName) => setSelectedCategory(categoryName as string)}
          />
        </s.CategoryTableArea>

        <s.TableArea>
          <p style={{ fontSize: '1.5rem' }}>
            <b>{t('categories')}:</b> {selectedCategory}
          </p>

          <TablePaginator
            data={productTableData}
            columns={columns}
            size={6}
            globalFiltering
            downloadXlsx
            showMoreRows
          />
        </s.TableArea>
      </s.Pag3Container>
    </Loader>
  );
};

export default Page3;
