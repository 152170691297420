import { useState } from 'react';
import { Order } from '../../../../../../types/apiResponse/order';
import { ColumnConfig } from '../constants/defaultColumnsConfig';
import ColumnsVisibilityToggle from './ColumnsVisibilityToggle';
import TableRowEffect from './TableRowEffect';

type Props = {
  orders: Order[];
  columnsConfig: ColumnConfig[];
  onlyFixed?: boolean;
  className?: string;
};

const RenderOrderTableData = ({
  orders,
  columnsConfig,
  onlyFixed = false,
  className = '',
}: Props) => {
  const [areColumnsVisible, setAreColumnsVisible] = useState(true);

  const renderColumnValue = (order: Order, columnKey: keyof Order) => {
    const value = order[columnKey];

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    if (typeof value === 'boolean') {
      return value ? 'Sim' : 'Não';
    }

    if (value === null || value === undefined) {
      return '-'; // Valor nulo ou indefinido
    }

    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    return '';
  };

  const handleColumnsAreaClass = () => {
    return areColumnsVisible ? '' : 'display-none';
  };

  if (orders.length === 0) {
    return null;
  }

  const hasNoFixedColumn = columnsConfig.every((column) => !column.fixed);

  if (onlyFixed && hasNoFixedColumn) {
    return null;
  }

  return (
    <div className={`orders-table-container ${className}`}>
      <ColumnsVisibilityToggle visible={areColumnsVisible} setVisible={setAreColumnsVisible} />

      <TableRowEffect numberOfrows={orders.length} />

      <div className="order-table-header-division" />

      <div className={`orders-table-columns-area ${handleColumnsAreaClass()}`}>
        {columnsConfig.map((columnConfig) => {
          if (onlyFixed && !columnConfig.fixed) {
            return null;
          }

          if (!onlyFixed && !columnConfig.visible) {
            return null;
          }

          return (
            <div className="orders-table-column" key={columnConfig.key}>
              <div
                className={`orders-table-column-header
                  ${columnConfig.subItems.length > 0 ? '' : 'visibility-hidden'}
                `}
                style={{
                  textAlign: columnConfig.subItems.length > 0 ? 'center' : 'left',
                }}
              >
                {columnConfig.label}
              </div>

              <div className="orders-table-sub-columns-area">
                {/* Renderiza sub-itens se houver, ou cria uma
                estrutura padrão se não houver sub-itens */}
                {columnConfig.subItems.length > 0 ? (
                  columnConfig.subItems.map((subItem) => (
                    <div className="orders-table-sub-colmun" key={subItem.key}>
                      <div className="orders-table-sub-colmun-header">{subItem.label}</div>
                      <div className="orders-table-data-list-area">
                        {orders.map((order, index) => (
                          <div className="orders-table-row" key={index}>
                            <div className="orders-table-row-value">
                              {renderColumnValue(order, subItem.key as keyof Order)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="orders-table-sub-colmun">
                    <div className="orders-table-sub-colmun-header">{columnConfig.label}</div>
                    <div className="orders-table-data-list-area">
                      {orders.map((order, index) => (
                        <div className="orders-table-row" key={index}>
                          <div className="orders-table-row-value">
                            {renderColumnValue(order, columnConfig.key)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RenderOrderTableData;
