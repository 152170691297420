import { t } from 'i18next';

import { ChangeEvent, useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import LocalStorage from '../../../../../../enums/localStorage';
import usePersistedState from '../../../../../../hooks/usePersistedState';
import {
  billingUnitApi,
  branchApi,
  customersApi,
  orderTypesApi,
  sellersApi,
  tenantManagerApi,
  tenantSupervisorApi,
} from '../../../../../../services/requests';
import { Customer, Seller } from '../../../../../../types';
import { BillingUnit } from '../../../../../../types/apiResponse/BillingUnit';
import { Branch } from '../../../../../../types/apiResponse/Branch';
import { OrdersFiltersInFrontend, OrderType } from '../../../../../../types/apiResponse/order';
import { TenantManager } from '../../../../../../types/apiResponse/TenantManager';
import { TenantSupervisor } from '../../../../../../types/apiResponse/TenantSupervisor';
import {
  exportedErpSelectOptions,
  filterTypeSelectOptions,
  getBooleanOptions,
  getOptionByValue,
  getSelectOptionFromBoolean,
  orderFreightTypeSelectOptions,
  orderOriginSelectOptions,
  orderStatusSelectOptions,
  SelectOption,
} from '../../../../../../utils/getSelectOptions';
import { defaultOrderFilters } from '../constants/defaultColumnsConfig';

type Props = {
  closeModal: () => void;
  setStoredOrderFilters: (orderFilters: OrdersFiltersInFrontend) => void;
  isLoading: boolean;
};

const OrderFiltersModal = ({ closeModal, setStoredOrderFilters, isLoading }: Props) => {
  const [storedOrderFilters] = usePersistedState<OrdersFiltersInFrontend>(
    LocalStorage.ORDER_FILTERS,
    defaultOrderFilters,
  );

  const [orderFilters, setOrderFilters] = useState<OrdersFiltersInFrontend>(storedOrderFilters);

  const [tenantSupervisorQuery, setTenantSupervisorQuery] = useState<string | undefined>();
  const [sellerQuery, setSellerQuery] = useState<string | undefined>();
  const [customerQuery, setCustomerQuery] = useState<string | undefined>();

  const resetFilters = () => {
    setOrderFilters(defaultOrderFilters);
  };

  useEffect(() => {
    if (orderFilters.tenantManagerCode) {
      setTenantSupervisorQuery(`tenantManagerCode=${orderFilters.tenantManagerCode}`);
    } else {
      setTenantSupervisorQuery(undefined);
    }

    if (orderFilters.tenantSupervisorCode) {
      setSellerQuery(`tenantSupervisorCode=${orderFilters.tenantSupervisorCode}`);
    } else {
      setSellerQuery(undefined);
    }

    if (orderFilters.sellerCode) {
      setCustomerQuery(`sellerCode=${orderFilters.sellerCode}`);
    } else {
      setCustomerQuery(undefined);
    }
  }, [orderFilters.tenantManagerCode, orderFilters.tenantSupervisorCode, orderFilters.sellerCode]);

  const handleAsyncSelectValue = (
    codeKey: keyof OrdersFiltersInFrontend,
    descriptionKey: keyof OrdersFiltersInFrontend,
  ): SelectOption | null => {
    if (orderFilters[codeKey] && orderFilters[descriptionKey]) {
      return {
        label: `${orderFilters[codeKey]} - ${orderFilters[descriptionKey]}`,
        value: orderFilters[codeKey] as string,
      };
    }
    return null;
  };

  const handleAsyncSelectOnChange = (
    codeKey: keyof OrdersFiltersInFrontend,
    descriptionKey: keyof OrdersFiltersInFrontend,
  ) => {
    // Retorna uma função que lida com o `onChange`
    return (option: SelectOption | null) => {
      if (option) {
        setOrderFilters({
          ...orderFilters,
          [codeKey]: option.value as string,
          [descriptionKey]: option.label.replace(`${option.value} - `, ''),
        });
      } else {
        setOrderFilters({
          ...orderFilters,
          [codeKey]: '',
          [descriptionKey]: '',
        });
      }
    };
  };

  return (
    <Modal
      title={t('tableConfig')}
      showModal={true}
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => setStoredOrderFilters(orderFilters)}
      actionNameBtn={t('toFilter')}
      additionalActions={[{ name: t('clearFilters'), onClick: () => resetFilters() }]}
      bodyStyle={orderFilters.filterType === 'detailed' ? { overflow: 'scroll' } : {}}
    >
      <>
        <div className="row" style={{ justifyContent: 'flex-start', marginTop: '-0.5rem' }}>
          <div className="inputWithLabel" style={{ marginTop: 0, maxWidth: '200px' }}>
            <p className="label">{t('filterType') as string}</p>
            <SingleSelect
              options={filterTypeSelectOptions()}
              onChange={(option: SelectOption) =>
                setOrderFilters({ ...orderFilters, filterType: option.value as any })
              }
              value={getOptionByValue(orderFilters.filterType, filterTypeSelectOptions())}
              disabled={isLoading}
            />
          </div>

          {orderFilters.filterType == 'simple' && (
            <div className="inputWithLabel">
              <p className="label">{t('orderNumber') as string}</p>
              <input
                onChange={(e: any) =>
                  setOrderFilters({ ...orderFilters, orderNumber: e.target.value })
                }
                value={orderFilters.orderNumber}
                className="input"
                disabled={isLoading}
              />
            </div>
          )}
        </div>

        {orderFilters.filterType == 'detailed' && (
          <>
            <div className="row">
              <div className="inputWithLabel">
                <p className="label">{t('orderStatus') as string}</p>
                <SingleSelect
                  options={orderStatusSelectOptions()}
                  onChange={(option: SelectOption) =>
                    setOrderFilters({ ...orderFilters, orderStatus: option.value as any })
                  }
                  value={getOptionByValue(orderFilters.orderStatus, orderStatusSelectOptions())}
                  style={{ minWidth: '120px' }}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('isBudget') as string}</p>
                <SingleSelect
                  options={getBooleanOptions()}
                  onChange={(option: any) =>
                    setOrderFilters({ ...orderFilters, isBudget: option.value as any })
                  }
                  value={getSelectOptionFromBoolean(orderFilters.isBudget as boolean)}
                  style={{ minWidth: '120px' }}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <div className="label">{t('orderType') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('orderTypeCode', 'orderTypeDescription')}
                  onChange={handleAsyncSelectOnChange('orderTypeCode', 'orderTypeDescription')}
                  request={orderTypesApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: OrderType) =>
                        `${item.CodigoTipoPed} - ${item.DescricaoTipoPed}`,
                      value: (item: OrderType) => item.CodigoTipoPed,
                    },
                  }}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('orderOrigin') as string}</p>
                <SingleSelect
                  options={orderOriginSelectOptions()}
                  onChange={(option: SelectOption) =>
                    setOrderFilters({ ...orderFilters, orderOriginCode: option.value as any })
                  }
                  value={getOptionByValue(orderFilters.orderOriginCode, orderOriginSelectOptions())}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputWithLabel">
                <div className="label">{t('manager') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('tenantManagerCode', 'tenantManagerDescription')}
                  onChange={handleAsyncSelectOnChange(
                    'tenantManagerCode',
                    'tenantManagerDescription',
                  )}
                  request={tenantManagerApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: TenantManager) => `${item.ger_Codigo} - ${item.ger_Nome}`,
                      value: (item: TenantManager) => item.ger_Codigo,
                    },
                  }}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <div className="label">{t('supervisor') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue(
                    'tenantSupervisorCode',
                    'tenantSupervisorDescription',
                  )}
                  onChange={handleAsyncSelectOnChange(
                    'tenantSupervisorCode',
                    'tenantSupervisorDescription',
                  )}
                  request={tenantSupervisorApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: TenantSupervisor) => `${item.sup_Codigo} - ${item.sup_Nome}`,
                      value: (item: TenantSupervisor) => item.sup_Codigo,
                    },
                  }}
                  query={tenantSupervisorQuery}
                  cacheUniqs={[tenantSupervisorQuery]}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <div className="label">{t('seller') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('sellerCode', 'sellerName')}
                  onChange={handleAsyncSelectOnChange('sellerCode', 'sellerName')}
                  request={sellersApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: Seller) => `${item.CodigoVendedor} - ${item.Nome}`,
                      value: (item: Seller) => item.CodigoVendedor,
                    },
                  }}
                  query={sellerQuery}
                  cacheUniqs={[sellerQuery]}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <div className="label">{t('customer') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('customerCode', 'customerName')}
                  onChange={handleAsyncSelectOnChange('customerCode', 'customerName')}
                  request={customersApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: Customer) => `${item.CodigoCliente} - ${item.RazaoSocial}`,
                      value: (item: Customer) => item.CodigoCliente,
                    },
                  }}
                  query={customerQuery}
                  cacheUniqs={[customerQuery]}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputWithLabel">
                <div className="label">{t('branch') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('orderTypeCode', 'orderTypeDescription')}
                  onChange={handleAsyncSelectOnChange('orderTypeCode', 'orderTypeDescription')}
                  request={branchApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: Branch) => `${item.filial_Codigo} - ${item.filial_Descricao}`,
                      value: (item: Branch) => item.filial_Codigo,
                    },
                  }}
                  disabled
                />
              </div>

              <div className="inputWithLabel">
                <div className="label">{t('billingUnit') as string}</div>
                <SelectAsyncJSX
                  value={handleAsyncSelectValue('billingUnitCode', 'billingUnitDescription')}
                  onChange={handleAsyncSelectOnChange('billingUnitCode', 'billingUnitDescription')}
                  request={billingUnitApi}
                  reqResponseToOption={{
                    mapper: {
                      label: (item: BillingUnit) =>
                        `${item.CodigoUnidFat} - ${item.DescricaoUnidFat}`,
                      value: (item: BillingUnit) => item.CodigoUnidFat,
                    },
                  }}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('freightType') as string}</p>
                <SingleSelect
                  options={orderFreightTypeSelectOptions()}
                  onChange={(option: SelectOption) =>
                    setOrderFilters({ ...orderFilters, freightType: option.value as any })
                  }
                  value={getOptionByValue(
                    orderFilters.freightType,
                    orderFreightTypeSelectOptions(),
                  )}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('exportedOrder') as string}</p>
                <SingleSelect
                  options={exportedErpSelectOptions()}
                  onChange={(option: SelectOption) =>
                    setOrderFilters({ ...orderFilters, exportedErp: option.value as any })
                  }
                  value={getOptionByValue(orderFilters.exportedErp, exportedErpSelectOptions())}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputWithLabel">
                <p className="label">{t('orderCreationDate') as string}</p>
                <p className="label">{t('start') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.initialOrderCreationDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, initialOrderCreationDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
                <p className="label">{t('end') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.finalOrderCreationDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, finalOrderCreationDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('orderSubmissionDate') as string}</p>
                <p className="label">{t('start') as string}</p>
                <input
                  className="input"
                  type="datetime-local"
                  value={orderFilters.initialOrderSendDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, initialOrderSendDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
                <p className="label">{t('end') as string}</p>
                <input
                  className="input"
                  type="datetime-local"
                  value={orderFilters.finalOrderSendDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, finalOrderSendDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('orderDeliveryDate') as string}</p>
                <p className="label">{t('start') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.initialOrderDeliveryDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, initialOrderDeliveryDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
                <p className="label">{t('end') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.finalOrderDeliveryDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, finalOrderDeliveryDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
              </div>

              <div className="inputWithLabel">
                <p className="label">{t('orderBillingDate') as string}</p>
                <p className="label">{t('start') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.initialOrderBillingDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, initialOrderBillingDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
                <p className="label">{t('end') as string}</p>
                <input
                  className="input"
                  type="date"
                  value={orderFilters.finalOrderBillingDate}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOrderFilters({ ...orderFilters, finalOrderBillingDate: e.target.value })
                  }
                  style={isLoading ? { opacity: '0.5' } : {}}
                  disabled={isLoading}
                />
              </div>
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export default OrderFiltersModal;
