import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { goalsReq } from '../../../../../../services/requests';
import { Goal } from '../../../../../../types/apiResponse/goal';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../components/Notification';
import HttpStatus from '../../../../../../enums/httpStatus';

type Props = ModalProps & {
  reqGetAllGoals: () => void;
  closeModal: () => void;
  goalSelected: Goal;
};

const DeleteGoalModal = ({ reqGetAllGoals, closeModal, goalSelected, ...props }: Props) => {
  const { t } = useTranslation();

  const [awaitReqDeleteGoals, setAwaitReqDeleteGoals] = useState(false);

  const reqDeleteGoal = async () => {
    setAwaitReqDeleteGoals(true);
    try {
      const { id } = goalSelected;

      goalsReq.query = '';
      const response = await goalsReq.delete(id as number);

      if (response.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        reqGetAllGoals();
        closeModal();
      } else {
        throw response;
      }
    } catch (err) {
      if (!goalsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setAwaitReqDeleteGoals(false);
  };

  return (
    <Modal
      {...props}
      action={() => reqDeleteGoal()}
      isLoading={awaitReqDeleteGoals}
      closeModal={closeModal}
      cancelButton={closeModal}
    >
      <p>{`${t('deleteSure')} (${t('goal')}):`}</p>
      <b>
        {goalSelected.date?.substring(0, 7)} -{goalSelected.seller?.Nome}
        {goalSelected.customer?.RazaoSocial}{' '}
      </b>
    </Modal>
  );
};

export default DeleteGoalModal;
