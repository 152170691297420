import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { mixCustomersRemoveApi } from '../../../../../services/requests';
import { MixCustomer } from '../../../../../types/apiResponse/customer';

type Props = {
  onClose: () => void;
  callback: () => void;
  selectedMix: MixCustomer;
};

const RemoveMixesTSX = ({ onClose, callback, selectedMix }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const removeRequest = useCallback(async () => {
    setIsLoading(true);

    const source = mixCustomersRemoveApi.axios.CancelToken.source();

    try {
      mixCustomersRemoveApi.query = '';
      const res = await mixCustomersRemoveApi.store({
        customerCode: selectedMix.customer.code,
        sellerCode: selectedMix.seller.code,
      });

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('success'),
          type: 'success',
        });

        callback();
        onClose();
      } else {
        throw res;
      }
    } catch (err) {
      if (!mixCustomersRemoveApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);

    return () => source.cancel('Component Roles got unmounted');
  }, [selectedMix]);

  return (
    <Modal
      title={t('toRemove')}
      action={removeRequest}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={t('toRemove')}
      isLoading={isLoading}
      bodyStyle={{
        overflowX: 'scroll',
        width: '100%',
      }}
      // style={{ minWidth: '50%', minHeight: '30vh' }}
    >
      <h3>{t('deleteSureMixCustomer') as string}</h3>
      <p>
        <b>{t('customer') as string}:</b> {selectedMix.customer.code} - {selectedMix.customer.name}
      </p>
      <p>
        <b>{t('seller') as string}:</b> {selectedMix.seller.code} - {selectedMix.seller.name}
      </p>
    </Modal>
  );
};

export default RemoveMixesTSX;
