import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../../../components/Notification';
import HttpStatus from '../../../../../../../enums/httpStatus';
import { motivesWithActionDelete } from '../../../../../../../services/requests';
import * as TP from '../../../../../../../types/apiResponseTypes';

type Props = {
  closeModal: () => void;
  reqGetAllMotivesActionOrigin: () => void;
  itemSelected: TP.MotiveAction;
};

const DeleteMotiveActionOrigin = ({
  closeModal,
  reqGetAllMotivesActionOrigin,
  itemSelected,
}: Props) => {
  const { t } = useTranslation();

  const [waitingDeleteMotives, setWaitingDeleteMotive] = useState(false);

  const reqDeleteMotiveActionOrigin = async () => {
    setWaitingDeleteMotive(true);

    const source = motivesWithActionDelete.axios.CancelToken.source();

    try {
      const res = await motivesWithActionDelete.store({
        motive_id: itemSelected.motive_id,
        action_id: itemSelected.action_id,
        order_origin_id: itemSelected.order_origin_id,
        communication_group_id: itemSelected.communication_group_id,
      });

      if (res.status === HttpStatus.OK) {
        setWaitingDeleteMotive(false);
        frontendNotification({
          message: t('successfullyDeleted'),
          type: 'success',
        });

        closeModal();
        reqGetAllMotivesActionOrigin();
      } else {
        throw res;
      }

      setWaitingDeleteMotive(false);
    } catch (err) {
      if (!motivesWithActionDelete.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
      setWaitingDeleteMotive(false);
    }

    return () => {
      source.cancel('Component got unmounted');
    };
  };

  return (
    <Modal
      title={`${t('delete')}`}
      showModal
      closeModal={closeModal}
      action={() => reqDeleteMotiveActionOrigin()}
      actionNameBtn={t('delete')}
      isLoading={waitingDeleteMotives}
    >
      <p>
        {`${t('deleteSure')}?`}
        <br />
        <br />
        <b>{t('motive')}:</b> {itemSelected.motive.description}
        <br />
        <br />
        <b>{t('action')}:</b> {itemSelected.action.label}
        <br />
        <br />
        <b>{t('origin')}:</b> {itemSelected.order_origin.label}
        <br />
        <br />
        <b>{t('communicationGroup')}:</b> {itemSelected.communication_group.description}
      </p>
    </Modal>
  );
};

export default DeleteMotiveActionOrigin;
