import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Column, Row } from 'react-table';
import { useTheme } from 'styled-components';

import { useTranslation } from 'react-i18next';

import { Log as tpLog } from '../../../types/apiResponseTypes';
import * as s from './styles';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { logsReq } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';

import TablePaginator from '../../../components/TablePaginator';
import HttpStatus from '../../../enums/httpStatus';
import i18n from '../../../i18n';
import Loader from './Loader';

import ViewLog from '../../../components/ViewLog';

const SystemLogs = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [viewLog, setViewLog] = useState(false);

  const [waitingReqGetIntegrationModels, setWaitinReqGetIntegrationModels] = useState(true);

  const [logs, setLogs] = useState([]);
  const [logSelected, setLogSelected] = useState<tpLog | Record<string, never>>({});

  const reqGetIntegrationModels = async () => {
    setWaitinReqGetIntegrationModels(true);
    const source = logsReq.axios.CancelToken.source();
    try {
      const res = await logsReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        setLogs(res.data.data.reverse());
      } else {
        throw res;
      }
    } catch (err) {
      if (!logsReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitinReqGetIntegrationModels(false);
    return () => source.cancel('Component Roles got unmounted');
  };

  useEffect(() => {
    reqGetIntegrationModels();
  }, []);

  const handleTableViewBtn = (row: Row<tpLog>) => {
    setLogSelected(row.original);
    setViewLog(true);
  };
  const handleTableCopyBtn = (row: Row<tpLog>) => {
    navigator.clipboard.writeText(JSON.stringify(row.original));
    frontendNotification({
      message: 'Copiado com sucesso',
      type: 'success',
    });
  };

  const actionsButtons = (row: Row<tpLog>) => (
    <s.ActionButtonsContainer>
      <s.RiEyeLineIcon onClick={() => handleTableViewBtn(row)} />
      <s.MdFileCopyIcon onClick={() => handleTableCopyBtn(row)} />
    </s.ActionButtonsContainer>
  );

  const logsMemo = useMemo(() => logs, [logs]);

  const typeJSX = (row: Row<tpLog>) =>
    row.original.is_error ? (
      <RiErrorWarningFill style={{ color: theme.colors.negative }} />
    ) : (
      <p>
        <RiErrorWarningFill style={{ color: theme.colors.warning }} />
      </p>
    );

  const columns: Column<tpLog>[] = useMemo(
    () => [
      {
        Header: t('type') as string,
        accessor: 'is_error',
        Cell: ({ row }: any) => typeJSX(row),
        style: { textAlign: 'center' },
      },
      {
        Header: t('id') as string,
        accessor: 'id',
      },
      {
        Header: t('user') as string,
        accessor: 'userName',
      },
      {
        Header: t('tenant') as string,
        accessor: 'tenantName',
      },
      {
        Header: t('message') as string,
        accessor: 'message',
        style: { overflow: 'hidden', whiteSpace: 'nowrap' },
      },
      {
        Header: t('createdAt') as string,
        accessor: 'created_at',
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('companies')}>
      <Loader isLoading={waitingReqGetIntegrationModels}>
        <TablePaginator data={logsMemo} columns={columns} showMoreRows globalFiltering />
      </Loader>

      {viewLog && (
        <ViewLog
          showModal={viewLog}
          closeModal={() => setViewLog(false)}
          log={JSON.stringify(logSelected, null, 2)}
        />
      )}
    </ContentContainer>
  );
};

export default SystemLogs;
