import styled from 'styled-components';
import { contentLoader } from '../../styles/ContentLoader';
import { defaultBorderRadius } from '../../styles/global';

export const TableSkeletonContainer = styled.div`
  ${contentLoader}
`;

export const Table = styled.div`
  width: 100%;
  ${defaultBorderRadius}
  background-color:  ${(p) => p.theme.colors.onSurface}15 !important;
  overflow: hidden;

  .line {
    height: 50px;
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;

    position: relative;

    &.header {
      height: 50px;
      background-color: ${(p) => p.theme.colors.onSurface}10;
    }

    .headerV {
      height: 15px;
      flex: 1;
      margin: 0 2rem 0 2rem;
      background-color: ${(p) => p.theme.colors.surface};
      border-radius: 0.2rem;
    }

    &#light {
      background-color: ${(p) => p.theme.colors.onSurface}10;
    }

    &::after {
      content: '';
      width: 100%;
      border: 1px solid ${(p) => p.theme.colors.onSurface}10;
      position: absolute;
      bottom: 0;
    }
  }
`;

export const Value = styled.div`
  height: 1rem;
  flex: 1;
  margin: 0 1rem 0 1rem;
  background-color: ${(p) => p.theme.colors.onSurface}10;
  border-radius: 0.2rem;
`;

export const Search = styled.div`
  width: 245px;
  height: 35px;
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const Bottons = styled.div`
  /* margin-top: 1rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  .arrowBtns {
    display: flex;
    .arrowBtn {
      margin: 0.2rem;
      width: 22.5px;
      height: 22.5px;

      border-radius: 3px;
    }
  }

  .selectPage {
    display: flex;

    .selectPageItem {
      height: 30px;
      margin: 0.2rem;
      border-radius: 3px;

      &.a {
        width: 50px;
      }
      &.b {
        width: 90px;
      }
    }
  }
`;

export default TableSkeletonContainer;
