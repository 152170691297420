import Modal from '../Modal';
import ViewPrettyJson from '../ViewPrettyJson';

const ViewLog = ({ showModal, closeModal, log }: any) => (
  <Modal
    title="Log"
    showModal={showModal}
    action={() => null}
    closeModal={closeModal}
    onlyCloseButton
    bodyStyle={{ overflow: 'scroll', width: '100%' }}
  >
    <ViewPrettyJson json={log as JSON} />
  </Modal>
);

export default ViewLog;
