import { PropsWithChildren } from 'react';

interface LoaderProps {
  isLoading: boolean;
  CustomLoader: (() => JSX.Element) | undefined;
}

const TemplateLoader = ({ isLoading, children, CustomLoader }: PropsWithChildren<LoaderProps>) => {
  const Rows = () => {
    const rowItems = [];

    for (let i = 0; i < 5; i += 1) {
      rowItems.push(
        <li className="row" key={i}>
          <div className="valueSkeleton" />
          <div className="valueSkeleton" />
          <div className="valueSkeleton" />
          <div className="valueSkeleton" />
        </li>,
      );
    }

    return rowItems;
  };

  const LoaderComp = () => {
    if (CustomLoader !== undefined) {
      return CustomLoader();
    }

    return (
      <div className="tableSkeletonContainer">
        <div className="tableSkeleton" id="ldg1">
          <li className="row header">
            <div className="valueSkeleton headerValue" />
            <div className="valueSkeleton headerValue" />
            <div className="valueSkeleton headerValue" />
            <div className="valueSkeleton headerValue" />
          </li>

          {Rows()}
        </div>
      </div>
    );
  };

  return !isLoading ? <div>{children}</div> : LoaderComp();
};

export default TemplateLoader;
