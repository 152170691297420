import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../../components/Notification';
import * as s from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import { parametersApi } from '../../../../services/requests';
import { Parameter } from '../../../../types/apiResponse/Parameters';
import Loader from './Loader';

import ContentContainter from '../../../../templates/Content';

import AsyncTable, { Column } from '../../../../components/AsyncTable';
import NoData from '../../../../components/NoData';
import getGenericRequestPageForAsyncTable from '../../../../utils/TablesFunctions';
import ChangeParameterValue from './Components/ChangeParameterValue';

const Parameters = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [parameterSelected, setParameterSelected] = useState<Parameter | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const reqGetParameters = useCallback(async () => {
    const source = parametersApi.axios.CancelToken.source();
    setIsLoading(true);

    try {
      const response = await parametersApi.index(source.token);

      if (response.status === HttpStatus.OK) {
        setParameters(response.data.data.reverse());
      } else {
        throw response;
      }
    } catch (err) {
      if (!parametersApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
    return () => source.cancel('Component got unmounted');
  }, []);

  useEffect(() => {
    reqGetParameters();
  }, []);

  const handleTableEditBtn = (row: Parameter) => {
    setParameterSelected(row);
    setShowEditModal(true);
  };

  const actionsButtons = (row: Parameter) => (
    <s.ActionButtonsContainer>
      <s.EditIconTable onClick={() => handleTableEditBtn(row)} />
    </s.ActionButtonsContainer>
  );

  const handleValueColumn = (row: Parameter) => {
    if (row.value_type === 'boolean') {
      if (row.boolean_value) {
        return <div>{t('yes')}</div>;
      } else {
        return <div>{t('no')}</div>;
      }
    }

    return <div>{row.valor}</div>;
  };

  const columns: Column<Parameter>[] = [
    {
      label: t('parameter') as string,
      accessor: 'parametro',
    },
    {
      label: t('value') as string,
      accessor: 'valor',
      Cell: handleValueColumn,
    },
    {
      label: t('description') as string,
      accessor: 'descricao',
      cellStyle: {
        maxWidth: '200px',
      },
    },
    {
      label: t('actions') as string,
      Cell: actionsButtons,
    },
  ];

  const data = useMemo(() => parameters, [parameters]);

  return (
    <ContentContainter title={t('parameters')}>
      <Loader isLoading={isLoading}>
        {data && data.length >= 1 ? (
          <AsyncTable
            tableName={t('parameters')}
            columns={columns}
            value={parameters}
            onChange={setParameters}
            requestPage={getGenericRequestPageForAsyncTable(parametersApi)}
            reqListener={[]}
            options={{
              styles: {
                primaryColor: `${theme.colors.surface}`,
                secondaryColor: `${theme.colors.onSurface}`,
                alternateRowColor: theme.colors.textLight,
                textColor: theme.colors.text,
              },
              quantityPerPageLabel: t('quantityPerPage'),
              searchPlaceholder: t('search'),
              totalPageLabel: `${t('totalPages')}: `,
            }}
          />
        ) : (
          <NoData />
        )}
      </Loader>

      {showEditModal ? (
        <ChangeParameterValue
          showModal={showEditModal}
          closeModal={() => setShowEditModal(false)}
          reqGetParameters={reqGetParameters}
          parameterSelected={parameterSelected as Parameter}
        />
      ) : (
        <div />
      )}
    </ContentContainter>
  );
};

export default Parameters;
