import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInterface, ReactSortable } from 'react-sortablejs';

import Btn from '../../../../../components/Buttons/Btn';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SelectDate from '../../../../../components/SelectDate';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { audienceAPI, formAPI as mainCompAPI } from '../../../../../services/requests';
import { Form, FormQuestion } from '../../../../../types';
import {
  getOptionsFromAudiences,
  getSelectOptionsFromFormOpeningLocations,
} from '../../../../../utils/getSelectOptions';
import { currentDateMoreDays, getFrequencyOptions } from '../../../../../utils/helpers';
import ModalCreateAudience from '../../../Registrations/Audience/Components/ModalCreate';
import ModalCreateQuestion from '../ModalCreateQuestion';
import ModalEditQuestion from '../ModalEditQuestion';
import QuestionCardJSX from '../QuestionItemList';
import * as s from './styles';

type Props = {
  closeModal: () => void;
  reqCallback: () => void;
};

type Option = {
  value: any;
  label: string;
};

type Question = FormQuestion & {
  id: string;
};

const ModalCreate = ({ closeModal, reqCallback }: Props) => {
  const { t } = useTranslation();

  const [waitingRequest, setWaitingRequest] = useState(false);

  const [description, setDescription] = useState('');
  const [initialDate, setInitialDate] = useState(currentDateMoreDays(1));
  const [finalDate, setFinalDate] = useState(currentDateMoreDays(31));
  const [isRequired, setIsRequired] = useState({ value: true, label: t('yes') });
  const [frequency, setFrequency] = useState(getFrequencyOptions()[0]);
  const [openingLocal, setOpeningLocal] = useState(getSelectOptionsFromFormOpeningLocations()[0]);

  const [withAudience] = useState(true);
  const [audience, setAudience] = useState<Option | Record<string, unknown>>({});

  const [showModalCreateQuestion, setShowModalCreateQuestion] = useState(false);
  const [showModalEditQuestion, setShowModalEditQuestion] = useState(false);
  const [questionSelected, setQuestionSelected] = useState<Question | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);

  const [showModalNewAudience, setShowModalNewAudience] = useState(false);
  const [selectAudienceCache, setSelectAudienceCache] = useState(0);

  const fieldsAreOK = () => {
    if (description.length <= 0) {
      frontendNotification({ message: t('descriptionIsRequired'), type: 'warning' });
      return false;
    }

    if (questions.length <= 0) {
      frontendNotification({ message: t('atLeastOneFieldIsRequired'), type: 'warning' });
      return false;
    }

    if (new Date(initialDate) >= new Date(finalDate)) {
      frontendNotification({
        message: t('finalDateMustBeGreaterThanInitialDate'),
        type: 'warning',
      });
      return false;
    }

    if (withAudience) {
      if (audience.value === undefined || audience.value.length <= 0) {
        frontendNotification({ message: t('audienceIsRequired'), type: 'warning' });
        return false;
      }
    }

    return true;
  };

  const reqCreate = async () => {
    const source = mainCompAPI.axios.CancelToken.source();
    try {
      if (!fieldsAreOK()) {
        return () => null;
      }

      const questionsCopy: {
        question: string;
        type: string;
        erpReferenceKey: string | null;
        is_required: boolean;
        min_size: number | null;
        max_size: number | null;
        options: string[];
      }[] = [];

      questions.forEach((qItem) => {
        const newOptions: any = [];
        qItem.options.forEach((oItem) => {
          newOptions.push(oItem.id);
        });

        questionsCopy.push({
          question: qItem.question,
          type: qItem.type,
          erpReferenceKey: qItem.erpReferenceKey,
          is_required: qItem.is_required,
          min_size: qItem.min_size,
          max_size: qItem.max_size,
          options: newOptions,
        });
      });

      const data: Form | any = {
        description,
        initialDate,
        finalDate,
        isRequired: isRequired.value,
        frequency: frequency.value,
        openingLocal: openingLocal.value,
        questions: questionsCopy,
      };

      if (withAudience) {
        data.audience = audience.value;
      }

      setWaitingRequest(true);

      mainCompAPI.query = '';
      const res = await mainCompAPI.store(data);

      if (res.status === HttpStatus.OK) {
        setWaitingRequest(false);
        frontendNotification({
          message: t('successfullyCreated'),
          type: 'success',
        });

        closeModal();
        reqCallback();
      } else {
        throw res;
      }
    } catch (err) {
      if (!mainCompAPI.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setWaitingRequest(false);
    return () => {
      source.cancel('Component got unmounted');
    };
  };

  const handleSaveBtn = () => {
    if (!fieldsAreOK()) {
      return () => null;
    }

    if (new Date(initialDate) <= new Date()) {
      if (!window.confirm(t('msg.InitialDateAlert'))) {
        return null;
      }
    }

    reqCreate();
    return null;
  };

  const addNewQuestion = (
    question: string,
    answerType: { label: string; value: string | number },
    erpReferenceKey: string | null,
    isRequired: boolean,
    minSize: number | null,
    maxSize: number | null,
    options: ItemInterface[],
  ) => {
    if (questions.find((o) => o.question === question) === undefined) {
      const questionsCopy = [...questions];
      questionsCopy.push({
        id: question as never,
        question,
        erpReferenceKey,
        is_required: isRequired,
        min_size: minSize,
        max_size: maxSize,
        type: answerType.value as string,
        options,
      });
      setQuestions(questionsCopy);
      return true;
    }

    frontendNotification({ message: t('questionAlreadyExists'), type: 'warning' });
    return false;
  };

  const editQuestion = (
    question: string,
    answerType: { label: string; value: string | number },
    erpReferenceKey: string | null,
    isRequired: boolean,
    minSize: number | null,
    maxSize: number | null,
    options: ItemInterface[],
    index: number,
  ) => {
    if (questions.find((o, i) => o.question === question && i !== index) === undefined) {
      const questionsCopy = [...questions];
      questionsCopy[index] = {
        id: question as never,
        question,
        erpReferenceKey,
        is_required: isRequired,
        min_size: minSize,
        max_size: maxSize,
        type: answerType.value as string,
        options,
      };
      setQuestions(questionsCopy);
      return true;
    }
    frontendNotification({ message: t('questionAlreadyExists'), type: 'warning' });
    return false;
  };

  const handleRemoveQuestion = (index: number) => {
    const questionsCopy = [...questions];
    questionsCopy.splice(index, 1);
    setQuestions(questionsCopy);
  };

  const handleEditQuestion = (item: Question, i: number) => {
    setQuestionSelected(item);
    setSelectedIndex(i);
    setShowModalEditQuestion(true);
  };

  return (
    <Modal
      title={t('register')}
      showModal
      closeModal={closeModal}
      cancelButton={closeModal}
      action={() => handleSaveBtn()}
      isLoading={waitingRequest}
      bodyStyle={questions.length >= 5 ? { overflowX: 'scroll' } : {}}
    >
      <s.ContainerModalCreate>
        <s.Line className="first-line">
          <s.Item id="description">
            <p>{t('description')}</p>
            <Input
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              disabled={waitingRequest}
              style={{ minWidth: '100px' }}
              placeholder={t('description')}
              required=""
              isRequired
            />
          </s.Item>
          <s.Item>
            <p>{t('initialDate')}</p>
            <SelectDate value={initialDate} onChange={setInitialDate} disablePast />
          </s.Item>
          <s.Item>
            <p>{t('finalDate')}</p>
            <SelectDate value={finalDate} onChange={setFinalDate} />
          </s.Item>
          <s.Item id="isRequired">
            <p>{t('isRequired')}</p>
            <SingleSelect
              value={isRequired}
              options={[
                { value: true, label: t('yes') },
                { value: false, label: t('not') },
              ]}
              onChange={(e) => setIsRequired(e)}
              placeholder={t('yesOrNo')}
            />
          </s.Item>
        </s.Line>

        <s.Line className="first-line">
          <s.Item id="frequency">
            <p>{t('frequency')}</p>
            <SingleSelect
              value={frequency}
              onChange={(e: any) => setFrequency(e)}
              options={getFrequencyOptions()}
              placeholder={t('frequency')}
              isRequired
            />
          </s.Item>
          <s.Item id="placeOfExecution">
            <p>{t('placeOfExecution')}</p>
            <SingleSelect
              value={openingLocal}
              onChange={(e: any) => setOpeningLocal(e)}
              options={getSelectOptionsFromFormOpeningLocations()}
              placeholder={t('placeOfExecution')}
            />
          </s.Item>
          <s.Item id="audience">
            <s.WithCheckbox>{t('audience')}</s.WithCheckbox>
            <SelectAsyncJSX
              value={audience}
              onChange={(e: any) => setAudience(e)}
              request={audienceAPI}
              reqResponseToOption={getOptionsFromAudiences}
              disabled={!withAudience}
              placeholder={t('audience')}
              isRequired
              cacheUniqs={[selectAudienceCache]}
            />
          </s.Item>

          <s.Item id="createNewAudience">
            <Btn
              text={t('newAudience')}
              disabled={waitingRequest}
              onClick={() => setShowModalNewAudience(true)}
            />
          </s.Item>
        </s.Line>

        {questions.length <= 0 && <s.NoQuestion>{t('noQuestion')}</s.NoQuestion>}

        <ReactSortable list={questions} setList={setQuestions}>
          {questions.map((item, index) => (
            <div key={item.question}>
              <QuestionCardJSX
                question={item}
                removeQuestion={() => handleRemoveQuestion(index)}
                editQuestion={() => handleEditQuestion(item, index)}
              />
            </div>
          ))}
        </ReactSortable>

        <s.Line>
          <s.Item style={{ margin: 0 }}>
            <Btn text={t('addField')} onClick={() => setShowModalCreateQuestion(true)} />
          </s.Item>
        </s.Line>

        {showModalCreateQuestion && (
          <ModalCreateQuestion
            showModalCreateQuestion={showModalCreateQuestion}
            closeModal={() => setShowModalCreateQuestion(false)}
            saveActionBtn={addNewQuestion}
          />
        )}

        {showModalEditQuestion && questionSelected != null && (
          <ModalEditQuestion
            showModalEditQuestion={showModalEditQuestion && questionSelected != null}
            closeModal={() => setShowModalEditQuestion(false)}
            saveActionBtn={editQuestion}
            selectedQuestion={questionSelected as Question}
            selectedIndex={selectedIndex as number}
          />
        )}

        {showModalNewAudience && (
          <ModalCreateAudience
            closeModal={() => setShowModalNewAudience(false)}
            callback={() => setSelectAudienceCache(selectAudienceCache + 1)}
            availableTypes={['seller']}
          />
        )}
      </s.ContainerModalCreate>
    </Modal>
  );
};

export default ModalCreate;
