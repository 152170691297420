import { createActionTypesMap } from '../utils/helpers';

export const superadmin = createActionTypesMap('/api/v1/superadmin', [
  '/tenants',
  '/integration/models',
  '/system/logs',
  '/paginated-users',
  '/tenant/run-migrations',
  '/tenant/run-seeds',
  '/tenant/create-users-from-sellers',
  '/job-histories',
  '/tenant-roles',
  '/smartsell/app-versions',
  '/tenant/access-log',
  '/access-control',
  '/access-control-metrics',
  '/access-control-tenants-metrics',
]);

export const auth = createActionTypesMap('/api/v1/auth', [
  '/login',
  '/superadmin/login',
  '/logout',
  '/refresh',
  '/user',
  '/send-email-to-reset-password',
  '/reset-password',
]);

export const common = createActionTypesMap('/api/v1/common', [
  '/visit-schedule',
  '/seller-visit-schedule',
  '/visit-by-seller',
  '/motive-visit',
  '/forms',
  '/audience',
  '/sandbox/test-code',
  '/goals',
  '/form-question',
  '/visit-history',
  '/marketing-research',
  '/marketing-research-returns',
  '/branch',
  '/tenant-manager',
  '/tenant-supervisor',
]);

// export const users = createActionTypesMap('/api/v1/system', ['/users']);
export const system = createActionTypesMap('/api/v1/system', [
  '/roles',
  '/users',
  '/permissions',
  '/tenants',
  '/tasks',
  '/tasks/done',
  '/smartbanners',
  '/parameters',
  '/sellers-parameters',
  '/new-seller-parameter-by-reference',
  '/motives',
  '/actions',
  '/motive-origin-action',
  '/link-motive-action-origin',
  '/motive-origin-action-delete',
  '/communication-group',
  '/communication-automation',
  '/edi-layout',
  '/edi-layout-parameters-required',
  '/edi-customers',
  '/edi-add-customers',
  '/edi-remove-customers',
  '/supervision-of-sellers/get-paginated',
  '/supervision-of-sellers/add-sellers',
  '/supervision-of-sellers/remove-sellers',
  '/smartsell/sync/lock',
  '/smartsell/app-version/link-audience',
  '/smartsell/app-version/versions',
  '/edi-payment-condition/upsert',
  '/edi-payment-condition/edit',
  '/edi-payment-condition/delete-many',
  '/edi-payment-condition/paginated',
]);

export const logs = createActionTypesMap('/api/v1/system/logs', [
  '/communication',
  '/order-change',
]);

export const integration = createActionTypesMap('/api/v1/integration', [
  '/log',
  '/config',
  '/models',
]);

export const customers = createActionTypesMap('/api/v1/customers', [
  '',
  '/',
  '/export',
  '/import',
  '/mix/grouped',
  '/mix/update-or-insert',
  '/mix/remove',
]);

export const customersPosition = createActionTypesMap('/api/v1/customers-position', ['']);

export const customerBills = createActionTypesMap('/api/v1/customers/bills', [
  '/export',
  '/import',
]);

export const customerLimit = createActionTypesMap('/api/v1/customers/limits', [
  '/export',
  '/import',
]);

export const customerChannel = createActionTypesMap('/api/v1/customers/channels', [
  '/export',
  '/import',
]);

export const orderOrigin = createActionTypesMap('/api/v1/order-origins', ['', '/']);

export const ordersTypes = createActionTypesMap('/api/v1/orders/types', ['', '/']);

export const dashboard = createActionTypesMap('/api/v1/dashboard', [
  '/etl/sales-consolidated',
  '/etl/sellers-ranking',
  '/etl/products-orders',
  '/etl/customers-orders',
  '/etl/goals',
  '/settings',
  '/log',
  '/marketing-research-metrics',
  '/visit-metrics',
]);

export const sellers = createActionTypesMap('/api/v1/sellers', ['', '/', '/goals-many']);

export const templates = createActionTypesMap('/api/v1', [
  '/templates',
  '/template-parameters',
  '/template-parameters/paginated',
  '/template-parameters/ediPaginated',
  '/template-parameters/get-by-name',
]);

export const email = createActionTypesMap('/api/v1/email', [
  '/send/communication-automation',
  '/logs',
  '/smtp/config',
  '/send',
  '/tenant-resend',
]);

export const reports = createActionTypesMap('/api/v1/reports', [
  '/jasper/bases',
  '/jasper/get-jrxml-file',
]);

export const advantageClub = createActionTypesMap('/api/v1/advantage-club', ['']);
export const advantageClubHistory = createActionTypesMap('/api/v1/advantage-club-history', ['']);
export const advantageClubBalance = createActionTypesMap('/api/v1/advantage-club-balance', ['']);
export const advantageClubWithdrawManual = createActionTypesMap(
  '/api/v1/advantage-club-withdraw-manual',
  [''],
);
export const clubPointValueInBrCurrency = createActionTypesMap(
  '/api/v1/club-point-Value-in-br-currency',
  [''],
);

export const product = createActionTypesMap('/api/v1/products', ['/']);

export const tenantRoles = createActionTypesMap('/api/v1/superadmin/tenant-roles', ['']);

export const tenantCustomLibs = createActionTypesMap('/api/v1/tenant-custom-libs', [
  '/libs',
  '/versions',
  '/last-version-by-seller',
]);

export const importer = createActionTypesMap('/api/v1/importer', ['', '/logs']);

export const exporter = createActionTypesMap('/api/v1/exporter', [
  '',
  '/logs',
  '/get-available-tables',
]);

export const orders = createActionTypesMap('/api/v1/orders', [
  '',
  '/payment-condition/api',
  '/payment-method/api',
  '/billing-units',
  '/types',
  '/fractional-items',
]);
