import styled from 'styled-components';

export const OrdersContainer = styled.div`
  .order-navigation-bar-area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    padding-right: 1rem;
    margin-top: 1rem;

    .order-navigation-page-buttons {
      display: flex;
    }

    .order-navigation-quantity-area {
      display: flex;
    }

    .order-navigation-labels {
      display: flex;
      align-items: center;
      margin: 0;
      margin-right: 0.5rem;
    }

    .order-navigation-item,
    .order-navigation-item-input {
      background: ${(p) => p.theme.colors.surface};
      color: ${(p) => p.theme.colors.onSurface};
      border: 1px solid ${(p) => p.theme.colors.onSurface};
      border-radius: 3px;
      cursor: pointer;
      transition: 0.3s;
      width: 2rem;

      margin-right: 0.3rem;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      text-align: center;
      height: 1.5rem;
      padding: 1px;
    }

    .order-navigation-item-input {
      width: 6rem;
      cursor: text;
    }
  }

  .order-config-sortable-item {
    display: flex;
    justify-content: space-between;

    .order-config-sortable-actions {
      display: flex;

      .order-config-sortable-actions-item {
        margin-left: 0.5rem;

        cursor: pointer;
        transition: 0.3s;

        :hover {
          filter: brightness(120%);
          transform: scale(1.1);
        }
      }
    }
  }
`;

export const OrdersTableContainer = styled.div`
  &.orders-tables-container {
    display: flex;
    width: 100%;
  }

  .orders-table-container {
    flex: 1;
    position: relative;
    max-width: max-content;
    display: flex;
    margin-right: 10px;
    overflow-x: hidden;
  }

  .orders-table-columns-area {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 1.2rem;

    z-index: 1;
  }

  .orders-table-column {
    /* max-width: 50rem; */
    flex: 0 0 auto;
    margin-left: 0.5rem;

    &.tb-column-effect {
      width: 100%;
      max-width: 100%;
    }
  }

  .orders-table-columns-visibility-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    background-color: ${(p) => p.theme.colors.onBackground}20;
    color: ${(p) => p.theme.colors.onBackground};
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin-top: 4rem;
    cursor: pointer;

    border: 1px solid ${(p) => p.theme.colors.onBackground}50;
    border-top: none;

    transition: 0.2s;

    :hover {
      filter: brightness(120%);
    }

    :active {
      filter: brightness(80%);
    }
  }

  .orders-table-sub-columns-area {
    display: flex;
  }

  .orders-table-column-header {
    text-align: center;
    padding: 0.5rem;
    font-weight: bold;
  }

  .orders-table-sub-colmun-header {
    padding: 0.5rem;
    padding-left: 0;
    font-weight: bold;
  }

  .orders-table-sub-colmun {
    padding-right: 0.5rem;

    :hover {
      background-color: ${(p) => p.theme.colors.onSurface}10;
    }

    &.tb-sub-column-effect {
      width: 100%;
    }
  }

  .orders-table-row {
    display: flex;
    align-items: center;

    min-height: 4rem;
    max-height: 4rem;
    max-width: 30rem;
    white-space: nowrap;
    overflow: auto;

    &.odd-row {
      background-color: ${(p) => p.theme.colors.onSurface}20;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .order-table-header-division {
    position: absolute;
    z-index: 0;
    width: 100%;
    border-top: 1px solid rgb(206, 206, 206);
    top: 4.1rem;
  }

  .orders-table-row-value {
  }

  .orders-table-actions-row {
    display: flex;
  }

  .orders-table-rows-effect {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;

    overflow: scroll;
    overflow-y: hidden;

    z-index: 1;
  }
`;
