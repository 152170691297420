import { t } from 'i18next';
import { useMemo } from 'react';
import { Column } from 'react-table';
import FormartNumberJSX from '../../../../../../../../components/FormartNumberJSX';
import TablePaginator from '../../../../../../../../components/TablePaginator';
import { TopCustomer } from '../../types';

type Props = {
  topCustomers: TopCustomer[];
};

type TopCustomerColumn = TopCustomer & {
  position: number;
};

export const TopCustomerTable = ({ topCustomers }: Props) => {
  const topCustomerWithPosition = () =>
    topCustomers.map((topCustomer, index) => ({
      position: index + 1,
      customer: topCustomer.customer,
      amount: topCustomer.amount,
    }));

  const columns: Column<TopCustomerColumn>[] = useMemo(
    () => [
      {
        Header: t('position') as string,
        accessor: 'position',
        style: { paddingLeft: '15px' },
      },
      {
        Header: t('name') as string,
        accessor: 'customer',
      },
      {
        Header: t('amount') as string,
        accessor: 'amount',
        Cell: (v) => FormartNumberJSX({ number: v.row.original.amount, type: 'currencyBR' }),
      },
    ],
    [],
  );

  const data = useMemo(() => topCustomerWithPosition(), [topCustomers]);

  return (
    <TablePaginator
      data={data}
      columns={columns}
      size={5}
      showButtons={false}
      showMoreRows={false}
    />
  );
};

export default TopCustomerTable;
