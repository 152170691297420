import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import ContentContainer from '../../../../templates/Content';
import Loader from './Loader';

import { backendErrorNotification } from '../../../../components/Notification';
import TablePaginator from '../../../../components/TablePaginator';
import {
  ActionButtonsContainer,
  DeleteIconTable,
  EditIconTable,
} from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import i18n from '../../../../i18n';
import { commAutoReq } from '../../../../services/requests';
import * as TP from '../../../../types/apiResponseTypes';

import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../../components/NoData';
import ModalCreate from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';

const CommunicationAutomationJSX = () => {
  const { t } = useTranslation();

  const [waitingGetCommGroups, setWaitingGetCommGroups] = useState(false);
  const [commGroup, setCommGroup] = useState<TP.CommAutomation[] | []>([]);
  const [commGroupSelected, setCommGroupSelected] = useState<TP.CommAutomation | null>(null);

  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const reqGetCommAuto = useCallback(async () => {
    setWaitingGetCommGroups(true);

    const source = commAutoReq.axios.CancelToken.source();

    try {
      const res = await commAutoReq.index(source.token);

      if (res.status === HttpStatus.OK) {
        const {
          data,
        }: {
          data: TP.CommAutomation[] | [];
        } = res.data;
        if (data.length >= 1) {
          setCommGroup(data);
        }
      } else {
        setCommGroup([]);
        throw res;
      }

      setWaitingGetCommGroups(false);
    } catch (err) {
      if (!commAutoReq.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<never, never>);
      }
      setWaitingGetCommGroups(false);
    }

    return () => {
      source.cancel('Component Integrations Logs got unmounted');
    };
  }, []);

  useEffect(() => {
    reqGetCommAuto();
  }, []);

  const handleTableEditBtn = (row: Row<TP.CommAutomation>) => {
    setCommGroupSelected(row.original);
    setShowModalEdit(true);
  };
  const handleTableDeleteBtn = (row: Row<TP.CommAutomation>) => {
    setCommGroupSelected(row.original);
    setShowModalDelete(true);
  };

  const actionsButtons = (row: Row<TP.CommAutomation>) => (
    <ActionButtonsContainer>
      <EditIconTable onClick={() => handleTableEditBtn(row)} />
      <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
    </ActionButtonsContainer>
  );

  const columns: Column<TP.CommAutomation>[] = useMemo(
    () => [
      {
        Header: t('id') as string,
        accessor: 'id',
      },
      {
        Header: t('description') as string,
        accessor: 'description',
      },
      {
        Header: t('action') as string,
        Cell: ({ row }: any) =>
          row.original.action ? row.original.action.label : row.original.action_id,
      },
      {
        Header: t('origin') as string,
        Cell: ({ row }: any) =>
          row.original.order_origin
            ? row.original.order_origin.label
            : row.original.order_origin_id,
      },
      {
        Header: t('audience') as string,
        Cell: ({ row }: any) =>
          row.original.communication_group
            ? row.original.communication_group.description
            : row.original.communication_group_id,
      },
      {
        Header: t('template') as string,
        Cell: ({ row }: any) =>
          row.original.template ? row.original.template.description : row.original.template_id,
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [i18n.language],
  );

  return (
    <ContentContainer title={t('automation')}>
      <NewRegisterBtn
        onClick={() => setShowModalCreate(true)}
        disabled={waitingGetCommGroups}
        style={{
          marginLeft: '0',
          marginBottom: '1rem',
        }}
      />
      {showModalCreate && (
        <ModalCreate
          closeModal={() => setShowModalCreate(false)}
          reqGetCommAuto={() => reqGetCommAuto()}
        />
      )}
      {showModalDelete && (
        <ModalDelete
          commAutoSelected={commGroupSelected as TP.CommAutomation}
          closeModal={() => setShowModalDelete(false)}
          reqGetCommAuto={() => reqGetCommAuto()}
        />
      )}
      {showModalEdit && (
        <ModalEdit
          commAutoSelected={commGroupSelected as TP.CommAutomation}
          closeModal={() => setShowModalEdit(false)}
          reqGetCommAuto={() => reqGetCommAuto()}
        />
      )}
      <Loader isLoading={waitingGetCommGroups}>
        {commGroup.length >= 1 ? (
          <TablePaginator data={commGroup} columns={columns} globalFiltering />
        ) : (
          <NoData />
        )}
      </Loader>
    </ContentContainer>
  );
};

export default CommunicationAutomationJSX;
