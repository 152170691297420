import AnimatedLoadingIcon from '../../components/LoadingOverlay/AnimatedLoadingIcon';

const ContentLoad = () => (
  <div
    style={{
      height: '30vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
    }}
  >
    <div
      style={{
        marginBottom: '1rem',
      }}
    >
      <AnimatedLoadingIcon />
    </div>
  </div>
);

export default ContentLoad;
