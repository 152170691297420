import { isJson } from '../../utils/helpers';

type props = {
  json: JSON;
};
const ViewPrettyJson = ({ json }: props) => {
  if (isJson(json)) {
    return <pre style={{ overflow: 'scroll' }}>{json as never}</pre>;
  }

  return <p>{JSON.stringify(json)}</p>;
};

export default ViewPrettyJson;
