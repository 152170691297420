import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persitor, store } from './store';

import App from './components/App';
import Loading from './components/LoadingOverlay';
import './i18n';

// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading loading />}>
      <PersistGate loading={null} persistor={persitor}>
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(consosle.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
