import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import NoData from '../../../../../components/NoData';
import { backendErrorNotification } from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import LocalStorage from '../../../../../enums/localStorage';
import usePersistedState from '../../../../../hooks/usePersistedState';
import { FilterIcon, SettingIcon } from '../../../../../icons';
import { ordersApi } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { Order, OrdersFiltersInFrontend } from '../../../../../types/apiResponse/order';
import OrdersTableActionsColumn from './Components/OrdersTableActionsColumn';
import RenderOrderTableData from './Components/RenderOrderTableData';
import {
  ColumnConfig,
  defaultColumnsConfig,
  defaultOrderFilters,
} from './constants/defaultColumnsConfig';
import OrderFiltersModal from './Modals/OrderFiltersModal';
import OrdersColumnsConfig from './Modals/OrdersColumnsConfig';
import * as s from './styles';

const OrdersHomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const [orders, setOrders] = useState<Order[]>([]);
  const [storedColumnsConfig, setStoredColumnsConfig] = usePersistedState<ColumnConfig[]>(
    LocalStorage.ORDER_COLUMN_CONFIG,
    defaultColumnsConfig,
  );
  const [orderFilters, setOrderFilters] = usePersistedState<OrdersFiltersInFrontend>(
    LocalStorage.ORDER_FILTERS,
    defaultOrderFilters,
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [quantityPerPage, setQuantityPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [currentPageNumberInput, setCurrentPageNumberInput] = useState(1);
  const [quantityPerPageInput, setQuantityPerPageInput] = useState(10);

  const [showTableConfig, setShowTableConfig] = useState(false);
  const [showOrderFilters, setShowOrderFilters] = useState(false);

  const getOrderQueryByOrderFilters = (): string => {
    if (orderFilters.filterType === 'simple' && orderFilters.orderNumber) {
      return `&orderNumber=${orderFilters.orderNumber}`;
    }

    let query = '';

    if (orderFilters.orderStatus) {
      query += `&orderStatus=${orderFilters.orderStatus}`;
    }

    query += `&isBudget=${orderFilters.isBudget}`;

    if (orderFilters.orderTypeCode) {
      query += `&orderTypeCode=${orderFilters.orderTypeCode}`;
    }

    if (orderFilters.orderOriginCode && orderFilters.orderOriginCode !== 'all') {
      query += `&orderOriginCode=${orderFilters.orderOriginCode}`;
    }

    if (orderFilters.tenantManagerCode) {
      query += `&tenantManagerCode=${orderFilters.tenantManagerCode}`;
    }

    if (orderFilters.tenantSupervisorCode) {
      query += `&tenantSupervisorCode=${orderFilters.tenantSupervisorCode}`;
    }

    if (orderFilters.sellerCode) {
      query += `&sellerCode=${orderFilters.sellerCode}`;
    }

    if (orderFilters.customerCode) {
      query += `&customerCode=${orderFilters.customerCode}`;
    }

    if (orderFilters.billingUnitCode) {
      query += `&billingUnitCode=${orderFilters.billingUnitCode}`;
    }

    if (orderFilters.freightType && orderFilters.freightType != 'all') {
      query += `&freightType=${orderFilters.freightType}`;
    }

    if (orderFilters.exportedErp && orderFilters.exportedErp != 'all') {
      query += `&exportedErp=${orderFilters.exportedErp}`;
    }

    if (orderFilters.initialOrderCreationDate) {
      query += `&initialOrderCreationDate=${orderFilters.initialOrderCreationDate}`;
    }
    if (orderFilters.finalOrderCreationDate) {
      query += `&finalOrderCreationDate=${orderFilters.finalOrderCreationDate}`;
    }

    if (orderFilters.initialOrderSendDate) {
      query += `&initialOrderSendDate=${orderFilters.initialOrderSendDate}`;
    }
    if (orderFilters.finalOrderSendDate) {
      query += `&finalOrderSendDate=${orderFilters.finalOrderSendDate}`;
    }

    if (orderFilters.initialOrderDeliveryDate) {
      query += `&initialOrderDeliveryDate=${orderFilters.initialOrderDeliveryDate}`;
    }
    if (orderFilters.finalOrderDeliveryDate) {
      query += `&finalOrderDeliveryDate=${orderFilters.finalOrderDeliveryDate}`;
    }

    if (orderFilters.initialOrderBillingDate) {
      query += `&initialOrderBillingDate=${orderFilters.initialOrderBillingDate}`;
    }
    if (orderFilters.finalOrderBillingDate) {
      query += `&finalOrderBillingDate=${orderFilters.finalOrderBillingDate}`;
    }

    return query;
  };

  const [query, setQuery] = useState(getOrderQueryByOrderFilters());

  const getOrders = async () => {
    try {
      ordersApi.query = `?page=${currentPageNumber}&quantityPerPage=${quantityPerPage}${query}`;

      const response = await ordersApi.index();

      if (response.status === HttpStatus.OK) {
        setOrders(response.data.data);
        setTotalPages(response.data.paginated.totalPage);
      } else {
        throw response;
      }
    } catch (erro) {
      backendErrorNotification(erro as any);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    await getOrders();
    setIsLoading(false);
  };

  const handlePageInputValue = (text: string) => {
    let numericValue = text.replace(/[^0-9]/g, '') as unknown as number;

    if (numericValue > totalPages) {
      numericValue = totalPages;
    }

    setCurrentPageNumberInput(numericValue);
  };

  const handleQuantityInputValue = (text: string) => {
    let numericValue = text.replace(/[^0-9]/g, '') as unknown as number;

    if (numericValue > 50) {
      numericValue = 50;
    }
    setQuantityPerPageInput(numericValue);
  };

  const handleNavigationInputKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (currentPageNumber <= 0) {
        setCurrentPageNumber(1);
        setCurrentPageNumberInput(1);
      }

      if (quantityPerPage <= 0) {
        setQuantityPerPage(10);
        setQuantityPerPageInput(10);
      }

      setCurrentPageNumber(currentPageNumberInput);
      setQuantityPerPage(quantityPerPageInput);
    }
  };

  useEffect(() => {
    setQuery(getOrderQueryByOrderFilters());
  }, [orderFilters]);

  useEffect(() => {
    loadData();
    setCurrentPageNumberInput(currentPageNumber);
    setQuantityPerPageInput(quantityPerPage);
  }, [currentPageNumber, quantityPerPage, query]);

  return (
    <>
      <ContentContainter
        title={t('orders')}
        isLoading={isLoading}
        actions={[
          <SettingIcon
            title={t('tableConfig')}
            color={theme.colors.onPrimary}
            onClick={() => setShowTableConfig(!showTableConfig)}
          />,
          <FilterIcon
            title={t('toFilter')}
            color={theme.colors.onPrimary}
            onClick={() => setShowOrderFilters(true)}
          />,
        ]}
      >
        <s.OrdersContainer>
          {orders.length >= 1 ? (
            <>
              <s.OrdersTableContainer className="orders-tables-container">
                <OrdersTableActionsColumn orders={orders} reloadOrders={loadData} />
                <RenderOrderTableData
                  orders={orders}
                  columnsConfig={storedColumnsConfig}
                  onlyFixed
                />
                <RenderOrderTableData orders={orders} columnsConfig={storedColumnsConfig} />
              </s.OrdersTableContainer>

              <div className="order-navigation-bar-area">
                <div className="order-navigation-page-buttons">
                  <div
                    className={`
                      order-navigation-item ${currentPageNumber <= 1 ? 'disabled' : ''}
                    `}
                    onClick={() => {
                      setCurrentPageNumber(1);
                    }}
                  >
                    {'<<'}
                  </div>
                  <div
                    className={`
                      order-navigation-item ${currentPageNumber <= 1 ? 'disabled' : ''}
                    `}
                    onClick={() => {
                      let newPage = currentPageNumber - 1;
                      if (newPage <= 0) {
                        newPage = 1;
                      }
                      setCurrentPageNumber(newPage);
                    }}
                  >
                    {'<'}
                  </div>
                  <div>
                    <input
                      type="text"
                      value={currentPageNumberInput}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handlePageInputValue(e.currentTarget.value)
                      }
                      onKeyDown={handleNavigationInputKeys}
                      className="order-navigation-item-input"
                    />
                  </div>
                  <div
                    className={`
                      order-navigation-item ${currentPageNumber >= totalPages ? 'disabled' : ''}
                    `}
                    onClick={() => {
                      let newPage = currentPageNumber + 1;
                      if (newPage >= totalPages) {
                        newPage = totalPages;
                      }
                      setCurrentPageNumber(newPage);
                    }}
                  >
                    {'>'}
                  </div>
                  <div
                    className={`
                      order-navigation-item ${currentPageNumber >= totalPages ? 'disabled' : ''}
                    `}
                    onClick={() => {
                      setCurrentPageNumber(totalPages);
                    }}
                  >
                    {'>>'}
                  </div>
                  <p className="order-navigation-labels">
                    {t('totalPages') as string}: {totalPages}
                  </p>
                </div>

                <div className="order-navigation-quantity-area">
                  <p className="order-navigation-labels">{t('quantityPerPage') as string}:</p>
                  <input
                    type="text"
                    value={quantityPerPageInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleQuantityInputValue(e.currentTarget.value)
                    }
                    onKeyDown={handleNavigationInputKeys}
                    className="order-navigation-item-input"
                  />
                </div>
              </div>
            </>
          ) : (
            <NoData />
          )}

          <OrdersColumnsConfig
            closeModal={() => setShowTableConfig(false)}
            visible={showTableConfig}
            columnsConfig={storedColumnsConfig}
            setColumnsConfig={(value) => setStoredColumnsConfig(value)}
          />
        </s.OrdersContainer>
      </ContentContainter>

      {showOrderFilters && (
        <OrderFiltersModal
          closeModal={() => setShowOrderFilters(false)}
          setStoredOrderFilters={(value) => {
            setOrderFilters(value);
            setShowOrderFilters(false);
          }}
          isLoading={false}
        />
      )}
    </>
  );
};

export default OrdersHomePage;
