import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';
import Btn from '../../../../../components/Buttons/Btn';

import Modal from '../../../../../components/Modal';
import {
  backendErrorNotification,
  frontendNotification,
} from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import TablePaginator from '../../../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../enums/httpStatus';
import { DeleteIcon, EditIcon } from '../../../../../icons';
import { ediLayoutApi, getParametersRequiredToEdi } from '../../../../../services/requests';
import {
  EdiLayout,
  EdiLayoutField,
  EdiLayoutPersisted,
} from '../../../../../types/apiResponse/edi';
import { TemplateParameter } from '../../../../../types/apiResponse/template';
import { SelectOption } from '../../../../../utils/getSelectOptions';
import ModalCreateOrEditField from '../ModalCreateOrEditField';
import ModalShowParametersRequired from '../ModalShowParametersRequired';

type Props = {
  onClose: () => void;
  selectedItem: EdiLayoutPersisted | null;
  callback: () => void;
};

type KeysToExclude = 'edi_layout_id' | 'created_at' | 'updated_at';
type Field = Pick<EdiLayoutField, Exclude<keyof EdiLayoutField, KeysToExclude>>;

const FieldDefault: Field = {
  parameter: '',
  dbTable: '',
  dbColumn: '',
  start_field: 0,
  size_field: 0,
  separatorPosition: 0,
  type: 'text',
  format_type: '',
  qtd_decimal: 0,
  record: '',
  isItem: false,
  fixed_value: null,
  formQuestionCode: null,
  define_order_grouping: false,
};

const ModalCreateOrEdit = ({ selectedItem, onClose, callback }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ediLayout, setEdiLayout] = useState<EdiLayout>({
    description: '',
    folder_edi_name: '',
    source_edi_folder_client_machine: '',
    source_edi_folder_bkp_client_machine: '',
    type: 'separator',
    is_csv: false,
    separator: '',
    obs: '',
    fields: [],
  });
  const [parametersRequired, setParametersRequired] = useState<TemplateParameter[]>([]);
  const [showParametersRequired, setShowParameterRequired] = useState(false);

  const [showModalField, setShowModalField] = useState(false);
  const [fieldToModal, setFieldToModal] = useState<Field>(FieldDefault);

  const getParametersRequired = async () => {
    setIsLoading(true);

    try {
      getParametersRequiredToEdi.query = `?layoutType=${ediLayout.type}`;
      const res = await getParametersRequiredToEdi.get();

      if (res.status === HttpStatus.OK) {
        setParametersRequired(res.data.data);
      } else {
        throw res;
      }
    } catch (err) {
      if (!getParametersRequiredToEdi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  };

  const validations = (newField: Field): void => {
    if (!newField.parameter) {
      throw new Error(t('parameterIsMissing'));
    }

    if (newField.type === 'date' && (!newField.format_type || newField.format_type.length <= 0)) {
      throw new Error(t('specificTheFormatType'));
    }

    if (ediLayout.type === 'position') {
      if (newField.size_field === undefined || newField.size_field <= 0) {
        throw new Error(t('invalidFieldSize'));
      }

      if (newField.record === undefined || newField.record.length <= 0) {
        throw new Error(t('recordIsRequired'));
      }
    }
  };

  const handleAddOrSaveField = (newField: Field) => {
    try {
      validations(newField);

      const copyEdiLayout = { ...ediLayout };
      const newFieldOrFieldEditedValues = {
        ...newField,
        edi_layout_id: selectedItem ? selectedItem.id : 0,
      };

      const index = copyEdiLayout.fields.findIndex(
        (field) => field.parameter === newField.parameter,
      );

      if (index !== -1) {
        // Se o campo já existe, substitui pelos dados do newField
        copyEdiLayout.fields[index] = newFieldOrFieldEditedValues;
      } else {
        // Se o campo não existe, adiciona o novo field
        copyEdiLayout.fields.push(newFieldOrFieldEditedValues);
      }

      setShowModalField(false);
      setEdiLayout(copyEdiLayout);
    } catch (err: unknown) {
      if (err instanceof Error) {
        frontendNotification({
          message: err.message,
          type: 'warning',
        });
      }
    }
  };

  useEffect(() => {
    getParametersRequired();
  }, [ediLayout.type]);

  useEffect(() => {
    if (selectedItem) {
      setEdiLayout(selectedItem);
    }
  }, [selectedItem]);

  const handleSaveBtn = useCallback(async () => {
    setIsLoading(true);

    try {
      ediLayoutApi.query = '';
      const res = selectedItem
        ? await ediLayoutApi.update(ediLayout, selectedItem.id)
        : await ediLayoutApi.store(ediLayout);

      if (res.status === HttpStatus.OK) {
        frontendNotification({
          message: t('successfullySaved'),
          type: 'success',
        });

        callback();
        onClose();
      } else {
        throw res;
      }
    } catch (err) {
      if (!ediLayoutApi.axios.isCancel(err)) {
        backendErrorNotification(err as AxiosError<any, any>);
      }
    }

    setIsLoading(false);
  }, [selectedItem, onClose, callback, ediLayout]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEdiLayout({ ...ediLayout, [name]: value });
  };

  const handleSelectInputChange = (option: SelectOption, key: string) => {
    setEdiLayout({ ...ediLayout, [key]: option.value });
  };

  const handleValueEdiTypeInput = (value: string) =>
    value === 'separator'
      ? { label: t('separator'), value: 'separator' }
      : { label: t('position'), value: 'position' };

  const handleEditField = (fieldToEdit: Field) => {
    setFieldToModal(fieldToEdit);
    setShowModalField(true);
  };

  const checkParameter = (parameter: TemplateParameter) => {
    return ediLayout.fields.some((field) => field.parameter === parameter.name);
  };

  const findMissingParameter = () => {
    for (const parameter of parametersRequired) {
      if (!checkParameter(parameter)) {
        return parameter;
      }
    }
    return null;
  };

  const handleAddNewField = () => {
    const missingParameter = findMissingParameter();
    const copyDefaultParameter = { ...FieldDefault };

    if (missingParameter) {
      copyDefaultParameter.dbColumn = missingParameter.dbColumn;
      copyDefaultParameter.dbTable = missingParameter.dbTable;
      copyDefaultParameter.parameter = `${missingParameter.name} - ${missingParameter.label}`;
    }

    setFieldToModal(copyDefaultParameter);
    setShowModalField(true);
  };

  const actionsButtons = (row: Row<EdiLayoutField>) => (
    <ActionButtonsContainer>
      <DeleteIcon
        title={t('delete')}
        onClick={() => {
          const copyLayout = { ...ediLayout };
          copyLayout.fields.splice(row.index, 1);
          setEdiLayout(copyLayout);
        }}
      />
      <EditIcon title={t('edit')} onClick={() => handleEditField(row.original)} />
    </ActionButtonsContainer>
  );

  const isItemColumn = (row: Row<EdiLayoutField>) => (
    <p>{row.original.isItem ? (t('yes') as string) : (t('no') as string)}</p>
  );

  const columns: Column<EdiLayoutField>[] = useMemo(
    () => [
      {
        Header: t('parameter') as string,
        accessor: 'parameter',
      },
      {
        Header: t('type') as string,
        accessor: 'type',
      },
      {
        Header: t('formatType') as string,
        accessor: 'format_type',
      },
      {
        Header: t('isItem') as string,
        accessor: 'isItem',
        Cell: ({ row }: any) => isItemColumn(row),
      },
      {
        id: 'actionsbtn',
        Cell: ({ row }: any) => actionsButtons(row),
      },
    ],
    [ediLayout],
  );

  return (
    <Modal
      title={selectedItem ? t('update') : t('new')}
      action={handleSaveBtn}
      showModal
      closeModal={onClose}
      cancelButton={onClose}
      actionNameBtn={selectedItem ? t('save') : t('register')}
      isLoading={isLoading}
      bodyStyle={{ overflowX: 'scroll', width: '100%' }}
      style={{ minWidth: '80%' }}
    >
      <form onSubmit={handleSaveBtn}>
        <div className="row">
          <div className="itemFormWithLabel">
            <label htmlFor="description">{t('description') as string}</label>
            <input
              type="text"
              id="description"
              name="description"
              // className='input'
              value={ediLayout.description}
              onChange={handleInputChange}
            />
          </div>

          <div className="itemFormWithLabel" style={{ maxWidth: '230px' }}>
            <label htmlFor="type">{t('type') as string}</label>
            <SingleSelect
              id="type"
              name="type"
              value={handleValueEdiTypeInput(ediLayout.type)}
              options={[
                { label: t('separator'), value: 'separator' },
                { label: t('position'), value: 'position' },
              ]}
              onChange={(e: any) => handleSelectInputChange(e, 'type')}
              disabled={isLoading || ediLayout.fields.length >= 1}
            />
          </div>

          <div className="itemFormWithLabel" style={{ maxWidth: '8rem' }}>
            <label htmlFor="startLine">{t('theFileIsCsv') as string}</label>
            <SingleSelect
              id="isCsv"
              name="isCsv"
              value={
                ediLayout.is_csv
                  ? { label: t('yes'), value: true }
                  : { label: t('not'), value: false }
              }
              options={[
                { label: t('yes'), value: true },
                { label: t('not'), value: false },
              ]}
              onChange={(e: any) => handleSelectInputChange(e, 'is_csv')}
              disabled={isLoading}
            />
          </div>

          <div
            className={`itemFormWithLabel ${ediLayout.type !== 'separator' ? 'disabled' : ''}`}
            style={{ maxWidth: '5.5rem' }}
          >
            <label htmlFor="separator">{t('separator') as string}</label>
            <input
              type="text"
              id="separator"
              name="separator"
              value={ediLayout.separator as string}
              onChange={handleInputChange}
              disabled={ediLayout.type !== 'separator'}
            />
          </div>
        </div>

        <div className="row">
          <div className="itemFormWithLabel">
            <label htmlFor="folder_edi_name">{t('folderEdiName') as string}</label>
            <input
              type="text"
              id="folder_edi_name"
              name="folder_edi_name"
              value={ediLayout.folder_edi_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="itemFormWithLabel">
            <label htmlFor="source_edi_folder_client_machine">
              {t('ediFolderPathOnTheServer') as string}
            </label>
            <input
              type="text"
              id="source_edi_folder_client_machine"
              name="source_edi_folder_client_machine"
              value={ediLayout.source_edi_folder_client_machine}
              onChange={handleInputChange}
            />
          </div>
          <div className="itemFormWithLabel">
            <label htmlFor="source_edi_folder_bkp_client_machine">
              {t('ediBackupFolderPathOnTheServer') as string}
            </label>
            <input
              type="text"
              id="source_edi_folder_bkp_client_machine"
              name="source_edi_folder_bkp_client_machine"
              value={ediLayout.source_edi_folder_bkp_client_machine}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="itemFormWithLabel">
            <label htmlFor="obs">{t('obs') as string}</label>
            <input
              type="text"
              id="obs"
              name="obs"
              value={ediLayout.obs}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="division" />

        <p className="labelSection">{t('fields') as string}</p>

        <div className="row">
          {showParametersRequired && (
            <ModalShowParametersRequired
              parametersRequired={parametersRequired}
              ediLayoutFields={ediLayout.fields}
              onClose={() => setShowParameterRequired(false)}
            />
          )}

          <div
            className="itemFormWithLabel"
            style={{ maxWidth: 'max-content', marginLeft: '0.7rem' }}
          >
            <Btn text={t('addField')} onClick={() => handleAddNewField()} />
          </div>

          <div className="itemFormWithLabel">
            <Btn
              type="text"
              text={t('showParametersRequired') as string}
              onClick={() => setShowParameterRequired(true)}
            />
          </div>
        </div>

        {showModalField && (
          <ModalCreateOrEditField
            onClose={() => setShowModalField(false)}
            ediLayout={ediLayout}
            initialField={fieldToModal}
            fieldParamDefault={null}
            addAction={handleAddOrSaveField}
          />
        )}

        <div className="row">
          <div className="itemFormWithLabel">
            <TablePaginator
              data={ediLayout.fields}
              columns={columns}
              size={10}
              showMoreRows={false}
              globalFiltering={false}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ModalCreateOrEdit;
