import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../../../components/Modal';

type Props = ModalProps & {
  dbInfos: {
    db: string;
    host: string;
    user: string;
    pass: string;
    port: string;
  };
};

export const ViewDBMOdal = ({ dbInfos, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal {...props}>
      <div className="row">
        <div className="inputWithLabel">
          <div className="label">{t('database')}</div>
          <input value={dbInfos.db} onChange={() => null} />
        </div>

        <div className="inputWithLabel">
          <div className="label">{t('host')}</div>
          <input value={dbInfos.host} onChange={() => null} />
        </div>

        <div className="inputWithLabel">
          <div className="label">{t('port')}</div>
          <input value={dbInfos.port} onChange={() => null} />
        </div>

        <div className="inputWithLabel">
          <div className="label">{t('user')}</div>
          <input value={dbInfos.user} onChange={() => null} />
        </div>
      </div>
    </Modal>
  );
};

export default ViewDBMOdal;
